import {hasKey} from '../../../../utils'

export const createOptions = config => {
  const values = config?.series
    ?.filter(s => hasKey(s, 'selected'))
    ?.map(s => ({
      format: s?.format,
      key: s?.id,
      title: s?.name,
      selected: s?.selected,
    }))
  const result = {
    title: config?.series_title,
    values,
  }
  return result
}

import {deepMerge} from '@aghealth/ag-components'
import variantsBase from '../theme/variants'

const texts = {
  'xxxs-regular': {
    fontFamily: 'primary',
    fontStyle: 'normal',
    fontSize: 'xxxs',
    fontWeight: 'regular',
    lineHeight: 'xxxs',
  },
  'xxxs-medium': {
    fontFamily: 'primary',
    fontStyle: 'normal',
    fontSize: 'xxxs',
    fontWeight: 'medium',
    lineHeight: 'xxxs',
  },
  'xxxs-semibold': {
    fontFamily: 'primary',
    fontStyle: 'normal',
    fontSize: 'xxxs',
    fontWeight: 'semibold',
    lineHeight: 'xxxs',
  },
  'xxxs-bold': {
    fontFamily: 'primary',
    fontStyle: 'normal',
    fontSize: 'xxxs',
    fontWeight: 'bold',
    lineHeight: 'xxxs',
  },
  'xxxs-light': {
    fontFamily: 'primary',
    fontStyle: 'normal',
    fontSize: 'xxxs',
    fontWeight: 'light',
    lineHeight: 'xxxs',
  },
  'xxs-regular': {
    fontFamily: 'primary',
    fontStyle: 'normal',
    fontSize: 'xxs',
    fontWeight: 'regular',
    lineHeight: 'xxs',
  },
  'xxs-medium': {
    fontFamily: 'primary',
    fontStyle: 'normal',
    fontSize: 'xxs',
    fontWeight: 'medium',
    lineHeight: 'xxs',
  },
  'xxs-semibold': {
    fontFamily: 'primary',
    fontStyle: 'normal',
    fontSize: 'xxs',
    fontWeight: 'semibold',
    lineHeight: 'xxs',
  },
  'xxs-bold': {
    fontFamily: 'primary',
    fontStyle: 'normal',
    fontSize: 'xxs',
    fontWeight: 'bold',
    lineHeight: 'xxs',
  },
  'xxs-light': {
    fontFamily: 'primary',
    fontStyle: 'normal',
    fontSize: 'xxs',
    fontWeight: 'light',
    lineHeight: 'xxs',
  },
  'xs-regular': {
    fontFamily: 'primary',
    fontStyle: 'normal',
    fontSize: 'xs',
    fontWeight: 'regular',
    lineHeight: 'xs',
  },
  'xs-medium': {
    fontFamily: 'primary',
    fontStyle: 'normal',
    fontSize: 'xs',
    fontWeight: 'medium',
    lineHeight: 'xs',
  },
  'xs-semibold': {
    fontFamily: 'primary',
    fontStyle: 'normal',
    fontSize: 'xs',
    fontWeight: 'semibold',
    lineHeight: 'xs',
  },
  'xs-bold': {
    fontFamily: 'primary',
    fontStyle: 'normal',
    fontSize: 'xs',
    fontWeight: 'bold',
    lineHeight: 'xs',
  },
  'xs-light': {
    fontFamily: 'primary',
    fontStyle: 'normal',
    fontSize: 'xs',
    fontWeight: 'light',
    lineHeight: 'xs',
  },
  'sm-regular': {
    fontFamily: 'primary',
    fontStyle: 'normal',
    fontSize: 'sm',
    fontWeight: 'regular',
    lineHeight: 'sm',
  },
  'sm-medium': {
    fontFamily: 'primary',
    fontStyle: 'normal',
    fontSize: 'sm',
    fontWeight: 'medium',
    lineHeight: 'sm',
  },
  'sm-semibold': {
    fontFamily: 'primary',
    fontStyle: 'normal',
    fontSize: 'sm',
    fontWeight: 'semibold',
    lineHeight: 'sm',
  },
  'sm-bold': {
    fontFamily: 'primary',
    fontStyle: 'normal',
    fontSize: 'sm',
    fontWeight: 'bold',
    lineHeight: 'sm',
  },
  'sm-light': {
    fontFamily: 'primary',
    fontStyle: 'normal',
    fontSize: 'sm',
    fontWeight: 'light',
    lineHeight: 'sm',
  },
  'md-regular': {
    fontFamily: 'primary',
    fontStyle: 'normal',
    fontSize: 'md',
    fontWeight: 'regular',
    lineHeight: 'md',
  },
  'md-medium': {
    fontFamily: 'primary',
    fontStyle: 'normal',
    fontSize: 'md',
    fontWeight: 'medium',
    lineHeight: 'md',
  },
  'md-semibold': {
    fontFamily: 'primary',
    fontStyle: 'normal',
    fontSize: 'md',
    fontWeight: 'semibold',
    lineHeight: 'md',
  },
  'md-bold': {
    fontFamily: 'primary',
    fontStyle: 'normal',
    fontSize: 'md',
    fontWeight: 'bold',
    lineHeight: 'md',
  },
  'md-light': {
    fontFamily: 'primary',
    fontStyle: 'normal',
    fontSize: 'md',
    fontWeight: 'light',
    lineHeight: 'md',
  },
  'lg-regular': {
    fontFamily: 'primary',
    fontStyle: 'normal',
    fontSize: 'lg',
    fontWeight: 'regular',
    lineHeight: 'lg',
  },
  'lg-medium': {
    fontFamily: 'primary',
    fontStyle: 'normal',
    fontSize: 'lg',
    fontWeight: 'medium',
    lineHeight: 'lg',
  },
  'lg-semibold': {
    fontFamily: 'primary',
    fontStyle: 'normal',
    fontSize: 'lg',
    fontWeight: 'semibold',
    lineHeight: 'lg',
  },
  'lg-bold': {
    fontFamily: 'primary',
    fontStyle: 'normal',
    fontSize: 'lg',
    fontWeight: 'bold',
    lineHeight: 'lg',
  },
  'lg-light': {
    fontFamily: 'primary',
    fontStyle: 'normal',
    fontSize: 'lg',
    fontWeight: 'light',
    lineHeight: 'lg',
  },
  'xl-regular': {
    fontFamily: 'primary',
    fontStyle: 'normal',
    fontSize: 'xl',
    fontWeight: 'regular',
    lineHeight: 'xl',
  },
  'xl-medium': {
    fontFamily: 'primary',
    fontStyle: 'normal',
    fontSize: 'xl',
    fontWeight: 'medium',
    lineHeight: 'xl',
  },
  'xl-semibold': {
    fontFamily: 'primary',
    fontStyle: 'normal',
    fontSize: 'xl',
    fontWeight: 'semibold',
    lineHeight: 'xl',
  },
  'xl-bold': {
    fontFamily: 'primary',
    fontStyle: 'normal',
    fontSize: 'xl',
    fontWeight: 'bold',
    lineHeight: 'xl',
  },
  'xl-light': {
    fontFamily: 'primary',
    fontStyle: 'normal',
    fontSize: 'xl',
    fontWeight: 'light',
    lineHeight: 'xl',
  },
}

const displays = {
  'xs-regular': {
    fontFamily: 'primary',
    fontStyle: 'normal',
    fontSize: 'xxl',
    fontWeight: 'regular',
    lineHeight: 'xxl',
  },
  'xs-medium': {
    fontFamily: 'primary',
    fontStyle: 'normal',
    fontSize: 'xxl',
    fontWeight: 'medium',
    lineHeight: 'xxl',
  },
  'xs-semibold': {
    fontFamily: 'primary',
    fontStyle: 'normal',
    fontSize: 'xxl',
    fontWeight: 'semibold',
    lineHeight: 'xxl',
  },
  'xs-bold': {
    fontFamily: 'primary',
    fontStyle: 'normal',
    fontSize: 'xxl',
    fontWeight: 'bold',
    lineHeight: 'xxl',
  },
  'xs-light': {
    fontFamily: 'primary',
    fontStyle: 'normal',
    fontSize: 'xxl',
    fontWeight: 'light',
    lineHeight: 'xxl',
  },
  'sm-regular': {
    fontFamily: 'primary',
    fontStyle: 'normal',
    fontSize: 'xxxl',
    fontWeight: 'regular',
    lineHeight: 'xxxl',
  },
  'sm-medium': {
    fontFamily: 'primary',
    fontStyle: 'normal',
    fontSize: 'xxxl',
    fontWeight: 'medium',
    lineHeight: 'xxxl',
  },
  'sm-semibold': {
    fontFamily: 'primary',
    fontStyle: 'normal',
    fontSize: 'xxxl',
    fontWeight: 'semibold',
    lineHeight: 'xxxl',
  },
  'sm-bold': {
    fontFamily: 'primary',
    fontStyle: 'normal',
    fontSize: 'xxxl',
    fontWeight: 'bold',
    lineHeight: 'xxxl',
  },
  'sm-light': {
    fontFamily: 'primary',
    fontStyle: 'normal',
    fontSize: 'xxxl',
    fontWeight: 'light',
    lineHeight: 'xxxl',
  },
  'md-regular': {
    fontFamily: 'primary',
    fontStyle: 'normal',
    fontSize: 'xxxxl',
    fontWeight: 'regular',
    lineHeight: 'xxxxl',
    letterSpacing: '-0.02em',
  },
  'md-medium': {
    fontFamily: 'primary',
    fontStyle: 'normal',
    fontSize: 'xxxxl',
    fontWeight: 'medium',
    lineHeight: 'xxxxl',
    letterSpacing: '-0.02em',
  },
  'md-semibold': {
    fontFamily: 'primary',
    fontStyle: 'normal',
    fontSize: 'xxxxl',
    fontWeight: 'semibold',
    lineHeight: 'xxxxl',
    letterSpacing: '-0.02em',
  },
  'md-bold': {
    fontFamily: 'primary',
    fontStyle: 'normal',
    fontSize: 'xxxxl',
    fontWeight: 'bold',
    lineHeight: 'xxxxl',
    letterSpacing: '-0.02em',
  },
  'md-light': {
    fontFamily: 'primary',
    fontStyle: 'normal',
    fontSize: 'xxxxl',
    fontWeight: 'light',
    lineHeight: 'xxxxl',
    letterSpacing: '-0.02em',
  },
  'lg-regular': {
    fontFamily: 'primary',
    fontStyle: 'normal',
    fontSize: 'xxxxxl',
    fontWeight: 'regular',
    lineHeight: 'xxxxxl',
    letterSpacing: '-0.02em',
  },
  'lg-medium': {
    fontFamily: 'primary',
    fontStyle: 'normal',
    fontSize: 'xxxxxl',
    fontWeight: 'medium',
    lineHeight: 'xxxxxl',
    letterSpacing: '-0.02em',
  },
  'lg-semibold': {
    fontFamily: 'primary',
    fontStyle: 'normal',
    fontSize: 'xxxxxl',
    fontWeight: 'semibold',
    lineHeight: 'xxxxxl',
    letterSpacing: '-0.02em',
  },
  'lg-bold': {
    fontFamily: 'primary',
    fontStyle: 'normal',
    fontSize: 'xxxxxl',
    fontWeight: 'bold',
    lineHeight: 'xxxxxl',
    letterSpacing: '-0.02em',
  },
  'lg-light': {
    fontFamily: 'primary',
    fontStyle: 'normal',
    fontSize: 'xxxxxl',
    fontWeight: 'light',
    lineHeight: 'xxxxxl',
    letterSpacing: '-0.02em',
  },
  'xl-regular': {
    fontFamily: 'primary',
    fontStyle: 'normal',
    fontSize: 'xxxxxxl',
    fontWeight: 'regular',
    lineHeight: 'xxxxxxl',
    letterSpacing: '-0.02em',
  },
  'xl-medium': {
    fontFamily: 'primary',
    fontStyle: 'normal',
    fontSize: 'xxxxxxl',
    fontWeight: 'medium',
    lineHeight: 'xxxxxxl',
    letterSpacing: '-0.02em',
  },
  'xl-semibold': {
    fontFamily: 'primary',
    fontStyle: 'normal',
    fontSize: 'xxxxxxl',
    fontWeight: 'semibold',
    lineHeight: 'xxxxxxl',
    letterSpacing: '-0.02em',
  },
  'xl-bold': {
    fontFamily: 'primary',
    fontStyle: 'normal',
    fontSize: 'xxxxxxl',
    fontWeight: 'bold',
    lineHeight: 'xxxxxxl',
    letterSpacing: '-0.02em',
  },
  'xl-light': {
    fontFamily: 'primary',
    fontStyle: 'normal',
    fontSize: 'xxxxxxl',
    fontWeight: 'light',
    lineHeight: 'xxxxxxl',
    letterSpacing: '-0.02em',
  },
  '2xl-regular': {
    fontFamily: 'primary',
    fontStyle: 'normal',
    fontSize: 'xxxxxxxl',
    fontWeight: 'regular',
    lineHeight: 'xxxxxxxl',
    letterSpacing: '-0.02em',
  },
  '2xl-medium': {
    fontFamily: 'primary',
    fontStyle: 'normal',
    fontSize: 'xxxxxxxl',
    fontWeight: 'medium',
    lineHeight: 'xxxxxxxl',
    letterSpacing: '-0.02em',
  },
  '2xl-semibold': {
    fontFamily: 'primary',
    fontStyle: 'normal',
    fontSize: 'xxxxxxxl',
    fontWeight: 'semibold',
    lineHeight: 'xxxxxxxl',
    letterSpacing: '-0.02em',
  },
  '2xl-bold': {
    fontFamily: 'primary',
    fontStyle: 'normal',
    fontSize: 'xxxxxxxl',
    fontWeight: 'bold',
    lineHeight: 'xxxxxxxl',
    letterSpacing: '-0.02em',
  },
  '2xl-light': {
    fontFamily: 'primary',
    fontStyle: 'normal',
    fontSize: 'xxxxxxxl',
    fontWeight: 'light',
    lineHeight: 'xxxxxxxl',
    letterSpacing: '-0.02em',
  },
}

const blurs = {
  'sm-light': {
    background: 'rgba(255, 255, 255, 0.6)',
    backdropFilter: 'blur(4px)',
  },
  'md-light': {
    background: 'rgba(255, 255, 255, 0.6)',
    backdropFilter: 'blur(8px)',
  },
  'lg-light': {
    background: 'rgba(255, 255, 255, 0.6)',
    backdropFilter: 'blur(12px)',
  },
  'xl-light': {
    background: 'rgba(255, 255, 255, 0.6)',
    backdropFilter: 'blur(20px)',
  },
  'sm-dark': {
    background: 'rgba(52, 64, 84, 0.6)',
    backdropFilter: 'blur(4px)',
  },
  'md-dark': {
    background: 'rgba(52, 64, 84, 0.6)',
    backdropFilter: 'blur(8px)',
  },
  'lg-dark': {
    background: 'rgba(52, 64, 84, 0.6)',
    backdropFilter: 'blur(12px)',
  },
  'xl-dark': {
    background: 'rgba(52, 64, 84, 0.6)',
    backdropFilter: 'blur(20px)',
  },
}

const tables = {
  insights: {
    width: '100%',
    borderCollapse: 'separate',
    borderSpacing: 0,
    tableLayout: 'fixed',
    th: {
      fontSize: 'xs',
      lineHeight: 'xs',
      color: 'new-gray.600',
      p: 3,
      borderTop: 'base',
      borderColor: 'new-gray.200',
      bg: 'new-gray.50',
    },
    td: {
      borderTop: 'base',
      borderColor: 'new-gray.200',
      fontSize: 'sm',
      lineHeight: 'sm',
      color: 'new-gray.900',
      p: 3,
    },
    'tbody tr:last-child td:first-child': {
      borderBottomLeftRadius: 'base',
    },
    'tbody tr:last-child td:last-child': {
      borderBottomRightRadius: 'base',
    },
  },
  construct: {
    width: '100%',
    borderCollapse: 'separate',
    borderSpacing: 0,
    tableLayout: 'fixed',
    th: {
      fontSize: 'xs',
      lineHeight: 'xs',
      color: 'new-gray.600',
      p: 3,
      borderTop: 'base',
      borderColor: 'new-gray.200',
      bg: 'new-gray.50',
    },
    td: {
      p: 3,
    },
    'tbody tr:last-child td:first-child': {
      borderBottomLeftRadius: 'base',
    },
    'tbody tr:last-child td:last-child': {
      borderBottomRightRadius: 'base',
    },
  },
  goals: {
    width: '100%',
    borderCollapse: 'separate',
    borderSpacing: 0,
    tableLayout: 'fixed',
    th: {
      fontSize: 'xs',
      lineHeight: 'xs',
      color: 'new-gray.600',
      px: '24px',
      py: '12px',
      borderTop: 'base',
      borderColor: 'new-gray.300',
      bg: 'new-gray.50',
    },
    td: {
      px: '24px',
      py: '24px',
      bg: 'base.white',
      fontSize: 'md',
      lineHeight: 'md',
    },
    'tbody tr:last-child td:first-child': {
      borderBottomLeftRadius: 'base',
    },
    'tbody tr:last-child td:last-child': {
      borderBottomRightRadius: 'base',
    },
  },
}

const variants = deepMerge(
  {
    texts,
    displays,
    blurs,
    tables,
  },
  variantsBase,
)

export default variants

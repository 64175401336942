import {toJson, baseURL, baseURLV1, getHeaders} from './utils'

const endpoint = `${baseURL}/api/config`

const userOptionsEndpoint = `${baseURL}/api/user-options`

export const fetchConfig = toJson(async (options = {}) =>
  fetch(endpoint, {
    headers: await getHeaders(),
    ...options,
  }),
)

export const fetchUserOptions = async (options = {}) =>
  fetch(userOptionsEndpoint, {
    headers: await getHeaders(),
    ...options,
  })

export const fetchSession = async () => {
  const result = await fetch(`${baseURLV1}/api/update-session/`, {
    credentials: 'include',
    headers: await getHeaders(),
  })
  return result
}

import React from 'react'
import PropTypes from 'prop-types'
import {
  AgFlex,
  AgText,
  AgAbsolute,
  useOnClickOutside,
} from '@aghealth/ag-components'
import {
  FiChevronLeft,
  FiChevronRight,
  FiChevronsLeft,
  FiChevronsRight,
  FiChevronDown,
} from 'react-icons/fi'
import {useTransition, animated} from 'react-spring'

const Absolute = animated(AgAbsolute)

const RowsPerPageSelector = ({
  rowsPerPage,
  rowsPerPageOptions,
  onChangeRowsPerPage,
}) => {
  const ref = React.useRef()
  const [visible, setVisible] = React.useState(false)
  const handleToggleShowMenu = React.useCallback(() => {
    setVisible(prev => !prev)
  }, [])

  const transitions = useTransition(visible, {
    from: {opacity: 0},
    enter: {opacity: 1},
    leave: {opacity: 0},
  })

  const handleNotShowMenu = React.useCallback(() => {
    setVisible(false)
  }, [setVisible])
  useOnClickOutside(ref, handleNotShowMenu, document)

  return (
    <AgFlex ref={ref} pl="5px">
      <AgFlex
        onClick={handleToggleShowMenu}
        cursor="pointer"
        alignItems="center"
        width="50px"
      >
        <AgText fontSize="16px">{rowsPerPage}</AgText>
        <FiChevronDown style={{paddingLeft: '5px'}} title="chevron-down" />
      </AgFlex>

      {transitions(
        (style, item) =>
          item && (
            <Absolute
              key={item}
              width={50}
              mt={20}
              ml={-10}
              borderRadius="2px"
              boxShadow="profile"
              bg="white"
              style={style}
            >
              {rowsPerPageOptions.map(option => (
                <AgText
                  key={`option${option}`}
                  fontSize="16px"
                  padding="5px 9px"
                  cursor="pointer"
                  fontWeight={option === rowsPerPage ? 'bold' : 'normal'}
                  onClick={() => onChangeRowsPerPage(option)}
                >
                  {option}
                </AgText>
              ))}
            </Absolute>
          ),
      )}
    </AgFlex>
  )
}

RowsPerPageSelector.propTypes = {
  rowsPerPage: PropTypes.number,
  rowsPerPageOptions: PropTypes.array,
  onChangeRowsPerPage: PropTypes.func,
  transition: PropTypes.object,
}

const Pagination = ({
  count,
  page,
  rowsPerPageOptions,
  labelRowsPerPage,
  onChangePage,
  rowsPerPage,
  onChangeRowsPerPage,
  totalPages,
}) => (
  <AgFlex alignItems="center">
    <AgText fontSize="16px">{labelRowsPerPage}</AgText>

    <RowsPerPageSelector
      rowsPerPage={rowsPerPage}
      rowsPerPageOptions={rowsPerPageOptions}
      onChangeRowsPerPage={onChangeRowsPerPage}
    />

    <AgText fontSize="16px" px="20px" width="130px">
      {`${1 + (page - 1) * rowsPerPage}-${
        page === totalPages ? count : page * rowsPerPage
      } of ${count}`}
    </AgText>

    <FiChevronsLeft
      size={18}
      style={{padding: '12px'}}
      cursor={page > 2 ? 'pointer' : 'default'}
      pointerEvents={page > 2 ? 'all' : 'none'}
      color={page > 2 ? '#121212 ' : 'rgba(0, 0, 0, 0.3)'}
      onClick={() => onChangePage(1)}
      title="chevrons-left"
    />
    <FiChevronLeft
      size={18}
      style={{padding: '12px'}}
      cursor={page > 1 ? 'pointer' : 'default'}
      pointerEvents={page > 1 ? 'all' : 'none'}
      color={page > 1 ? '#121212 ' : 'rgba(0, 0, 0, 0.3)'}
      onClick={() => onChangePage(page - 1)}
      title="chevron-left"
    />
    <FiChevronRight
      size={18}
      style={{padding: '12px', cursor: 'pointer'}}
      cursor={page < totalPages ? 'pointer' : 'default'}
      pointerEvents={page < totalPages ? 'all' : 'none'}
      color={page < totalPages ? '#121212 ' : 'rgba(0, 0, 0, 0.3)'}
      onClick={() => onChangePage(page + 1)}
      title="chevron-right"
    />
    <FiChevronsRight
      size={18}
      style={{padding: '12px', cursor: 'pointer'}}
      cursor={page < totalPages - 1 ? 'pointer' : 'default'}
      pointerEvents={page < totalPages - 1 ? 'all' : 'none'}
      color={page < totalPages - 1 ? '#121212 ' : 'rgba(0, 0, 0, 0.3)'}
      onClick={() => onChangePage(totalPages)}
      title="chevrons-right"
    />
  </AgFlex>
)

Pagination.propTypes = {
  count: PropTypes.number,
  page: PropTypes.number,
  rowsPerPageOptions: PropTypes.array,
  labelRowsPerPage: PropTypes.string,
  onChangePage: PropTypes.func,
  rowsPerPage: PropTypes.number,
  onChangeRowsPerPage: PropTypes.func,
  totalPages: PropTypes.number,
}

export default Pagination

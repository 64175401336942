export const SET_SYSTEM_DATA = 'SET_SYSTEM_DATA'
export const SET_SYSTEM_ERROR = 'SET_SYSTEM_ERROR'
export const SET_SYSTEM_FILTERS = 'SET_SYSTEM_FILTERS'
export const SET_SYSTEM_TIME_PERIODS = 'SET_SYSTEM_TIME_PERIODS'
export const SET_SYSTEM_PROCEDURE_GROUPS = 'SET_SYSTEM_PROCEDURE_GROUPS'
export const SET_SYSTEM_METRICS = 'SET_SYSTEM_METRICS'
export const SET_SYSTEM_RISK_ADJUSTED = 'SET_SYSTEM_RISK_ADJUSTED'
export const SET_SYSTEM_DATE_SAVED = 'SET_SYSTEM_DATE_SAVED'
export const SET_SYSTEM_LOADING_DATA = 'SET_SYSTEM_LOADING_DATA'
export const SET_SYSTEM_LOADING_FILTERS = 'SET_SYSTEM_LOADING_FILTERS'
export const SET_SYSTEM_FILTER_DATA_BY = 'SET_SYSTEM_FILTER_DATA_BY'
export const SET_SYSTEM_IS_ONCOLOGY = 'SET_SYSTEM_IS_ONCOLOGY'
export const SET_SYSTEM_EXCLUDE_ONCOLOGY = 'SET_SYSTEM_EXCLUDE_ONCOLOGY'
export const SET_SYSTEM_DEPARTMENTS = 'SET_DEPARTMENTS'

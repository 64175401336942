import minimum from 'lodash/min'
import maximum from 'lodash/max'
import {computeDisplayKeys} from '../utils'

const createKeys = (config, data) => {
  const groupKey = config.chart.y_scale.group_key
  if (groupKey) {
    const keys = computeDisplayKeys(config, data)
    return keys
  }
  if (config.options) {
    return config.options.values.filter(v => v.selected).map(v => v.key)
  }
  return config.chart.y_scale.keys
}

const createScales = (config, {data, percentiles}) => {
  const keys = createKeys(config, data)
  // const y_key_max = config.chart?.max_value_accessor
  //   ? config.chart.max_value_accessor
  //   : undefined
  let max = percentiles.max
  let min = 0
  if (keys?.length > 0) {
    keys.forEach(key => {
      const _max = maximum(config.unfiltered_data.map(d => d[key]))
      const _min = minimum(config.unfiltered_data.map(d => d[key]))
      max = _max > max ? _max : max
      min = _min < min ? _min : min
    })
  }

  const yScale = {
    keys,
    type: config.chart.y_scale.type,
    key: config.chart.y_scale.key,
    max,
    min,
  }
  const xScale = {
    key: config.chart.x_scale.key,
    type: config.chart.x_scale.type,
    parse: config.chart.x_scale.parse,
    format: config.chart.x_scale.format,
  }
  return {xScale, yScale}
}

export default createScales

import React from 'react'
import PropTypes from 'prop-types'
import {AgColumn, AgRow, AgText, X} from '@aghealth/ag-components'
import CheckboxList from '../CheckboxList'
import MessageInput from './MessageInput'

const CheckboxListStyles = {
  Root: {
    mt: '8px',
    px: '16px',
  },
  Checkbox: {
    Root: {
      mt: '8px',
    },
  },
}

const defaultSuggestions = [
  {
    id: 1,
    name: 'Sid didn’t understand my question',
    selected: false,
  },
  {
    id: 2,
    name: 'Answers were incorrect',
    selected: false,
  },
  {
    id: 3,
    name: 'Answers were not helpful',
    selected: false,
  },
  {
    id: 4,
    name: 'Answers were not understandable',
    selected: false,
  },
]

const FeedbackForm = ({onClose, onSendFeedback}) => {
  const [suggestions, setSuggestions] = React.useState(defaultSuggestions)
  const handleOnChangeSuggestions = item => {
    setSuggestions(prev => {
      return prev.map(suggestion => {
        if (suggestion.id === item.id) {
          return {...suggestion, selected: !suggestion.selected}
        }
        return suggestion
      })
    })
  }
  const handleOnSendFeedback = React.useCallback(
    comments => {
      const selectedSuggestions = suggestions.filter(
        suggestion => suggestion.selected,
      )
      const feedback = {
        improvement_options: selectedSuggestions?.map(s => s.id)?.join(','),
        comments,
      }
      if (selectedSuggestions?.length > 0 || comments) {
        onSendFeedback(feedback)
      }
    },
    [suggestions, onSendFeedback],
  )
  return (
    <AgColumn
      sx={{bg: 'base.white', width: '610px', borderRadius: '8px 8px 8px 8px'}}
    >
      <AgRow
        sx={{
          p: '24px',
          borderBottom: 'base',
          borderColor: '#C4C4C4',
          justifyContent: 'space-between',
        }}
      >
        <AgText
          sx={{
            color: '#1B263F',
            fontFamily: 'Roboto',
            fontSize: '20px',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '28px',
          }}
        >
          Provide Feedback on Sid
        </AgText>
        <X
          onClick={onClose}
          sx={{
            transform: 'scale(1.5)',
            color: '#041424',
            cursor: 'pointer',
          }}
        />
      </AgRow>
      <AgRow sx={{p: '24px'}}>
        <AgText sx={{variant: 'texts.md-regular', color: '#727891'}}>
          Thank you for the opportunity to learn how Sid can be a better service
          for you.
        </AgText>
      </AgRow>
      <AgColumn sx={{px: '24px'}}>
        <AgText sx={{variant: 'texts.md-regular', color: 'new-gray.700'}}>
          1. What should Sid work on improving, if any?
        </AgText>
        <CheckboxList
          list={suggestions}
          onChange={handleOnChangeSuggestions}
          styles={CheckboxListStyles}
        />
      </AgColumn>
      <AgColumn sx={{px: '24px', mt: '16px'}}>
        <AgText sx={{variant: 'texts.md-regular', color: 'new-gray.700'}}>
          2. Any other comments?
        </AgText>
        <MessageInput
          placeholder="Type comments about your experience with Sid"
          sendButtonText="Send Feedback"
          onSend={handleOnSendFeedback}
          sendDisabled={false}
          height="100%"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            mt: '16px',
            height: '200px',
            width: '100%',
            borderColor: 'rgba(0, 0, 0, 0.23)',
          }}
          showSendButtonIcon={false}
          sendButtonStyles={{
            my: '24px',
            variant: 'texts.md-semibold',
            boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
            border: '1px solid #12B76A',
            borderRadius: '8px',
            bg: '#12B76A',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '10px 18px',
            color: 'base.white',
          }}
        />
      </AgColumn>
    </AgColumn>
  )
}

FeedbackForm.propTypes = {
  onClose: PropTypes.func,
  onSendFeedback: PropTypes.func,
}

export default FeedbackForm

import React from 'react'
import PropTypes from 'prop-types'
import capitalize from 'lodash/capitalize'
import uniqueId from 'lodash/uniqueId'
import {MdClose} from 'react-icons/md'
import {IoIosArrowDown, IoIosArrowUp} from 'react-icons/io'
import {
  AgText,
  AgSpan,
  AgRow,
  AgColumn,
  AgImage,
  AgTable,
} from '@aghealth/ag-components'
import {toDollarString} from '../../../utils'
import docs from '../../../assets/supplytip-docs.png'
import cases from '../../../assets/supplytip-cases.png'
import doctor from '../../../assets/supplytip-doctor.png'
import calendar from '../../../assets/supplytip-calendar.png'
import patient from '../../../assets/supplytip-patient.png'

const Text = ({label, value, bold}) => (
  <AgText fontSize={12} color="font-primary">
    <AgSpan fontWeight={bold ? 'bold' : 'normal'}>{label}</AgSpan>: {value}
  </AgText>
)

Text.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  bold: PropTypes.bool,
}

const ShowLess = props => (
  <AgRow ml="56px" mt="12px" {...props}>
    <AgText
      color="#007DFF"
      fontSize="14px"
      lineHeight="24px"
      fontWeight="bold"
      cursor="pointer"
    >
      SHOW LESS CASE INFO
    </AgText>
    <IoIosArrowUp
      color="#3399FF"
      fontSize="20px"
      cursor="pointer"
      marginLeft="16px"
    />
  </AgRow>
)

const ShowMore = props => (
  <AgRow ml="56px" mt="12px" {...props}>
    <AgText
      color="#007DFF"
      fontSize="14px"
      lineHeight="24px"
      fontWeight="bold"
      cursor="pointer"
    >
      SHOW MORE CASE INFO
    </AgText>
    <IoIosArrowDown
      color="#3399FF"
      fontSize="20px"
      cursor="pointer"
      marginLeft="16px"
    />
  </AgRow>
)

const IconText = ({icon, title, subtitle}) => (
  <AgRow marginTop="12px">
    <AgImage src={icon} height="40px" width="40px" borderRadius="circle" />
    <AgColumn marginLeft="16px">
      <AgText fontSize="12px" lineHeight="16px" color="#041424">
        {title}
      </AgText>
      <AgText fontSize="12px" lineHeight="16px" color="#9CACBC">
        {subtitle}
      </AgText>
    </AgColumn>
  </AgRow>
)

IconText.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
}

const TableStyles = {
  Th: {
    fontSize: '12px',
    lineHeight: '16px',
    color: '#9CACBC',
    fontWeight: 'normal',
  },
  Td: {
    fontSize: '12px',
    lineHeight: '16px',
    color: '#041424',
    py: '8px',
  },
  TdRow: {
    bg: 'transparent',
    cursor: 'default',
    '&:hover': {
      bg: 'transparent',
    },
  },
}

const NameColumn = {
  heading: 'Name',
  accessor: d => d.name,
  align: 'left',
  format: capitalize,
}

const createTotalSpendColumn = (isCostType, format) => ({
  heading: isCostType ? 'Total Spend' : 'Total',
  accessor: d => d.value,
  format: value => (isCostType ? toDollarString(value) : format(value)),
  align: 'right',
})

const createColumns = (isCostType, format) => [
  NameColumn,
  createTotalSpendColumn(isCostType, format),
]

const Content = ({
  hospital_case_number,
  metric,
  drg_id,
  drg_name,
  icd_name,
  raw_cpt,
  procedure_filter,
  raw_procedure_icd10_all,
  surgeon_name,
  date_of_surgery,
  hospital_patient_id,
  costs,
  close,
  isCostType,
  format,
}) => {
  const [show, setShow] = React.useState(false)
  const handleShowMore = React.useCallback(() => {
    setShow(true)
  }, [])
  const handleShowLess = React.useCallback(() => {
    setShow(false)
  }, [])
  const total = React.useMemo(
    () =>
      costs?.length ? costs?.reduce((acc, item) => acc + item.value, 0) : 0,
    [costs],
  )
  const columns = React.useMemo(
    () => createColumns(isCostType, format),
    [isCostType, format],
  )

  return (
    <AgColumn flexDirection="column" bg="#FFFFFF">
      <AgRow
        px="16px"
        height="56px"
        justifyContent="space-between"
        borderBottom="base"
        borderColor="#EFEFEF"
      >
        <AgText
          fontSize="18px"
          lineHeight="25px"
          fontWeight="bold"
          color="#041424"
        >
          {`Case No. ${hospital_case_number}`}
        </AgText>
        <MdClose
          fontSize={28}
          onClick={close}
          color="#041424"
          style={{cursor: 'pointer'}}
        />
      </AgRow>
      <AgColumn px="16px">
        <AgText
          mt="16px"
          fontSize="14px"
          fontWeight="bold"
          lineHeight="20px"
          color="#041424"
        >
          {metric}
        </AgText>
        <IconText
          icon={cases}
          title={drg_id ? icd_name : procedure_filter}
          subtitle="Procedure Filter"
        />
        <IconText
          icon={docs}
          title={
            drg_id
              ? `${drg_id}: ${drg_name}`
              : `${raw_cpt?.replaceAll(',', ', ')}`
          }
          subtitle={drg_id ? 'DRG Name' : 'CPT Codes'}
        />
        {!show && <ShowMore onClick={handleShowMore} />}
        {show && (
          <>
            {drg_id && raw_procedure_icd10_all && (
              <IconText
                icon={docs}
                subtitle="ICD10 Codes"
                title={raw_procedure_icd10_all?.replaceAll(',', ', ')}
              />
            )}
            <IconText icon={doctor} subtitle="Physician" title={surgeon_name} />
            <IconText
              icon={calendar}
              subtitle="Date of Surgery"
              title={new Date(`${date_of_surgery}`).toLocaleDateString(
                'en-US',
                {
                  year: '2-digit',
                  month: 'numeric',
                  day: 'numeric',
                  timeZone: 'UTC',
                },
              )}
            />
            <IconText
              icon={patient}
              subtitle="Hospital Patient ID"
              title={hospital_patient_id}
            />
            <ShowLess onClick={handleShowLess} />
          </>
        )}
      </AgColumn>
      <AgColumn px="16px">
        {isCostType && (
          <AgRow py="16px">
            <AgText
              fontSize="14px"
              fontWeight="bold"
              lineHeight="20px"
              color="#041424"
            >
              Costs
            </AgText>
          </AgRow>
        )}
        <AgTable
          data={costs}
          columns={columns}
          rowKeyAccessor={() => `row_${uniqueId()}`}
          styles={TableStyles}
        />
        <AgRow
          borderTop="base"
          borderColor="#EFEFEF"
          justifyContent="space-between"
          py="16px"
        >
          <AgText
            fontSize="14px"
            lineHeight="20px"
            color="#041424"
            fontWeight="bold"
          >
            {isCostType ? 'Total Cost' : 'Total'}
          </AgText>
          <AgText
            fontSize="14px"
            lineHeight="20px"
            color="#041424"
            fontWeight="bold"
          >
            {isCostType ? toDollarString(total) : format(total)}
          </AgText>
        </AgRow>
      </AgColumn>
    </AgColumn>
  )
}

Content.propTypes = {
  hospital_case_number: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  metric: PropTypes.string,
  hospital_patient_id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  surgeon_name: PropTypes.string,
  drg_id: PropTypes.number,
  icd_name: PropTypes.string,
  raw_cpt: PropTypes.string,
  date_of_surgery: PropTypes.string,
  costs: PropTypes.array,
  close: PropTypes.func,
  drg_name: PropTypes.string,
  procedure_filter: PropTypes.string,
  raw_procedure_icd10_all: PropTypes.string,
  isCostType: PropTypes.bool,
  format: PropTypes.func,
}

const label = {
  score_delta: 'PRO Delta',
  starts_on_time: 'Minutes Started Past Scheduled Time',
  length_of_stay: 'Length of Stay (Days)',
  risk_score: 'Patient Risk Score',
}

const CaseLevelTooltip = ({data, close, metric, keys, format}) => {
  const costs = React.useMemo(
    () =>
      Array.isArray(keys)
        ? keys
            .filter(key => key.selected)
            .reduce((acc, key) => [...acc, {...key, value: data[key.key]}], [])
        : [{id: label[keys], name: label[keys], value: data[keys]}],
    [keys, data],
  )
  const isCostType = React.useMemo(
    () => (Array.isArray(keys) ? keys[0]?.format === '$' : false),
    [keys],
  )

  return (
    <AgColumn
      bg="#FFFFFF"
      border="base"
      borderColor="#EFEFEF"
      borderRadius="8px"
      boxShadow="tooltip"
      width={483}
      maxHeight={492}
      sx={{
        variant: 'scrollbars.y',
      }}
    >
      <Content
        close={close}
        metric={metric}
        {...data}
        costs={costs}
        isCostType={isCostType}
        format={format}
      />
    </AgColumn>
  )
}
CaseLevelTooltip.propTypes = {
  data: PropTypes.object,
  close: PropTypes.func,
  metric: PropTypes.string,
  format: PropTypes.func,
  keys: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
}

export default CaseLevelTooltip

import React from 'react'
import {AgImage} from '@aghealth/ag-components'
import PropTypes from 'prop-types'
import arrow from '../assets/arrow.png'

const colors = {
  green:
    'brightness(0) saturate(100%) invert(61%) sepia(24%) saturate(645%) hue-rotate(70deg) brightness(92%) contrast(80%)',
  red: 'brightness(0) saturate(100%) invert(24%) sepia(56%) saturate(2919%) hue-rotate(356deg) brightness(95%) contrast(87%)',
}

const styles = {
  filter: (_, props) => (props.variant === 'good' ? colors.green : colors.red),
  transform: (_, props) =>
    props.direction === '+' ? 'rotate(180deg)' : 'rotate(0deg)',
  ml: '4px',
}

const directions = ['+', '-']

const ArrowIcon = ({variant, direction, size, sx}) => {
  if (!directions.includes(direction)) return null
  return (
    <AgImage
      src={arrow}
      direction={direction}
      variant={variant}
      sx={{
        ...styles,
        ...sx,
        size,
      }}
    />
  )
}

ArrowIcon.propTypes = {
  variant: PropTypes.string,
  direction: PropTypes.string,
  size: PropTypes.number,
  sx: PropTypes.object,
}

ArrowIcon.defaultProps = {
  size: 20,
  sx: {},
}

export default ArrowIcon

import React from 'react'
import {useSelector} from 'react-redux'
import {AgButton, AgRow} from '@aghealth/ag-components'
import PropTypes from 'prop-types'
import {RiHome3Line} from 'react-icons/ri'
import {selectHasNavigationV2} from '../utils/selectors'

export const homeBreadCrumb = {
  name: 'Home',
  url: '/',
  icon: <RiHome3Line />,
}

const sx = {
  display: 'flex',
  fontSize: '14px',
  lineHeight: '16px',
  color: '#F8F8F8',
  bg: 'transparent',
  padding: 0,
}

const cursor = {
  cursor: 'default',
}

const Link = ({onClick, children, ...props}) => (
  <AgButton onClick={onClick} sx={sx} {...props}>
    {children}
  </AgButton>
)

Link.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.node,
}

const noop = () => {}

const Breadcrumbs = ({crumbs, separator, onClick, ...props}) => {
  const hasNavigationV2 = useSelector(selectHasNavigationV2)
  return (
    <AgRow {...props} justifyContent="space-between">
      {crumbs.map(({name, icon, url, type, search}, index) =>
        index + 1 === crumbs.length ? (
          <Link
            key={name}
            onClick={url ? () => onClick(url, search, type) : noop}
            style={url ? undefined : cursor}
          >
            {name}
          </Link>
        ) : (
          <Link key={name} onClick={() => onClick(url, search, type)}>
            {icon ? <AgRow mr="4px">{icon}</AgRow> : name}
            {hasNavigationV2 ? (
              <>&nbsp;{separator}&nbsp;&nbsp;</>
            ) : (
              <>{separator}&nbsp;</>
            )}
          </Link>
        ),
      )}
    </AgRow>
  )
}

Breadcrumbs.propTypes = {
  crumbs: PropTypes.array,
  separator: PropTypes.string,
  onClick: PropTypes.func,
}

Breadcrumbs.defaultProps = {
  crumbs: [],
  separator: ' >',
  onClick: () => {},
}

export default Breadcrumbs

import {flattenArray} from './array'

export const filterEntries = (obj, condition) => {
  const filtered = Object.entries(obj)
    .filter(condition)
    .reduce((acc, [key, value]) => {
      acc[key] = value
      return acc
    }, {})
  return filtered
}

export const hasKey = (obj, key) => Object.keys(obj).includes(key)

export const getLeafNodes = (node, key = 'items') => {
  if (node[key]?.length > 0)
    return flattenArray(node[key].map(d => getLeafNodes(d, key)))
  return node
}

export const updateObject = (obj, keyToUpdate, newValue) => {
  if (obj !== null && typeof obj === 'object') {
    Object.keys(obj).forEach(key => {
      if (key === keyToUpdate) {
        obj[key] = newValue
      } else if (typeof obj[key] === 'object') {
        updateObject(obj[key], keyToUpdate, newValue)
      }
    })
  }
}

export const clone = obj => JSON.parse(JSON.stringify(obj))

export const optional = obj => obj || {}

import React from 'react'
import PropTypes from 'prop-types'
import {animated} from 'react-spring'
import {AgBox} from '@aghealth/ag-components'
import classNames from 'classnames'
import {prefix} from './settings'

const AnimatedBox = animated(AgBox)
export const MainContainer = ({responsive, children, className, ...rest}) => {
  const cName = `${prefix}-main-container`

  return (
    <AnimatedBox
      className={classNames(
        cName,
        {[`${cName}--responsive`]: responsive},
        className,
      )}
      {...rest}
    >
      {children}
    </AnimatedBox>
  )
}

MainContainer.propTypes = {
  /** Primary content. */
  children: PropTypes.node,

  /** Is container responsive. */
  responsive: PropTypes.bool,

  /** Additional classes. */
  className: PropTypes.string,
}

MainContainer.defaultProps = {
  children: undefined,
  responsive: false,
}

export default MainContainer

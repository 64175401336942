import createData from './data'
import createPhysicians from './physicians'
import createOrganizations from './organizations'
import createOptions from './options'
import createChart from './chart'
import {
  isGroupByOrganization,
  isGroupBySurgeon,
  withGroupBySurgeonNames,
  withGroupByOrganizationNames,
} from './utils'

const handleData = config => {
  if (isGroupByOrganization(config)) {
    return withGroupByOrganizationNames(createData(config))
  }
  if (isGroupBySurgeon(config))
    return withGroupBySurgeonNames(createData(config))

  return createData(config)
}

export const createConfig = config => {
  const data = handleData(config)
  const options = createOptions(config)
  const physicians = createPhysicians(data)
  const organizations = isGroupByOrganization(config)
    ? createOrganizations(data)
    : undefined
  const chart = createChart(config)
  const unfiltered_data = data
  const result = {
    ...config,
    data,
    unfiltered_data,
    physicians,
    organizations,
    options,
    chart,
  }

  return result
}

import createDimensions from './dimensions'
import createScales from './scales'
import createLabels from './labels'
import createAxes from './axes'
import createMarkers from './markers'
import createLegend from './legend'
import createData from './data'
import createPercentiles from '../percentiles'

const createChart = config => {
  const data = createData(config)
  const percentiles = createPercentiles(config)
  const dimensions = createDimensions(config)
  const {xScale, yScale} = createScales(config, {data, percentiles})
  const {title, info} = createLabels(config)
  const {leftAxis, bottomAxis} = createAxes(config)
  const markers = createMarkers(config, percentiles)
  const {legend, showLegend} = createLegend(config, percentiles)
  const result = {
    type: 'line',
    id: config.id,
    data,
    title,
    info,
    leftAxis,
    bottomAxis,
    legend,
    showLegend,
    markers,
    xScale,
    yScale,
    dimensions,
  }

  return result
}

export default createChart

import React from 'react'
import PropTypes from 'prop-types'
import {AgBox} from '@aghealth/ag-components'

export const MessageHeader = ({
  sender,
  sentTime,
  children,
  className,
  ...rest
}) => {
  return (
    <AgBox
      {...rest}
      display="flex"
      alignItems="flex-start"
      justifyContent="space-between"
      py={1}
    >
      {typeof children !== 'undefined' ? (
        children
      ) : (
        <>
          <AgBox
            // className={`${prefix}-message__sender-name`}
            sx={{variant: 'texts.sm-medium', color: 'new-gray.700'}}
          >
            {sender}
          </AgBox>
          <AgBox sx={{variant: 'texts.xs-regular', color: 'new-gray.600'}}>
            {sentTime}
          </AgBox>
        </>
      )}
    </AgBox>
  )
}

MessageHeader.displayName = 'Message.Header'

MessageHeader.propTypes = {
  sender: PropTypes.string,
  sentTime: PropTypes.string,

  /** Primary content. */
  children: PropTypes.node,

  /** Additional classes. */
  className: PropTypes.string,
}

MessageHeader.defaultProps = {
  sender: '',
  sentTime: '',
  children: undefined,
}

export default MessageHeader

import React from 'react'
import PropTypes from 'prop-types'
import {
  AgButton,
  AgColumn,
  AgRow,
  AgText,
  FormField,
  AuthContainer,
} from '@aghealth/ag-components'
import {validateNPI} from '../utils'

const NPIPrompt = ({onSave, onSkip, onDontAskAgain}) => {
  const [npi, setNPI] = React.useState({
    isValid: true,
    value: '',
    error: '',
  })
  const handleOnChange = React.useCallback(e => {
    e.preventDefault()
    setNPI({value: e.target.value, isValid: true, error: ''})
  }, [])
  const handleOnSave = React.useCallback(() => {
    const value = validateNPI(npi.value)
    if (value.isValid) {
      onSave(value.value)
    } else {
      setNPI(value)
    }
  }, [onSave, npi])
  return (
    <AuthContainer slot="require-new-password">
      <AgColumn
        bg="white"
        px="50px"
        pt="20px"
        pb="20px"
        minWidth="496px"
        borderRadius="4px"
      >
        <AgText
          fontSize="28px"
          lineHeight="40px"
          color="#333333"
          fontWeight="bold"
        >
          Enhance Your Experience
        </AgText>

        <AgText
          fontSize="14px"
          lineHeight="20px"
          color="#041424"
          mt="25px"
          mb="36px"
        >
          Link your National Provider Identifier (NPI) to your account for an
          enhanced more personalized CareMeasurement experience, tailored to
          your specialty.
        </AgText>
        <FormField
          label="NPI"
          type="text"
          minLength={10}
          maxLength={10}
          value={npi.value}
          handleInputChange={handleOnChange}
          hint={npi.error}
          onEnter={handleOnSave}
        />
        <AgRow mt="32px">
          <AgButton
            height="44px"
            width="100%"
            bg="#FFFFFF"
            border="base"
            borderColor="#129459"
            borderRadius="4px"
            color="#129459"
            fontSize="14px"
            lineHeight="20px"
            onClick={onSkip}
          >
            Skip
          </AgButton>
          <AgButton
            height="44px"
            width="100%"
            bg="#129459"
            border="base"
            borderColor="#129459"
            borderRadius="4px"
            color="#FFFFFF"
            fontSize="14px"
            lineHeight="20px"
            ml="16px"
            onClick={handleOnSave}
          >
            Save
          </AgButton>
        </AgRow>
        <AgRow py="24px" justifyContent="center">
          <AgText
            color="#337EDC"
            fontSize="14px"
            lineHeight="16px"
            onClick={onDontAskAgain}
            cursor="pointer"
          >
            Don’t ask me again / I don’t have a NPI
          </AgText>
        </AgRow>
      </AgColumn>
    </AuthContainer>
  )
}

NPIPrompt.propTypes = {
  onSkip: PropTypes.func,
  onSave: PropTypes.func,
  onDontAskAgain: PropTypes.func,
}

export default NPIPrompt

import React from 'react'
import PropTypes from 'prop-types'

const Anchor = ({children, href}) => (
  <a href={href} target="_blank" rel="noopener noreferrer">
    {children}
  </a>
)

Anchor.propTypes = {
  href: PropTypes.string,
  children: PropTypes.node,
}

export default Anchor

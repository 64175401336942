import React from 'react'
import PropTypes from 'prop-types'
import {AgFlex} from '@aghealth/ag-components'

const ScrollX = ({children, sx, ...props}) => (
  <AgFlex {...props} flexDirection="row" sx={sx}>
    {children}
  </AgFlex>
)

ScrollX.propTypes = {
  children: PropTypes.node,
  sx: PropTypes.object,
}
ScrollX.defaultProps = {
  sx: {
    paddingRight: '8px',
    variant: 'scrollbars.x',
  },
}

export default ScrollX

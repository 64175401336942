import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {animated} from 'react-spring'
import {getChildren} from './utils'
import ConversationHeader from './ConversationHeader'
import MessageList from './MessageList'
import MessageInput from './MessageInput'
import InputToolbox from './InputToolbox'
import {prefix} from './settings'

export const ChatContainer = ({children, className, ...rest}) => {
  const cName = `${prefix}-chat-container`

  const [header, messageList, messageInput, inputToolbox] = getChildren(
    children,
    [ConversationHeader, MessageList, MessageInput, InputToolbox],
  )

  return (
    <animated.div className={classNames(cName, className)} {...rest}>
      {header}
      {messageList}
      {messageInput}
      {inputToolbox}
    </animated.div>
  )
}

ChatContainer.propTypes = {
  /**
   * Primary content.
   * Allowed elements:
   *
   * * &lt;ConversationHeader /&gt;
   * * &lt;MessageList /&gt;
   * * &lt;MessageInput /&gt;
   * * &lt;InputToolbox /&gt;
   */
  children: PropTypes.node,

  /** Additional classes. */
  className: PropTypes.string,
}

ChatContainer.defaultProps = {
  children: undefined,
}

export default ChatContainer

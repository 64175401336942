import {getHeaders, baseURL, toJson} from './utils'

const url = `${baseURL}/api/organizations`

export const fetchOrganizations = toJson(async options => {
  const {signal} = options
  return fetch(url, {headers: await getHeaders(), signal})
})

export const changeOrganization = async options => {
  const {body, onSuccess = () => {}, onFail = () => {}} = options
  return fetch(`${url}/change`, {
    headers: await getHeaders(),
    body: JSON.stringify(body),
    method: 'POST',
  }).then(r => {
    if (/2[0-9][0-9]/g.test(r.status)) {
      onSuccess(r)
    } else {
      onFail(r)
    }
    return Promise.resolve(r)
  })
}

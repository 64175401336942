import React from 'react'
import PropTypes from 'prop-types'
import {
  AgFlex,
  AgImage,
  AgInput,
  AgText,
  AgAbsolute,
  useOnClickOutside,
} from '@aghealth/ag-components'
import isEmpty from 'lodash/isEmpty'
import uniqueId from 'lodash/uniqueId'
import searchIcon from '../assets/icon-search.png'
import closeIcon from '../assets/icon-close.png'

const styles = {
  Dropdown: {
    Root: {
      boxSizing: 'border-box',
      width: '254px',
      top: 'auto',
      mt: '40px',
      border: (_, props) => (props.isEmpty ? 'none' : 'base'),
      borderColor: 'box-border-gray',
      borderRadius: '4px',
      boxShadow: 'subnav',
      zIndex: 1,
      maxHeight: 300,
      bg: 'white',
      variant: 'scrollbars.y',
    },
  },
  Search: {
    Root: {
      borderRadius: 3,
      border: 'base',
      borderColor: '#999999',
      width: '254px',
      flexDirection: 'row',
      mr: '20px',
      alignItems: 'center',
      paddingLeft: '20px',
      paddingRight: '12px',
    },
    Input: {
      width: '100%',
      outline: 'none',
      border: 'none',
      fontSize: 14,
      paddingLeft: 12,
      paddingRight: 12,
    },
    Icon: {
      cursor: 'pointer',
      size: 19,
    },
  },
  Option: {
    Root: {
      bg: 'white',
      fontSize: '14px',
      textAlign: 'left',
      py: 3,
      paddingLeft: '53px',
      paddingRight: '20px',
      cursor: 'pointer',
      transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      '&:hover ': {
        bg: '#F7065B',
      },
      '&:hover h1': {
        color: 'white',
      },
      '& h1': {
        whiteSpace: 'break-spaces',
      },
    },
  },
}

const computeLabel = ({label, searchTerm}) => {
  const result = label.split(new RegExp(`(${searchTerm})`, 'gi'))
  return result.map(r =>
    r.toLowerCase() === searchTerm.toLowerCase() ? (
      <strong key={uniqueId('option_label_')}>{r}</strong>
    ) : (
      <span key={uniqueId('option_label_')}>{r}</span>
    ),
  )
}

const Option = ({option, searchTerm, onClick}) => (
  <AgFlex
    flexDirection="column"
    variant="procedure-search"
    onClick={() => onClick(option)}
    sx={styles.Option.Root}
    data-testid="procedure-search-option"
  >
    <AgText fontSize="14px">
      {computeLabel({label: option.label, searchTerm})}
    </AgText>
    <AgText color="#666B6C">{option.description}</AgText>
  </AgFlex>
)

Option.propTypes = {
  option: PropTypes.object,
  searchTerm: PropTypes.string,
  onClick: PropTypes.func,
}

const ProcedureSearch = ({onFilterBy, selections}) => {
  const searchRef = React.useRef(null)
  const searchOptionsRef = React.useRef(null)
  const [options, setOptions] = React.useState(null)
  const [searchTerm, setSearchTerm] = React.useState('')

  const handleOnChange = e => {
    setSearchTerm(e.target.value)
    if (e.target.value !== '') {
      const _options = selections.filter(selection =>
        selection.label.toLowerCase().includes(e.target.value.toLowerCase()),
      )
      setOptions(_options)
    } else {
      setOptions([])
    }
  }

  const clear = () => {
    onFilterBy(null)
    setOptions([])
    setSearchTerm('')
  }

  const onHandleFilterBy = filter => {
    setSearchTerm(filter.label)
    onFilterBy(filter)
  }

  const handleOptionsClose = e => {
    if (
      !searchOptionsRef.current ||
      searchOptionsRef.current.contains(e.target)
    ) {
      return
    }
    setOptions(null)
  }

  useOnClickOutside(searchRef, handleOptionsClose, document)

  return (
    <>
      <AgFlex ref={searchRef} sx={styles.Search.Root}>
        <AgImage src={searchIcon} sx={styles.Search.Icon} />
        <AgInput
          onChange={handleOnChange}
          value={searchTerm}
          placeholder="Procedure search"
          sx={styles.Search.Input}
          data-testid="procedure-search"
        />
        <AgImage
          src={closeIcon}
          onClick={() => clear()}
          sx={styles.Search.Icon}
        />
      </AgFlex>
      {options ? (
        <AgAbsolute
          ref={searchOptionsRef}
          isEmpty={isEmpty(options)}
          sx={styles.Dropdown.Root}
        >
          {options.map(option => (
            <Option
              key={uniqueId('option_')}
              option={option}
              onClick={onHandleFilterBy}
              searchTerm={searchTerm}
            />
          ))}
        </AgAbsolute>
      ) : null}
    </>
  )
}
ProcedureSearch.propTypes = {
  selections: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
      selected: PropTypes.bool,
    }),
  ),
  onFilterBy: PropTypes.func,
}

export default ProcedureSearch

import {toJson, getHeaders, baseURL} from './utils'

const importReportEndpoint = `${baseURL}/api/reports/import-report`

export const fetchReport = toJson(async options => {
  const {search, signal, type} = options
  return fetch(`${importReportEndpoint}/${type}`.concat(search), {
    headers: await getHeaders(),
    signal,
  })
})

export const fetchReportDetailsById = toJson(async options => {
  const {search, signal, id, type} = options
  return fetch(`${importReportEndpoint}/${type}/${id}`.concat(search), {
    headers: await getHeaders(),
    signal,
  })
})

export const fetchReportExport = async options => {
  const {search, type} = options
  const url = `${importReportEndpoint}/${type}/export${search}`
  const result = await fetch(url, {
    headers: await getHeaders(),
  })
  const blob = await result.blob()
  return blob
}

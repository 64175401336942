import React from 'react'
import PropTypes from 'prop-types'
import {AgFlex, AgText} from '@aghealth/ag-components'

const CaseLevelView = ({hospital_case_number, surgeon_name}) => (
  <AgFlex flexDirection="column">
    <AgText fontSize="14px" data-testid="case-detail-surgeon-name">
      {surgeon_name}
    </AgText>
    <AgText fontSize="14px" color="#9CACBC">
      #{hospital_case_number}
    </AgText>
  </AgFlex>
)

CaseLevelView.propTypes = {
  hospital_case_number: PropTypes.string,
  surgeon_name: PropTypes.string,
}

export default CaseLevelView

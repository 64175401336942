import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {prefix} from './settings'
import ReactMarkdown from 'react-markdown'

export const MessageTextContent = ({text, className, children}) => {
  const cName = `${prefix}-message__text-content`

  const content = children ?? text

  return (
    <div className={classNames(cName, className)}>
      <ReactMarkdown>{content}</ReactMarkdown>
    </div>
  )
}

MessageTextContent.displayName = 'Message.TextContent'

MessageTextContent.propTypes = {
  /** Primary content - message text */
  children: PropTypes.string,

  /** Message text. Property has precedence over children */
  text: PropTypes.string,

  /** Additional classes. */
  className: PropTypes.string,
}

MessageTextContent.defaultProps = {}

export default MessageTextContent

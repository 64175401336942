import groupBy from 'lodash/groupBy'

const createGroup = (data, {by, key, value}) => {
  const groups = groupBy(data, d => d[by])
  const grouped = Object.entries(groups).reduce((acc, [group, data]) => {
    const datum = {[by]: group}
    data.forEach(d => {
      datum[d[key]] = d[value]
    })
    return [...acc, datum]
  }, [])

  return grouped
}

const createData = config => {
  if (config.chart?.y_scale?.group_key) {
    const result = createGroup(config.data, {
      by: config.chart.x_scale.key,
      key: config.chart.y_scale.group_key,
      value: config.chart.y_scale.keys[0],
    })
    return result
  }
  return config.data
}

export default createData

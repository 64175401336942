import orderBy from 'lodash/orderBy'
import {hasSupplyCategories, hasSupplyNames} from './utils'
import {format} from '../format'

const computeOrganizationFullName = d => {
  if (d.organization_name === d.organization_short_name)
    return d.organization_name
  return `${d.organization_name} (${d.organization_short_name})`
}

const withSurgeonNames = physician => ({
  ...physician,
  show_name: physician.surgeon_name,
  hide_name:
    physician.surgeon_id === -1
      ? physician.surgeon_name
      : `Physician ${physician.surgeon_id}`,
  show_name_asterisk: `*${physician.surgeon_name}`,
  hide_name_asterisk:
    physician.surgeon_id === -1
      ? `*${physician.surgeon_name}`
      : `*Physician ${physician.surgeon_id}`,
  id: physician.surgeon_id,
  selected: true,
  organization_full_name: computeOrganizationFullName(physician),
})

const createSupplyCategories = data => {
  const costs = data
    .filter(item => item.supply_cost)
    .map(item => item.supply_cost)
  const supply_max = Math.max(...costs)
  const quantities = data
    .filter(item => item.quantity_used)
    .map(item => item.quantity_used)
  const quantity_max = Math.max(...quantities)
  return data.map(d => ({
    ...d,
    supply_max,
    quantity_max,
    category_name: format(d.category_name, 'supply_category'),
  }))
}

const createSupplyNames = data =>
  orderBy(data, d => d.total_supply_cost, 'desc')

const createData = config => {
  const data = config.data.map(d =>
    d.surgeon_name
      ? {
          ...d,
          ...withSurgeonNames(d),
        }
      : d,
  )
  if (hasSupplyNames(config) && ![96].includes(config.id)) {
    return createSupplyNames(data)
  }
  if (hasSupplyCategories(config)) {
    return createSupplyCategories(data)
  }

  return data
}

export default createData

import max from 'lodash/max'
import min from 'lodash/min'
import {createKeysFromOptions, createBandAccessor} from '../utils'

const computeMaxValue = config => {
  if (config.id === 48)
    return max(config.unfiltered_data.map(d => d.total_supply_cost))
  if (config.chart.dimension === 'x') {
    if (config?.chart?.bottom_axis?.tick_format === '%') return 100
    return max(
      config.unfiltered_data.map(
        d =>
          d[
            config?.chart?.max_value_accessor
              ? config?.chart?.max_value_accessor
              : config?.chart?.x_accessor
          ],
      ),
    )
  }
  if (config?.chart?.left_axis?.tick_format === '%') return 100
  return undefined
}

const computeMinValue = config => {
  if (config?.chart?.dimension === 'x') {
    if (config?.unfiltered_data?.some(d => d[config?.chart?.x_accessor] < 0)) {
      return min(config.unfiltered_data.map(d => d[config?.chart?.x_accessor]))
    }
  }
  return config?.chart?.min
}

const createAccessors = config => {
  const keyAccessor = d => d[config.chart.key_accessor]
  const keys = config.options
    ? createKeysFromOptions(config)
    : config.chart.keys
  const yAccessor =
    config.chart.dimension === 'x'
      ? createBandAccessor(config)
      : d => d[config.chart.y_accessor]
  const xAccessor =
    config.chart.dimension === 'x'
      ? d => d[config.chart.x_accessor]
      : createBandAccessor(config)
  const max = computeMaxValue(config)
  const min = computeMinValue(config)
  return {
    keyAccessor,
    keys,
    yAccessor,
    xAccessor,
    min,
    max,
  }
}

export default createAccessors

import groupBy from 'lodash/groupBy'
import {hasPhysicians, toNumber} from './utils'
import {orderList} from '../../../../utils'

const createPhysiciansList = list =>
  Object.values(groupBy(list, d => d.surgeon_id)).reduce(
    (acc, value) => [
      ...acc,
      value.reduce(
        (acc, p) => ({
          ...p,
          case_count: acc.case_count + toNumber(p.case_count),
        }),
        {case_count: 0},
      ),
    ],
    [],
  )

const createPhysicians = data => {
  if (hasPhysicians(data)) {
    const list = createPhysiciansList(data)
    return orderList(list, 'surgeon_name')
  }

  return undefined
}

export default createPhysicians

import React from 'react'
import {AgSpan} from '@aghealth/ag-components'
import {Anchor} from '../../../components'

const MCIDInfo = () => (
  <AgSpan>
    MCID was calculated using the method outlined in this{' '}
    <Anchor href="https://journals.lww.com/lww-medicalcare/fulltext/2003/05000/Interpretation_of_Changes_in_Health_related.4.aspx">
      paper
    </Anchor>
  </AgSpan>
)

export default MCIDInfo

import {batch} from 'react-redux'
import isArray from 'lodash/isArray'
import {getDateRange} from '../utils'
import {withBailOnLoading, setQuery} from './utils'
import {
  selectOnlyOneById,
  selectOnlyOneByIndex,
  selectOneById,
  unselectOneById,
  everySelected,
  selectManyByIds,
  unselectManyByIds,
  selectAll,
  unselectAll,
  fromQueryString,
  everyUnselected,
  getLeafNodes,
} from '../../utils'
import {createPhysicians} from '../../pages/data-import-report/utils/physicians'
import {
  SET_REPORT_DATA,
  SET_REPORT_LOADING,
  SET_REPORT_NOT_LOADING,
  SET_REPORT_LOADING_DATA,
  SET_REPORT_NOT_LOADING_DATA,
  SET_REPORT_ERROR,
  SET_REPORT_FILTERS,
  SET_REPORT_TIME_PERIODS,
  SET_REPORT_TAB,
  SET_REPORT_PHYSICIAN,
  SET_REPORT_SEARCH,
  SET_REPORT_ALL_INPATIENT,
  SET_REPORT_ALL_OUTPATIENT,
  SET_REPORT_DRGS,
  SET_REPORT_OUTPATIENT_PROCEDURES,
  SET_REPORT_EXCLUSIONS,
  SET_REPORT_ALL_EXCLUSIONS,
  SET_REPORT_DATE_SAVED,
  SET_REPORT_ICDS,
  SET_REPORT_CPTS,
  SET_REPORT_EXCLUDE_IP,
  SET_REPORT_EXCLUDE_OP,
} from '../types'

import {fetchReport} from '../../api'

const setFetchReport =
  ({signal, initial, search}) =>
  (dispatch, getState) => {
    const {tabs} = getState().report
    const {type} = tabs.find(t => t.selected)
    if (initial) {
      dispatch(setLoading())
    } else {
      dispatch(setLoadingData())
    }
    return fetchReport({signal, search, type})
      .then(({data, filters}) => {
        dispatch(setData(data))
        dispatch(setPhysician(createPhysicians(data, type)))
        dispatch(setFilters(filters))
        if (initial) {
          dispatch(setNotLoading())
          dispatch(setDataNotLoading())
        } else {
          dispatch(setDataNotLoading())
        }
      })
      .catch(error => {
        dispatch(setError(error))
        if (initial) {
          dispatch(setNotLoading())
        } else {
          dispatch(setDataNotLoading())
        }
      })
  }

const setFilters = (payload, meta) => ({
  type: SET_REPORT_FILTERS,
  payload,
  meta,
})

const setData = payload => ({type: SET_REPORT_DATA, payload})

const setError = payload => ({type: SET_REPORT_ERROR, payload})

const setLoading = () => ({type: SET_REPORT_LOADING})

const setNotLoading = () => ({type: SET_REPORT_NOT_LOADING})

const setLoadingData = () => ({type: SET_REPORT_LOADING_DATA})

const setDataNotLoading = () => ({type: SET_REPORT_NOT_LOADING_DATA})

const setTab = payload => ({type: SET_REPORT_TAB, payload})

const setTimePeriods = payload => ({type: SET_REPORT_TIME_PERIODS, payload})

const setPhysician = payload => ({type: SET_REPORT_PHYSICIAN, payload})

const setSearch = payload => ({type: SET_REPORT_SEARCH, payload})

const setAllInpatient = payload => ({type: SET_REPORT_ALL_INPATIENT, payload})

const setAllOutpatient = payload => ({type: SET_REPORT_ALL_OUTPATIENT, payload})

const setDrgs = payload => ({type: SET_REPORT_DRGS, payload})

const setOutpatientProcedures = payload => ({
  type: SET_REPORT_OUTPATIENT_PROCEDURES,
  payload,
})

const setExclusions = payload => ({type: SET_REPORT_EXCLUSIONS, payload})

const setAllExclusions = payload => ({type: SET_REPORT_ALL_EXCLUSIONS, payload})

const setDateSaved = payload => ({type: SET_REPORT_DATE_SAVED, payload})

const setIcds = payload => ({type: SET_REPORT_ICDS, payload})

const setCpts = payload => ({type: SET_REPORT_CPTS, payload})

const setExcludeIp = payload => ({type: SET_REPORT_EXCLUDE_IP, payload})

const setExcludeOp = payload => ({type: SET_REPORT_EXCLUDE_OP, payload})

const fetch =
  ({signal, search, initial, reportType, bail = false}) =>
  (dispatch, getState) => {
    const {
      tabs: current_tabs,
      date_saved: current_date_saved,
      all_exclusions: current_all_exclusions,
      all_inpatient: current_all_inpatient,
      all_outpatient: current_all_outpatient,
    } = getState().report
    const tabs = current_tabs.map(t => ({
      ...t,
      selected: reportType === t.type,
    }))
    const {
      date_saved = false,
      all_inpatient = false,
      all_outpatient = false,
      all_exclusions = false,
    } = fromQueryString(search, [
      'date_saved',
      'all_inpatient',
      'all_outpatient',
      'all_exclusions',
    ])
    batch(() => {
      if (date_saved !== current_date_saved) {
        dispatch(setDateSaved(date_saved))
      }
      if (all_inpatient !== current_all_inpatient) {
        dispatch(setAllInpatient(all_inpatient))
      }
      if (all_outpatient !== current_all_outpatient) {
        dispatch(setAllOutpatient(all_outpatient))
      }
      if (all_exclusions !== current_all_exclusions) {
        dispatch(setAllExclusions(all_exclusions))
      }
      dispatch(setTab(tabs))
    })

    if (!bail) {
      dispatch(setFetchReport({signal, search, initial}))
    }
  }

const selectTimePeriod = (payload, history) => (dispatch, getState) => {
  const {time_periods: current_time_periods} = getState().report.filters
  const time_periods = {
    ...current_time_periods,
    selected_start: payload.selected_start,
    selected_end: payload.selected_end,
    values: current_time_periods.values.map(selectOnlyOneById(payload.value)),
  }
  if (payload.value.id === 5 && payload.selected_start === '') {
    const {id} = current_time_periods.values.find(d => d.selected)
    const range = getDateRange(id, time_periods.max)
    dispatch(setTimePeriods({...time_periods, ...range}))
  } else {
    batch(() => {
      dispatch(setDateSaved(true))
      dispatch(setTimePeriods(time_periods))
      dispatch(
        setQuery({
          history,
          store: 'report',
          onLoading: setLoadingData,
          overrides: {
            time_periods,
          },
        }),
      )
    })
  }
}

const selectTab = payload => (dispatch, getState) => {
  const {tabs: current_tabs} = getState().report
  const tabs = current_tabs.map(selectOnlyOneByIndex(payload))
  batch(() => {
    dispatch(setSearch(''))
    dispatch(setLoadingData())
    dispatch(setTab(tabs))
  })
}

const togglePhysician = payload => (dispatch, getState) => {
  const {physicians: current_physicians} = getState().report
  const physicians = current_physicians.map(
    payload.selected ? unselectOneById(payload) : selectOneById(payload),
  )
  dispatch(setPhysician(physicians))
}

const toggleAllPhysicians = () => (dispatch, getState) => {
  const {physicians: current_physicians} = getState().report
  const everyPhysicianSelected = everySelected(current_physicians)
  const physicians = current_physicians.map(
    everyPhysicianSelected ? unselectAll : selectAll,
  )
  dispatch(setPhysician(physicians))
}

const toggleDrg = (payload, history) => (dispatch, getState) => {
  const {filters: current_filters, all_outpatient: current_all_outpatient} =
    getState().report
  const {
    drgs: current_drgs,
    icds,
    cpts,
    outpatient_procedures,
  } = current_filters
  let drgs
  if (isArray(payload?.items)) {
    const nodes = getLeafNodes(payload)
    const payload_ids = nodes.map(p => p.id)
    drgs = current_drgs.map(
      nodes.every(p => p.selected)
        ? unselectManyByIds(payload_ids)
        : selectManyByIds(payload_ids),
    )
  } else {
    drgs = current_drgs.map(
      payload.selected ? unselectOneById(payload) : selectOneById(payload),
    )
  }
  const everyIpUnselected = everyUnselected(icds, drgs)
  const everyOpUnselected =
    everyUnselected(cpts, outpatient_procedures) && !current_all_outpatient
  batch(() => {
    dispatch(setDrgs(drgs))
    if (everyIpUnselected && everyOpUnselected) {
      dispatch(setExcludeIp(false))
      dispatch(setExcludeOp(false))
    } else if (everyIpUnselected && !everyOpUnselected) {
      dispatch(setExcludeIp(true))
      dispatch(setExcludeOp(false))
    } else {
      dispatch(setExcludeIp(false))
      dispatch(setExcludeOp(everyOpUnselected))
    }
    dispatch(
      setQuery({
        history,
        store: 'report',
        onLoading: setLoadingData,
      }),
    )
  })
}

const toggleOutpatientProcedure =
  (payload, history) => (dispatch, getState) => {
    const {filters: current_filters, all_inpatient: current_all_inpatient} =
      getState().report
    const {
      outpatient_procedures: current_outpatient_procedures,
      cpts,
      icds,
      drgs,
    } = current_filters
    let outpatient_procedures
    if (isArray(payload?.items)) {
      const nodes = getLeafNodes(payload)
      const payload_ids = nodes.map(p => p.id)
      outpatient_procedures = current_outpatient_procedures.map(
        nodes.every(p => p.selected)
          ? unselectManyByIds(payload_ids)
          : selectManyByIds(payload_ids),
      )
    } else {
      outpatient_procedures = current_outpatient_procedures.map(
        payload.selected ? unselectOneById(payload) : selectOneById(payload),
      )
    }
    const everyIpUnselected = everyUnselected(icds, drgs)
    const everyOpUnselected =
      everyUnselected(cpts, outpatient_procedures) && !current_all_inpatient
    batch(() => {
      dispatch(setOutpatientProcedures(outpatient_procedures))
      if (everyIpUnselected && everyOpUnselected) {
        dispatch(setExcludeIp(false))
        dispatch(setExcludeOp(false))
      } else if (everyIpUnselected && !everyOpUnselected) {
        dispatch(setExcludeIp(true))
        dispatch(setExcludeOp(false))
      } else {
        dispatch(setExcludeIp(false))
        dispatch(setExcludeOp(everyOpUnselected))
      }
      dispatch(
        setQuery({
          history,
          store: 'report',
          onLoading: setLoadingData,
        }),
      )
    })
  }

const toggleExclusions = (payload, history) => (dispatch, getState) => {
  const {exclusions: current_exclusions} = getState().report.filters
  const exclusions = current_exclusions.map(
    payload.selected ? unselectOneById(payload) : selectOneById(payload),
  )
  batch(() => {
    dispatch(setExclusions(exclusions))
    dispatch(
      setQuery({
        history,
        store: 'report',
        onLoading: setLoadingData,
      }),
    )
  })
}

const toggleAllExclusions = history => (dispatch, getState) => {
  const {all_exclusions: current_all_exclusions} = getState().report
  const all_exclusions = !current_all_exclusions
  batch(() => {
    dispatch(setAllExclusions(all_exclusions))
    dispatch(
      setQuery({
        history,
        store: 'report',
        omit: ['exclusions'],
        onLoading: setLoadingData,
      }),
    )
  })
}

const toggleDateSaved = history => (dispatch, getState) => {
  const {date_saved: current_date_saved} = getState().report
  const date_saved = !current_date_saved
  batch(() => {
    dispatch(setDateSaved(date_saved))
    dispatch(
      setQuery({
        history,
        store: 'report',
        fetch: false,
        onLoading: setLoadingData,
      }),
    )
  })
}
const applyProcedures = (payload, history) => dispatch => {
  const everyIpUnselected =
    !payload.all_inpatient && everyUnselected(payload.icds, payload.drgs)
  const everyOpUnselected =
    !payload.all_outpatient && everyUnselected(payload.cpts, payload.procedures)
  batch(() => {
    dispatch(setDrgs(payload.drgs))
    dispatch(setCpts(payload.cpts))
    dispatch(setOutpatientProcedures(payload.procedures))
    dispatch(setIcds(payload.icds))
    if (everyIpUnselected && everyOpUnselected) {
      dispatch(setExcludeIp(false))
      dispatch(setExcludeOp(false))
    } else if (everyOpUnselected && !everyIpUnselected) {
      dispatch(setExcludeOp(true))
      dispatch(setExcludeIp(false))
    } else if (everyIpUnselected && !everyOpUnselected) {
      dispatch(setExcludeIp(true))
      dispatch(setExcludeOp(false))
    } else {
      dispatch(setExcludeOp(everyOpUnselected))
      dispatch(setExcludeIp(everyIpUnselected))
    }
    dispatch(setAllOutpatient(payload.all_outpatient))
    dispatch(setAllInpatient(payload.all_inpatient))
    dispatch(
      setQuery({
        history,
        onLoading: setLoadingData,
        store: 'report',
      }),
    )
  })
}
const applyExclusions = (payload, history) => dispatch => {
  const all_exclusions = payload?._exclusions[0]
  const exclusions = payload?._exclusions[0]?.items

  batch(() => {
    dispatch(setAllExclusions(all_exclusions.selected))
    dispatch(setExclusions(exclusions))
    dispatch(
      setQuery({
        history,
        store: 'report',
        onLoading: setLoadingData,
      }),
    )
  })
}
const resetFilters = history => (dispatch, getState) => {
  const {
    icds: current_icds,
    drgs: current_drgs,
    cpts: current_cpts,
    exclusions: current_exclusions,
    outpatient_procedures: current_outpatient_procedures,
    time_periods: current_time_periods,
  } = getState().report.filters
  const icds = current_icds.map(unselectAll)
  const drgs = current_drgs.map(unselectAll)
  const cpts = current_cpts.map(unselectAll)
  const exclusions = current_exclusions.map(unselectAll)
  const outpatient_procedures = current_outpatient_procedures.map(unselectAll)
  const time_periods = {
    ...current_time_periods,
    selected_start: '',
    selected_end: '',
    values: current_time_periods.values.map(selectOnlyOneByIndex(0)),
  }
  batch(() => {
    dispatch(setDateSaved(false))
    dispatch(setAllExclusions(false))
    dispatch(setAllInpatient(false))
    dispatch(setAllOutpatient(false))
    dispatch(setTimePeriods(time_periods))
    dispatch(setExcludeIp(null))
    dispatch(setExcludeOp(null))
    dispatch(setIcds(icds))
    dispatch(setDrgs(drgs))
    dispatch(setCpts(cpts))
    dispatch(setExclusions(exclusions))
    dispatch(setOutpatientProcedures(outpatient_procedures))
    dispatch(
      setQuery({
        history,
        store: 'report',
        overrides: {
          cpts,
          icds,
          drgs,
          exclusions,
          outpatient_procedures,
          exclude_ip: null,
          exclude_op: null,
          all_inpatient: false,
          all_outpatient: false,
          all_exclusions: false,
          date_saved: false,
        },
        onLoading: setLoadingData,
      }),
    )
  })
}

const toggleAllIpProcedures = history => (dispatch, getState) => {
  const {
    all_inpatient: current_all_inpatient,
    all_outpatient: current_all_outpatient,
    filters: current_filters,
  } = getState().report
  const {cpts, outpatient_procedures} = current_filters
  const all_inpatient = !current_all_inpatient
  const everyIpUnselected = !all_inpatient
  const everyOpUnselected =
    everyUnselected(cpts, outpatient_procedures) && !current_all_outpatient
  batch(() => {
    if (everyIpUnselected && everyOpUnselected) {
      dispatch(setExcludeIp(false))
      dispatch(setExcludeOp(false))
    } else if (everyIpUnselected && !everyOpUnselected) {
      dispatch(setExcludeIp(true))
      dispatch(setExcludeOp(false))
    } else {
      dispatch(setExcludeIp(false))
      dispatch(setExcludeOp(everyOpUnselected))
    }
    dispatch(setAllInpatient(all_inpatient))
    dispatch(
      setQuery({
        history,
        store: 'report',
        omit: ['icds', 'drgs'],
        onLoading: setLoadingData,
      }),
    )
  })
}

const toggleAllOpProcedures = history => (dispatch, getState) => {
  const {
    all_outpatient: current_all_outpatient,
    all_inpatient: current_all_inpatient,
    filters: current_filters,
  } = getState().report
  const {icds, drgs} = current_filters
  const all_outpatient = !current_all_outpatient
  const everyOpUnselected = !all_outpatient
  const everyIpUnselected =
    everyUnselected(icds, drgs) && !current_all_inpatient
  batch(() => {
    if (everyIpUnselected && everyOpUnselected) {
      dispatch(setExcludeIp(false))
      dispatch(setExcludeOp(false))
    } else if (everyOpUnselected && !everyIpUnselected) {
      dispatch(setExcludeOp(true))
      dispatch(setExcludeIp(false))
    } else {
      dispatch(setExcludeOp(false))
      dispatch(setExcludeIp(everyIpUnselected))
    }
    dispatch(setAllOutpatient(all_outpatient))
    dispatch(
      setQuery({
        history,
        store: 'report',
        omit: ['cpts', 'outpatient_procedures'],
        onLoading: setLoadingData,
      }),
    )
  })
}

const toggleIcd = (payload, history) => (dispatch, getState) => {
  const {filters: current_filters, all_outpatient: current_all_outpatient} =
    getState().report
  const {
    icds: current_icds,
    drgs,
    cpts,
    outpatient_procedures,
  } = current_filters
  let icds
  if (isArray(payload?.items)) {
    const nodes = getLeafNodes(payload)
    const payload_ids = nodes.map(p => p.id)
    icds = current_icds.map(
      nodes.every(p => p.selected)
        ? unselectManyByIds(payload_ids)
        : selectManyByIds(payload_ids),
    )
  } else {
    icds = current_icds.map(
      payload.selected ? unselectOneById(payload) : selectOneById(payload),
    )
  }
  const everyIpUnselected = everyUnselected(icds, drgs)
  const everyOpUnselected =
    everyUnselected(cpts, outpatient_procedures) && !current_all_outpatient
  batch(() => {
    dispatch(setIcds(icds))
    if (everyIpUnselected && everyOpUnselected) {
      dispatch(setExcludeIp(false))
      dispatch(setExcludeOp(false))
    } else if (everyIpUnselected && !everyOpUnselected) {
      dispatch(setExcludeIp(true))
      dispatch(setExcludeOp(false))
    } else {
      dispatch(setExcludeIp(false))
      dispatch(setExcludeOp(everyOpUnselected))
    }
    dispatch(
      setQuery({
        history,
        store: 'report',
        onLoading: setLoadingData,
      }),
    )
  })
}

const toggleCpt = (payload, history) => (dispatch, getState) => {
  const {filters: current_filters, all_inpatient: current_all_inpatient} =
    getState().report
  const {
    cpts: current_cpts,
    outpatient_procedures,
    icds,
    drgs,
  } = current_filters
  let cpts
  if (isArray(payload?.items)) {
    const nodes = getLeafNodes(payload)
    const payload_ids = nodes.map(p => p.id)
    cpts = current_cpts.map(
      nodes.every(p => p.selected)
        ? unselectManyByIds(payload_ids)
        : selectManyByIds(payload_ids),
    )
  } else {
    cpts = current_cpts.map(
      payload.selected ? unselectOneById(payload) : selectOneById(payload),
    )
  }
  const everyIpUnselected =
    everyUnselected(drgs, icds) && !current_all_inpatient
  const everyOpUnselected = everyUnselected(cpts, outpatient_procedures)
  batch(() => {
    dispatch(setCpts(cpts))
    if (everyOpUnselected && everyIpUnselected) {
      dispatch(setExcludeIp(false))
      dispatch(setExcludeOp(false))
    } else if (everyOpUnselected && !everyIpUnselected) {
      dispatch(setExcludeOp(true))
      dispatch(setExcludeIp(false))
    } else {
      dispatch(setExcludeOp(false))
      dispatch(setExcludeIp(everyIpUnselected))
    }
    dispatch(
      setQuery({
        history,
        store: 'report',
        onLoading: setLoadingData,
      }),
    )
  })
}

const actions = {
  fetch,
  selectTab,
  togglePhysician,
  toggleAllPhysicians,
  setSearch,
  toggleDateSaved: withBailOnLoading(toggleDateSaved, 'report'),
  toggleIcd: withBailOnLoading(toggleIcd, 'report'),
  toggleCpt: withBailOnLoading(toggleCpt, 'report'),
  toggleAllIpProcedures: withBailOnLoading(toggleAllIpProcedures, 'report'),
  toggleAllOpProcedures: withBailOnLoading(toggleAllOpProcedures, 'report'),
  resetFilters: withBailOnLoading(resetFilters, 'report'),
  selectTimePeriod: withBailOnLoading(selectTimePeriod, 'report'),
  toggleDrg: withBailOnLoading(toggleDrg, 'report'),
  toggleOutpatientProcedure: withBailOnLoading(
    toggleOutpatientProcedure,
    'report',
  ),
  toggleExclusions: withBailOnLoading(toggleExclusions, 'report'),
  toggleAllExclusions: withBailOnLoading(toggleAllExclusions, 'report'),
  applyProcedures: withBailOnLoading(applyProcedures, 'report'),
  applyExclusions: withBailOnLoading(applyExclusions, 'report'),
}

export default actions

import {baseURL, getHeaders} from './utils'

const endpoint = `${baseURL}/api/scalpey/v2/chat`

const feedbackEndpoint = `${baseURL}/api/scalpey/v2/chat_feedback`

export const fetchChatbot = async options => {
  const {method = 'POST', body = {}} = options
  return fetch(endpoint, {
    method,
    headers: await getHeaders(),
    body: JSON.stringify(body),
  }).then(r => {
    if (/2[0-9][0-9]/g.test(r.status)) {
      return r.json()
    } else {
      return Promise.reject()
    }
  })
}

export const fetchChatbotById = async id => {
  return fetch(`${endpoint}/${id}`, {
    headers: await getHeaders(),
  }).then(r => {
    if (/2[0-9][0-9]|404/g.test(r.status)) {
      return r.json()
    } else {
      return Promise.reject()
    }
  })
}

export const fetchChatbotFeedback = async options => {
  const {method = 'POST', body = {}} = options
  return fetch(feedbackEndpoint, {
    method,
    headers: await getHeaders(),
    body: JSON.stringify(body),
  }).then(r => {
    if (/2[0-9][0-9]/g.test(r.status)) {
      return r.json()
    } else {
      return Promise.reject()
    }
  })
}

import isFunction from 'lodash/isFunction'
import {createFormatTick, createAxisStyles} from '../utils'

const createAxes = (config, dimensions) => {
  const leftAxis = {
    tickCount: 5,
    showGridLines: config.chart.dimension === 'y',
    formatTick: createFormatTick(config, 'left_axis'),
    onClickTickSublabel:
      config.id === 48
        ? (e, meta) =>
            isFunction(config?.showSupplyDetailsView)
              ? config.showSupplyDetailsView(meta.id)
              : null
        : () => {},
    styles: createAxisStyles(config),
    showTickCheckbox:
      [48].includes(config.id) && !config.hideCaseRemovalButtons,
    tickBandwidth: [48].includes(config.id) ? dimensions.tickWidth : undefined,
    tickFormat: config.chart.left_axis
      ? config.chart.left_axis.tick_format
      : null,
  }

  const computeTickRotation = config => (config.data.length > 0 ? -45 : 0)

  const bottomAxis = {
    labels: config.chart.bottom_axis?.labels,
    tickRotation: computeTickRotation(config),
    tickCount: 5,
    showGridLines: config.chart.dimension === 'x',
    formatTick: createFormatTick(config, 'bottom_axis'),
    styles: createAxisStyles(config),
  }

  if (
    ['Celsius', 'Celcius', '', 'mL', 'Kg', 'kg'].includes(leftAxis.tickFormat)
  ) {
    leftAxis.tickFormat = null
  }

  return {
    leftAxis,
    bottomAxis,
  }
}

export default createAxes

export const SET_PAC_FILTERS = 'SET_PAC_FILTERS'
export const SET_PAC_DATA = 'SET_PAC_DATA'
export const SET_PAC_LOADING = 'SET_PAC_LOADING'
export const SET_PAC_NOT_LOADING = 'SET_PAC_NOT_LOADING'
export const SET_PAC_ERROR = 'SET_PAC_ERROR'
export const SET_PAC_FILTERS_LOADING = 'SET_PAC_FILTERS_LOADING'
export const SET_PAC_ROWS_LIMIT = 'SET_ROWS_LIMIT'
export const SET_PAC_CURRENT_PAGE = 'SET_PAC_CURRENT_PAGE'
export const SET_PAC_TOTAL_ROWS = 'SET_PAC_TOTAL_ROWS'
export const SET_PAC_SORT_BY = 'SET_PAC_SORT_BY'
export const SET_PAC_SORT_DIRECTION = 'SET_PAC_SORT_DIRECTION'
export const SET_PAC_TOTAL_PAGES = 'SET_PAC_TOTAL_PAGES'
export const TOGGLE_PAC_HIDE_FACILITIES = 'TOGGLE_PAC_HIDE_FACILITIES'
export const PAC_HIDE_FACILITIES = 'PAC_HIDE_FACILITIES'

import React from 'react'
import {MCIDInfo} from '../../../components'

const createLabels = config => {
  const info = [5, 15, 16, 17].includes(config.id) ? <MCIDInfo /> : null
  const title = config.chart.title ? config.chart.title : null
  return {info, title}
}

export default createLabels

export const SET_GOALS_DATA = 'SET_GOALS_DATA'
export const SET_GOALS_LOADING = 'SET_GOALS_LOADING'
export const SET_GOALS_NOT_LOADING = 'SET_GOALS_NOT_LOADING'
export const SET_GOALS_LOADING_DATA = 'SET_GOALS_LOADING_DATA'
export const SET_GOALS_NOT_LOADING_DATA = 'SET_GOALS_NOT_LOADING_DATA'
export const SET_GOALS_ERROR = 'SET_GOALS_ERROR'
export const SET_GOALS_SUMMARY_DATA = 'SET_GOALS_SUMMARY_DATA'
export const SET_GOALS_FILTERS = 'SET_GOALS_FILTERS'
export const SET_GOALS_DEPARTMENTS = 'SET_GOALS_DEPARTMENTS'
export const SET_GOALS_SHOW_ARCHIVED = ' SET_GOALS_SHOW_ARCHIVED'

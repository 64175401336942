import React from 'react'
import PropTypes from 'prop-types'
import {AgSelections} from '@aghealth/ag-components'

const styles = {
  Root: {
    maxHeight: 150,
    variant: 'scrollbars.y',
  },
  Selection: {
    fontSize: '14px',
    mt: '8px',
  },
}

const Selections = ({selections, onSelect}) => (
  <AgSelections selections={selections} onSelect={onSelect} styles={styles} />
)

Selections.propTypes = {
  selections: PropTypes.array,
  onSelect: PropTypes.func,
}

export default Selections

import queryString from 'query-string'
import isEmpty from 'lodash/isEmpty'
import lowerCase from 'lodash/lowerCase'
import capitalize from 'lodash/capitalize'
import omit from 'lodash/omit'
import {isNullType, filterEntries} from '.'

export const toQueryString = (obj, allowed = []) => {
  const filtered = filterEntries(obj, ([key, value]) =>
    !isEmpty(allowed)
      ? allowed.includes(key) && !isNullType(value)
      : !isNullType(value),
  )
  const query = queryString.stringify(filtered, {
    skipNull: true,
    arrayFormat: 'comma',
    encode: true,
  })
  return !isEmpty(query) ? '?'.concat(query) : ''
}

export const fromQueryString = (str, filters = []) => {
  const params = queryString.parse(str, {
    parseBooleans: true,
    parseNumbers: true,
    arrayFormat: 'comma',
  })
  const filtered = filterEntries(params, ([key, value]) =>
    !isEmpty(filters)
      ? filters.includes(key) && !isNullType(value)
      : !isNullType(value),
  )
  return filtered
}

export const toCommonQueryString = str => {
  const {
    date_saved,
    time_period_id,
    date_of_surgery_start,
    date_of_surgery_end,
    risk_adjusted,
  } = fromQueryString(str, [
    'date_saved',
    'time_period_id',
    'date_of_surgery_start',
    'date_of_surgery_end',
    'risk_adjusted',
  ])
  const payload = date_saved
    ? {
        date_saved,
        time_period_id,
        date_of_surgery_start,
        date_of_surgery_end,
        risk_adjusted,
      }
    : {risk_adjusted}
  return toQueryString(payload)
}

export const toCommonQueryStringV2 = search => {
  const {
    date_saved,
    time_period_id,
    date_of_surgery_start,
    date_of_surgery_end,
    risk_adjusted,
    department_ids,
    procedure_group_ids,
  } = fromQueryString(search, [
    'date_saved',
    'time_period_id',
    'date_of_surgery_start',
    'date_of_surgery_end',
    'risk_adjusted',
    'department_ids',
    'procedure_group_ids',
  ])

  const payload = date_saved
    ? {
        date_saved,
        time_period_id,
        date_of_surgery_start,
        date_of_surgery_end,
        risk_adjusted,
        department_ids,
        procedure_group_ids,
      }
    : {risk_adjusted, department_ids, procedure_group_ids}
  return toQueryString(payload)
}

export const toFilteredQueryString = (str, filters) => {
  const params = fromQueryString(str)
  const payload = omit(params, filters)
  return toQueryString(payload)
}

export const toUrl = str => {
  const url = `/${str.split(' ').map(lowerCase).join('-')}`
  return url
}

const handleCapitalize = word =>
  !['and', 'or'].includes(word) ? capitalize(word) : word

export const fromUrl = str =>
  str.slice(1).split('-').map(handleCapitalize).join(' ')

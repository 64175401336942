import React from 'react'
import PropTypes from 'prop-types'
import {AgFlex, AgText} from '@aghealth/ag-components'

const SupplyDetails = ({
  hospital_case_number,
  surgeon_name,
  drg_name,
  date_of_surgery,
  showSupplyDetailsView,
}) => (
  <AgFlex flexDirection="column">
    <AgFlex>
      <AgText fontSize="14px" fontWeight="xlg" color="#041424">
        Case No.&nbsp;
      </AgText>
      <AgText
        fontSize="14px"
        fontWeight="xlg"
        color="#4A90E2"
        style={{textDecoration: 'underline'}}
        onClick={() => showSupplyDetailsView(hospital_case_number)}
      >
        {`${hospital_case_number}`}
      </AgText>
    </AgFlex>
    <AgText fontSize="base" color="#595C5F" pt={2}>
      {drg_name}
    </AgText>
    <AgText
      fontSize="base"
      color="#595C5F"
      data-testid="supply-details-table-surgeon-name"
    >
      {surgeon_name}
    </AgText>
    <AgText fontSize="base" color="#595C5F">
      {`Date of Surgery: ${new Date(`${date_of_surgery}`).toLocaleDateString(
        'en-US',
        {
          year: '2-digit',
          month: 'numeric',
          day: 'numeric',
          timeZone: 'UTC',
        },
      )}`}
    </AgText>
  </AgFlex>
)

SupplyDetails.propTypes = {
  hospital_case_number: PropTypes.string,
  surgeon_name: PropTypes.string,
  drg_name: PropTypes.string,
  date_of_surgery: PropTypes.string,
  showSupplyDetailsView: PropTypes.func,
}

export default SupplyDetails

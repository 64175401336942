import React from 'react'
import {toast} from 'react-hot-toast'
import {AnimatedToast} from '../components'

const duration = 3000

const useToast = messages => {
  React.useEffect(() => {
    messages?.forEach(message => {
      // Display the toast with a custom component
      toast.custom(
        t => (
          <AnimatedToast
            message={message}
            onRemove={() => toast.remove(t.id)}
            duration={duration}
          />
        ),
        {
          duration: duration,
          position: 'bottom-center',
        },
      )

      // No need to manually remove the toast here as `onRemove` callback will handle it
    })
  }, [messages])
}

export default useToast

import React from 'react'
import PropTypes from 'prop-types'

const WindowRedirect = ({url}) => {
  React.useEffect(() => {
    window.location.replace(url)
  }, [url])
  return <div />
}

WindowRedirect.propTypes = {
  url: PropTypes.string,
}

export default WindowRedirect

import {baseURL, getHeaders, toJson} from './utils'

import {toQueryString} from '../utils'

const endpoint = `${baseURL}/api/insights/v2/researchsummary/`

export const fetchResearchSummary = toJson(async options => {
  const {signal, id, insightId} = options

  const params = toQueryString({insightId})

  const url = endpoint.concat(id) + params

  return fetch(url, {
    signal,
    headers: await getHeaders(),
  })
})

import {useDidMount as _useDidMount} from '@aghealth/ag-components'

const useDidMount = callback => {
  _useDidMount(() => {
    const controller = new AbortController()
    callback(controller.signal)
    return () => {
      controller.abort()
    }
  })
}

export default useDidMount

import {createGlobalStyle} from 'styled-components'

export default createGlobalStyle`
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number']:hover::-webkit-inner-spin-button,
  input[type='number']:hover::-webkit-outer-spin-button {
    /* Styles for hover state */
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
  @keyframes fade-in-from-left {
     0% {
      opacity: 0.6;
      left: 100%;
    }
    25% {
      opacity: 0.7;
      left: 80%;
    }
    50% {
      opacity: 0.8;
      left: 60%;
    }
    75% {
      opacity: 0.9;
      left: 40%;
    }
    100% {
      opacity: 1;
      left: 20%;
    }
  }
`

import React from 'react'
import PropTypes from 'prop-types'
import {AgFlex} from '@aghealth/ag-components'

const ScrollY = ({children, sx, ...props}) => (
  <AgFlex
    {...props}
    flexDirection="column"
    sx={sx}
  >
    {children}
  </AgFlex>
)

ScrollY.propTypes = {
  children: PropTypes.node,
  sx: PropTypes.object,
}
ScrollY.defaultProps = {
  sx: {
    paddingRight: '8px',
    variant: 'scrollbars.y',
  },
}

export default ScrollY

const zIndices = {
  sm: -1,
  base: 1,
  md: 10,
  lg: 100,
  xlg: 1000,
  xxlg: 10000,
  xxxlg: 100000,
  xxxxlg: 1000000,
}

export default zIndices

import React, {Suspense} from 'react'
import PropTypes from 'prop-types'
import {lazyRetry} from '../../../utils'

const BarChart = lazyRetry(
  () =>
    import(
      /* webpackChunkName: "bar-chart" */
      /* webpackPrefetch: true */
      './BarChart'
    ),
  'explore-data-bar-chart',
)

const LineChart = lazyRetry(
  () =>
    import(
      /* webpackChunkName: "line-chart" */
      /* webpackPrefetch: true */
      './LineChart'
    ),
  'explore-data-line-chart',
)

const BubbleChart = lazyRetry(
  () =>
    import(
      /* webpackChunkName: "bubble-chart" */
      /* webpackPrefetch: true */
      './BubbleChart'
    ),
  'explore-data-bubble-chart',
)

const Charts = {
  bar: BarChart,
  line: LineChart,
  bubble: BubbleChart,
}

const Chart = ({type, showFootnote, ...props}) => {
  const Component = Charts[type] ? Charts[type] : null

  return (
    <Suspense fallback={null}>
      <div data-testid="chart">
        <Component showFootnote={showFootnote} {...props} />
      </div>
    </Suspense>
  )
}

Chart.propTypes = {
  type: PropTypes.string,
  showFootnote: PropTypes.bool,
}

export default Chart

import React from 'react'
import trim from 'lodash/trim'
import toLower from 'lodash/toLower'
import isEmpty from 'lodash/isEmpty'
import {useDidUpdate} from '@aghealth/ag-components'

const isPrevious = (next, current) => next.length < current.length

const useSearch = list => {
  const [search, setSearch] = React.useState('')
  const [current, setCurrent] = React.useState(list)
  const [previous, setPrevious] = React.useState([])
  const unsearched = React.useMemo(
    () => previous.reduce((acc, prev) => [...acc, ...prev], []),
    [previous],
  )
  useDidUpdate(() => {
    if (isEmpty(search)) {
      setCurrent(list)
    }
  }, [list, search])

  const handleSetSearchTerm = React.useCallback(
    value => {
      if (isPrevious(value, search)) {
        setSearch(value)
        const formerPrevious = previous.pop()
        const nextCurrent = [...formerPrevious, ...current]
        setCurrent(nextCurrent)
        setPrevious([...previous])
      } else {
        setSearch(value)
        const nextPrevious = []
        setCurrent(
          current.filter(c => {
            const term = toLower(trim(value))
            const name = toLower(trim(c.name))
            if (name.includes(term)) {
              return true
            }
            nextPrevious.push(c)
            return false
          }),
        )
        setPrevious([...previous, nextPrevious])
      }
    },
    [current, previous, search],
  )

  const handleSetCurrent = React.useCallback(newCurrent => {
    setCurrent(newCurrent)
  }, [])

  return [
    {
      searched: current,
      unsearched,
      searchTerm: search,
    },
    {
      setSearchTerm: handleSetSearchTerm,
      setSearched: handleSetCurrent,
    },
  ]
}

export default useSearch

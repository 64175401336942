/* eslint-disable no-plusplus */
/* eslint-disable max-len */
import groupBy from 'lodash/groupBy'
import isArray from 'lodash/isArray'
import isNumber from 'lodash/isNumber'
import size from 'lodash/size'
import sum from 'lodash/sum'
import orderBy from 'lodash/orderBy'
import times from 'lodash/times'
import isNaN from 'lodash/isNaN'
import isObject from 'lodash/isPlainObject'
import omit from 'lodash/omit'
import isEmpty from 'lodash/isEmpty'
import {createSelector} from 'reselect'
import {format} from './format'
import {
  fromQueryString,
  selectIsSystemView,
  computeChipLabel,
  getSelectedValues,
} from '../../../utils'

const computeWeightedAverage = (d, key, total) =>
  (d.case_count / total) * d[key]

const getDigitsAmtFromKey = key => {
  if (key === 'risk_score') return 4
  return 1
}

const toDigits = (num, key) => {
  if (!isNumber(num) || isNaN(num)) return null
  const amt = getDigitsAmtFromKey(key)
  const str = num.toFixed(amt)
  const result = Number(str)
  return result
}

export const excludeMedicareData = data => {
  const newData = []
  data.forEach(d => {
    if (d.insufficient_data) {
      newData.push(
        omit(d, [
          'home_health_agency_spend',
          'inpatient_spend',
          'medicare_part_b_spend',
          'other_outpatient_spend',
          'readmission_spend',
          'skilled_nursing_facility_spend',
          'total_spend',
          'readmission_count',
          'readmission_percent',
          'complication_count',
          'complication_percent',
        ]),
      )
    } else {
      newData.push(d)
    }
  })
  return newData
}

export const withWeightedAverages = (data, key) => {
  if (!key) return data
  const hasAvg = data?.some(d => d.id === -1)
  const onlyAvg = data?.every(d => d.id === -1)
  if (onlyAvg) return data
  if (!hasAvg) return data
  const non_averages = data.filter(d => ![-1].includes(d.id))
  const total_case_count = sum(non_averages.map(d => d.case_count))
  if (key === 'case_count') {
    const updated = data.map(d => {
      if (d.id === -1) return {...d, case_count: total_case_count}
      return d
    })
    return updated
  }
  const raw_value = non_averages.reduce(
    (acc, d) =>
      isArray(acc)
        ? acc.map(
            (prev, i) =>
              prev + computeWeightedAverage(d, key[i], total_case_count),
          )
        : acc + computeWeightedAverage(d, key, total_case_count),
    isArray(key) ? times(key.length, () => 0) : 0,
  )
  const value = isArray(raw_value)
    ? raw_value.map((v, i) => toDigits(v, key[i]))
    : toDigits(raw_value, key)

  const payload = isArray(key)
    ? key.reduce((acc, k, i) => {
        const val = value[i]
        const result = {...acc, [k]: val}
        return result
      }, {})
    : {[key]: value}
  return data.map(d => {
    if (d.id === -1) {
      return {
        ...d,
        case_count: total_case_count,
        ...payload,
      }
    }
    return d
  })
}

export const toggleName = show => physician => ({
  ...physician,
  surgeon_name:
    physician.insufficient_data && show
      ? physician.show_name_asterisk
      : physician.insufficient_data && !show
      ? physician.hide_name_asterisk
      : show
      ? physician.show_name
      : physician.hide_name,
})

const computeLineChartName = ({id, physician, show, newData}) =>
  [29, 8, 11].includes(id) &&
  newData.find(item => item.name === physician.name)?.asterisk &&
  show
    ? physician.show_name_asterisk
    : [29, 8, 11].includes(id) &&
      newData.find(item => item.name === physician.name)?.asterisk &&
      !show
    ? physician.hide_name_asterisk
    : show
    ? physician.show_name
    : physician.hide_name

export const withToggleLineChartNames = (config, data, show, newData) =>
  data.map(d => ({
    ...d,
    surgeon_name: computeLineChartName({
      id: config.id,
      physician: d,
      show,
      newData,
    }),
  }))

export const filterByCache = cache => item => !!cache[item.id]

export const isPhysicianOverTimeChart = config =>
  (config.chart &&
    config.chart.type === 2 &&
    config.chart.y_scale?.group_key === 'surgeon_name') ||
  config.chart.y_scale?.group_key === 'organization_full_name'

export const getChartMetricKey = config => {
  if (!config.chart) return null
  if (config.chart.type === 1) {
    return config.chart.group_mode === 'stacked'
      ? config.chart.max_value_accessor
        ? config.options
          ? config.options.values
              .filter(v => v.selected)
              .map(v => v.key)
              .concat([config.chart.max_value_accessor])
          : [...config.chart.keys, config.chart.max_value_accessor]
        : config.options
        ? config.options.values.filter(v => v.selected).map(v => v.key)
        : config.chart.keys
      : config.chart.dimension === 'y'
      ? config.chart.y_accessor
      : config.chart.x_accessor
  }
  if (config.chart.type === 2) {
    return config.chart.y_scale?.group_key === 'surgeon_name' ||
      config.chart.y_scale?.group_key === 'organization_full_name'
      ? config.chart.y_scale.keys[0]
      : null
  }
  return null
}

const selectPhysiciansCache = createSelector(
  state => state.charts.config.physicians,
  physicians =>
    physicians
      ? physicians
          .filter(p => p.selected)
          .reduce((acc, p) => {
            acc[p.id] = p
            return acc
          }, {})
      : null,
)

const selectOrganizationsCache = createSelector(
  state => state.charts.config.organizations,
  organizations =>
    organizations
      ? organizations
          .filter(o => o.selected)
          .reduce((acc, o) => {
            acc[o.id] = o
            return acc
          }, {})
      : null,
)

const selectSelectedSupplyGenericNameIds = createSelector(
  state => state.charts.supply_generic_names,
  supply_generic_names =>
    supply_generic_names?.filter(s => s.selected)?.map(s => s.id),
)

const add = (a, b) => {
  const _a = isNumber(a) ? a : 0
  const _b = isNumber(b) ? b : 0
  return _a + _b
}

export const sumKeys = (d, keys) => keys.reduce((acc, k) => add(acc, d[k]), 0)

export const selectData = createSelector(
  state => state.charts.show_physician_names,
  state => state.charts.show_all_case_supply_costs,
  selectPhysiciansCache,
  state => state.charts.config,
  state => state.charts.data,
  state => state.charts.deleted,
  selectSelectedSupplyGenericNameIds,
  selectOrganizationsCache,

  (
    show_names,
    show_all_supply_costs,
    physicians,
    config,
    _data,
    deleted,
    generic_ids,
    organizations,
  ) => {
    let data = _data
    const key = getChartMetricKey(config)

    if (physicians) {
      const filtered = data.filter(filterByCache(physicians))
      let result
      if (isPhysicianOverTimeChart(config)) {
        const group = groupBy(filtered, d => d.date_of_surgery)
        result = Object.values(group).reduce(
          (acc, value) => [...acc, ...withWeightedAverages(value, key)],
          [],
        )
      } else {
        result = withWeightedAverages(filtered, key)
      }
      if (config.chart && config.chart.type === 1) {
        data = result.map(toggleName(show_names))
      } else {
        const newData = []
        Object.keys(physicians).forEach(p => {
          if (
            physicians[p].case_count < 11 ||
            physicians[p].insufficient_data
          ) {
            const newItem = {
              name: physicians[p].surgeon_name,
              asterisk: true,
            }
            newData.push(newItem)
          } else {
            const newItem = {
              name: physicians[p].surgeon_name,
              asterisk: false,
            }
            newData.push(newItem)
          }
        })
        data = withToggleLineChartNames(config, result, show_names, newData)
      }
    }
    if (organizations) {
      const filtered = data.filter(filterByCache(organizations))
      let result
      if (isPhysicianOverTimeChart(config)) {
        const group = groupBy(filtered, d => d.date_of_surgery)
        result = Object.values(group).reduce(
          (acc, value) => [...acc, ...withWeightedAverages(value, key)],
          [],
        )
      } else {
        result = withWeightedAverages(filtered, key)
      }
      data = result
    }
    if (config.id === 48) {
      const removed = data.filter(d => !deleted[d[config.chart.key_accessor]])
      const selected = config.options.values.filter(v => v.selected)
      const newData = orderBy(
        removed
          .filter(d => selected.some(v => v.selected && !!d[v.key]))
          .map(d => ({
            ...d,
            selected_total: sumKeys(
              d,
              selected.map(v => v.key),
            ),
          })),
        show_all_supply_costs ? 'total_cost' : 'selected_total',
        'desc',
      )
      data = newData
    }
    if (config.id === 49 && generic_ids?.length > 0) {
      data = data.filter(d => generic_ids.includes(d.generic_name_id))
    }

    if (data?.some(item => item.insufficient_data)) {
      data = excludeMedicareData(data)
    }
    if (config?.chart?.max_value_accessor && [4].includes(config?.variant_id)) {
      data = orderBy(
        data,
        d =>
          d.id === -1
            ? -Infinity
            : isNumber(d[config.chart.max_value_accessor])
            ? d[config.chart.max_value_accessor]
            : -Infinity,
        'desc',
      )
    }
    if (
      data?.some(d => isNumber(d?.case_count)) &&
      [1].includes(config?.variant_id)
    ) {
      data = orderBy(
        data,
        d =>
          d.id === -1
            ? -Infinity
            : isNumber(d.case_count)
            ? d.case_count
            : -Infinity,
        'desc',
      )
    }

    return data
  },
)

export const selectConfig = createSelector(
  selectData,
  state => state.charts.config,
  state => state.charts.show_all_case_supply_costs,
  state => state.charts.hide_case_removal_buttons,
  state => state.charts.deleted,
  (
    data,
    config,
    show_all_case_supply_costs,
    hide_case_removal_buttons,
    deleted,
  ) => {
    let _config = config
    if (_config.id === 48 && show_all_case_supply_costs) {
      const set = data.reduce((set, d) => {
        Object.entries(d).forEach(([key, value]) => {
          if (isNumber(value)) {
            set.add(key)
          }
        })
        return set
      }, new Set())
      const options = {
        ..._config.options,
        values: _config.options.values.map(v => ({
          ...v,
          selected: set.has(v.key),
        })),
      }
      _config = {..._config, options}
    }
    if (_config.id === 48 && !hide_case_removal_buttons) {
      const unfiltered_data = _config.unfiltered_data.filter(
        d => !deleted[d[_config.chart.key_accessor]],
      )
      _config = {..._config, unfiltered_data}
    }
    return _config
  },
)

export const selectSelectedSupplyCategoriesIds = createSelector(
  selectConfig,
  config =>
    config?.options?.values
      ?.filter(c => c.selected)
      ?.map(i => parseInt(i.key.substring(5), 10)),
)

const selectTimePeriod = createSelector(
  state => state.charts.filters.time_periods,
  time_periods => ({
    start: time_periods.selected_start,
    end: time_periods.selected_end,
    max: time_periods.max,
    id: time_periods.values.filter(v => v.selected).map(v => v.id)[0],
    type: 'time_periods',
  }),
)

export const selectDroppedFilters = createSelector(
  selectTimePeriod,
  state => state.charts?.dropped_filters,
  (time_period, dropped) => {
    if (isEmpty(dropped)) return null
    const names = Object.entries(dropped).reduce(
      (acc, [key, values]) => [
        ...acc,
        ...values.map(v =>
          ['drgs', 'icds'].includes(key)
            ? `Inpatient: ${key === 'drgs' ? `DRG ${v.id}` : v.name}`
            : `Outpatient: ${v.name}`,
        ),
      ],
      [],
    )
    return {time_period, names}
  },
)

export const selectDeletedCount = createSelector(
  state => state?.charts?.deleted,
  deleted => (isObject(deleted) ? size(deleted) : 0),
)

export const selectMessages = createSelector(selectDroppedFilters, dropped => {
  const messages = []
  if (dropped) {
    messages.push(format(dropped, 'dropped_filters'))
  }
  return messages
})

export const selectSystemGroupByToggles = createSelector(
  state => state.charts.group_by_surgeon,
  state => state.charts.group_by_organization,
  (group_by_surgeon, group_by_organization) => {
    const items = [
      {
        id: 1,
        name: 'Group by Surgeon by Organization',
        selected: group_by_surgeon && !group_by_organization,
        type: 'group_by_surgeon_by_organization',
      },
      {
        id: 2,
        name: 'Group by Surgeon',
        selected: !group_by_surgeon && !group_by_organization,
        type: 'group_by_surgeon',
      },
      {
        id: 3,
        name: 'Group by Organization',
        selected: group_by_surgeon && group_by_organization,
        type: 'group_by_organization',
      },
    ]
    return items
  },
)

export const selectIncludeOncology = createSelector(
  state => state.charts.filters.is_oncology,
  state => state.charts.filters.exclude_oncology,
  (is_oncology, exclude_oncology) => {
    const items = [
      {
        id: 1,
        name: 'All Cases',
        selected: !is_oncology && !exclude_oncology,
        type: 'include_oncology',
      },
      {
        id: 2,
        name: 'Only Oncology',
        selected: is_oncology && !exclude_oncology,
        type: 'include_oncology',
      },
      {
        id: 3,
        name: 'Exclude Oncology',
        selected: exclude_oncology && !is_oncology,
        type: 'include_oncology',
      },
    ]
    return items
  },
)

export const selectIncludeOncologySelected = createSelector(
  selectIncludeOncology,
  state =>
    state.charts?.filters?.departments
      ?.filter(s => s.selected)
      ?.some(s => s.id === 4),
  (items, hasOncology) => {
    const selected = items.filter(item => item.selected)

    return hasOncology || selected[0]?.id === 1 ? [] : selected
  },
)

export const selectIncludeRobotics = createSelector(
  state => state.charts.filters.is_robotics,
  state => state.charts.filters.exclude_robotics,
  (is_robotics, exclude_robotics) => {
    const items = [
      {
        id: 1,
        name: 'All Cases',
        selected: !is_robotics && !exclude_robotics,
        type: 'include_robotics',
      },
      {
        id: 2,
        name: 'Only Robotics Cases',
        selected: is_robotics && !exclude_robotics,
        type: 'include_robotics',
      },
      {
        id: 3,
        name: 'Exclude Robotics Cases',
        selected: exclude_robotics && !is_robotics,
        type: 'include_robotics',
      },
    ]
    return items
  },
)

export const selectIncludeRoboticsSelected = createSelector(
  selectIncludeRobotics,
  items => {
    const selected = items.filter(item => item.selected)
    return selected[0]?.id === 1 ? [] : selected
  },
)

export const selectHospitalCaseNumbers = createSelector(
  state => state.charts.filters.hospital_case_numbers,
  hospital_case_numbers =>
    hospital_case_numbers.map(h => ({
      name: h,
      id: h,
      type: 'hospital_case_numbers',
    })),
)

export const selectSupplyManufacturerNumbers = createSelector(
  state => state.charts.filters.supply_manufacturer_numbers,
  numbers =>
    numbers.map(h => ({
      name: h,
      id: h,
      type: 'supply_manufacturer_numbers',
    })),
)

export const selectRawCpts = createSelector(
  state => state?.charts?.filters?.raw_cpt,
  raw => raw?.map(h => ({name: h, id: h, type: 'raw_cpt'})),
)

export const selectChartFilterParams = createSelector(
  state => state.config,

  (_, search) => search,

  (config, search) => {
    const {group_by_surgeon, department_ids} = fromQueryString(search, [
      'group_by_surgeon',
      'department_ids',
    ])
    const userOrganizations = config.user.organizations

    const isSystem = userOrganizations.find(
      organization => organization.selected && organization.system,
    )
    const selectedOrganization = userOrganizations.find(
      organization => organization.selected,
    )

    const isOncologyDepartment =
      department_ids === 4 ||
      (Array.isArray(department_ids) && department_ids.includes(4))

    const refreshDate = selectedOrganization.refresh_date

    let filterParams = isEmpty(search)
      ? `?refresh_date=${refreshDate}`
      : `${search}&refresh_date=${refreshDate}`

    if (isOncologyDepartment) {
      filterParams += `${filterParams}&is_oncology=true`
    }

    if (isSystem && group_by_surgeon == null) {
      filterParams += '&group_by_surgeon=true'
    }

    return filterParams
  },
)

export const selectComparisonType = createSelector(
  state => state.charts.comparison_types,
  comparison_types => comparison_types.find(c => c.selected),
)

export const CHIPS_ORDER = {
  group_by_surgeon: 6,
  group_by_organization: 6,
  time_periods: 5,
  date_saved: 4,
  icds: 3,
  drgs: 3,
  all_inpatient: 3,
  cpts: 2,
  procedures: 2,
  all_outpatient: 2,
  stages: 4,
  histologies: 4,
  cancer_sites: 4,
  comorbidities: 5,
  procedure_details: 5,
  sub_departments: 1,
  department: 1,
  include_oncology: 2,
  include_robotics: 2,
}

const selectIcds = createSelector(
  state => state.charts.filters.icds,
  icds => icds.filter(icd => icd.selected),
)
const selectCpts = createSelector(
  state => state.charts.filters.cpts,
  cpts => cpts.filter(cpt => cpt.selected),
)
const selectDrgs = createSelector(
  state => state.charts.filters.drgs,
  drgs => drgs.filter(drg => drg.selected),
)
const selectProcedures = createSelector(
  state => state.charts.filters.procedures,
  procedures => procedures.filter(procedure => procedure.selected),
)
const selectInsuranceTypes = createSelector(
  state => state.charts.filters.insurance_types,
  insurance_types =>
    insurance_types.filter(insurance_type => insurance_type.selected),
)
const selectInstrumentTypes = createSelector(
  state => state.charts.filters.instrument_types,
  instrument_types =>
    instrument_types.filter(instrument_type => instrument_type.selected),
)
const selectSupplyProducts = createSelector(
  state => state.charts.filters.supply_products,
  supply_products =>
    supply_products.map(supply_product => ({
      ...supply_product,
      type: 'supply_products',
    })),
)

const selectTimePeriodValue = createSelector(
  state => state.charts.filters.time_periods.values,
  values => values.filter(value => value.selected),
)
const selectChipsTimePeriod = createSelector(
  state => state.charts.filters.time_periods.selected_start,
  state => state.charts.filters.time_periods.selected_end,
  state => state.charts.filters.time_periods.max,
  selectTimePeriodValue,
  (start, end, max, selected) =>
    selected.map(s => ({
      ...s,
      start,
      end,
      max,
    })),
)

const selectComorbidities = createSelector(
  state => state.charts.filters.comorbidities,
  comorbidities => comorbidities.filter(comorbiditie => comorbiditie.selected),
)

const selectProcedureDetails = createSelector(
  state => state.charts.filters.procedure_details,
  procedure_details =>
    procedure_details.filter(procedureDetail => procedureDetail.selected),
)

const selectStages = createSelector(
  state => state.charts.filters.stages,
  stages => {
    const selectedStages = stages.filter(stage => stage.selected)
    if (stages.length && stages.length === selectedStages.length) {
      return [{name: 'All Stages', id: 'all_stages', type: 'all_stages'}]
    }
    return selectedStages
  },
)

const selectHistologies = createSelector(
  state => state.charts.filters.histologies,
  histologies => {
    const selectedHistologies = histologies.filter(
      histologie => histologie.selected,
    )
    if (
      histologies.length &&
      histologies.length === selectedHistologies.length
    ) {
      return [
        {
          name: 'All Histologies',
          id: 'all_histologies',
          type: 'all_histologies',
        },
      ]
    }
    return selectedHistologies
  },
)

const selectCancerSites = createSelector(
  state => state.charts.filters.cancer_sites,
  cancer_sites => {
    const selectedCancerSites = cancer_sites.filter(
      cancer_site => cancer_site.selected,
    )
    if (
      cancer_sites.lenght &&
      cancer_sites.length === selectedCancerSites.length
    ) {
      return [
        {
          name: 'All Cancer Sites',
          id: 'all_cancer_sites',
          type: 'all_cancer_sites',
        },
      ]
    }
    return selectedCancerSites
  },
)

const selectDiagnosisCodes = createSelector(
  state => state.charts.filters.diagnosis_codes,
  state => state.charts.filters.exclude_diagnosis_codes,
  (diagnosis_codes, exclude_diagnosis_codes) => {
    const list = []
    const excludeDiagnosisText = type =>
      type === true ? 'Excluded Diagnosis:' : 'Included Diagnosis:'
    const children = data =>
      data.forEach(code => {
        if (code.selected) {
          list.push({
            ...code,
            id: code.code,
            name: code.diagnosis_codes
              ? `${excludeDiagnosisText(exclude_diagnosis_codes)} All ${
                  code.code
                } Codes ${code.description}`
              : `${excludeDiagnosisText(exclude_diagnosis_codes)} ${
                  code.code
                } - ${code.description}`,
            type: 'diagnosis_codes',
          })
        } else if (Array.isArray(code.diagnosis_codes)) {
          children(code.diagnosis_codes)
        }
      })
    if (Array.isArray(diagnosis_codes)) {
      diagnosis_codes.forEach(code => {
        if (code.selected) {
          list.push({
            ...code,
            id: code.code,
            name: `${excludeDiagnosisText(exclude_diagnosis_codes)} All ${
              code.code
            } Codes ${code.description}`,
            type: 'diagnosis_codes',
          })
        } else if (Array.isArray(code.diagnosis_codes)) {
          children(code.diagnosis_codes)
        }
      })
    }
    return list
  },
)

const selectSupplyGenericNames = createSelector(
  state => state.charts.supply_generic_names,
  supply_generic_names =>
    supply_generic_names
      ?.filter(s => s.selected)
      ?.map(supply_generic_name => ({
        ...supply_generic_name,
        type: 'supply_generic_names',
      })),
)

export const selectChips = createSelector(
  state => state.charts.all_inpatient,
  state => state.charts.all_outpatient,
  state => state.charts.date_saved,
  selectCpts,
  selectDrgs,
  selectHospitalCaseNumbers,
  selectIcds,
  selectInstrumentTypes,
  selectInsuranceTypes,
  selectProcedures,
  selectSupplyProducts,
  selectChipsTimePeriod,
  state => state.charts.filters.risk_adjusted,
  state => state.charts.filters.has_quality_metric,
  selectComorbidities,
  selectProcedureDetails,
  selectStages,
  selectHistologies,
  selectCancerSites,
  selectDiagnosisCodes,
  state => state.charts.group_by_surgeon,
  state => state.charts.group_by_organization,
  selectIsSystemView,
  state => state.charts.filters.hospital_case_numbers?.length > 0,
  selectIncludeOncologySelected,
  selectIncludeRoboticsSelected,
  selectSupplyManufacturerNumbers,

  selectSupplyGenericNames,
  selectRawCpts,
  state =>
    state.charts.filters.departments
      .filter(
        ({sub_departments, selected}) =>
          selected &&
          sub_departments?.filter(s => s.selected)?.length ===
            sub_departments?.length,
      )
      .map(department => ({
        id: department.id,
        name: `All ${department.name}`,
        type: 'department',
      })),
  state =>
    state.charts.filters.departments
      .filter(
        ({sub_departments}) =>
          sub_departments?.length > 0 &&
          !sub_departments.every(s => s.selected),
      )
      .flatMap(({sub_departments}) =>
        sub_departments.map(subDepartment => ({
          ...subDepartment,
          type: 'sub_department',
        })),
      )
      .filter(({selected}) => selected),

  (
    all_inpatient,
    all_outpatient,
    date_saved,
    cpts,
    drgs,
    icds,
    hospital_case_numbers,
    instrument_types,
    insurance_types,
    procedures,
    supply_products,
    time_period,
    risk_adjusted,
    has_quality_metric,
    comorbidities,
    procedure_details,
    stages,
    histologies,
    cancer_sites,
    diagnosis_codes,
    group_by_surgeon,
    group_by_organization,
    isSystem,
    hasHospitalCaseNumbers,
    include_oncology,
    include_robotics,
    supply_manufacturer_numbers,
    supply_generic_names,
    raw_cpt,
    departments,
    subDepartments,
  ) => {
    let chips = [
      ...hospital_case_numbers,
      ...cpts,
      ...drgs,
      ...icds,
      ...instrument_types,
      ...insurance_types,
      ...procedures,
      ...supply_products,
      ...time_period,
      ...comorbidities,
      ...procedure_details,
      ...stages,
      ...histologies,
      ...cancer_sites,
      ...diagnosis_codes,
      ...include_oncology,
      ...include_robotics,
      ...supply_manufacturer_numbers,
      ...supply_generic_names,
      ...raw_cpt,
    ].map(item => ({
      ...item,
      label: computeChipLabel(item),
    }))

    chips.push(
      ...[...departments, ...subDepartments].map(item => ({
        ...item,
        label: computeChipLabel(item),
      })),
    )

    if (all_inpatient) {
      chips = [
        {
          label: 'All Inpatient Procedures',
          id: 'all_inpatient',
          type: 'all_inpatient',
        },
        ...chips.filter(chip => !['icds', 'drgs'].includes(chip.type)),
      ]
    }
    if (all_outpatient) {
      chips = [
        {
          label: 'All Outpatient Procedures',
          id: 'all_outpatient',
          type: 'all_outpatient',
        },
        ...chips.filter(chip => !['cpts', 'procedures'].includes(chip.type)),
      ]
    }
    if (date_saved) {
      chips = [
        {label: 'Date Range Saved', id: 'date_saved', type: 'date_saved'},
        ...chips,
      ]
    }
    if (risk_adjusted) {
      chips = [
        {label: 'Risk Adjusted', id: 'risk_adjusted', type: 'risk_adjusted'},
        ...chips,
      ]
    }
    if (has_quality_metric) {
      chips = [
        {
          label: 'Included in ERAS Program',
          id: 'has_quality_metric',
          type: 'has_quality_metric',
        },
        ...chips,
      ]
    }
    if (isSystem && !group_by_surgeon && !group_by_organization) {
      chips = [
        {
          label: 'Group by Surgeon',
          id: 'group_by',
          type: 'group_by',
        },
        ...chips,
      ]
    }

    if (isSystem && group_by_surgeon && !group_by_organization) {
      chips = [
        {
          label: 'Group by Surgeon by Organization',
          id: 'group_by',
          type: 'group_by',
        },
        ...chips,
      ]
    }

    if (isSystem && group_by_surgeon && group_by_organization) {
      chips = [
        {
          label: 'Group by Organization',
          id: 'group_by',
          type: 'group_by',
        },
        ...chips,
      ]
    }

    const filtered = chips.filter(chip =>
      hasHospitalCaseNumbers
        ? chip.type === 'hospital_case_numbers'
        : chip.type !== 'hospital_case_numbers',
    )
    return orderBy(
      filtered,
      d => (isNumber(CHIPS_ORDER[d.type]) ? CHIPS_ORDER[d.type] : 0),
      'desc',
    )
  },
)

export const selectMetricName = createSelector(
  state => state.charts.filters.categories,
  categories => getSelectedValues(categories)?.find(c => c.selected)?.name,
)

export const selectTracked = createSelector(
  selectChips,
  selectMetricName,
  (chips, metric) => ({
    chips,
    title: metric,
  }),
)

export const createBooleanBarTable = config => {
  const series = config?.series?.map(s => ({
    format: '%',
    key: s?.id,
    title: s?.name,
  }))
  const columns = [
    {
      align: 'left',
      key: 'surgeon_name',
      title: 'Physician',
    },
    {
      key: 'case_count',
      title: 'Case Volume',
    },
  ]
  return {columns: [...columns, ...series]}
}

export const createBooleanLineTable = config => {
  const series = config?.series?.slice(0, 1)?.map(s => ({
    format: '%',
    key: s?.id,
    title: s?.name,
  }))
  const columns = [
    {
      align: 'left',
      format: '%-b %-y',
      key: 'date_of_surgery',
      parse: '%Y-%m',
      title: 'Date Of Surgery',
    },
  ]
  return {columns: [...columns, ...series]}
}

export const createBooleanMultiLineTable = config => {
  const columns = [
    {
      align: 'left',
      format: '%-b %-y',
      key: 'date_of_surgery',
      parse: '%Y-%m',
      title: 'Date Of Surgery',
    },
  ]
  return {
    columns,
    data_format: '%',
    data_key: config?.series?.slice(0, 1)?.map(s => s.id),
    group_key: 'surgeon_name',
  }
}

export const createNumericBarTable = config => {
  const series = config?.series?.map(s => ({
    format: s?.format,
    key: s?.id,
    title: s?.name,
  }))
  const columns = [
    {
      align: 'left',
      key: 'surgeon_name',
      title: 'Physician',
    },
    {
      key: 'case_count',
      title: 'Case Volume',
    },
    ...series,
  ]
  return {columns}
}

export const createNumericScrollingBarTable = config => {
  const series = config?.series?.map(s => ({
    format: s?.format,
    key: s?.id,
    title: s?.name,
  }))
  const columns = [
    {
      align: 'left',
      key: 'hospital_case_number',
      title: 'Case Information',
    },
    ...series,
  ]
  return {columns}
}

export const createNumericLineTable = config => {
  const series = config?.series?.map(s => ({
    format: s?.format,
    key: s?.id,
    title: s?.name,
  }))
  const columns = [
    {
      align: 'left',
      format: '%-b %-y',
      key: 'date_of_surgery',
      parse: '%Y-%m',
      title: 'Date Of Surgery',
    },
    ...series,
  ]
  return {columns}
}

export const createNumericMultiLineTable = config => {
  const table = {
    columns: [
      {
        align: 'left',
        format: '%-b %-y',
        key: 'date_of_surgery',
        parse: '%Y-%m',
        title: 'Date Of Surgery',
      },
    ],
    data_key: config?.series
      ?.slice(0, 1)
      ?.map(s => s.id)
      .join(),
    group_key: 'surgeon_name',
    data_format: config?.series
      ?.slice(0, 1)
      ?.map(s => s.format)
      .join(),
  }
  return table
}

export const createOptionsBarTable = config => {
  const columns = [
    {
      align: 'left',
      key: 'surgeon_name',
      title: 'Physician',
    },
    {
      key: 'case_count',
      title: 'Case Volume',
    },
  ]
  return {columns}
}

export const createOptionsLineTable = config => {
  const columns = [
    {
      align: 'left',
      format: '%-b %-y',
      key: 'date_of_surgery',
      parse: '%Y-%m',
      title: 'Date Of Surgery',
    },
  ]
  return {columns}
}

export const createOptionsMultiLineTable = config => {
  const columns = [
    {
      align: 'left',
      format: '%-b %-y',
      key: 'date_of_surgery',
      parse: '%Y-%m',
      title: 'Date Of Surgery',
    },
  ]
  return {
    columns,
    data_format: '%',
    data_key: config?.series?.slice(0, 1)?.map(s => s.id),
    group_key: 'surgeon_name',
  }
}

import colorsBase from '../theme/colors'

const base = {
  black: '#000000',
  white: '#FFFFFF',
}

const gray = {
  25: '#FCFCFD',
  50: '#F9FAFB',
  100: '#F2F4F7',
  200: '#EAECF0',
  300: '#E0E3E8',
  400: '#98A2B3',
  500: '#667085',
  600: '#475467',
  700: '#344054',
  800: '#1D2939',
  900: '#101828',
}

const primary = {
  25: '#FCFAFF',
  50: '#F9F5FF',
  100: '#F4EBFF',
  200: '#E9D7FE',
  300: '#D6BBFB',
  400: '#B692F6',
  500: '#9E77ED',
  600: '#7F56D9',
  700: '#6941C6',
  800: '#53389E',
  900: '#42307D',
}

const error = {
  25: '#FFFBFA',
  50: '#FEF3F2',
  100: '#FEE4E2',
  200: '#FECDCA',
  300: '#FDA29B',
  400: '#F97066',
  500: '#F04438',
  600: '#D92D20',
  700: '#B42318',
  800: '#912018',
  900: '#7A271A',
}
const warning = {
  25: '#FFFCF5',
  50: '#FFFAEB',
  100: '#FEF0C7',
  200: '#FEDF89',
  300: '#FEC84B',
  400: '#FDB022',
  500: '#F79009',
  600: '#DC6803',
  700: '#B54708',
  800: '#93370D',
  900: '#7A2E0E',
}

const success = {
  25: '#f6fef9',
  50: '#ecfdf3',
  100: '#d1fadf',
  200: '#a6f4c5',
  300: '#6ce9a6',
  400: '#32d583',
  500: '#12b76a',
  600: '#039855',
  700: '#027a48',
  800: '#05603a',
  900: '#054f31',
}

const grayBlue = {
  25: '#fcfcfd',
  50: '#f8f9fc',
  100: '#eaecf5',
  200: '#d5d9eb',
  300: '#b3b8db',
  400: '#717bbc',
  500: '#4e5ba6',
  600: '#3e4784',
  700: '#363f72',
  800: '#293056',
  900: '#101323',
}

const grayCool = {
  25: '#fcfcfd',
  50: '#f9f9fb',
  100: '#eff1f5',
  200: '#dcdfea',
  300: '#b9c0d4',
  400: '#7d89b0',
  500: '#5d6b98',
  600: '#4a5578',
  700: '#404968',
  800: '#30374f',
  900: '#111322',
}

const grayModern = {
  25: '#fcfcfd',
  50: '#f8fafc',
  100: '#eef2f6',
  200: '#e3e8ef',
  300: '#cdd5df',
  400: '#9aa4b2',
  500: '#697586',
  600: '#4b5565',
  700: '#364152',
  800: '#202939',
  900: '#121926',
}

const grayNeutral = {
  25: '#fcfcfd',
  50: '#f9fafb',
  100: '#f3f4f6',
  200: '#e5e7eb',
  300: '#d2d6db',
  400: '#9da4ae',
  500: '#6c737f',
  600: '#4d5761',
  700: '#384250',
  800: '#1f2a37',
  900: '#111927',
}

const grayIron = {
  25: '#FCFCFC',
  50: '#FAFAFA',
  100: '#F4F4F5',
  200: '#E4E4E7',
  300: '#D1D1D6',
  400: '#A0A0AB',
  500: '#70707B',
  600: '#51525C',
  700: '#3F3F46',
  800: '#26272B',
  900: '#18181B',
}

const grayTrue = {
  25: '#FCFCFC',
  50: '#FAFAFA',
  100: '#F5F5F5',
  200: '#E5E5E5',
  300: '#D6D6D6',
  400: '#A3A3A3',
  500: '#737373',
  600: '#525252',
  700: '#424242',
  800: '#292929',
  900: '#141414',
}

const grayWarm = {
  25: '#FDFDFC',
  50: '#FAFAF9',
  100: '#F5F5F4',
  200: '#E7E5E4',
  300: '#D7D3D0',
  400: '#A9A29D',
  500: '#79716B',
  600: '#57534E',
  700: '#44403C',
  800: '#292524',
  900: '#1C1917',
}

const moss = {
  25: '#FAFDF7',
  50: '#F5FBEE',
  100: '#E6F4D7',
  200: '#CEEAB0',
  300: '#ACDC79',
  400: '#86CB3C',
  500: '#669F2A',
  600: '#4F7A21',
  700: '#3F621A',
  800: '#335015',
  900: '#2B4212',
}

const greenLight = {
  25: '#FAFEF5',
  50: '#F3FEE7',
  100: '#E4FBCC',
  200: '#D0F8AB',
  300: '#A6EF67',
  400: '#85E13A',
  500: '#66C61C',
  600: '#4CA30D',
  700: '#3B7C0F',
  800: '#326212',
  900: '#2B5314',
}

const green = {
  25: '#F6FEF9',
  50: '#EDFCF2',
  100: '#D3F8DF',
  200: '#AAF0C4',
  300: '#73E2A3',
  400: '#3CCB7F',
  500: '#16B364',
  600: '#099250',
  700: '#087443',
  800: '#095C37',
  900: '#084C2E',
}

const teal = {
  25: '#F6FEFC',
  50: '#F0FDF9',
  100: '#CCFBEF',
  200: '#99F6E0',
  300: '#5FE9D0',
  400: '#2ED3B7',
  500: '#15B79E',
  600: '#0E9384',
  700: '#107569',
  800: '#125D56',
  900: '#134E48',
}

const cyan = {
  25: '#F5FEFF',
  50: '#ECFDFF',
  100: '#CFF9FE',
  200: '#A5F0FC',
  300: '#67E3F9',
  400: '#22CCEE',
  500: '#06AED4',
  600: '#088AB2',
  700: '#0E7090',
  800: '#155B75',
  900: '#164C63',
}

const blueLight = {
  25: '#F5FBFF',
  50: '#F0F9FF',
  100: '#E0F2FE',
  200: '#B9E6FE',
  300: '#7CD4FD',
  400: '#36BFFA',
  500: '#0BA5EC',
  600: '#0086C9',
  700: '#026AA2',
  800: '#065986',
  900: '#0B4A6F',
}

const blue = {
  25: '#F5FAFF',
  50: '#EFF8FF',
  100: '#D1E9FF',
  200: '#B2DDFF',
  300: '#84CAFF',
  400: '#53B1FD',
  500: '#2E90FA',
  600: '#1570EF',
  700: '#175CD3',
  800: '#1849A9',
  900: '#194185',
}

const blueDark = {
  25: '#F5F8FF',
  50: '#EFF4FF',
  100: '#D1E0FF',
  200: '#B2CCFF',
  300: '#84ADFF',
  400: '#528BFF',
  500: '#2970FF',
  600: '#155EEF',
  700: '#004EEB',
  800: '#0040C1',
  900: '#00359E',
}

const indigo = {
  25: '#F5F8FF',
  50: '#EEF4FF',
  100: '#E0EAFF',
  200: '#C7D7FE',
  300: '#A4BCFD',
  400: '#8098F9',
  500: '#6172F3',
  600: '#444CE7',
  700: '#3538CD',
  800: '#2D31A6',
  900: '#2D3282',
}

const violet = {
  25: '#FBFAFF',
  50: '#F5F3FF',
  100: '#ECE9FE',
  200: '#DDD6FE',
  300: '#C3B5FD',
  400: '#A48AFB',
  500: '#875BF7',
  600: '#7839EE',
  700: '#6927DA',
  800: '#5720B7',
  900: '#491C96',
}

const purple = {
  25: '#FAFAFF',
  50: '#F4F3FF',
  100: '#EBE9FE',
  200: '#D9D6FE',
  300: '#BDB4FE',
  400: '#9B8AFB',
  500: '#7A5AF8',
  600: '#6938EF',
  700: '#5925DC',
  800: '#4A1FB8',
  900: '#3E1C96',
}

const fuchsia = {
  25: '#FEFAFF',
  50: '#FDF4FF',
  100: '#FBE8FF',
  200: '#F6D0FE',
  300: '#EEAAFD',
  400: '#E478FA',
  500: '#D444F1',
  600: '#BA24D5',
  700: '#9F1AB1',
  800: '#821890',
  900: '#6F1877',
}

const pink = {
  25: '#FEF6FB',
  50: '#FDF2FA',
  100: '#FCE7F6',
  200: '#FCCEEE',
  300: '#FAA7E0',
  400: '#F670C7',
  500: '#EE46BC',
  600: '#DD2590',
  700: '#C11574',
  800: '#9E165F',
  900: '#851651',
}

const rose = {
  25: '#FFF5F6',
  50: '#FFF1F3',
  100: '#FFE4E8',
  200: '#FECDD6',
  300: '#FEA3B4',
  400: '#FD6F8E',
  500: '#F63D68',
  600: '#E31B54',
  700: '#C01048',
  800: '#A11043',
  900: '#89123E',
}

const orangeDark = {
  25: '#FFF9F5',
  50: '#FFF4ED',
  100: '#FFE6D5',
  200: '#FFD6AE',
  300: '#FF9C66',
  400: '#FF692E',
  500: '#FF4405',
  600: '#E62E05',
  700: '#BC1B06',
  800: '#97180C',
  900: '#771A0D',
}

const orange = {
  25: '#FEFAF5',
  50: '#FEF6EE',
  100: '#FDEAD7',
  200: '#F9DBAF',
  300: '#F7B27A',
  400: '#F38744',
  500: '#EF6820',
  600: '#E04F16',
  700: '#B93815',
  800: '#932F19',
  900: '#772917',
}

const yellow = {
  25: '#FEFDF0',
  50: '#FEFBE8',
  100: '#FEF7C3',
  200: '#FEEE95',
  300: '#FDE272',
  400: '#FAC515',
  500: '#EAAA08',
  600: '#CA8504',
  700: '#A15C07',
  800: '#854A0E',
  900: '#713B12',
}

const colors = {
  base,
  'new-gray': gray,
  primary,
  error,
  warning,
  success,
  'gray-blue': grayBlue,
  'gray-cool': grayCool,
  'gray-modern': grayModern,
  'gray-neutral': grayNeutral,
  'gray-iron': grayIron,
  'gray-true': grayTrue,
  'gray-warm': grayWarm,
  moss,
  'green-light': greenLight,
  green,
  teal,
  cyan,
  'blue-light': blueLight,
  'new-blue': blue,
  'blue-dark': blueDark,
  indigo,
  violet,
  purple,
  fuchsia,
  pink,
  rose,
  'orange-dark': orangeDark,
  orange,
  yellow,
  ...colorsBase,
}

export default colors

import React from 'react'
import PropTypes from 'prop-types'
import {AgCollapsible} from '@aghealth/ag-components'
import RadioButtonGroup from './RadioButtonGroup'

const CollapsibleRadioButtonGroup = ({
  label,
  onClick,
  items,
  styles,
  marginLeft,
  visible,
}) => {
  const [isVisible, setIsVisible] = React.useState(() => {
    if (process.env.NODE_ENV === 'test') return true
    return visible
  })
  const toggleVisible = React.useCallback(() => {
    setIsVisible(prev => !prev)
  }, [])
  React.useEffect(() => {
    if (process.env.NODE_ENV === 'test') {
      setIsVisible(true)
    } else {
      setIsVisible(visible)
    }
  }, [visible])
  return (
    <AgCollapsible
      visible={isVisible}
      icon="angle"
      label={label}
      onClick={toggleVisible}
      styles={{
        ...styles?.Collapsible,
        Button: {
          ...styles?.Collapsible?.Button,
          marginLeft: `${marginLeft}px`,
        },
      }}
    >
      {items?.some(item => item?.values?.some(v => !!v?.values)) ? (
        items.map(item => (
          <CollapsibleRadioButtonGroup
            key={item.id}
            label={item.name}
            items={item.values}
            styles={styles}
            visible={isVisible}
            onClick={onClick}
            marginLeft={marginLeft + 20}
          />
        ))
      ) : (
        <RadioButtonGroup
          items={items}
          onClick={onClick}
          styles={{
            ...styles?.RadioButtonGroup,
            Root: {
              ...styles?.RadioButtonGroup?.Root,
              marginLeft: `${marginLeft}px`,
            },
          }}
        />
      )}
    </AgCollapsible>
  )
}

CollapsibleRadioButtonGroup.propTypes = {
  visible: PropTypes.bool,
  marginLeft: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClick: PropTypes.func,
  label: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.any),
  styles: PropTypes.object,
}

CollapsibleRadioButtonGroup.defaultProps = {
  marginLeft: 0,
  visible: false,
}

export default CollapsibleRadioButtonGroup

import {createOptions} from './options'
import {createChart} from './chart'
import {createTable} from './table'

const createStaticConfig = config => {
  const chart = createChart(config)
  const table = createTable(config)
  return {
    chart,
    table,
    download: !!config?.download,
    export: !!config?.export,
    id: config?.id,
    disable_chart_options:
      config?.disable_chart_options || config?.variant_id === 2,
    variant_id: config?.variant_id,
  }
}

const createOptionsConfig = config => {
  const options = createOptions(config)
  const chart = createChart(config)
  const table = createTable(config)
  return {
    ...config,
    chart,
    table,
    options,
    download: !!config?.download,
    export: !!config?.export,
    id: config?.id,
    variant_id: config?.variant_id,
  }
}

export const createConfig = config => {
  if (!config?.variant_id) return config
  if (config?.type === 'options') return createOptionsConfig(config)
  return createStaticConfig(config)
}

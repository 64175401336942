import React from 'react'
import {Loading} from '../components'

const withLoading = Component => {
  const Container = ({loading, ...props}) => {
    if (loading) return <Loading height="600px" />
    return <Component {...props} />
  }

  return Container
}

export default withLoading

const config = {
  Auth: {
    Cognito: {
      userPoolClientId: process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
      userPoolId: process.env.REACT_APP_AWS_USER_POOLS_ID,
      identityPoolId: process.env.REACT_APP_AWS_IDENTITY_POOL_ID,
      loginWith: {
        email: true,
        oauth: {
          domain: `auth.${process.env.REACT_APP_DOMAIN}`,
          scopes: [
            'phone',
            'email',
            'openid',
            'profile',
            'aws.cognito.signin.user.admin',
          ],
          redirectSignIn: [
            process.env.REACT_APP_CI
              ? `${process.env.REACT_APP_BASE_URL}/`
              : `${process.env.REACT_APP_HOST}/`,
          ],
          redirectSignOut: [
            process.env.REACT_APP_CI
              ? `${process.env.REACT_APP_BASE_URL}/`
              : `${process.env.REACT_APP_HOST}/`,
          ],
          responseType: 'code',
        },
      },
    },
  },
}

export default config

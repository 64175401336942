import React from 'react'
import PropTypes from 'prop-types'
import {GoTriangleDown} from 'react-icons/go'
import {
  AgColumn,
  AgRow,
  AgAbsolute,
  AgText,
  AgButton,
  useOnClickOutside,
} from '@aghealth/ag-components'
import CheckboxList from './CheckboxList'
import bgspecialties from '../assets/specialties.png'

const styles = {
  CheckboxList: {
    Root: {
      top: '58px',
      position: 'absolute',
      maxHeight: '180px',
      width: '268px',
      bg: '#FFFFFF',
      borderRadius: '4px',
      padding: '8px 40px 8px 16px',
      boxShadow:
        '0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 3px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.2)',
      variant: 'scrollbars.y',
    },
    Checkbox: {
      Root: {
        py: '6px',
      },
      Input: {
        color: 'rgba(0, 0, 0, 0.54)',
        width: '15px',
        height: '15px',
      },
      Label: {
        fontSize: '14px',
        lineHeight: '20px',
        marginLeft: '11.5px',
      },
    },
  },
}

const DownArrow = () => (
  <GoTriangleDown
    color="rgba(0, 0, 0, 0.54)"
    size="18px"
    style={{
      position: 'absolute',
      right: '10px',
      top: 'calc(50% - 9px)',
      cursor: 'pointer',
    }}
  />
)

const Dropdown = ({selections, onChange}) => {
  const ref = React.useRef()
  const [showMenu, setShowMenu] = React.useState(false)
  const toggleMenu = React.useCallback(() => {
    setShowMenu(prev => !prev)
  }, [])
  const closeMenu = React.useCallback(() => {
    setShowMenu(false)
  }, [])
  const selectedName = React.useMemo(
    () =>
      selections
        .filter(s => s.selected)
        .map(s => s.name)
        .join(),
    [selections],
  )
  useOnClickOutside(ref, closeMenu, document)
  return (
    <AgColumn ref={ref} my="32px" width="268px" position="relative">
      <AgButton
        height="56px"
        width="268px"
        border="1px solid rgba(0, 0, 0, 0.23)"
        borderRadius="4px"
        bg="#FFFFFF"
        onClick={toggleMenu}
        textAlign="left"
        position="relative"
      >
        <AgText ml="12px" color="#595C5F" fontSize="16px" lineHeight="19px">
          {selectedName || 'Specialty Options'}
        </AgText>
        <DownArrow />
      </AgButton>
      {showMenu && (
        <CheckboxList
          list={selections}
          onChange={onChange}
          styles={styles.CheckboxList}
        />
      )}
    </AgColumn>
  )
}

Dropdown.propTypes = {
  selections: PropTypes.array,
  onChange: PropTypes.func,
}

Dropdown.defaultProps = {
  selections: [],
  onChange: () => {},
}

const SpecialtiesPrompt = ({onClose, onSave, onNoThanks, specialties}) => {
  const ref = React.useRef()
  const [selections, setSelections] = React.useState(specialties)
  const selected = React.useMemo(
    () => selections.filter(s => s.selected)[0],
    [selections],
  )
  const handleOnChange = React.useCallback(item => {
    setSelections(prev =>
      prev.map(p => {
        if (p.id === item.id) return {...p, selected: !p.selected}
        return {...p, selected: false}
      }),
    )
  }, [])
  const handleOnSave = React.useCallback(() => {
    if (selected) {
      onSave(selected)
    }
  }, [selected, onSave])
  useOnClickOutside(ref, onClose, document)
  return (
    <AgColumn
      ref={ref}
      width="600px"
      bg="#FFFFFF"
      boxShadow="0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 3px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.2)"
      borderRadius="8px"
      mt="80px"
    >
      <AgRow
        width="100%"
        height="240px"
        justifyContent="flex-end"
        alignItems="flex-start"
        background={`url(${bgspecialties})`}
        backgroundSize="contain"
        position="relative"
      >
        <AgAbsolute
          width="17.37px"
          height="17.37px"
          right="27.8px"
          top="26.31px"
          cursor="pointer"
          onClick={onClose}
        />
      </AgRow>
      <AgColumn px="25px">
        <AgText
          fontSize="20px"
          lineHeight="28px"
          color="#1B263F"
          fontWeight="bold"
          mt="16px"
          mb="8px"
        >
          Set Your Specialty
        </AgText>
        <AgText
          fontSize="14px"
          lineHeight="21px"
          letterSpacing="0.15px"
          color="#595C5F"
        >
          Select the specialty you work in for an enhanced CareMeasurement
          experience. Contact our support team to change your default specialty
          anytime in the future.
        </AgText>
        <Dropdown selections={selections} onChange={handleOnChange} />
      </AgColumn>
      <AgRow
        width="100%"
        height="92px"
        borderTop="base"
        borderColor="#C4C4C4"
        px="25px"
      >
        <AgButton
          height="44px"
          width="100%"
          bg="#129459"
          border="base"
          borderColor="#129459"
          borderRadius="4px"
          color="#FFFFFF"
          fontSize="14px"
          lineHeight="20px"
          onClick={handleOnSave}
        >
          Save
        </AgButton>
        <AgButton
          height="44px"
          width="100%"
          bg="#FFFFFF"
          border="base"
          borderColor="#129459"
          borderRadius="4px"
          color="#129459"
          fontSize="14px"
          lineHeight="20px"
          ml="16px"
          onClick={onNoThanks}
        >
          No Thanks
        </AgButton>
      </AgRow>
    </AgColumn>
  )
}

SpecialtiesPrompt.propTypes = {
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  onNoThanks: PropTypes.func,
  specialties: PropTypes.array,
}

SpecialtiesPrompt.defaultProps = {
  onClose: () => {},
  onSave: () => {},
  onNoThanks: () => {},
  specialties: [],
}

export default SpecialtiesPrompt

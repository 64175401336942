import './trusted-types'
import 'react-app-polyfill/ie9'
import React from 'react'
import {createRoot} from 'react-dom/client'
import {withProviders, withAmplify, configure} from '@aghealth/ag-components'
import * as Sentry from '@sentry/react'
import ThemeProvider from './theme-2/Theme'
import StoreProvider from './store/LazyStore'
import {baseURL} from './api/utils'
import App from './App'
import {withErrorBoundary} from './hocs'
import configs from './configs'
import * as serviceWorker from './serviceWorker'
import '@aghealth/ag-components/lib/cjs/index.css'
import '@aws-amplify/ui-react/styles.css'
import './index.css'

configure(configs.aws)

const AppWithAmplify = withAmplify(withErrorBoundary(App), {
  config: {BASE_URL: baseURL},
})

const AppWithProviders = withProviders(
  StoreProvider,
  ThemeProvider,
)(AppWithAmplify)

if (configs.sentry.dsn) {
  Sentry.init(configs.sentry)
}

const container = document.getElementById('root')
const root = createRoot(container)

root.render(<AppWithProviders />)

serviceWorker.unregister()

import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {prefix} from './settings'

export const ConversationHeaderActions = ({children, className, ...rest}) => {
  const cName = `${prefix}-conversation-header__actions`

  return (
    <section {...rest} className={classNames(cName, className)}>
      {children}
    </section>
  )
}

ConversationHeaderActions.displayName = 'ConversationHeader.Actions'

ConversationHeaderActions.propTypes = {
  /** Primary content. */
  children: PropTypes.node,

  /** Additional classes. */
  className: PropTypes.string,
}

ConversationHeaderActions.defaultProps = {
  children: undefined,
}

export default ConversationHeaderActions

import partition from 'lodash/partition'
import groupBy from 'lodash/groupBy'
import {baseURL, getHeaders, withMassageInsights} from './utils'
import {toFilteredQueryString} from '../utils'
const endpoint = `${baseURL}/api/insights/v2`

const groupDetailsData = insights => {
  if (!insights?.length > 0) return null
  if (insights?.length === 1) return insights[0]
  const [parents, children] = partition(
    insights,
    insight =>
      insight.parent_id == null &&
      insight.subcategory !== 'actionable_insights',
  )
  const groups = groupBy(children, c => c.parent_id)
  const results = parents.map(parent => ({
    ...parent,
    children: groups[parent.id],
  }))
  return results?.length > 0 ? results[0] : null
}

export const fetchInsightsV2 = withMassageInsights(async options => {
  const {search: _search, signal} = options
  const search = toFilteredQueryString(_search, ['actionable_opportunities'])
  const response = await fetch(endpoint.concat(search), {
    headers: await getHeaders(),
    signal,
  })
  const result = await response.json()
  return result
})

export const fetchInsightDetails = async options => {
  const {search: _search, id} = options
  const search = toFilteredQueryString(_search, ['procedure_group_ids'])
  const url = `${endpoint}/${id}`
  return fetch(url.concat(search), {
    ...options,
    method: 'GET',
    headers: await getHeaders(),
  })
    .then(res => res.json())
    .then(res => {
      const insight = groupDetailsData(res?.data)
      const filters = res?.filters
      return {insight, filters}
    })
}

import {
  toJson,
  massageMetrics,
  withMassageFilters,
  baseURL,
  getHeaders,
} from './utils'

const endpoint = `${baseURL}/api/system`

const withMassageSystem = fetchCall => options =>
  fetchCall(options).then(response =>
    Promise.resolve({
      ...response,
      filters: {
        ...response.filters,
        metrics: massageMetrics(response.filters.metrics),
      },
    }),
  )

const _fetchSystem = toJson(async options => {
  const {search, signal} = options
  return fetch(endpoint.concat(search), {headers: await getHeaders(), signal})
})

export const fetchSystemOrganizationData = toJson(async options => {
  const {search, signal} = options
  return fetch(`${endpoint}/organizations`.concat(search), {
    signal,
    headers: await getHeaders(),
  })
})

export const fetchSystem = withMassageFilters(withMassageSystem(_fetchSystem))

import React from 'react'
import PropTypes from 'prop-types'
import isString from 'lodash/isString'
import {AgFlex, AgText} from '@aghealth/ag-components'

const FilterBox = React.forwardRef(({children, label, ...rest}, ref) => (
  <AgFlex flexDirection="column" pb="24px" ref={ref} {...rest}>
    {isString(label) ? (
      <AgText
        color="font-primary"
        fontWeight="bold"
        fontSize="15.75px"
        mb="16px"
        mt={rest?.borderTop ? '24px' : '0px'}
      >
        {label}
      </AgText>
    ) : (
      label
    )}
    {children}
  </AgFlex>
))

FilterBox.propTypes = {
  children: PropTypes.node,
  label: PropTypes.node,
}

export default FilterBox

import React from 'react'
import PropTypes from 'prop-types'
import {AgText, AgImage, AgButton, AgColumn} from '@aghealth/ag-components'
import isFunction from 'lodash/isFunction'
import search from '../assets/search.png'

const defaultText =
  'No results found. Try a different set of filters to get more results.'

const NoResults = ({onReset, text}) => (
  <AgColumn
    alignItems="center"
    justifyContent="space-around"
    mx="16px"
    mb="16px"
    height={382}
    bg="white"
    borderRadius="base"
    data-testid="no-results"
  >
    <AgImage src={search} height={172} width={172} borderRadius="circle" />
    <AgText
      fontSize="14px"
      color="#333333"
      lineHeight="16px"
      textAlign="center"
    >
      {text}
    </AgText>
    {isFunction(onReset) && (
      <AgButton
        fontSize="14px"
        color="white"
        lineHeight="21px"
        bg="#129459"
        borderRadius="sm"
        p={3}
        onClick={onReset}
        data-testid="no-results-reset-button"
      >
        Reset Filters
      </AgButton>
    )}
  </AgColumn>
)

NoResults.propTypes = {
  onReset: PropTypes.func,
  text: PropTypes.string,
}

NoResults.defaultProps = {
  text: defaultText,
}

export default NoResults

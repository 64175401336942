export const FAVORITE_BOX_WIDTH = 220
export const FAVORITE_BOX_MARGIN_X = 8
export const FAVORITE_BOX_TOTAL_WIDTH =
  FAVORITE_BOX_WIDTH + 2 * FAVORITE_BOX_MARGIN_X

export const SCREEN_WIDTHS = {
  LARGE: 992,
  MEDIUM: 768,
}

export const NUMBER_OF_FAVORITE_BOXES_BY_SCREEN_WIDTH_THRESHOLD = {
  [SCREEN_WIDTHS.LARGE]: 3,
  [SCREEN_WIDTHS.MEDIUM]: 2,
  SMALL: 1,
}

export const FAVORITE_FETCH_BATCH_SIZE = 3

export const DEFAULT_FAVORITE_FETCH_OFFSET = 0

export const CUSTOM_TIME_PERIOD_ID = 5

export const SUPPLY_CATEGORIES_CHART_ID = 48
export const METRIC_BY_PHYSICIAN_VARIANT_ID = 1
export const METRIC_BY_CASE_VARIANT_ID = 4
export const COST_BY_SUPPLY_DETAILS_CHART_ID = 49
export const AGGREGATED_VALUE_DATA_POINT_ID = -1
export const THRESHOLD_PHYSICIAN_CASE_COUNT = 11
export const BAR_CHART_TYPE_ID = 1

export const OPPORTUNITY_BY_PROCEDURE_TABLE_COLUMNS = {
  NAME: 'name',
  PROCEDURE: 'procedure',
  SAVINGS: 'savings',
}

export const ACTION_BY_SPECIALITY_TABLE_COLUMNS = {
  PROCEDURE: 'procedure',
  SAVINGS: 'savings',
}
export const TOP_OPPORTUNITY_TABLE_SORT_DIRECTIONS = {
  ASC: 'asc',
  DESC: 'desc',
}

export const TOP_OPPORTUNITY_TABLE_MAX_ROW_DISPLAY_COUNT = 10

export const OVER_TIME_CHART_VARIANT_ID = 2
export const PHYSICIAN_OVER_TIME_VARIANT_ID = 3

export const TIME_PERIOD_365_DAYS_ID = 3

export const ALL_PREFIX = 'All '

import {extent} from '../../../../../utils'

const createScales = config => {
  const keyAccessor = d => d[config.chart.key_accessor]
  const yAccessor = d => d[config.chart.y_accessor]
  const xAccessor = d => d[config.chart.x_accessor]
  const radiusAccessor = d => d[config.chart.radius_accessor]
  const [minX, maxX] = extent(config.unfiltered_data, xAccessor)
  const [minY, maxY] = extent(config.unfiltered_data, yAccessor)
  const [minRadius, maxRadius] = extent(
    config.unfiltered_data,
    radiusAccessor,
  )
  const min = {x: minX, y: minY, r: minRadius}
  const max = {x: maxX, y: maxY, r: maxRadius}
  return {
    keyAccessor,
    yAccessor,
    xAccessor,
    radiusAccessor,
    min,
    max,
  }
}

export default createScales

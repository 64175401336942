import React from 'react'
import PropTypes from 'prop-types'
import {ThemeProvider as StyledThemeProvider} from 'styled-components'
import GlobalStyle from './GlobalStyle'
import {animations, animationDurations} from './animations'
import borders from './borders'
import colors from './colors'
import radii from './radii'
import shadows from './shadows'
import space from './space'
import zIndices from './zIndices'
import variants from './variants'
import {fontSizes, fonts, fontWeights} from './typography'

export const theme = {
  borders,
  colors,
  animations,
  animationDurations,
  radii,
  fontSizes,
  fontWeights,
  fonts,
  shadows,
  space,
  zIndices,
  ...variants,
}
const Theme = React.createContext()
export const useTheme = () => React.useContext(Theme)
const ThemeProvider = ({children}) => (
  <StyledThemeProvider theme={theme}>
    <GlobalStyle />
    {children}
  </StyledThemeProvider>
)

ThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ThemeProvider

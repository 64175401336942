import capitalize from 'lodash/capitalize'
import lowerCase from 'lodash/lowerCase'
import {timeFormat, timeParse} from 'd3-time-format'
import {toDollarString, toPercentString, computeChipLabel} from '../../../utils'

const formatTime = timeFormat('%-b %-y')

const parseTime = timeParse('%Y-%m')

const toSupplyCategoryString = value =>
  value
    .split(' ')
    .map(word =>
      ['Or', 'And', 'or', 'and'].includes(word)
        ? lowerCase(word)
        : capitalize(word),
    )
    .join(' ')

const toCaseCountString = value => `${value} Cases`

const toHospitalCaseNumberString = value => `#${value}`

const toDroppedString = dropped => `
  ${dropped.names.join(', ')} ${
  dropped.names.length > 1 ? 'have' : 'has'
} been removed because there is no data for ${
  dropped.names.length > 1 ? 'them' : 'it'
} in this time period: ${computeChipLabel(dropped.time_period)}.
`
const toDeletedString = count => `
  ${count} ${count > 1 ? 'cases' : 'case'} removed from chart.
`

export const format = (value, type) => {
  if (['$'].includes(type)) return toDollarString(value)
  if (['hospital_case_number'].includes(type))
    return toHospitalCaseNumberString(value)
  if (['supply_category'].includes(type)) return toSupplyCategoryString(value)
  if (['case_count'].includes(type)) return toCaseCountString(value)
  if (['%'].includes(type)) return toPercentString(value)
  if (['%-b %-y'].includes(type)) return formatTime(value)
  if (['dropped_filters'].includes(type)) return toDroppedString(value)
  if (['deleted'].includes(type)) return toDeletedString(value)
  if (['Celcius', 'Celsius'].includes(type)) {
    const v = value?.toLocaleString(undefined, {
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    })
    return v === undefined ? undefined : `${v} \u00B0C`
  }
  if (['mL', 'mL'].includes(type)) {
    const v = value?.toLocaleString(undefined, {
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    })
    return v === undefined ? undefined : `${v} mL`
  }
  if (['Kg', 'kg'].includes(type)) {
    const v = value?.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
    return v === undefined ? undefined : `${v} Kg`
  }
  return value
}

export const parse = (value, type) => {
  if (['%Y-%m'].includes(type)) return parseTime(value)
  return value
}

import React from 'react'
import PropTypes from 'prop-types'
import isArray from 'lodash/isArray'
import styled from 'styled-components'
import {Link as RouterLink} from 'react-router-dom'
import {AgFixed, AgButton, AgRow, AgImage} from '@aghealth/ag-components'
import {BsFillCaretDownFill} from 'react-icons/bs'
import {ProfileMenu, SupportMenu, ScorecardIcon, AlertIcon} from '.'
import colors from '../theme/colors'
import logo from '../assets/logo.png'

const DownArrow = () => (
  <BsFillCaretDownFill size={20} style={{marginLeft: '5px'}} />
)

const boxShadowInsetAsBorder = 'inset 0 -5px 0 0 #3399FF'

const Link = styled(RouterLink)`
  box-shadow: ${props => (props.active ? boxShadowInsetAsBorder : '')};
  font-weight: bold;
  text-decoration: none;
  color: ${colors['font-primary']};
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  margin-right: 30px;
  padding-top: 24.5px;
  padding-bottom: 25.5px;
`

const Header = ({
  home,
  pathname,
  search,
  organizations,
  name,
  onChangeOrganization,
  onChangePassword,
  onLogout,
  onEditProfile,
  onClickDepartments,
  onClickManagementDashboard,
  onClickHospitalList,
  isSystemView,
  isStaff,
  isSSOUser,
  isOpen,
  provider,
  hasDashboard,
  hasExploreData,
  hasInsights,
  hasManagement,
  hasMiscodingTool,
  hasPatientVolumes,
  hasScorecardManagement,
  hasAlerts,
  hasGoals,
}) => {
  const organization = React.useMemo(() => {
    const [selected] = isArray(organizations)
      ? organizations.filter(o => o.selected)
      : [null]
    return selected ? selected.name : null
  }, [organizations])
  const route = React.useMemo(() => {
    const name = pathname.split('/')[1]
    return '/'.concat(name)
  }, [pathname])
  const isScorecard = React.useMemo(() => route === '/scorecard', [route])
  const handleOnClickDepartments = React.useCallback(
    e => {
      if (!isOpen) {
        const isDepartmentsLink = [
          '/dashboard',
          '/explore-data',
          '/top-insights-v2',
        ].includes(route)
        onClickDepartments(isDepartmentsLink)
      }
    },
    [isOpen, onClickDepartments, route],
  )

  const showSpecialties = hasDashboard || hasExploreData || hasInsights

  return (
    <AgFixed
      bg="white"
      width="100%"
      height={70}
      display="flex"
      alignItems="center"
      justifyContent="center"
      mb={70}
      top={0}
      left={0}
      zIndex="xlg"
    >
      <AgRow width="calc(90% - 46px)" justifyContent="space-between">
        <Link to={home}>
          <AgImage
            height={45}
            src={logo}
            alt="CareMeasurement by Avant-garde Health"
          />
        </Link>
        {!isScorecard && (
          <AgRow height="100%" alignItems="center" justifyContent="flex-end">
            <Link
              data-testid="navlink-dashboard-v2"
              active={route === '/' ? 1 : 0}
              to="/"
            >
              Home
            </Link>

            {showSpecialties && (
              <AgButton
                data-testid="navlink-departments"
                id="navlink-departments"
                boxShadow={
                  ['/dashboard', '/explore-data', '/top-insights-v2'].includes(
                    route,
                  )
                    ? boxShadowInsetAsBorder
                    : ''
                }
                fontWeight="bold"
                bg="transparent"
                textDecoration="none"
                color="font-primary"
                fontSize="16px"
                lineHeight="19px"
                display="flex"
                alignItems="center"
                fontFamily="Roboto"
                mr="30px"
                pt="24.5px"
                pb="24.5px"
                height="100%"
                onMouseEnter={handleOnClickDepartments}
              >
                Specialties
                <DownArrow />
              </AgButton>
            )}
            <Link
              data-testid="navlink-pac-tools"
              active={route === '/pac-tools' ? 1 : 0}
              to="/pac-tools"
            >
              PAC Tools
            </Link>
            {hasPatientVolumes && (
              <Link
                data-testid="navlink-pac-tools"
                active={route === '/throughput' ? 1 : 0}
                to="/throughput"
              >
                Throughput
              </Link>
            )}
            {hasMiscodingTool && (
              <Link
                data-testid="navlink-miscoding-tool"
                active={route === '/coding' ? 1 : 0}
                to={`/coding${search}`}
              >
                Coding
              </Link>
            )}
            {hasGoals && (
              <Link
                data-testid="navlink-goals"
                active={route === '/goals' ? 1 : 0}
                to={`/goals${search}`}
              >
                Goals
              </Link>
            )}
            {hasScorecardManagement && <ScorecardIcon pathname={pathname} />}
            {hasAlerts && <AlertIcon active={route === '/alerts'} />}
            <SupportMenu
              pathname={pathname}
              search={search}
              isStaff={isStaff}
              isSystemView={isSystemView}
            />
            <ProfileMenu
              organization={organization}
              name={name}
              organizations={organizations}
              onChangeOrganization={onChangeOrganization}
              onChangePassword={onChangePassword}
              onEditProfile={onEditProfile}
              onLogout={onLogout}
              onClickHospitalList={onClickHospitalList}
              onClickManagementDashboard={onClickManagementDashboard}
              showHospitalList={isStaff}
              showManagement={isStaff || hasManagement}
              isSSOUser={isSSOUser}
              provider={provider}
            />
          </AgRow>
        )}
      </AgRow>
    </AgFixed>
  )
}

Header.propTypes = {
  pathname: PropTypes.string,
  search: PropTypes.string,
  onClickDepartments: PropTypes.func,
  organizations: PropTypes.array,
  name: PropTypes.string,
  onChangeOrganization: PropTypes.func,
  onChangePassword: PropTypes.func,
  onLogout: PropTypes.func,
  onEditProfile: PropTypes.func,
  isSystemView: PropTypes.bool,
  onClickHospitalList: PropTypes.func,
  onClickManagementDashboard: PropTypes.func,
  isStaff: PropTypes.bool,
  isSSOUser: PropTypes.bool,
  isOpen: PropTypes.bool,
  home: PropTypes.string,
  provider: PropTypes.string,
  hasDashboard: PropTypes.bool,
  hasExploreData: PropTypes.bool,
  hasInsights: PropTypes.bool,
  hasManagement: PropTypes.bool,
  hasMiscodingTool: PropTypes.bool,
  hasPatientVolumes: PropTypes.bool,
  hasScorecardManagement: PropTypes.bool,
  hasAlerts: PropTypes.bool,
  hasGoals: PropTypes.bool,
}

export default Header

export const SET_REPORT_DATA = 'SET_REPORT_DATA'
export const SET_REPORT_LOADING = 'SET_REPORT_LOADING'
export const SET_REPORT_NOT_LOADING = 'SET_REPORT_NOT_LOADING'
export const SET_REPORT_LOADING_DATA = 'SET_REPORT_LOADING_DATA'
export const SET_REPORT_NOT_LOADING_DATA = 'SET_REPORT_NOT_LOADING_DATA'
export const SET_REPORT_ERROR = 'SET_REPORT_ERROR'
export const SET_REPORT_FILTERS = 'SET_REPORT_FILTERS'
export const SET_REPORT_TIME_PERIODS = 'SET_REPORT_TIME_PERIODS'
export const SET_REPORT_TAB = 'SET_REPORT_TAB'
export const SET_REPORT_PHYSICIAN = 'SET_REPORT_PHYSICIAN'
export const SET_REPORT_SEARCH = 'SET_REPORT_SEARCH'
export const SET_REPORT_ALL_INPATIENT = 'SET_REPORT_ALL_INPATIENT'
export const SET_REPORT_ALL_OUTPATIENT = 'SET_REPORT_ALL_OUTPATIENT'
export const SET_REPORT_DRGS = 'SET_REPORT_DRGS'
export const SET_REPORT_OUTPATIENT_PROCEDURES =
  'SET_REPORT_OUTPATIENT_PROCEDURES'
export const SET_REPORT_EXCLUSIONS = 'SET_REPORT_EXCLUSIONS'
export const SET_REPORT_ALL_EXCLUSIONS = 'SET_REPORT_ALL_EXCLUSIONS'
export const SET_REPORT_DATE_SAVED = 'SET_REPORT_DATE_SAVED'
export const SET_REPORT_ICDS = 'SET_REPORT_ICDS'
export const SET_REPORT_CPTS = 'SET_REPORT_CPTS'
export const SET_REPORT_EXCLUDE_IP = 'SET_REPORT_EXCLUDE_IP'
export const SET_REPORT_EXCLUDE_OP = 'SET_REPORT_EXCLUDE_OP'

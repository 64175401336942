export const fontSizes = {
  sm: 10,
  base: 12,
  md: 14,
  lg: 18,
  xlg: 24,
  xxlg: 28,
  xxxlg: 36,
}
export const fonts = {
  primary: "'Roboto', sans-serif",
}
export const fontWeights = {
  sm: 100,
  base: 200,
  md: 400,
  lg: 500,
  xlg: 700,
}

import React from 'react'
import PropTypes from 'prop-types'
import {animated, useTransition} from 'react-spring'
import {AgFixed} from '@aghealth/ag-components'

const Fixed = animated(AgFixed)

const Modal = ({children, onClose, visible, justifyContent}) => {
  React.useEffect(() => {
    if (visible) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'unset'
    }

    // Cleanup function to re-enable scrolling when the modal closes or component unmounts
    return () => {
      document.body.style.overflow = 'unset'
    }
  }, [visible])

  const transitions = useTransition(visible, {
    from: {opacity: 0},
    enter: {opacity: 1},
    leave: {opacity: 0},
  })

  return transitions(
    (style, item) =>
      item && (
        <Fixed
          display="flex"
          flexDirection="column"
          justifyContent={justifyContent}
          alignItems="center"
          bg="rgba(17, 17, 17, 0.8)"
          zIndex="xxxlg"
          width="100%"
          height="100vh"
          key={item}
          style={style}
          top={0}
          left={0}
        >
          {React.cloneElement(children, {onClose})}
        </Fixed>
      ),
  )
}

Modal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    PropTypes.func,
    PropTypes.string,
  ]),
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  justifyContent: PropTypes.string,
}

Modal.defaultProps = {
  justifyContent: 'flex-start',
}

export default Modal

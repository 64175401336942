const createDimensions = () => ({
  marginTop: 50,
  marginLeft: 100,
  marginBottom: 100,
  marginRight: 100,
  paddingTop: 50,
  paddingBottom: 50,
  paddingLeft: 50,
  paddingRight: 50,
})

export default createDimensions

import React from 'react'
import {SupplyChartTooltip, CaseLevelTooltip} from '../../../components'

const createTooltip = (config, format) => {
  const tooltip = [48].includes(config.id)
    ? {
        styles: {
          zIndex: 10,
        },
        content: (
          <SupplyChartTooltip
            onSeeCostDetails={config?.showSupplyDetailsView}
          />
        ),
        showOnClick: true,
        context: {document, window},
      }
    : config.chart.dimension === 'x' && config.id !== 48
    ? {
        styles: {
          zIndex: 10,
        },
        content: (
          <CaseLevelTooltip
            configId={config.id}
            metric={config.metric}
            keys={config.options?.values || config.chart.x_accessor}
            format={format}
          />
        ),
        showOnClick: true,
        context: {document, window},
      }
    : undefined

  return tooltip
}

export default createTooltip

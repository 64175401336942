import isPlainObject from 'lodash/isPlainObject'
import merge from 'lodash/merge'
import isArray from 'lodash/isArray'
import colors from './colors'
import createBarChart from './bar'
import createLineChart from './line'
import createBubbleChart from './bubble'
import {isGroupByOrganization} from '../config/utils'

const withHandleTestEnv = create => config => {
  const created = create(config)
  if (process.env.NODE_ENV === 'test')
    return {...created, dimensions: {width: 1000}}
  return created
}

export const convertToOrganizationKeys = obj => {
  const deepCopyObj = JSON.parse(JSON.stringify(obj))
  function updateValues(object) {
    for (const key in object) {
      if (isPlainObject(object[key])) {
        updateValues(object[key])
      }

      if (isArray(object[key])) {
        object[key].forEach(item => {
          updateValues(item)
        })
      }

      if (object[key] === 'surgeon_name') {
        object[key] = 'organization_full_name'
      } else if (object[key] === 'surgeon_id') {
        object[key] = 'organization_id'
      }
    }
  }

  updateValues(deepCopyObj)
  return deepCopyObj
}
export const createGroupByOrganizationKeys = obj => {
  const b = convertToOrganizationKeys(obj)

  return b
}

const withGroupByOrganizationKey = create => config => {
  if (isGroupByOrganization(config)) {
    const chart = createGroupByOrganizationKeys(config?.chart)
    return create({...config, chart})
  }
  return create(config)
}

export const createChart = withGroupByOrganizationKey(
  withHandleTestEnv(config => {
    const chart = {colors}
    if (config.chart.type === 1) return merge(chart, createBarChart(config))
    if (config.chart.type === 2) return merge(chart, createLineChart(config))
    if (config.chart.type === 3) return merge(chart, createBubbleChart(config))
    return {data: []}
  }),
)

import React from 'react'
import PropTypes from 'prop-types'
import {useTransition, animated} from 'react-spring'
import {
  AgImage,
  AgText,
  AgAbsolute,
  useOnClickOutside,
  AgRow,
  AgColumn,
} from '@aghealth/ag-components'

import avatar from '../assets/avatar.png'
import avatarV2 from '../assets/avatar-icon-nav-v2.png'

const styles = {
  Trigger: {
    TriggerWrapper: {cursor: 'pointer'},
    Icon: {
      size: (_, props) => (props.isNavV2 ? 20 : 26),
    },
  },
}

const Absolute = animated(AgAbsolute)

const Trigger = ({onClick, name, organization, isNavV2}) => (
  <AgRow onClick={onClick} sx={styles.Trigger.TriggerWrapper}>
    <AgImage
      src={isNavV2 ? avatarV2 : avatar}
      data-testid="user-avatar"
      isNavV2={isNavV2}
      sx={styles.Trigger.Icon}
    />
    <AgColumn ml={isNavV2 ? 3 : 2}>
      <AgText
        fontSize="base"
        lineHeight="14px"
        color="font-primary"
        fontWeight="bold"
        py="1.5px"
      >
        {`Hi, ${name}!`}
      </AgText>
      <AgText
        data-testid="user-organization"
        fontSize="base"
        lineHeight="14px"
        color="font-secondary"
        py="1.5px"
      >
        {organization}
      </AgText>
    </AgColumn>
  </AgRow>
)

Trigger.propTypes = {
  onClick: PropTypes.func,
  name: PropTypes.string,
  organization: PropTypes.string,
  isNavV2: PropTypes.bool,
}

const MenuText = ({children, ...props}) => (
  <AgText
    cursor="pointer"
    ml="12px"
    mt="15px"
    color="font-primary"
    fontSize="14px"
    lineHeight="16px"
    {...props}
  >
    {children}
  </AgText>
)

MenuText.propTypes = {
  children: PropTypes.node,
}

const Menu = ({
  organizations,
  transition,
  onChangeOrganization,
  onLogout,
  onChangePassword,
  onEditProfile,
  onClickHospitalList,
  onClickManagementDashboard,
  showHospitalList,
  showManagement,
  isSSOUser,
}) => (
  <Absolute
    width={300}
    top={70}
    right="5%"
    borderRadius="2px"
    boxShadow="profile"
    bg="white"
    style={transition}
  >
    <AgColumn
      maxHeight={200}
      sx={{
        variant: 'scrollbars.y',
      }}
    >
      {organizations?.map((o, i) => (
        <MenuText
          data-testid="organization"
          key={o.name}
          mb={i === organizations?.length - 1 ? '15px' : '0px'}
          fontWeight={o.selected ? 'bold' : 'normal'}
          onClick={() => onChangeOrganization(o)}
        >
          {o.name}
        </MenuText>
      ))}
    </AgColumn>
    {(showHospitalList || showManagement) && (
      <AgColumn borderTop="1px solid #CCCCCC">
        {showHospitalList && (
          <MenuText
            onClick={onClickHospitalList}
            mb={showManagement ? '' : '15px'}
          >
            List of Hospitals
          </MenuText>
        )}
        {showManagement && (
          <MenuText onClick={onClickManagementDashboard} mb="15px">
            Management Dashboard
          </MenuText>
        )}
      </AgColumn>
    )}
    <AgColumn borderTop="1px solid #CCCCCC">
      <MenuText onClick={onEditProfile} mb="15px">
        My Profile
      </MenuText>
    </AgColumn>
    {!isSSOUser && (
      <AgColumn borderTop="1px solid #CCCCCC">
        <MenuText onClick={onChangePassword} mb="15px">
          Change Password
        </MenuText>
      </AgColumn>
    )}
    <AgColumn borderTop="1px solid #CCCCCC">
      <MenuText mb="15px" onClick={onLogout}>
        Logout
      </MenuText>
    </AgColumn>
  </Absolute>
)

Menu.propTypes = {
  organizations: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    }),
  ),
  transition: PropTypes.object,
  onChangeOrganization: PropTypes.func,
  onChangePassword: PropTypes.func,
  onLogout: PropTypes.func,
  onEditProfile: PropTypes.func,
  showHospitalList: PropTypes.bool,
  showManagement: PropTypes.bool,
  isSSOUser: PropTypes.bool,
  onClickHospitalList: PropTypes.func,
  onClickManagementDashboard: PropTypes.func,
}

const ProfileMenu = ({
  name,
  organizations,
  organization,
  onChangeOrganization,
  onChangePassword,
  onEditProfile,
  onLogout,
  onClickHospitalList,
  onClickManagementDashboard,
  showHospitalList,
  showManagement,
  isSSOUser,
  provider,
  isNavV2,
}) => {
  const ref = React.useRef()
  const [visible, setVisible] = React.useState(false)
  const transitions = useTransition(visible, {
    from: {opacity: 0},
    enter: {opacity: 1},
    leave: {opacity: 0},
  })
  const handleToggleShowMenu = React.useCallback(() => {
    setVisible(prev => !prev)
  }, [setVisible])
  const handleNotShowMenu = React.useCallback(() => {
    setVisible(false)
  }, [setVisible])
  const handleOnClickManagementDashboard = React.useCallback(() => {
    setVisible(false)
    onClickManagementDashboard()
  }, [onClickManagementDashboard])
  const handleOnChangePassword = React.useCallback(() => {
    setVisible(false)
    onChangePassword()
  }, [onChangePassword])
  const handleOnEditProfile = React.useCallback(() => {
    setVisible(false)
    onEditProfile()
  }, [onEditProfile])
  const handleOnClickHospitalList = React.useCallback(() => {
    setVisible(false)
    onClickHospitalList()
  }, [onClickHospitalList])
  useOnClickOutside(ref, handleNotShowMenu, document)
  return (
    <AgRow ref={ref} height="100%" alignItems="center">
      <Trigger
        onClick={handleToggleShowMenu}
        name={name}
        organization={organization}
        isNavV2={isNavV2}
      />
      {transitions(
        (style, item) =>
          item && (
            <Menu
              key={item}
              transition={style}
              organizations={organizations}
              onChangeOrganization={onChangeOrganization}
              onLogout={onLogout}
              onChangePassword={handleOnChangePassword}
              onEditProfile={handleOnEditProfile}
              onClickHospitalList={handleOnClickHospitalList}
              onClickManagementDashboard={handleOnClickManagementDashboard}
              showHospitalList={showHospitalList}
              showManagement={showManagement}
              isSSOUser={isSSOUser}
              provider={provider}
              handleToggleShowMenu={handleToggleShowMenu}
            />
          ),
      )}
    </AgRow>
  )
}

ProfileMenu.propTypes = {
  organizations: PropTypes.array,
  name: PropTypes.string,
  organization: PropTypes.string,
  onChangeOrganization: PropTypes.func,
  onChangePassword: PropTypes.func,
  onEditProfile: PropTypes.func,
  onLogout: PropTypes.func,
  onClickHospitalList: PropTypes.func,
  onClickManagementDashboard: PropTypes.func,
  showHospitalList: PropTypes.bool,
  showManagement: PropTypes.bool,
  isSSOUser: PropTypes.bool,
  provider: PropTypes.string,
  isNavV2: PropTypes.bool,
}

export default ProfileMenu

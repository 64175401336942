import React from 'react'
import PropTypes from 'prop-types'
import {AgChips} from '@aghealth/ag-components'

const styles = {
  Root: {
    px: '16px',
    py: '16px',
  },
}

const Chips = ({chips, onClose, label}) => (
  <AgChips label={label} list={chips} onClose={onClose} styles={styles} />
)

Chips.propTypes = {
  chips: PropTypes.array,
  onClose: PropTypes.func,
  label: PropTypes.string,
}

Chips.defaultProps = {
  chips: [],
  onClose: () => {},
  label: 'Your Selections',
}

export default React.memo(Chips)

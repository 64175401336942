import {createFormatTick, createAxisStyles} from '../utils'

const createAxes = config => {
  const leftAxis = {
    showGridLines: true,
    formatTick: createFormatTick(config, 'left_axis'),
    tickCount: 5,
    labels: config.chart.left_axis.labels,
    styles: createAxisStyles(config),
  }
  const bottomAxis = {
    tickCount: 5,
    formatTick: createFormatTick(config, 'bottom_axis'),
    labels: config.chart.bottom_axis.labels,
    styles: createAxisStyles(config),
  }
  const topAxisLabels = [{
    label: config.chart.top_axis?.labels[0]?.label,
    labelOffsetY: -10,
  }]
  const topAxis = {
    labels: topAxisLabels,
    hideAxis: true,
    hideTicks: true,
  }
  return {topAxis, leftAxis, bottomAxis}
}

export default createAxes

import React, {useEffect, useMemo, useRef, useState} from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import groupBy from 'lodash/groupBy'
import isString from 'lodash/isString'
import orderBy from 'lodash/orderBy'
import partition from 'lodash/partition'
import {
  AgBox,
  AgButton,
  AgColumn,
  AgFlex,
  AgSearchInput,
  AgText,
} from '@aghealth/ag-components'
import {useSearch} from '../hooks'
import {
  flattenArray,
  selectManyByIds,
  unselectManyByIds,
  selectOneById,
  unselectOneById,
  getLeafNodes,
} from '../utils'
import {ScrollY, CheckboxDirectory} from '.'

const styles = {
  SearchInput: {
    Root: {
      borderRadius: '4px',
    },
    Input: {
      py: '7.5px',
    },
    Icon: {
      size: '17.49px',
    },
  },

  UpdateButton: {
    height: '44px',
    border: 'base',
    borderColor: 'success.500',
    borderRadius: 'base',
    bg: 'success.500',
    padding: '8px 18px',
  },

  UpdateButtonText: {
    color: 'base.white',
    fontSize: 'md',
    fontWeight: 'semibold',
    lineHeight: 'md',
  },

  ClearButton: {
    height: '44px',
    border: 'base',
    borderColor: 'new-gray.300',
    borderRadius: 'base',
    bg: 'base.white',
    padding: '8px 18px',
  },

  ClearButtonText: {
    color: 'new-gray.700',
    fontSize: 'md',
    fontWeight: 'semibold',
    lineHeight: 'md',
  },
}

export const isAllType = selection =>
  isString(selection.type) && selection.type.startsWith('all_')

export const createFlattened = ({list}) =>
  flattenArray(list).filter(l => !isAllType(l))

export const createRecursiveFlattened = ({list}) =>
  flattenArray(list).filter(l => l.searchable)

export const createFiltered = ({searched, list}) => {
  const groups = groupBy(searched, s => (s.parent ? s.parent : s.type))
  const groupsKeys = Object.keys(groups)
  const _filtered = []

  const createItems = ({items, id}) => {
    const filteredItems = groups[id] ? groups[id].map(d => d.id) : []
    const _items = []
    items.forEach(item => {
      if (
        filteredItems.includes(item.id) ||
        groupsKeys.find(key => key.includes(item.id))
      ) {
        _items.push({
          ...item,
          items: item.items ? createItems(item) : [],
        })
      }
    })
    return _items
  }
  list.forEach(l => {
    if (groupsKeys.find(key => key.includes(l.id))) {
      _filtered.push({
        ...l,
        items: l.items ? createItems(l) : [],
      })
    }
  })
  return _filtered
}

export const createRecursiveFiltered = ({searched}) => {
  const groups = groupBy(searched, s => JSON.stringify(s.parent))
  const parents = Object.entries(groups).reduce((acc, [p, items]) => {
    const parent = JSON.parse(p)
    const selected = items?.every(i => i.selected)
    const type = isAllType(parent) ? items[0]?.type : parent.type
    const name = isAllType(parent)
      ? parent?.name?.split(' ')?.slice(1)?.join(' ')
      : parent.name
    return [...acc, {...parent, items, selected, type, name}]
  }, [])
  const [roots, nonRoots] = partition(parents, p => !p.parent)
  let remaining = []
  if (nonRoots?.length > 0) {
    remaining = createRecursiveFiltered({searched: nonRoots})
  }
  return orderBy([...roots, ...remaining], b => b.id)
}

export const createSearched = ({searched, item}) => {
  const result = searched.map(s => {
    if (s.id === item.id || s.type === item.id) {
      return {...s, selected: !s.selected}
    }
    return s
  })
  return result
}

export const createRecursiveSearched = ({searched, item}) => {
  if (item?.items?.length > 0) {
    const nodes = getLeafNodes(item)
    const ids = nodes.map(l => l.id)
    return searched.map(
      nodes.every(l => l.selected)
        ? unselectManyByIds(ids)
        : selectManyByIds(ids),
    )
  }
  return searched.map(
    item?.selected ? unselectOneById(item) : selectOneById(item),
  )
}
const PAGE_SIZE = 15

const PaginatedSearchableCheckboxDirectory = ({
  list,
  onChange,
  search,
  overrideVisible,
  recursiveStrategy,
  loading,
  onUpdate,
  onClear,
  hasUpdateButton,
}) => {
  const endOfListRef = useRef()
  const [endIndex, setEndPageIndex] = useState(1)
  const flattened = React.useMemo(
    () =>
      recursiveStrategy
        ? createRecursiveFlattened({list})
        : createFlattened({list}),
    [list, recursiveStrategy],
  )

  const [{searched, searchTerm}, {setSearchTerm, setSearched}] =
    useSearch(flattened)
  const handleOnSearch = React.useCallback(
    term => {
      setSearchTerm(term)
      setEndPageIndex(1)
    },
    [setSearchTerm, setEndPageIndex],
  )

  const handleOnChange = React.useCallback(
    item => {
      onChange(item)
    },
    [onChange],
  )

  const handleSetSearch = React.useCallback(
    item => {
      if (!loading) {
        if (recursiveStrategy) {
          const current = createRecursiveSearched({searched, item})
          setSearched(current)
          handleOnChange(item)
        } else {
          const current = createSearched({searched, item})
          setSearched(current)
          handleOnChange(item)
        }
      }
    },
    [handleOnChange, searched, setSearched, recursiveStrategy, loading],
  )

  useEffect(() => {
    if (window.IntersectionObserver) {
      const endOfPage = endOfListRef.current
      if (!endOfPage) {
        return
      }

      const intersectionObserver = new window.IntersectionObserver(entries => {
        if (entries[0].isIntersecting) {
          setEndPageIndex(endIndex => endIndex + 1)
        }
      })

      intersectionObserver.observe(endOfPage)

      return () => {
        intersectionObserver.unobserve(endOfPage)
      }
    }
  }, [])

  const filtered = React.useMemo(() => {
    const result = recursiveStrategy
      ? createRecursiveFiltered({searched})
      : createFiltered({searched, list})
    return result
  }, [list, searched, recursiveStrategy])
  const paginatedList = useMemo(() => {
    return filtered.slice(0, endIndex * PAGE_SIZE)
  }, [endIndex, filtered])
  return (
    <AgColumn>
      {search && (
        <AgSearchInput
          data-testid="search-input"
          value={searchTerm}
          onChange={handleOnSearch}
          styles={styles.SearchInput}
          context={document}
        />
      )}
      <ScrollY maxHeight={200} width="100%" mt={2}>
        {isEmpty(searchTerm) ? (
          <CheckboxDirectory
            list={paginatedList}
            onChange={handleOnChange}
            overrideVisible={overrideVisible}
          />
        ) : (
          <CheckboxDirectory list={paginatedList} onChange={handleSetSearch} />
        )}
        <AgBox ref={endOfListRef} />
      </ScrollY>

      {hasUpdateButton && (
        <AgFlex justifyContent="flex-end" mt={16}>
          <AgButton sx={styles.UpdateButton} onClick={onUpdate} mr="8px">
            <AgFlex padding="0px 8px" alignItems="center">
              <AgText sx={styles.UpdateButtonText}>Update</AgText>
            </AgFlex>
          </AgButton>

          <AgButton sx={styles.ClearButton} onClick={onClear}>
            <AgFlex padding="0px 8px" alignItems="center">
              <AgText sx={styles.ClearButtonText}>Clear</AgText>
            </AgFlex>
          </AgButton>
        </AgFlex>
      )}
    </AgColumn>
  )
}
PaginatedSearchableCheckboxDirectory.propTypes = {
  onChange: PropTypes.func,
  list: PropTypes.arrayOf(PropTypes.any),
  search: PropTypes.bool,
  overrideVisible: PropTypes.bool,
  recursiveStrategy: PropTypes.bool,
  loading: PropTypes.bool,
  onUpdate: PropTypes.func,
  onClear: PropTypes.func,
  hasUpdateButton: PropTypes.bool,
}

PaginatedSearchableCheckboxDirectory.defaultProps = {
  search: true,
  hasUpdateButton: false,
  onUpdate: () => {},
  onClear: () => {},
}

export default PaginatedSearchableCheckboxDirectory

import React from 'react'
import PropTypes from 'prop-types'
import {AgColumn, AgBox, AgSpinner} from '@aghealth/ag-components'

const Loading = ({height = '300px'}) => (
  <AgColumn width="100%" height="100%" alignItems="center">
    <AgBox height={height}>
      <AgSpinner color="#1B263F" size={100} />
    </AgBox>
  </AgColumn>
)

Loading.propTypes = {
  height: PropTypes.string,
}

export default Loading

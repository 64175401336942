import {batch} from 'react-redux'
import {fetchGoals} from '../../api'
import {
  SET_GOALS_DATA,
  SET_GOALS_LOADING,
  SET_GOALS_ERROR,
  SET_GOALS_NOT_LOADING,
  SET_GOALS_LOADING_DATA,
  SET_GOALS_NOT_LOADING_DATA,
  SET_GOALS_SUMMARY_DATA,
  SET_GOALS_FILTERS,
  SET_GOALS_DEPARTMENTS,
  SET_GOALS_SHOW_ARCHIVED,
} from '../types'
import {setQuery, withBailOnLoading} from './utils'
import {selectOneById, unselectOneById} from '../../utils'

const setData = payload => ({type: SET_GOALS_DATA, payload})

const setLoading = () => ({type: SET_GOALS_LOADING})

const setNotLoading = () => ({type: SET_GOALS_NOT_LOADING})

const setLoadingData = () => ({type: SET_GOALS_LOADING_DATA})

const setNotLoadingData = () => ({type: SET_GOALS_NOT_LOADING_DATA})

const setError = payload => ({type: SET_GOALS_ERROR, payload})

const setSummaryData = payload => ({type: SET_GOALS_SUMMARY_DATA, payload})

const setFilters = payload => ({type: SET_GOALS_FILTERS, payload})

const setDepartments = payload => ({type: SET_GOALS_DEPARTMENTS, payload})

const setArchivedGoals = payload => ({type: SET_GOALS_SHOW_ARCHIVED, payload})

const toggleDepartment = (payload, history) => (dispatch, getState) => {
  const {departments: current_departments} = getState().goals.filters
  const departments = current_departments.map(
    payload.selected ? unselectOneById(payload) : selectOneById(payload),
  )
  batch(() => {
    dispatch(setDepartments(departments))
    dispatch(setQuery({history, store: 'goals', onLoading: setLoadingData}))
  })
}
const toggleShowArchived = () => (dispatch, getState) => {
  const {show_archived} = getState().goals
  const show_archived_goals = !show_archived
  dispatch(setArchivedGoals(show_archived_goals))
}

const toggleDepartmentList = (payload, history) => dispatch => {
  batch(() => {
    dispatch(setDepartments(payload))
    dispatch(setQuery({history, store: 'goals', onLoading: setLoadingData}))
  })
}

const resetFilters = history => (dispatch, getState) => {
  const {departments: config_departments} = getState().config
  const {departments: current_departments} = getState().goals.filters
  const default_department = config_departments.find(d => d.default)
  const departments = current_departments.map(d => ({
    ...d,
    selected: d.id === default_department?.id,
  }))

  batch(() => {
    dispatch(setDepartments(departments))
    dispatch(
      setQuery({
        history,
        store: 'goals',
        onLoading: setLoadingData,
      }),
    )
  })
}

const deleteGoal = id => (dispatch, getState) => {
  const {data} = getState().goals
  const updated = data.filter(d => d.id !== id)
  dispatch(setData(updated))
}

const fetch =
  ({signal, initial, bail, search, skipLoading}) =>
  dispatch => {
    if (bail) return undefined
    if (!skipLoading) {
      if (initial) {
        dispatch(setLoading())
      } else {
        dispatch(setLoadingData())
      }
    }
    return fetchGoals({signal, method: 'GET', search})
      .then(response => {
        const sortByStartingGoal = response?.data?.sort(
          (a, b) => b.customized_goal - a.customized_goal,
        )
        const {departments} = response?.filters
        const depts = departments?.reduce((acc, dept) => {
          if (!acc[dept?.id]) {
            acc[dept?.id] = dept
          }
          return acc
        }, {})
        batch(() => {
          dispatch(
            setData(
              sortByStartingGoal?.map(d => ({
                ...d,
                department_name: d?.department_id
                  ?.split(',')
                  .map(id => depts[parseInt(id.trim())]?.name)
                  .filter(name => name)
                  .join(', '),
                summary: response?.summary,
              })),
            ),
          )
          dispatch(setFilters(response?.filters))
          dispatch(setSummaryData(response?.summary))

          if (initial) {
            dispatch(setNotLoading())
            dispatch(setNotLoadingData())
          } else {
            dispatch(setNotLoadingData())
          }
        })
      })
      .catch(error => {
        batch(() => {
          dispatch(setError(error))
          if (initial) {
            dispatch(setNotLoading())
            dispatch(setNotLoadingData())
          } else {
            dispatch(setNotLoadingData())
          }
        })
      })
  }

const actions = {
  fetch,
  toggleDepartment: withBailOnLoading(toggleDepartment, 'goals'),
  toggleShowArchived,
  toggleDepartmentList: withBailOnLoading(toggleDepartmentList, 'goals'),
  resetFilters: withBailOnLoading(resetFilters, 'goals'),
  deleteGoal,
}

export default actions

import React from 'react'
import {useHistory} from 'react-router-dom'
import PropTypes from 'prop-types'
import {useTransition, useSpring, animated} from 'react-spring'

import {
  AgButton,
  AgAbsolute,
  AgRow,
  AgColumn,
  AgText,
} from '@aghealth/ag-components'
import {BsChevronUp} from 'react-icons/bs'

const UpArrow = () => <BsChevronUp size={20} />

const ToggleArrow = ({visible}) => {
  const {transform} = useSpring({
    transform: visible
      ? 'rotate(0deg) translateY(2px)'
      : 'rotate(180deg) translateY(0px)',
  })

  return (
    <AgRow style={{marginLeft: '5px'}}>
      <animated.div style={{transform}}>
        <UpArrow />
      </animated.div>
    </AgRow>
  )
}

ToggleArrow.propTypes = {
  visible: PropTypes.bool,
}

const MenuLink = ({children, ...props}) => {
  return (
    <AgText
      cursor="pointer"
      color="font-primary"
      p="16px 32px"
      fontSize="16px"
      lineHeight="16px"
      fontWeight="bold"
      borderRadius="8px"
      sx={{
        cursor: 'pointer',
        '&:hover': {
          background: '#F0F9FF',
        },
      }}
      {...props}
    >
      {children}
    </AgText>
  )
}

MenuLink.propTypes = {
  children: PropTypes.node,
}

const Menu = ({menuItems, transition, search}) => {
  const history = useHistory()
  return (
    <Absolute
      minWidth={150}
      top={70}
      borderRadius="8px"
      backgroundColor="blue"
      bg="white"
      style={transition}
      boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25), 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)"
    >
      <AgColumn
        maxHeight={200}
        sx={{
          variant: 'scrollbars.y',
        }}
      >
        {menuItems?.map(({name, url}) => (
          <MenuLink
            key={name}
            onClick={() => {
              history.push(`${url}${search}`)
            }}
          >
            {name}
          </MenuLink>
        ))}
      </AgColumn>
    </Absolute>
  )
}

Menu.propTypes = {
  menuItems: PropTypes.array,
  transition: PropTypes.object,
  search: PropTypes.string,
}

const Absolute = animated(AgAbsolute)

const DropdownMenu = ({name, id, menuItems, search}) => {
  const [visible, setVisible] = React.useState(false)

  const handleMouseEnter = () => {
    setVisible(true)
  }

  const handleMouseLeave = () => {
    setVisible(false)
  }

  const transitions = useTransition(visible, {
    from: {opacity: 0},
    enter: {opacity: 1},
    leave: {opacity: 0},
  })
  return (
    <AgRow onMouseLeave={handleMouseLeave}>
      <AgButton
        id={`navlink-${id}`}
        fontWeight="bold"
        bg="transparent"
        textDecoration="none"
        color="font-primary"
        fontSize="16px"
        lineHeight="19px"
        display="flex"
        alignItems="center"
        fontFamily="Roboto"
        pt="24.5px"
        pb="24.5px"
        mx="4px"
        height="100%"
        onMouseEnter={handleMouseEnter}
      >
        {name}
        <ToggleArrow visible={visible} />
      </AgButton>

      {transitions(
        (style, item) =>
          item && (
            <Menu
              key={item}
              transition={style}
              menuItems={menuItems}
              search={search}
            />
          ),
      )}
    </AgRow>
  )
}

DropdownMenu.propTypes = {
  name: PropTypes.string,
  id: PropTypes.string,
  menuItems: PropTypes.array,
  search: PropTypes.string,
}

export default DropdownMenu

const hasAggregate = config => config?.series?.some(s => s.aggregate)

const createOptionsConfig = config => ({
  ...config,
  format: config?.format
    ? config.format
    : config?.series
        ?.filter(s => !s.aggregate)
        ?.slice(0, 1)
        ?.map(s => s.format)
        ?.join(),
  max_key: hasAggregate(config)
    ? config?.series
        ?.filter(s => s.aggregate)
        ?.slice(0, 1)
        ?.map(s => s.id)
        .join()
    : config?.max_key,
  series: hasAggregate(config)
    ? config?.series?.filter(s => s.aggregate)
    : [{id: null, name: null, format: config?.series?.[0]?.format}],
})

const createBooleanConfig = config => ({
  ...config,
  series: config?.series?.filter(s => s.format === '%'),
})

export const createByPhysicianBarChart = config => {
  const result = {
    bottom_axis: {tick_sublabel_accessor: 'case_count'},
    dimension: 'y',
    key_accessor: 'surgeon_name',
    left_axis: {
      tick_format: config?.series
        ?.slice(0, 1)
        ?.map(s => s.format)
        .join(),
    },
    title: config?.name,
    type: 1,
    x_accessor: 'surgeon_name',
    y_accessor: config?.series
      ?.slice(0, 1)
      ?.map(s => s.id)
      .join(),
    max_value_accessor: config?.series
      ?.slice(0, 1)
      ?.map(s => s.id)
      .join(),
  }
  return result
}

export const createCaseLevelBarChart = config => {
  const labels = config?.label ? [{label: config?.label}] : undefined
  const result = {
    title: config?.name,
    dimension: 'x',
    key_accessor: 'hospital_case_number',
    y_accessor: 'hospital_case_number',
    x_accessor: config?.series
      ?.slice(0, 1)
      ?.map(s => s.id)
      .join(),
    max_value_accessor: config?.series
      ?.slice(0, 1)
      ?.map(s => s.id)
      .join(),
    left_axis: {
      tick_label_accessor: 'surgeon_name',
      tick_sublabel_accessor: 'hospital_case_number',
    },
    bottom_axis: {
      tick_format: config?.series
        ?.slice(0, 1)
        ?.map(s => s.format)
        .join(),
      labels,
    },
    type: 1,
  }
  return result
}

export const createByPhysicianOptionsBarChart = config => {
  const result = {
    title: config?.name,
    dimension: 'y',
    max_value_accessor: config?.max_key,
    group_mode: 'stacked',
    key_accessor: 'surgeon_name',
    x_accessor: 'surgeon_name',
    left_axis: {
      tick_format: config?.format,
    },
    bottom_axis: {
      tick_sublabel_accessor: 'case_count',
    },
    type: 1,
  }
  return result
}

export const createCaseLevelOptionsBarChart = config => {
  const labels = config?.label ? [{label: config?.label}] : undefined
  const result = {
    title: config?.name,
    max_value_accessor: config?.max_key,
    dimension: 'x',
    group_mode: 'stacked',
    key_accessor: 'hospital_case_number',
    x_accessor: 'hospital_case_number',
    left_axis: {
      tick_label_accessor: 'surgeon_name',
      tick_sublabel_accessor: 'hospital_case_number',
    },
    show_bar_label: true,
    bottom_axis: {
      tick_format: config?.format,
      labels,
    },
    type: 1,
  }
  return result
}

export const createOverTimeLineChart = config => {
  const result = {
    title: config?.name,
    type: 2,
    left_axis: {
      tick_format: config?.series
        ?.slice(0, 1)
        ?.map(s => s.format)
        .join(),
    },
    bottom_axis: {
      tick_format: '%-b %-y',
      tick_count: 'month',
    },
    x_scale: {
      type: 'time',
      key: 'date_of_surgery',
      format: '%-b %-y',
      parse: '%Y-%m',
    },
    y_scale: {
      type: 'linear',
      keys: [
        config?.series
          ?.slice(0, 1)
          ?.map(s => s.id)
          .join(),
      ],
    },
    max_value_accessor: config?.series
      ?.slice(0, 1)
      ?.map(s => s.id)
      .join(),
  }
  return result
}

export const createOptionsOverTimeLineChart = config => {
  const result = {
    type: 2,
    title: config?.name,
    max_value_accessor: config?.max_key,
    left_axis: {
      tick_format: config?.format,
    },
    bottom_axis: {
      tick_format: '%-b %-y',
      tick_count: 'month',
    },
    x_scale: {
      type: 'time',
      key: 'date_of_surgery',
      format: '%-b %-y',
      parse: '%Y-%m',
    },
    y_scale: {
      type: 'linear',
    },
  }
  return result
}

export const createOverTimeByPhysicianLineChart = config => {
  const result = {
    bottom_axis: {
      tick_count: 'month',
      tick_format: '%-b %-y',
    },
    max_value_accessor: config?.series
      ?.slice(0, 1)
      ?.map(s => s.id)
      .join(),
    left_axis: {
      tick_format: config?.series
        ?.slice(0, 1)
        ?.map(s => s.format)
        .join(),
    },
    title: config?.name,
    type: 2,
    x_scale: {
      format: '%-b %-y',
      key: 'date_of_surgery',
      parse: '%Y-%m',
      type: 'time',
    },
    y_scale: {
      group_key: 'surgeon_name',
      keys: config?.series?.slice(0, 1)?.map(s => s.id),
      type: 'linear',
    },
  }
  return result
}

const createByPhysicianChart = config => {
  if (config.type === 'options')
    return createByPhysicianOptionsBarChart(createOptionsConfig(config))
  if (config.type === 'boolean')
    return createByPhysicianBarChart(createBooleanConfig(config))
  return createByPhysicianBarChart(config)
}

const createCaseLevelChart = config => {
  if (config.type === 'options')
    return createCaseLevelOptionsBarChart(createOptionsConfig(config))
  return createCaseLevelBarChart(config)
}

const createOverTimeChart = config => {
  if (config.type === 'options')
    return createOptionsOverTimeLineChart(createOptionsConfig(config))
  return createOverTimeLineChart(config)
}

export const createChart = config => {
  if (config?.variant_id === 1) return createByPhysicianChart(config)
  if (config?.variant_id === 2) return createOverTimeChart(config)
  if (config?.variant_id === 3)
    return createOverTimeByPhysicianLineChart(config)
  if (config?.variant_id === 4) return createCaseLevelChart(config)
  return config?.chart
}

import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {animated} from 'react-spring'
import {AgBox} from '@aghealth/ag-components'
import {prefix} from './settings'
import {getChildren} from './utils'
import Avatar from './Avatar'
import AvatarGroup from './AvatarGroup'
import ConversationHeaderActions from './ConversationHeaderActions'
import ConversationHeaderContent from './ConversationHeaderContent'

const AnimatedBox = animated(AgBox)

export const ConversationHeader = ({children, className, ...rest}) => {
  const cName = `${prefix}-conversation-header`

  const [avatar, avatarGroup, content, actions] = getChildren(children, [
    // ConversationHeaderBack,
    Avatar,
    AvatarGroup,
    ConversationHeaderContent,
    ConversationHeaderActions,
  ])

  return (
    <AnimatedBox {...rest} className={classNames(cName, className)}>
      {avatar && <div>{avatar}</div>}
      {!avatar && avatarGroup && <div>{avatarGroup}</div>}
      {content}
      {actions}
    </AnimatedBox>
  )
}

ConversationHeader.displayName = 'ConversationHeader'

ConversationHeader.propTypes = {
  /**
   * Primary content.
   * Available elements:
   *
   * * &lt;Avatar /&gt;
   * * &lt;AvatarGroup /&gt;
   * * &lt;ConversationHeader.Back /&gt;
   * * &lt;ConversationHeader.Content /&gt;
   * * &lt;ConversationHeader.Actions /&gt;
   */
  children: PropTypes.node,

  /** Additional classes. */
  className: PropTypes.string,
}

ConversationHeader.defaultProps = {
  children: undefined,
}

// ConversationHeader.Back = ConversationHeaderBack
ConversationHeader.Actions = ConversationHeaderActions
ConversationHeader.Content = ConversationHeaderContent
export default ConversationHeader

import React from 'react'
import {AgColumn, AgSpinner} from '@aghealth/ag-components'

const OverlaySpinner = () => (
  <AgColumn
    width="100%"
    height="100%"
    alignItems="center"
    bg="rgba(255, 255, 255, 0.8)"
    sx={{position: 'absolute', left: 0, top: 0}}
    zIndex="xxlg"
  >
    <AgSpinner color="#1B263F" size={100} />
  </AgColumn>
)

export default OverlaySpinner

import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {usePapaParse} from 'react-papaparse'
import format from 'date-fns/format'
import isDate from 'date-fns/isDate'
import {chatbot} from '../store/actions'
import {Chatbot} from '../components'
import {selectChatbotUserName} from '../utils'
import configs from '../configs'
import {fetchChatbotFeedback} from '../api'

const downloadConversation = csv => {
  const element = document.createElement('a')
  const file = new Blob(['\ufeff' + csv], {type: 'text/csv;charset=utf-8;'})
  element.href = URL.createObjectURL(file)
  element.download = 'conversation.csv'
  document.body.appendChild(element) // Required for this to work in FireFox
  element.click()
}

const ChatbotContainer = () => {
  const {jsonToCSV} = usePapaParse()

  const stepId = useSelector(state => state.chatbot.stepId)
  const username = useSelector(selectChatbotUserName)

  const show = useSelector(state => state.chatbot.show)
  const messages = useSelector(state => state.chatbot.messages)

  const isProcessing = useSelector(state => state.chatbot.loading)

  const dispatch = useDispatch()
  React.useEffect(() => {
    dispatch(chatbot.initialize())
  }, [dispatch])

  const handleOnSend = React.useCallback(
    text => {
      const message = {
        type: 'human',
        text,
      }
      dispatch(chatbot.postText(message))
    },
    [dispatch],
  )

  const handleShow = React.useCallback(() => {
    dispatch(chatbot.setShow(true))
  }, [dispatch])

  const handleClose = React.useCallback(() => {
    dispatch(chatbot.setShow(false))
  }, [dispatch])

  const handleOnDownload = React.useCallback(() => {
    const formatted = messages?.map(message => ({
      Date: isDate(message.date) ? format(message.date, 'EEEE h:mma') : '',
      Sender:
        message.type === 'bot' ? 'Searchable Intelligent Data (Sid)' : username,
      Message: message?.responseCards
        ? message?.responseCards?.reduce((acc, next) => {
            acc += next.title
            return next?.buttons
              ? [acc, next.buttons.map(b => b.value).join(', ')].join(' ')
              : acc
          }, '')
        : message.text.replace(/\n/g, ''),
    }))

    const csv = jsonToCSV(formatted)
    downloadConversation(csv)
  }, [messages, username, jsonToCSV])
  const handleOnRefresh = React.useCallback(() => {
    dispatch(chatbot.resetHistory())
  }, [dispatch])

  const handleOnSendMessageFeedback = React.useCallback(
    result => {
      const step = result?.step
      const message_id = result?.message_id
      const feedback_details = result?.feedback_details
      const is_positive = feedback_details?.is_positive
      const body = {
        message_id,
        feedback_details: JSON.stringify(feedback_details),
      }
      fetchChatbotFeedback({
        body,
      }).then(r => {
        const feedback_id = r?.feedback_id
        if (step === 1 && feedback_id) {
          dispatch(
            chatbot.setMessageFeedback({message_id, feedback_id, is_positive}),
          )
        }
      })
    },
    [dispatch],
  )

  return (
    <Chatbot
      show={show}
      onShow={handleShow}
      onClose={handleClose}
      onDownload={handleOnDownload}
      messages={messages}
      onRefresh={handleOnRefresh}
      onSend={handleOnSend}
      name={configs.chatbot.toolbarTitle}
      isProcessing={isProcessing}
      textInputPlaceholder={configs.chatbot.textInputPlaceholder}
      stepId={stepId}
      onSendMessageFeedback={handleOnSendMessageFeedback}
    />
  )
}

export default ChatbotContainer

import createDimensions from './dimensions'
import createScales from './scales'
import createLabels from './labels'
import createAxes from './axes'
import createMarkers from './markers'

const createChart = config => {
  const dimensions = createDimensions(config)
  const {
    keyAccessor, xAccessor, yAccessor, radiusAccessor, min, max,
  } = createScales(
    config,
  )
  const {
    title, info,
  } = createLabels(config)
  const {
    leftAxis, bottomAxis, topAxis,
  } = createAxes(config)
  const markers = createMarkers(config)
  return {
    id: config.id,
    type: 'bubble',
    keyAccessor,
    yAccessor,
    xAccessor,
    radiusAccessor,
    leftAxis,
    bottomAxis,
    topAxis,
    markers,
    dimensions,
    min,
    max,
    title,
    info,
    data: config.data,
  }
}

export default createChart

import React from 'react'
import PropTypes from 'prop-types'
import {AgFlex, AgText, AgImage} from '@aghealth/ag-components'
import {MdClose} from 'react-icons/md'
import iconInfo from '../../../assets/icon-info.png'

const ManufacturerDetails = ({
  manufacturer_name,
  manufacturer_number,
  surgeon_name,
  product_name,
  groupByManufacturer,
}) => {
  const ref = React.useRef()
  const [visible, setVisible] = React.useState(false)
  const handleSetVisible = React.useCallback(() => {
    setVisible(true)
  }, [setVisible])
  const handleSetNotVisible = React.useCallback(() => {
    setVisible(false)
  }, [setVisible])
  return (
    <AgFlex flexDirection="column" ref={ref}>
      <AgFlex width="100%" height="100%" alignItems="center">
        <AgText
          fontSize="inherit"
          fontWeight={groupByManufacturer ? 'bold' : 'normal'}
        >
          {manufacturer_name}
        </AgText>
        <AgImage
          src={iconInfo}
          onClick={handleSetVisible}
          ml={1}
          sx={{size: 18}}
        />
      </AgFlex>
      {visible && (
        <AgFlex
          flexDirection="column"
          textAlign="left"
          marginTop="20px"
          border="base"
          borderColor="base"
          borderRadius="base"
          padding="10px"
          zIndex="100"
          position="absolute"
          backgroundColor="#F8F8F8"
        >
          <AgFlex justifyContent="space-between">
            <AgText
              fontWeight="bold"
              fontSize="14px"
              marginBottom="10px"
              marginTop="5px"
            >
              Manufacturer: {manufacturer_name}
            </AgText>
            <MdClose size={30} onClick={handleSetNotVisible} />
          </AgFlex>
          <AgText>Physician: {surgeon_name}</AgText>
          <AgText>Product Name: {product_name}</AgText>
          <AgText marginBottom="20px">
            Manufacturer Number: {manufacturer_number}
          </AgText>
        </AgFlex>
      )}
    </AgFlex>
  )
}

ManufacturerDetails.propTypes = {
  manufacturer_name: PropTypes.string,
  manufacturer_number: PropTypes.string,
  surgeon_name: PropTypes.string,
  product_name: PropTypes.string,
  groupByManufacturer: PropTypes.bool,
}

export default ManufacturerDetails

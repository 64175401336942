import React from 'react'
import PropTypes from 'prop-types'
import {
  AgFlex,
  AgButton,
  AgText,
  useOnClickOutside,
} from '@aghealth/ag-components'
import {GoTriangleDown} from 'react-icons/go'
import {FiSettings} from 'react-icons/fi'
import {MdClose} from 'react-icons/md'
import {RiShareForwardLine} from 'react-icons/ri'
import {RadioButtonGroup, ProcedureSearch} from '.'

const DownArrow = () => (
  <GoTriangleDown color="#129459" size={25} style={{marginLeft: '5px'}} />
)

const SettingsIcon = () => (
  <FiSettings color="#129459" size={25} style={{marginRight: '5px'}} />
)

const ShareIcon = () => (
  <RiShareForwardLine color="#129459" size={25} style={{marginRight: '5px'}} />
)

const Controls = ({
  title,
  onClickSettings,
  items,
  onClickSortSelect,
  selections,
  onFilterBy,
  onClickShare,
}) => {
  const sortRef = React.useRef()
  const [showSort, setShowSort] = React.useState(false)
  const onClickSort = () => {
    setShowSort(prevShowSort => !prevShowSort)
  }
  const handleCloseSort = () => {
    setShowSort(false)
  }
  const header = items
    .filter(item => item.selected)
    .map(item => `Sort by: ${item.name}`)
  const handleOnClickSortSelect = React.useCallback(
    item => {
      onClickSortSelect(item)
      setShowSort(false)
    },
    [onClickSortSelect],
  )
  useOnClickOutside(sortRef, handleCloseSort, document)

  return (
    <AgFlex width="100%" justifyContent="space-between" py={3}>
      <AgText
        fontWeight="bold"
        fontSize="16px"
        paddingLeft="20px"
        color="#464D69"
      >
        {title}
      </AgText>
      <AgFlex>
        <AgFlex>
          {selections && (
            <ProcedureSearch selections={selections} onFilterBy={onFilterBy} />
          )}
        </AgFlex>
        <AgFlex ref={sortRef}>
          <AgButton
            height={40}
            border="1px solid #129459"
            borderRadius="4px"
            marginRight="20px"
            bg="#E4FCEB"
            onClick={onClickSort}
            data-testid="sort-button"
          >
            <AgFlex padding="0px 10px" alignItems="center">
              <AgText color="#129459" fontSize="14px">
                {header}
              </AgText>
              <DownArrow />
            </AgFlex>
          </AgButton>
          {showSort && (
            <AgFlex
              flexDirection="column"
              width="268px"
              marginTop="45px"
              borderRadius="sm"
              position="absolute"
              zIndex="base"
              bg="#FFFFFF"
              maxHeight="550px"
              boxShadow="0px 2px 6px rgba(0, 0, 0, 0.5)"
            >
              <AgFlex
                flexDirection="column"
                width="100%"
                marginTop="15px"
                paddingLeft="15px"
                paddingRight="15px"
                paddingBottom="30px"
              >
                <AgFlex
                  width="100%"
                  justifyContent="space-between"
                  alignItems="center"
                  marginBottom="10px"
                >
                  <AgFlex>
                    <AgText fontWeight="bold" fontSize="16px">
                      Sort By
                    </AgText>
                  </AgFlex>
                  <AgFlex onClick={handleCloseSort}>
                    <MdClose size={40} color="gray" cursor="pointer" />
                  </AgFlex>
                </AgFlex>
                <RadioButtonGroup
                  items={items}
                  onClick={handleOnClickSortSelect}
                />
              </AgFlex>
            </AgFlex>
          )}
          {onClickShare && (
            <AgButton
              height={40}
              border="1px solid #129459"
              borderRadius="4px"
              marginRight="20px"
              onClick={onClickShare}
              bg="white"
              cursor="pointer"
              data-testid="share-button"
            >
              <AgFlex padding="0px 10px" alignItems="center">
                <ShareIcon />
                <AgText color="#129459" fontSize="14px">
                  Share Options
                </AgText>
              </AgFlex>
            </AgButton>
          )}
          <AgButton
            height={40}
            border="1px solid #129459"
            borderRadius="4px"
            marginRight="20px"
            onClick={onClickSettings}
            bg="white"
            cursor="pointer"
            data-testid="settings-button"
          >
            <AgFlex padding="0px 10px" alignItems="center">
              <SettingsIcon />
              <AgText color="#129459" fontSize="14px">
                Settings
              </AgText>
            </AgFlex>
          </AgButton>
        </AgFlex>
      </AgFlex>
    </AgFlex>
  )
}

Controls.propTypes = {
  items: PropTypes.array,
  title: PropTypes.string,
  onClickSettings: PropTypes.func,
  onClickSortSelect: PropTypes.func,
  selections: PropTypes.array,
  onFilterBy: PropTypes.func,
  onClickShare: PropTypes.func,
}

export default Controls

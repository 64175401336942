import React, {useState, useRef, useImperativeHandle} from 'react'
import {MdClose} from 'react-icons/md'
import PropTypes from 'prop-types'
import merge from 'lodash/merge'

import {
  AgButton,
  AgFlex,
  AgText,
  useOnClickOutside,
} from '@aghealth/ag-components'

import {GoChevronDown} from 'react-icons/go'

const DownArrow = () => (
  <GoChevronDown color="#344054" size={20} style={{marginLeft: '8px'}} />
)

const defaultStyles = {
  Root: {
    position: 'relative',
  },
  Button: {
    height: '40px',
    border: 'base',
    borderColor: 'new-gray.300',
    borderRadius: 'base',
    bg: 'base.white',
  },

  ButtonText: {
    color: 'new-gray.700',
    fontSize: 'sm',
    fontWeight: '500',
    lineHeight: '20px',
  },
  PopUpWrapper: {
    flexDirection: 'column',
    minWidth: '280px',
    marginTop: '45px',
    borderRadius: 'md',
    position: 'absolute',
    zIndex: '900',
    bg: 'base.white',
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.5)',
    padding: '24px',
    paddingBottom: '0px',
  },
  PopUpHeaderBodyWrapper: {
    flexDirection: 'column',
    width: '100%',
    marginBottom: '16px',
  },

  PopUpHeaderWrapper: {
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '10px',
  },

  PopUpHeaderText: {
    fontWeight: 'semibold',
    fontSize: 'lg',
    lineHeight: 'xs',
  },

  UpdateButton: {
    height: '44px',
    border: 'base',
    borderColor: 'success.500',
    borderRadius: 'base',
    bg: 'success.500',
    padding: '8px 18px',
  },

  ButtonDisabled: {
    opacity: 0.6,
    cursor: 'not-allowed',
  },

  UpdateButtonText: {
    color: 'base.white',
    fontSize: 'md',
    fontWeight: 'semibold',
    lineHeight: 'md',
  },

  ClearButton: {
    height: '44px',
    border: 'base',
    borderColor: 'new-gray.300',
    borderRadius: 'base',
    bg: 'base.white',
    padding: '8px 18px',
    marginLeft: '12px',
  },

  ClearButtonText: {
    color: 'new-gray.700',
    fontSize: 'md',
    fontWeight: 'semibold',
    lineHeight: 'md',
  },
}

const DropdownButton = React.forwardRef(
  (
    {
      buttonLabel,
      dropdownHeader,
      handleUpdate,
      handleClear,
      handleDropdownOpen,
      handleDropdownClose,
      showFooter,
      updateLabel,
      clearLabel,
      cssStyles,
      styles: _styles,
      buttonDisabled,
      isUpdateButtonDisabled,
      isClearButtonDisabled,
      showUpdateButton,
      children,
    },
    ref,
  ) => {
    const dropdownRef = useRef()
    const [showMenu, setShowMenu] = useState(false)

    const styles = React.useMemo(
      () => merge({}, defaultStyles, _styles),
      [_styles],
    )

    const onClickDropdownButton = () => {
      handleDropdownOpen()
      setShowMenu(prevShowMenu => !prevShowMenu)
    }

    const handleUpdateMenu = () => {
      handleUpdate()
      setShowMenu(false)
    }

    const handleClearMenu = () => {
      handleClear()
    }

    const handleCloseMenu = () => {
      handleDropdownClose()
      setShowMenu(false)
    }

    useOnClickOutside(dropdownRef, handleCloseMenu, document)

    useImperativeHandle(ref, () => ({
      closeDropdown() {
        handleCloseMenu()
      },
    }))

    return (
      <AgFlex sx={styles.Root} ref={dropdownRef}>
        <AgButton
          sx={{
            ...styles.Button,
            ...(buttonDisabled ? styles.ButtonDisabled : {}),
          }}
          onClick={onClickDropdownButton}
          disabled={buttonDisabled}
        >
          <AgFlex padding="0px 8px" alignItems="center">
            <AgText sx={styles.ButtonText}>{buttonLabel}</AgText>
            <DownArrow />
          </AgFlex>
        </AgButton>
        {showMenu && (
          <AgFlex sx={{...styles.PopUpWrapper, ...cssStyles.popUpStyles}}>
            <AgFlex sx={styles.PopUpHeaderBodyWrapper}>
              <AgFlex sx={styles.PopUpHeaderWrapper}>
                <AgFlex>
                  <AgText sx={styles.PopUpHeaderText}>{dropdownHeader}</AgText>
                </AgFlex>
                <AgFlex onClick={handleCloseMenu}>
                  <MdClose size={24} color="#667085" cursor="pointer" />
                </AgFlex>
              </AgFlex>
              {children}
            </AgFlex>
            {showFooter && (
              <AgFlex justifyContent="flex-end" pb={24}>
                {showUpdateButton && (
                  <AgButton
                    sx={{
                      ...styles.UpdateButton,
                      ...(isUpdateButtonDisabled ? styles.ButtonDisabled : {}),
                    }}
                    onClick={handleUpdateMenu}
                    disabled={isUpdateButtonDisabled}
                  >
                    <AgFlex padding="0px 8px" alignItems="center">
                      <AgText sx={styles.UpdateButtonText}>
                        {updateLabel}
                      </AgText>
                    </AgFlex>
                  </AgButton>
                )}
                <AgButton
                  sx={{
                    ...styles.ClearButton,
                    ...(isClearButtonDisabled ? styles.ButtonDisabled : {}),
                  }}
                  disabled={isClearButtonDisabled}
                  onClick={handleClearMenu}
                >
                  <AgFlex padding="0px 8px" alignItems="center">
                    <AgText sx={styles.ClearButtonText}>{clearLabel}</AgText>
                  </AgFlex>
                </AgButton>
              </AgFlex>
            )}
          </AgFlex>
        )}
      </AgFlex>
    )
  },
)

DropdownButton.propTypes = {
  buttonLabel: PropTypes.string.isRequired,
  dropdownHeader: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  BodyComponent: PropTypes.elementType,
  handleUpdate: PropTypes.func,
  handleClear: PropTypes.func,
  handleDropdownOpen: PropTypes.func,
  handleDropdownClose: PropTypes.func,
  showFooter: PropTypes.bool,
  updateLabel: PropTypes.string,
  clearLabel: PropTypes.string,
  cssStyles: PropTypes.shape({
    popUpStyles: PropTypes.object,
  }),
  styles: PropTypes.object,
  buttonDisabled: PropTypes.bool,
  isUpdateButtonDisabled: PropTypes.bool,
  isClearButtonDisabled: PropTypes.bool,
  showUpdateButton: PropTypes.bool,
}

DropdownButton.defaultProps = {
  buttonLabel: 'Button Label',
  dropdownHeader: 'Dropdown Header',
  BodyComponent: null,
  handleUpdate: () => {},
  handleClear: () => {},
  handleDropdownOpen: () => {},
  handleDropdownClose: () => {},
  showFooter: true,
  updateLabel: 'Update',
  clearLabel: 'Clear',
  cssStyles: {popUpStyles: {}},
  styles: {},
  buttonDisabled: false,
  isUpdateButtonDisabled: false,
  isClearButtonDisabled: false,
  showUpdateButton: true,
}

export default DropdownButton

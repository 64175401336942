import React from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {fetchUserOptions} from '../api'
import {config} from '../store/actions'
import {NPIPrompt, SpecialtiesPrompt, Modal} from '../components'

export const withNPIPrompt = Component => {
  const Container = () => {
    const dispatch = useDispatch()
    const skipNPI = useSelector(state => state?.config?.skip_npi_prompt)
    const showNPI = useSelector(state => state?.config?.user?.show_npi_prompt)
    const hasNPI = useSelector(state => !!state?.config?.user?.npi)
    const handleSkip = React.useCallback(() => {
      dispatch(config.setSkipNPIPrompt(true))
    }, [dispatch])
    const handleSave = React.useCallback(npi => {
      const payload = {
        show_npi_prompt: false,
        npi,
      }
      fetchUserOptions({
        body: JSON.stringify(payload),
        method: 'POST',
      }).then(r => {
        if (r.status === 204) {
          window.location.assign(window.location.origin)
        }
      })
    }, [])
    const handleDontAskAgain = React.useCallback(() => {
      dispatch(config.setSkipNPIPrompt(true))
      const payload = {
        show_npi_prompt: false,
      }
      fetchUserOptions({
        body: JSON.stringify(payload),
        method: 'POST',
      }).then(r => {
        if (r.status === 204) {
          window.location.assign(window.location.origin)
        }
      })
    }, [dispatch])
    if (showNPI && !hasNPI && !skipNPI)
      return (
        <NPIPrompt
          onSkip={handleSkip}
          onSave={handleSave}
          onDontAskAgain={handleDontAskAgain}
        />
      )
    return <Component />
  }
  return Container
}

const allSpecialties = {
  name: 'All Specialties',
  id: -1,
}

export const withSpecialtiesPrompt = Component => {
  const Container = () => {
    const dispatch = useDispatch()
    const departments = useSelector(state => state?.config?.departments)
    const skipSpecialty = useSelector(state => state?.config?.skip_dept_prompt)
    const showSpecialty = useSelector(
      state => state?.config?.user?.show_dept_prompt,
    )
    const specialties = React.useMemo(
      () =>
        Array.isArray(departments)
          ? [allSpecialties, ...departments].map(d => ({
              ...d,
              selected: false,
            }))
          : [],
      [departments],
    )
    const handleClose = React.useCallback(() => {
      dispatch(config.setSkipDeptPrompt(true))
    }, [dispatch])
    const handleSave = React.useCallback(department => {
      const payload = {
        show_dept_prompt: false,
        department_id: department.id === -1 ? '' : `${department.id}`,
      }
      fetchUserOptions({
        body: JSON.stringify(payload),
        method: 'POST',
      }).then(r => {
        if (r.status === 204) {
          window.location.assign(window.location.origin)
        }
      })
    }, [])
    const handleNoThanks = React.useCallback(() => {
      const payload = {
        show_dept_prompt: false,
      }
      fetchUserOptions({
        body: JSON.stringify(payload),
        method: 'POST',
      }).then(r => {
        if (r.status === 204) {
          window.location.assign(window.location.origin)
        }
      })
    }, [])
    return (
      <>
        <Component />
        <Modal visible={showSpecialty && !skipSpecialty} onClose={handleClose}>
          <SpecialtiesPrompt
            onSave={handleSave}
            onNoThanks={handleNoThanks}
            specialties={specialties}
          />
        </Modal>
      </>
    )
  }
  return Container
}

export const SET_MISCODINGS_DATA = 'SET_MISCODINGS_DATA'
export const SET_MISCODINGS_LOADING = 'SET_MISCODINGS_LOADING'
export const SET_MISCODINGS_NOT_LOADING = 'SET_MISCODINGS_NOT_LOADING'
export const SET_MISCODINGS_LOADING_DATA = 'SET_MISCODINGS_LOADING_DATA'
export const SET_MISCODINGS_NOT_LOADING_DATA = 'SET_MISCODINGS_NOT_LOADING_DATA'
export const SET_MISCODINGS_ERROR = 'SET_MISCODINGS_ERROR'
export const SET_MISCODINGS_FILTERS = 'SET_MISCODINGS_FILTERS'
export const SET_MISCODINGS_TIME_PERIODS = 'SET_MISCODINGS_TIME_PERIODS'
export const SET_MISCODINGS_SEARCH = 'SET_MISCODINGS_SEARCH'
export const SET_MISCODINGS_ALL_INPATIENT = 'SET_MISCODINGS_ALL_INPATIENT'
export const SET_MISCODINGS_ALL_OUTPATIENT = 'SET_MISCODINGS_ALL_OUTPATIENT'
export const SET_MISCODINGS_DRGS = 'SET_MISCODINGS_DRGS'
export const SET_MISCODINGS_PROCEDURES = 'SET_MISCODINGS_PROCEDURES'
export const SET_MISCODINGS_MISCODINGS = 'SET_MISCODINGS_MISCODINGS'
export const SET_MISCODINGS_DATE_SAVED = 'SET_MISCODINGS_DATE_SAVED'
export const SET_MISCODINGS_ICDS = 'SET_MISCODINGS_ICDS'
export const SET_MISCODINGS_CPTS = 'SET_MISCODINGS_CPTS'
export const SET_MISCODINGS_EXCLUDE_IP = 'SET_MISCODINGS_EXCLUDE_IP'
export const SET_MISCODINGS_EXCLUDE_OP = 'SET_MISCODINGS_EXCLUDE_OP'

import {
  createBooleanBarChart,
  createBooleanLineChart,
  createBooleanMultiLineChart,
  createNumericBarChart,
  createNumericScrollingBarChart,
  createNumericLineChart,
  createNumericMultiLineChart,
  createOptionsBarChart,
  createOptionsLineChart,
  createOptionsMultiLineChart,
} from './chart'
import {
  createBooleanBarTable,
  createBooleanLineTable,
  createBooleanMultiLineTable,
  createNumericBarTable,
  createNumericScrollingBarTable,
  createNumericLineTable,
  createNumericMultiLineTable,
  createOptionsBarTable,
  createOptionsLineTable,
  createOptionsMultiLineTable,
} from './table'
import {createOptionsOptions} from './options'

const createBooleanConfigOne = config => {
  const chart = createBooleanBarChart(config)
  const table = createBooleanBarTable(config)
  return {
    chart,
    table,
    download: !!config?.download,
    export: !!config?.export,
    id: config?.variant_id,
    variant_id: config?.variant_id,
  }
}

const createBooleanConfigTwo = config => {
  const chart = createBooleanLineChart(config)
  const table = createBooleanLineTable(config)
  return {
    chart,
    table,
    download: !!config?.download,
    export: !!config?.export,
    id: config?.variant_id,
    disable_chart_options: true,
    variant_id: config?.variant_id,
  }
}

const createBooleanConfigThree = config => {
  const chart = createBooleanMultiLineChart(config)
  const table = createBooleanMultiLineTable(config)
  return {
    chart,
    table,
    download: !!config?.download,
    export: !!config?.export,
    id: config?.variant_id,
    variant_id: config?.variant_id,
  }
}

const createBooleanConfig = config => {
  if (config?.variant_id === 1) return createBooleanConfigOne(config)
  if (config?.variant_id === 2) return createBooleanConfigTwo(config)
  if (config?.variant_id === 3) return createBooleanConfigThree(config)
  return config
}

const createNumericConfigOne = config => {
  const chart = createNumericBarChart(config)
  const table = createNumericBarTable(config)
  return {
    chart,
    table,
    download: !!config?.download,
    export: !!config?.export,
    id: config?.variant_id,
    variant_id: config?.variant_id,
  }
}

const createNumericConfigTwo = config => {
  const chart = createNumericLineChart(config)
  const table = createNumericLineTable(config)
  return {
    chart,
    table,
    download: !!config?.download,
    export: !!config?.export,
    id: config?.variant_id,
    disable_chart_options: true,
    variant_id: config?.variant_id,
  }
}

const createNumericConfigThree = config => {
  const chart = createNumericMultiLineChart(config)
  const table = createNumericMultiLineTable(config)
  return {
    chart,
    table,
    download: !!config?.download,
    export: !!config?.export,
    id: config?.variant_id,
    variant_id: config?.variant_id,
  }
}

const createNumericConfigFour = config => {
  const chart = createNumericScrollingBarChart(config)
  const table = createNumericScrollingBarTable(config)
  return {
    chart,
    table,
    download: !!config?.download,
    export: !!config?.export,
    id: config?.variant_id,
    variant_id: 4,
  }
}

const createNumericConfig = config => {
  if (config?.variant_id === 1) return createNumericConfigOne(config)
  if (config?.variant_id === 2) return createNumericConfigTwo(config)
  if (config?.variant_id === 3) return createNumericConfigThree(config)
  if (config?.variant_id === 4) return createNumericConfigFour(config)
  return config
}

const createOptionsConfigOne = config => {
  const chart = createOptionsBarChart(config)
  const table = createOptionsBarTable(config)
  const options = createOptionsOptions(config)
  return {
    chart,
    table,
    options,
    download: !!config?.download,
    export: !!config?.export,
    id: config?.variant_id,
    variant_id: config?.variant_id,
  }
}

const createOptionsConfigTwo = config => {
  const chart = createOptionsLineChart(config)
  const table = createOptionsLineTable(config)
  const options = createOptionsOptions(config)
  return {
    chart,
    table,
    options,
    download: !!config?.download,
    export: !!config?.export,
    id: config?.variant_id,
    variant_id: config?.variant_id,
  }
}

const createOptionsConfigThree = config => {
  const chart = createOptionsMultiLineChart(config)
  const table = createOptionsMultiLineTable(config)

  return {
    chart,
    table,
    download: !!config?.download,
    export: !!config?.export,
    id: config?.variant_id,
    variant_id: config?.variant_id,
  }
}

const createOptionsConfig = config => {
  if (config?.variant_id === 1) return createOptionsConfigOne(config)
  if (config?.variant_id === 2) return createOptionsConfigTwo(config)
  if (config?.variant_id === 3) return createOptionsConfigThree(config)
  return config
}

export const createERASConfig = config => {
  if (config?.type === 'boolean') return createBooleanConfig(config)
  if (config?.type === 'numeric') return createNumericConfig(config)
  if (config?.type === 'options') return createOptionsConfig(config)
  return config
}

import React from 'react'
import {deepMerge} from '@aghealth/ag-components'
import {Identify, add, init, remove} from '@amplitude/analytics-browser'

const createIdentify = (organizationId, organizationGroupId) => {
  const identity = new Identify()

  if (organizationId) {
    identity.set('Organization ID', organizationId)
    identity.unset('Organization Group ID')
  } else if (organizationGroupId) {
    identity.set('Organization Group ID', organizationGroupId)
    identity.unset('Organization ID')
  }

  return identity
}

const createEnrichUserPropertiesPlugin = identity => {
  return {
    name: 'enrichUserPropertiesPlugin',
    execute: async event => {
      event.user_properties = deepMerge(event.user_properties, identity.getUserProperties())
      return event
    },
  }
}

const useAmplitude = (userId, organizationId, organizationGroupId, isStaff) => {
  const identify = React.useMemo(
    () => createIdentify(organizationId, organizationGroupId),
    [organizationId, organizationGroupId],
  )
  const enrichUserPropertiesPlugin = React.useMemo(
    () => createEnrichUserPropertiesPlugin(identify),
    [identify],
  )

  React.useEffect(() => {
    add(enrichUserPropertiesPlugin)

    init(
      process.env.REACT_APP_AMPLITUDE_API_KEY ||
        'bf6a7cb7c92ff57959d3d3d253122ee8',
      userId,
      {
        autocapture: {
          attribution: true,
          pageViews: false,
          sessions: true,
          formInteractions: false,
          fileDownloads: false,
          elementInteractions: false,
        },

        optOut: isStaff,

        // https://amplitude.com/docs/analytics/domain-proxy
        // https://amplitude.com/docs/experiment/advanced-techniques/proxy-requests-to-experiment-with-aws-cloudfront
        serverUrl: 'https://amplitude.aghealth.io/2/httpapi',

        // 10 Minutes
        sessionTimeout: 600_000,
      },
    )

    return () => {
      remove(enrichUserPropertiesPlugin.name)
    }
  }, [userId, isStaff, enrichUserPropertiesPlugin])
}

export default useAmplitude

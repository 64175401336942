import React from 'react'
import PropTypes from 'prop-types'
import {animated, useSpring} from 'react-spring'
import {
  AgText,
  AgAbsolute,
  AgRelative,
  AgFixed,
  useOnClickOutside,
} from '@aghealth/ag-components'
import {FiChevronRight} from 'react-icons/fi'
import colors from '../theme/colors'
import {useSelector} from 'react-redux'
import {selectHasNavigationV2} from '../utils/selectors'

const AngleRight = animated(FiChevronRight)
const Fixed = animated(AgFixed)

const SidebarTab = ({visible, onClick, transition, hasNavigationV2}) => {
  const angle = useSpring({
    transform: visible ? 'rotate(90deg)' : 'rotate(-90deg)',
  })
  return (
    <Fixed
      data-testid="sidebar-tab"
      onClick={onClick}
      cursor="pointer"
      top={hasNavigationV2 ? 170 : 100}
      height={0}
      width={101}
      borderBottom={`64px solid ${colors['bg-gray']}`}
      borderRight="10px solid transparent"
      borderLeft="10px solid transparent"
      zIndex="lg"
      style={{
        cursor: 'pointer',
        transform: 'rotate(90deg)',
        filter: 'drop-shadow(3px -0.25px 0px rgba(0,0,0,0.6))',
        ...transition,
      }}
    >
      <AgAbsolute
        display="flex"
        alignItems="center"
        justifyContent="center"
        top="16px"
        width="100%"
      >
        <AngleRight
          style={{
            fontSize: '20px',
            padding: '10px',
            borderRadius: '50%',
            backgroundColor: '#D5D5D5',
            color: '#041424',
            ...angle,
          }}
        />

        <AgText
          color="font-primary"
          fontSize="base"
          fontWeight="500"
          style={{
            transform: 'rotate(-90deg)',
          }}
        >
          Menu
        </AgText>
      </AgAbsolute>
    </Fixed>
  )
}

SidebarTab.propTypes = {
  transition: PropTypes.object,
  visible: PropTypes.bool,
  onClick: PropTypes.func,
  hasNavigationV2: PropTypes.bool,
}

const getChildren = (children, visible) => {
  if (process.env.NODE_ENV === 'test') return visible ? children : null
  return React.cloneElement(children, {visible})
}

const SidebarBox = React.memo(({width, children, disableOnClickOutside}) => {
  const ref = React.useRef()
  const [visible, setVisible] = React.useState(false)
  const hasNavigationV2 = useSelector(selectHasNavigationV2)
  const handleToggleVisible = React.useCallback(() => {
    setVisible(prevVisible => !prevVisible)
  }, [setVisible])
  const handleSetNotVisible = React.useCallback(() => {
    if (!disableOnClickOutside) {
      setVisible(false)
    }
  }, [setVisible, disableOnClickOutside])

  const style = useSpring({left: visible ? 0 : -width})
  const transition = useSpring({left: visible ? width - 20 : -20})
  useOnClickOutside(ref, handleSetNotVisible, document)
  return (
    <AgRelative width="100%" ref={ref}>
      <Fixed
        data-testid="sidebar-container"
        display="flex"
        flexDirection="column"
        zIndex="lg"
        width={width}
        minHeight="calc(100vh - 70px)"
        height="calc(100% - 70px)"
        bg="bg-gray"
        boxShadow="base"
        style={style}
        px="40px"
        pt="40px"
        pb="100px"
        sx={{
          variant: 'scrollbars.y-hide',
        }}
      >
        {getChildren(children, visible)}
      </Fixed>
      <SidebarTab
        transition={transition}
        visible={visible}
        onClick={handleToggleVisible}
        hasNavigationV2={hasNavigationV2}
      />
    </AgRelative>
  )
})

SidebarBox.propTypes = {
  width: PropTypes.number,
  children: PropTypes.node,
  disableOnClickOutside: PropTypes.bool,
}

export default SidebarBox

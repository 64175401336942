import {batch} from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import {getDateRange} from '../utils'
import {
  createComparisonTypes,
  setQuery,
  withBailOnLoading,
  withFacilityType,
} from './utils'
import {
  SET_DASHBOARD_LOADING_FILTERS,
  SET_DASHBOARD_FILTERS,
  SET_DASHBOARD_ERROR,
  SET_DASHBOARD_DATA,
  SET_DASHBOARD_LOADING_DATA,
  SET_DASHBOARD_TIME_PERIODS,
  SET_DASHBOARD_DATE_SAVED,
  SET_DASHBOARD_METRIC_PREFERENCES,
  SET_DASHBOARD_LOADING_METRIC_PREFERENCES,
  SET_DASHBOARD_METRICS,
  SET_SORT_BY,
  SET_DASHBOARD_IS_DEPARTMENT_TREND,
  SET_DASHBOARD_SURGEONS,
  SET_DASHBOARD_RISK_ADJUSTED,
  SET_DASHBOARD_FILTER_DATA_BY,
  SET_DASHBOARD_IS_ONCOLOGY,
  SET_DASHBOARD_EXCLUDE_ONCOLOGY,
  SET_DASHBOARD_DEPARTMENT,
  SET_DASHBOARD_COMPARISON_TYPES,
} from '../types'
import {fetchDashboard, fetchDashboardMetrics} from '../../api'
import {
  fromQueryString,
  selectOnlyOneById,
  selectOnlyOneByIndex,
  unselectAll,
  selectIsInFacilityType,
  selectDefaultDepartment,
} from '../../utils'
import {ALL_PREFIX} from '../../pages/dashboard-v2/constants'

const createFilters = (filters, {isInFacilityType}) => {
  if (isInFacilityType) return withFacilityType(filters)
  return filters
}

const setFetchDashboard =
  ({signal, search, comparison_type_id}) =>
  (dispatch, getState) => {
    const {loading_data} = getState().dashboard
    const isInFacilityType = selectIsInFacilityType(getState())
    const comparison_types = createComparisonTypes(comparison_type_id)

    batch(() => {
      dispatch(setComparisonTypes(comparison_types))
      if (!loading_data) {
        dispatch(setLoadingData(true))
      }
    })

    return fetchDashboard({signal, search})
      .then(({data, filters}) => {
        batch(() => {
          dispatch(setData(data))
          const _filters = createFilters(filters, {isInFacilityType})
          dispatch(setFilters(_filters))
          dispatch(setLoadingData(false))
          dispatch(setLoadingFilters(false))
        })
      })
      .catch(error => {
        batch(() => {
          dispatch(setError(error))
          dispatch(setLoadingData(false))
          dispatch(setLoadingFilters(false))
        })
      })
  }

const setLoadingMetricPreferences = payload => ({
  type: SET_DASHBOARD_LOADING_METRIC_PREFERENCES,
  payload,
})

const setMetricPreferences = payload => ({
  type: SET_DASHBOARD_METRIC_PREFERENCES,
  payload,
})

const setError = payload => ({type: SET_DASHBOARD_ERROR, payload})

const setData = payload => ({type: SET_DASHBOARD_DATA, payload})

const setFilters = (payload, meta) => ({
  type: SET_DASHBOARD_FILTERS,
  payload,
  meta,
})

const setTimePeriods = payload => ({type: SET_DASHBOARD_TIME_PERIODS, payload})

const setDepartments = payload => ({
  type: SET_DASHBOARD_DEPARTMENT,
  payload,
})

const setMetrics = payload => ({type: SET_DASHBOARD_METRICS, payload})

const setDateSaved = payload => ({type: SET_DASHBOARD_DATE_SAVED, payload})

const setLoadingData = payload => ({type: SET_DASHBOARD_LOADING_DATA, payload})

const setLoadingFilters = payload => ({
  type: SET_DASHBOARD_LOADING_FILTERS,
  payload,
})

const setFilterDataBy = payload => ({
  type: SET_DASHBOARD_FILTER_DATA_BY,
  payload,
})

const setRiskAdjusted = payload => ({
  type: SET_DASHBOARD_RISK_ADJUSTED,
  payload,
})
const setSortBy = payload => ({type: SET_SORT_BY, payload})
const setSurgeons = payload => ({
  type: SET_DASHBOARD_SURGEONS,
  payload,
})

const setIsDepartmentTrend = payload => ({
  type: SET_DASHBOARD_IS_DEPARTMENT_TREND,
  payload,
})

const setIsOncology = payload => ({
  type: SET_DASHBOARD_IS_ONCOLOGY,
  payload,
})

const setExcludeOncology = payload => ({
  type: SET_DASHBOARD_EXCLUDE_ONCOLOGY,
  payload,
})

const setComparisonTypes = payload => ({
  type: SET_DASHBOARD_COMPARISON_TYPES,
  payload,
})

const selectSortBy = item => (dispatch, getState) => {
  const {sortBy} = getState().dashboard
  const selected = sortBy.map(selectOnlyOneById({id: item.id}))
  dispatch(setSortBy(selected))
}

const getMetricPreferences = options => (dispatch, getState) => {
  const isInFacilityType = selectIsInFacilityType(getState())
  dispatch(setLoadingMetricPreferences(true))
  fetchDashboardMetrics(options)
    .then(({data}) => {
      const {metrics} = createFilters(
        {metrics: data},
        {
          isInFacilityType,
        },
      )
      batch(() => {
        dispatch(setMetricPreferences(metrics))
        dispatch(setLoadingMetricPreferences(false))
      })
    })
    .catch(error => {
      batch(() => {
        dispatch(setError(error))
        dispatch(setLoadingMetricPreferences(false))
      })
    })
}

const fetchMetricPreferences = options => dispatch => {
  const {method = 'GET'} = options
  if (method === 'POST') {
    fetchDashboardMetrics(options)
  } else {
    dispatch(getMetricPreferences({...options, method}))
  }
}

const selectMetric = (payload, history) => (dispatch, getState) => {
  const {filters: current_filters, date_saved} = getState().dashboard
  const {metrics: current_metrics, time_periods: current_time_periods} =
    current_filters
  const metrics = current_metrics.map(selectOnlyOneByIndex(payload))
  const time_periods = date_saved ? current_time_periods : {}
  batch(() => {
    dispatch(setMetrics(metrics))
    dispatch(
      setQuery({
        history,
        store: 'dashboard',
        overrides: {
          time_periods,
        },
        onLoading: setLoadingData,
      }),
    )
  })
}

const selectTimePeriod = (payload, history) => (dispatch, getState) => {
  const {filters: current_filters} = getState().dashboard
  const {time_periods: current_time_periods} = current_filters
  const time_periods = {
    ...current_time_periods,
    selected_start: payload.selected_start,
    selected_end: payload.selected_end,
    values: current_time_periods.values.map(selectOnlyOneById(payload.value)),
  }
  if (payload.value.id === 5 && payload.selected_start === '') {
    const {id} = current_time_periods.values.find(d => d.selected)
    const range = getDateRange(id, time_periods.max)
    dispatch(setTimePeriods({...time_periods, ...range}))
  } else {
    batch(() => {
      dispatch(setDateSaved(true))
      dispatch(setTimePeriods(time_periods))
      dispatch(
        setQuery({
          history,
          store: 'dashboard',
          onLoading: setLoadingData,
        }),
      )
    })
  }
}

const unselectTimePeriod = history => (dispatch, getState) => {
  const {filters: current_filters} = getState().dashboard
  const {time_periods: current_time_periods} = current_filters
  const time_periods = {
    ...current_time_periods,
    selected_start: '',
    selected_end: '',
    values: current_time_periods.values.map(unselectAll),
  }
  dispatch(
    setQuery({
      history,
      store: 'dashboard',
      onLoading: setLoadingData,
      overrides: {
        time_periods,
      },
    }),
  )
}

const selectDepartment = (payload, history) => (dispatch, getState) => {
  const {filters: current_filters, date_saved} = getState().dashboard
  const {time_periods: current_time_periods, departments: current_departments} =
    current_filters

  const time_periods = date_saved ? current_time_periods : {}

  // Track if department_id 4 was previously selected
  const wasOncologyPreviouslySelected = current_departments.some(
    department =>
      department.id === 4 &&
      (department.selected ||
        department.sub_departments.some(subDept => subDept.selected)),
  )

  // Check if any department with id 4 is still selected after the update
  const isOncologyCurrentlySelected = payload?.some(
    department =>
      department.id === 4 &&
      (department.selected ||
        department.sub_departments.some(subDept => subDept.selected)),
  )

  // Set is_oncology to false only if it was previously selected and is now unselected
  const isOncology =
    wasOncologyPreviouslySelected && !isOncologyCurrentlySelected
      ? false
      : current_filters.is_oncology

  batch(() => {
    dispatch(setIsOncology(isOncology))
    dispatch(setDepartments(payload))
    dispatch(
      setQuery({
        history,
        store: 'dashboard',
        onLoading: setLoadingData,
        overrides: {
          time_periods,
        },
      }),
    )
  })
}

const unselectProcedureGroupV2 = (payload, history) => (dispatch, getState) => {
  const {filters: current_filters, date_saved} = getState().dashboard
  const {departments: current_departments, time_periods: current_time_periods} =
    current_filters

  let departments = []

  // Track if department_id 4 was previously selected
  const wasOncologyPreviouslySelected = current_departments.some(
    department =>
      department.id === 4 &&
      (department.selected ||
        department.sub_departments.some(subDept => subDept.selected)),
  )

  if (payload.label.includes(ALL_PREFIX)) {
    const departmentLabel = payload.label.split(ALL_PREFIX)[1]

    departments = current_departments.map(department => {
      if (department.name === departmentLabel) {
        const sub_departments = department.sub_departments.map(subDept => ({
          ...subDept,
          selected: false,
        }))

        return {
          ...department,
          sub_departments: sub_departments,
          selected: false,
        }
      }

      return department
    })
  } else {
    departments = current_departments.map(department => {
      let hasSelectedSubDept = false
      const sub_departments = department.sub_departments.map(subDept => {
        if (subDept.name === payload.label) {
          return {...subDept, selected: false}
        }

        hasSelectedSubDept = hasSelectedSubDept || subDept.selected

        return subDept
      })

      return sub_departments?.length > 0
        ? {
            ...department,
            sub_departments: sub_departments,
            selected: hasSelectedSubDept,
          }
        : {
            ...department,
          }
    })
  }

  // Check if any department with id 4 is still selected after the update
  const isOncologyCurrentlySelected = departments.some(
    department =>
      department.id === 4 &&
      (department.selected ||
        department.sub_departments.some(subDept => subDept.selected)),
  )

  // Set is_oncology to false only if it was previously selected and is now unselected
  const isOncology =
    wasOncologyPreviouslySelected && !isOncologyCurrentlySelected
      ? false
      : current_filters.is_oncology

  const time_periods = date_saved ? current_time_periods : {}

  batch(() => {
    dispatch(setIsOncology(isOncology))
    dispatch(setDepartments(departments))
    dispatch(
      setQuery({
        history,
        store: 'dashboard',
        onLoading: setLoadingData,
        overrides: {
          time_periods,
        },
      }),
    )
  })
}

const selectSurgeon = (payload, history) => (dispatch, getState) => {
  const {filters: current_filters, date_saved} = getState().dashboard
  const {surgeons: current_surgeons, time_periods: current_time_periods} =
    current_filters
  const surgeons = current_surgeons.map(selectOnlyOneById(payload))
  const time_periods = date_saved ? current_time_periods : {}
  batch(() => {
    dispatch(setSurgeons(surgeons))
    dispatch(
      setQuery({
        history,
        store: 'dashboard',
        onLoading: setLoadingData,
        overrides: {time_periods},
      }),
    )
  })
}

const unselectSurgeon = history => (dispatch, getState) => {
  const {filters: current_filters, date_saved} = getState().dashboard
  const {surgeons: current_surgeons, time_periods: current_time_periods} =
    current_filters

  const surgeons = current_surgeons.map(selectOnlyOneByIndex(0))
  const time_periods = date_saved ? current_time_periods : {}
  batch(() => {
    dispatch(setSurgeons(surgeons))
    dispatch(
      setQuery({
        history,
        store: 'dashboard',
        onLoading: setLoadingData,
        overrides: {time_periods},
      }),
    )
  })
}

const toggleRiskAdjusted = (payload, history) => (dispatch, getState) => {
  const {filters: current_filters} = getState().dashboard
  const {risk_adjusted: current_risk_adjusted} = current_filters
  if (current_risk_adjusted !== payload) {
    batch(() => {
      dispatch(setRiskAdjusted(payload))
      dispatch(
        setQuery({
          history,
          store: 'dashboard',
          onLoading: setLoadingData,
        }),
      )
    })
  }
}

const handleSetIsOncology = (payload, history) => (dispatch, getState) => {
  const {filters: current_filters} = getState().dashboard
  const {is_oncology: current_is_oncology} = current_filters
  if (current_is_oncology !== payload) {
    batch(() => {
      dispatch(setIsOncology(payload))
      dispatch(setExcludeOncology(false))
      dispatch(
        setQuery({
          history,
          store: 'dashboard',
          onLoading: setLoadingData,
        }),
      )
    })
  }
}

const handleSetAllCases = history => dispatch => {
  batch(() => {
    dispatch(setIsOncology(false))
    dispatch(setExcludeOncology(false))
    dispatch(
      setQuery({
        history,
        store: 'dashboard',
        onLoading: setLoadingData,
      }),
    )
  })
}

const handleSetExcludeOncology = (payload, history) => (dispatch, getState) => {
  const {filters: current_filters} = getState().dashboard
  const {exclude_oncology: current_exclude_oncology} = current_filters
  if (current_exclude_oncology !== payload) {
    batch(() => {
      dispatch(setExcludeOncology(payload))
      dispatch(setIsOncology(false))
      dispatch(
        setQuery({
          history,
          store: 'dashboard',
          onLoading: setLoadingData,
        }),
      )
    })
  }
}

const selectTrend = (payload, history) => (dispatch, getState) => {
  const {filters} = getState().dashboard
  const {surgeons: current_surgeons} = filters
  const is_department_trend = payload === 0
  const surgeons =
    !is_department_trend && !current_surgeons.some(s => s.selected)
      ? current_surgeons.map(selectOnlyOneByIndex(0))
      : current_surgeons
  batch(() => {
    dispatch(setIsDepartmentTrend(is_department_trend))
    dispatch(
      setQuery({
        history,
        store: 'dashboard',
        overrides: {surgeons},
        onLoading: setLoadingData,
      }),
    )
  })
}

const selectComparisonType = (payload, history) => (dispatch, getState) => {
  const {comparison_types: current_comparison_types} = getState().dashboard

  const comparison_types = current_comparison_types.map(
    selectOnlyOneById(payload),
  )
  batch(() => {
    dispatch(setComparisonTypes(comparison_types))
    dispatch(
      setQuery({
        history,
        store: 'dashboard',
        onLoading: setLoadingData,
      }),
    )
  })
}

const fetch =
  ({signal, search, bail = false, initial}) =>
  (dispatch, getState) => {
    const {
      is_department_trend = true,
      date_saved = false,
      risk_adjusted = false,
      department_ids,
      comparison_type_id,
    } = fromQueryString(search, [
      'date_saved',
      'is_department_trend',
      'risk_adjusted',
      'department_ids',
      'comparison_type_id',
    ])

    const refresh_date = getState()
      .config.user.organizations.filter(o => o.selected)
      .map(o => o.refresh_date)
      .join()
    const default_department = selectDefaultDepartment(getState())?.id
    let query = isEmpty(search)
      ? `?refresh_date=${refresh_date}`
      : `?refresh_date=${refresh_date}&${search.slice(1)}`
    const isOncologyDepartment =
      department_ids === 4 ||
      (Array.isArray(department_ids) && department_ids.includes(4))

    if (isOncologyDepartment) {
      query = `${query}&is_oncology=true`
    }

    query = new URLSearchParams(query)

    if (!department_ids && default_department) {
      query.append('department_ids', default_department)
    }

    const {date_saved: current_date_saved} = getState().dashboard
    batch(() => {
      dispatch(setError(null))
      dispatch(setIsDepartmentTrend(is_department_trend))
      dispatch(setRiskAdjusted(risk_adjusted))
      if (date_saved !== current_date_saved) {
        dispatch(setDateSaved(date_saved))
      }
    })
    if (initial) {
      dispatch(selectFilterDataBy(null))
    }
    if (!bail) {
      dispatch(
        setFetchDashboard({
          signal,
          search: `?${query.toString()}`,
          initial,
          comparison_type_id,
        }),
      )
    }
  }

const toggleDateSaved = history => (dispatch, getState) => {
  const {date_saved: current_date_saved} = getState().dashboard
  const date_saved = !current_date_saved
  batch(() => {
    dispatch(setDateSaved(date_saved))
    dispatch(
      setQuery({
        history,
        store: 'dashboard',
        fetch: false,
        onLoading: setLoadingData,
      }),
    )
  })
}

const resetFilters = history => (dispatch, getState) => {
  const {is_department_trend, filters: current_filters} = getState().dashboard
  const {
    time_periods: current_time_periods,
    procedure_groups: current_procedure_groups,
    surgeons: current_surgeons,
    metrics,
  } = current_filters
  const time_periods = {
    ...current_time_periods,
    selected_start: '',
    selected_end: '',
    values: current_time_periods.values.map(unselectAll),
  }
  const procedure_groups = is_department_trend
    ? current_procedure_groups.map(selectOnlyOneByIndex(0))
    : current_procedure_groups
  const surgeons = is_department_trend
    ? current_surgeons
    : current_surgeons.map(selectOnlyOneByIndex(0))
  dispatch(
    setQuery({
      history,
      store: 'dashboard',
      omit: true,
      overrides: {
        time_periods,
        surgeons,
        procedure_groups,
        metrics,
        risk_adjusted: null,
        date_saved: false,
        is_department_trend,
        is_oncology: null,
        exclude_oncology: null,
        comparison_types: createComparisonTypes(),
      },
      onLoading: setLoadingData,
    }),
  )
}

const selectFilterDataBy = payload => (dispatch, getState) => {
  const {filter_by} = getState().dashboard
  if (filter_by !== payload) {
    dispatch(setFilterDataBy(payload))
  }
}

const actions = {
  fetch,
  fetchMetricPreferences,
  selectDepartment: withBailOnLoading(selectDepartment, 'dashboard'),
  unselectProcedureGroupV2: withBailOnLoading(
    unselectProcedureGroupV2,
    'dashboard',
  ),
  selectMetric: withBailOnLoading(selectMetric, 'dashboard'),
  selectTimePeriod: withBailOnLoading(selectTimePeriod, 'dashboard'),
  unselectTimePeriod: withBailOnLoading(unselectTimePeriod, 'dashboard'),
  toggleRiskAdjusted: withBailOnLoading(toggleRiskAdjusted, 'dashboard'),
  toggleDateSaved: withBailOnLoading(toggleDateSaved, 'dashboard'),
  selectSortBy,
  resetFilters: withBailOnLoading(resetFilters, 'dashboard'),
  selectTrend: withBailOnLoading(selectTrend, 'dashboard'),
  selectSurgeon: withBailOnLoading(selectSurgeon, 'dashboard'),
  unselectSurgeon: withBailOnLoading(unselectSurgeon, 'dashboard'),
  selectFilterDataBy,
  setIsOncology: withBailOnLoading(handleSetIsOncology, 'dashboard'),
  setExcludeOncology: withBailOnLoading(handleSetExcludeOncology, 'dashboard'),
  setAllCases: withBailOnLoading(handleSetAllCases, 'dashboard'),
  selectComparisonType: withBailOnLoading(selectComparisonType, 'dashboard'),
}

export default actions

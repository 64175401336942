import addMinutes from 'date-fns/addMinutes'
import addDays from 'date-fns/addDays'
import isAfter from 'date-fns/isAfter'
import format from 'date-fns/format'
import startOfWeek from 'date-fns/startOfWeek'
import endOfWeek from 'date-fns/endOfWeek'
import startOfMonth from 'date-fns/startOfMonth'
import endOfMonth from 'date-fns/endOfMonth'
import isDate from 'date-fns/isDate'

export const toDate = (dateString, timeString = '00:00:00') => {
  const splitDate = dateString?.split('-')?.map(s => Number(s))
  const splitTime = timeString?.split(':')?.map(s => Number(s))
  if (splitDate?.length === 3 && splitTime?.length === 3) {
    return new Date(
      splitDate[0],
      splitDate[1] - 1,
      splitDate[2],
      splitTime[0],
      splitTime[1],
      splitTime[2],
    )
  }
  return null
}

export const createTimes = (range, type) => {
  if (isDate(range?.start) && isDate(range?.end)) {
    const times = []
    const addTime = type === 1 ? addMinutes : addDays
    const timeKeyFormat = type === 1 ? 'HH:mm' : 'yyyy-MM-dd'
    const timeTitleFormat = type === 1 ? 'h a' : 'yyyy-MM-dd'
    let time = range?.start
    while (!isAfter(time, range?.end)) {
      times.push(time)
      time = addTime(time, type === 1 ? 30 : 1)
    }
    return times.map(t => ({
      key: format(t, timeKeyFormat),
      title: format(t, timeTitleFormat),
    }))
  }
  return []
}

export const fillTimes = (data, {range, type}) => {
  const dataAtTimes = data?.reduce((acc, d) => {
    acc[d.time] = d
    return acc
  }, {})

  const times = []
  const addTime = [1, 2].includes(type) ? addMinutes : addDays
  const timeKeyFormat = [1, 2].includes(type) ? 'HH:mm:ss' : 'yyyy-MM-dd'
  // const timeTitleFormat = [1, 2].includes(type) ? 'h a' : 'yyyy-MM-dd'
  let time = range?.start
  while (!isAfter(time, range?.end)) {
    const timeKey = format(time, timeKeyFormat)
    const dateKey = format(time, 'yyyy-MM-dd')
    if (dataAtTimes[timeKey]) {
      times.push(dataAtTimes[timeKey])
    } else if ([1, 2].includes(type)) {
      times.push({
        time: timeKey,
        date: dateKey,
      })
    } else {
      times.push({time: timeKey})
    }
    time = addTime(time, [1, 2].includes(type) ? 30 : 1)
  }

  return times
}

export const toDateString = item => {
  if (item.time_period_type === 2) {
    if (!item.time_period_value) return ''
    const start = startOfWeek(toDate(item.time_period_value))
    const end = endOfWeek(start)
    return item.abbreviate
      ? format(start, 'MM/dd/yyyy')
      : `${format(start, 'MM/dd/yyyy')} - ${format(end, 'MM/dd/yyyy')}`
  }
  if (item.time_period_type === 3) {
    if (!item.time_period_value) return ''
    const start = startOfMonth(toDate(item.time_period_value))
    const end = endOfMonth(start)
    return item.abbreviate
      ? format(start, 'MM/yyyy')
      : `${format(start, 'MM/dd/yyyy')} - ${format(end, 'MM/dd/yyyy')}`
  }
  if (!item.time_period_value) return ''
  return format(toDate(item.time_period_value), 'MM/dd/yyyy')
}

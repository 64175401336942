import {batch} from 'react-redux'
import snakeCase from 'lodash/snakeCase'
import omit from 'lodash/omit'
import addDays from 'date-fns/addDays'
import subDays from 'date-fns/subDays'
import addWeeks from 'date-fns/addWeeks'
import subWeeks from 'date-fns/subWeeks'
import addMonths from 'date-fns/addMonths'
import subMonths from 'date-fns/subMonths'
import nextSunday from 'date-fns/nextSunday'
import nextSaturday from 'date-fns/nextSaturday'
import isWeekend from 'date-fns/isWeekend'
import isBefore from 'date-fns/isBefore'
import isAfter from 'date-fns/isAfter'
import format from 'date-fns/format'
import orderBy from 'lodash/orderBy'
import isEmpty from 'lodash/isEmpty'
import {fetchPatientVolumes} from '../../api'
import {
  SET_PACU_DATA,
  SET_PACU_LOADING,
  SET_PACU_ERROR,
  SET_PACU_NOT_LOADING,
  SET_PACU_LOADING_DATA,
  SET_PACU_NOT_LOADING_DATA,
  SET_PACU_FILTERS,
  SET_SCHEDULED_CASE_VOLUME,
  SET_PACU_PATIENT_TYPES,
  SET_PACU_VIEWS,
  SET_PACU_SHOW_PREDICTED,
  SET_PACU_ARRIVES_IP_FLOOR,
  SET_PACU_HIDE_WEEKENDS,
} from '../types'
import {setQuery, withBailOnLoading} from './utils'
import {toDate, fillTimes} from '../../pages/patient-volumes/utils/date'
import {selectOnlyOneById, fromQueryString} from '../../utils'

const createPatientType = (key, name) => ({
  key,
  title: name,
})

const createDataPoint = ({data, show_predicted, patient_types_dict}) => {
  const datum = data.reduce(
    (acc, d) => {
      const service = d?.service ? snakeCase(d?.service) : undefined
      if (!show_predicted && service === 'add_on') return acc
      if (
        service &&
        !patient_types_dict[service] &&
        service !== 'total_scheduled'
      ) {
        patient_types_dict[service] = d?.service
      }
      const payloadA = service
        ? ['total_scheduled'].includes(service)
          ? {...d, [service]: d?.scheduled, patient_ids: {...acc?.patient_ids}}
          : {
              ...d,
              patient_ids: {
                ...acc?.patient_ids,
                [service]: d?.patient_ids,
              },
              [service]: d?.scheduled,
            }
        : {...d}
      const payloadB =
        service === 'total_scheduled'
          ? {...payloadA, total_volume: d?.scheduled}
          : payloadA

      return omit({...acc, ...payloadB}, ['service', 'total_scheduled'])
    },
    {
      patient_ids: {},
    },
  )

  return datum
}

const createByHour = ({data, show_predicted}) => {
  const patient_types_dict = {}
  const results = Object.entries(data).reduce((acc, [date, times]) => {
    const result = Object.entries(times).reduce((acc, [time, data]) => {
      const datum = createDataPoint({data, show_predicted, patient_types_dict})
      return [
        ...acc,
        {
          ...datum,
          time,
          date,
        },
      ]
    }, [])

    const mapped = result.map(d => ({...d, is_offhour: d?.off_hours}))
    const filled = fillTimes(mapped, {
      type: 1,
      range: {start: toDate(date, '00:00:00'), end: toDate(date, '23:30:00')},
    })

    return [...acc, filled]
  }, [])
  const patient_types = Object.entries(patient_types_dict).map(([key, name]) =>
    createPatientType(key, name),
  )
  return {
    results,
    patient_types: orderBy(patient_types, ['key'], ['asc']),
  }
}

const createByDayOverview = ({data, time_period_value, show_predicted}) => {
  const sunday = nextSunday(toDate(time_period_value))

  const rest = Array.from({length: 6}, (_, i) => addDays(sunday, i + 1))
  const daysOfTheWeek = {
    0: format(sunday, 'yyyy-MM-dd'),
    1: format(rest[0], 'yyyy-MM-dd'),
    2: format(rest[1], 'yyyy-MM-dd'),
    3: format(rest[2], 'yyyy-MM-dd'),
    4: format(rest[3], 'yyyy-MM-dd'),
    5: format(rest[4], 'yyyy-MM-dd'),
    6: format(rest[5], 'yyyy-MM-dd'),
  }

  const patient_types_dict = {}
  const results = Object.entries(data).reduce((acc, [month, days]) => {
    const result = Object.entries(days).reduce((acc, [day, data]) => {
      const datum = createDataPoint({data, show_predicted, patient_types_dict})
      return [
        ...acc,
        {
          ...datum,
          date: daysOfTheWeek[Number(day)],
          month,
        },
      ]
    }, [])
    const mapped = result.map(d => ({...d, is_offhour: d?.off_hours}))
    return [...acc, mapped]
  }, [])
  const patient_types = Object.entries(patient_types_dict).map(([key, name]) =>
    createPatientType(key, name),
  )
  return {
    results,
    patient_types: orderBy(patient_types, ['key'], ['asc']),
  }
}

const createByDay = ({data, hide_weekends, show_predicted}) => {
  const patient_types_dict = {}

  const results = Object.entries(data).reduce((acc, [date, _days]) => {
    const first = toDate(date?.split(' ')[0], '00:00:00')
    const currentDay = first.getDay()
    // if day is saturday itself then set last day to self.
    const last = currentDay === 6 ? first : nextSaturday(first)
    const days = {}
    const lastDay = Object.keys(_days)
      ?.map(Number)
      ?.reduce((acc, d) => {
        if (d > acc) {
          return d
        }
        return acc
      }, 0)
    const daysOfTheWeek = []
    let current = first
    while (!isAfter(current, last)) {
      daysOfTheWeek.push(current)
      const day = current.getDay()
      if (!_days[day]) {
        if (day <= lastDay && !hide_weekends) {
          days[day] = []
        }
      } else {
        days[day] = _days[day]
      }
      current = addDays(current, 1)
    }

    const dates = daysOfTheWeek.reduce((acc, d) => {
      const day = d.getDay()
      acc[day] = d
      return acc
    }, {})
    const result = Object.entries(days).reduce((acc, [day, _data]) => {
      const datum = createDataPoint({
        data: _data,
        show_predicted,
        patient_types_dict,
      })
      return [
        ...acc,
        {
          ...datum,
          date: format(dates[day], 'yyyy-MM-dd'),
        },
      ]
    }, [])
    const mapped = result.map(d => ({...d, is_offhour: d?.off_hours}))
    return [...acc, mapped]
  }, [])
  const patient_types = Object.entries(patient_types_dict).map(([key, name]) =>
    createPatientType(key, name),
  )
  return {
    results,
    patient_types: orderBy(patient_types, ['key'], ['asc']),
  }
}

const massageData = ({
  data,
  time_periods,
  detail,
  hide_weekends,
  show_predicted,
}) => {
  if (!data || isEmpty(data)) {
    return {
      results: [],
      patient_types: [],
    }
  }
  if (time_periods.time_period_type === 3) {
    return detail
      ? createByDay({data, hide_weekends, show_predicted})
      : createByDayOverview({
          data,
          time_period_value: time_periods.time_period_value,
          show_predicted,
        })
  }
  if (time_periods.time_period_type === 2) {
    return detail
      ? createByHour({data, show_predicted})
      : createByDay({data, hide_weekends, show_predicted})
  }
  return createByHour({data, show_predicted})
}

const setData = payload => ({type: SET_PACU_DATA, payload})

const setLoading = () => ({type: SET_PACU_LOADING})

const setNotLoading = () => ({type: SET_PACU_NOT_LOADING})

const setLoadingData = () => ({type: SET_PACU_LOADING_DATA})

const setNotLoadingData = () => ({type: SET_PACU_NOT_LOADING_DATA})

const setError = payload => ({type: SET_PACU_ERROR, payload})

const setFilters = payload => ({type: SET_PACU_FILTERS, payload})

const setVolumes = payload => ({type: SET_SCHEDULED_CASE_VOLUME, payload})

const setPatientTypes = payload => ({type: SET_PACU_PATIENT_TYPES, payload})

const setViews = payload => ({type: SET_PACU_VIEWS, payload})

const setShowPredicted = payload => ({type: SET_PACU_SHOW_PREDICTED, payload})

const setArrivesIPFloor = payload => ({
  type: SET_PACU_ARRIVES_IP_FLOOR,
  payload,
})

const setHideWeekends = payload => ({
  type: SET_PACU_HIDE_WEEKENDS,
  payload,
})

const selectTimePeriod = (payload, history) => (dispatch, getState) => {
  const {filters: current_filters} = getState().pacu
  const {time_periods: current_time_periods} = current_filters
  const time_periods = {
    ...current_time_periods,
    time_period_value: payload.value,
    time_period_type: payload.type,
  }
  dispatch(
    setQuery({
      history,
      store: 'pacu',
      onLoading: setLoadingData,
      overrides: {
        time_periods,
      },
    }),
  )
}

const unselectTimePeriod = history => (dispatch, getState) => {
  const {filters: current_filters} = getState().pacu
  const {time_periods: current_time_periods} = current_filters
  const time_periods = {
    ...current_time_periods,
    time_period_value: null,
    time_period_type: null,
  }
  dispatch(
    setQuery({
      history,
      store: 'pacu',
      onLoading: setLoadingData,
      overrides: {
        time_periods,
      },
    }),
  )
}

const setFloorArrivals = history => dispatch => {
  batch(() => {
    dispatch(setArrivesIPFloor(true))
    dispatch(
      setQuery({
        history,
        store: 'pacu',
        onLoading: setLoadingData,
      }),
    )
  })
}

const setPACU = history => dispatch => {
  batch(() => {
    dispatch(setArrivesIPFloor(false))
    dispatch(
      setQuery({
        history,
        store: 'pacu',
        onLoading: setLoadingData,
      }),
    )
  })
}

const resetFilters = history => (dispatch, getState) => {
  const {filters: current_filters} = getState().pacu
  const {time_periods: current_time_periods} = current_filters
  const time_periods = {
    ...current_time_periods,
    time_period_type: null,
    time_period_value: null,
  }
  batch(() => {
    dispatch(
      setQuery({
        history,
        store: 'pacu',
        onLoading: setLoadingData,
        overrides: {
          time_periods,
        },
      }),
    )
  })
}

const fetch =
  ({signal, search, initial, bail}) =>
  (dispatch, getState) => {
    const {
      detail = true,
      arrives_ip_floor = false,
      hide_weekends = false,
      show_predicted = true,
    } = fromQueryString(search, [
      'detail',
      'arrives_ip_floor',
      'hide_weekends',
      'show_predicted',
    ])
    if (bail) return undefined
    if (initial) {
      dispatch(setLoading())
    } else {
      dispatch(setLoadingData())
    }
    return fetchPatientVolumes({signal, search})
      .then(response => {
        const {views: current_views} = getState().pacu
        const view = {id: detail ? 2 : 1}
        const views = current_views.map(selectOnlyOneById(view))
        const data = massageData({
          data: response?.data,
          time_periods: response?.filters?.time_periods,
          detail,
          hide_weekends,
          show_predicted,
        })

        batch(() => {
          dispatch(setData(data?.results))
          dispatch(setPatientTypes(data?.patient_types))
          dispatch(setViews(views))
          dispatch(setHideWeekends(hide_weekends))
          dispatch(setArrivesIPFloor(arrives_ip_floor))
          dispatch(setFilters(response?.filters))
          dispatch(setVolumes(response?.volumes))
        })
        if (initial) {
          dispatch(setNotLoading())
          dispatch(setNotLoadingData())
        } else {
          dispatch(setNotLoadingData())
        }
      })
      .catch(error => {
        batch(() => {
          dispatch(setError(error))
          if (initial) {
            dispatch(setNotLoading())
            dispatch(setNotLoadingData())
          } else {
            dispatch(setNotLoadingData())
          }
        })
      })
  }

const getNextWeekday = date => {
  let next = addDays(date, 1)

  while (isWeekend(next)) {
    next = addDays(next, 1)
  }
  return next
}

const getPrevWeekday = date => {
  let prev = subDays(date, 1)
  while (isWeekend(prev)) {
    prev = subDays(prev, 1)
  }
  return prev
}

const getMaxWeekday = date => {
  let max = toDate(date)
  while (isWeekend(max)) {
    max = subDays(max, 1)
  }
  return addDays(max, 1)
}

const getMinWeekday = date => {
  let min = toDate(date)
  while (isWeekend(min)) {
    min = addDays(min, 1)
  }
  return min
}

const setNext = history => (dispatch, getState) => {
  const {filters: current_filters, hide_weekends} = getState().pacu
  const {time_periods: current_time_periods} = current_filters
  const {time_period_value, max_date, time_period_type} = current_time_periods

  let value = time_period_value
  const max = max_date
    ? hide_weekends && time_period_type === 1
      ? getMaxWeekday(max_date)
      : addDays(toDate(max_date), 1)
    : null

  if (time_period_type === 1) {
    const current = toDate(time_period_value)
    const next = hide_weekends ? getNextWeekday(current) : addDays(current, 1)
    if (max) {
      if (isBefore(next, max)) {
        value = format(next, 'yyyy-MM-dd')
      }
    } else {
      value = format(next, 'yyyy-MM-dd')
    }
  }
  if (time_period_type === 2) {
    const current = toDate(time_period_value)
    const next = addWeeks(current, 1)
    if (max) {
      if (isBefore(next, max)) {
        value = format(next, 'yyyy-MM-dd')
      }
    } else {
      value = format(next, 'yyyy-MM-dd')
    }
  }
  if (time_period_type === 3) {
    const current = toDate(time_period_value)
    const next = addMonths(current, 1)
    if (max) {
      if (isBefore(next, max)) {
        value = format(next, 'yyyy-MM-dd')
      }
    } else {
      value = format(next, 'yyyy-MM-dd')
    }
  }

  const time_periods = {...current_time_periods, time_period_value: value}
  dispatch(
    setQuery({
      history,
      store: 'pacu',
      onLoading: setLoadingData,
      overrides: {
        time_periods,
      },
    }),
  )
}

const setPrevious = history => (dispatch, getState) => {
  const {filters: current_filters, hide_weekends} = getState().pacu
  const {time_periods: current_time_periods} = current_filters
  const {time_period_value, min_date, time_period_type} = current_time_periods

  let value = time_period_value
  const min = min_date
    ? hide_weekends && time_period_type === 1
      ? getMinWeekday(min_date)
      : toDate(min_date)
    : null

  if (time_period_type === 1) {
    const current = toDate(time_period_value)
    const previous = hide_weekends
      ? getPrevWeekday(current)
      : subDays(current, 1)
    if (min) {
      if (isAfter(previous, min)) {
        value = format(previous, 'yyyy-MM-dd')
      } else {
        value = format(min, 'yyyy-MM-dd')
      }
    } else {
      value = format(previous, 'yyyy-MM-dd')
    }
  }

  if (time_period_type === 2) {
    const current = toDate(time_period_value)
    const previous = subWeeks(current, 1)
    if (min) {
      if (isAfter(previous, min)) {
        value = format(previous, 'yyyy-MM-dd')
      } else {
        value = format(min, 'yyyy-MM-dd')
      }
    } else {
      value = format(previous, 'yyyy-MM-dd')
    }
  }

  if (time_period_type === 3) {
    const current = toDate(time_period_value)
    const previous = subMonths(current, 1)
    if (min) {
      if (isAfter(previous, min)) {
        value = format(previous, 'yyyy-MM-dd')
      } else {
        value = format(min, 'yyyy-MM-dd')
      }
    } else {
      value = format(previous, 'yyyy-MM-dd')
    }
  }

  const time_periods = {...current_time_periods, time_period_value: value}
  dispatch(
    setQuery({
      history,
      store: 'pacu',
      onLoading: setLoadingData,
      overrides: {
        time_periods,
      },
    }),
  )
}

const selectView = (item, history) => (dispatch, getState) => {
  const {views: current_view} = getState().pacu
  const selected = current_view.map(selectOnlyOneById({id: item.id}))
  batch(() => {
    dispatch(setViews(selected))
    dispatch(
      setQuery({
        history,
        store: 'pacu',
        onLoading: setLoadingData,
      }),
    )
  })
}

const toggleHideWeekends = history => (dispatch, getState) => {
  const {hide_weekends: current_hide_weekends} = getState().pacu
  const hide_weekends = !current_hide_weekends
  batch(() => {
    dispatch(setHideWeekends(hide_weekends))
    dispatch(setQuery({history, store: 'pacu', onLoading: setLoadingData}))
  })
}

const toggleShowPredicted = history => (dispatch, getState) => {
  const {show_predicted: current_show_predicted} = getState().pacu
  const show_predicted = !current_show_predicted
  batch(() => {
    dispatch(setShowPredicted(show_predicted))
    dispatch(setQuery({history, store: 'pacu', onLoading: setLoadingData}))
  })
}

const actions = {
  fetch,
  selectTimePeriod: withBailOnLoading(selectTimePeriod, 'pacu'),
  unselectTimePeriod: withBailOnLoading(unselectTimePeriod, 'pacu'),
  resetFilters: withBailOnLoading(resetFilters, 'pacu'),
  setPrevious: withBailOnLoading(setPrevious, 'pacu'),
  setNext: withBailOnLoading(setNext, 'pacu'),
  selectView: withBailOnLoading(selectView, 'pacu'),
  toggleHideWeekends: withBailOnLoading(toggleHideWeekends, 'pacu'),
  toggleShowPredicted: withBailOnLoading(toggleShowPredicted, 'pacu'),
  setFloorArrivals: withBailOnLoading(setFloorArrivals, 'pacu'),
  setPACU: withBailOnLoading(setPACU, 'pacu'),
}

export default actions

import isEmpty from 'lodash/isEmpty'
import isNull from 'lodash/isNull'
import isArray from 'lodash/isArray'
import isNumber from 'lodash/isNumber'
import isUndefined from 'lodash/isUndefined'
import isBoolean from 'lodash/isBoolean'
import validator from 'validator'

export const isNullType = value =>
  !isBoolean(value) &&
  !isNumber(value) &&
  (isEmpty(value) || isNull(value) || isUndefined(value))

export const isNullList = list => !(isArray(list) && !isEmpty(list))

export const validateNPI = (value = '') => {
  if (validator.isEmpty(value))
    return {value, isValid: false, error: 'This field is empty'}
  if (value?.length !== 10 || !validator.isNumeric(value))
    return {
      value,
      isValid: false,
      error: 'NPI must be a 10-digit numeric identifier',
    }
  if (value[0] === '0') {
    return {
      value,
      isValid: false,
      error: 'Zero cannot be the first digit',
    }
  }
  return {value, isValid: true, error: ''}
}

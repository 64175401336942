import createDimensions from './dimensions'
import createScales from './scales'
import createLabels from './labels'
import createAxes from './axes'
import createMarkers from './markers'
import createLegend from './legend'
import createTooltip from './tooltip'
import createStyles from './styles'
import createPercentiles from '../percentiles'

const createBarChart = config => {
  const percentiles = createPercentiles(config)
  const dimensions = createDimensions(config)
  const {keyAccessor, keys, xAccessor, yAccessor, min, max} =
    createScales(config)
  const {
    title,
    subtitle,
    formatBarLabel,
    formatStackedBarLabel,
    stackedBarLabelAccessor,
    showBarLabel,
    showStackedBarLabel,
  } = createLabels(config)
  const {leftAxis, bottomAxis} = createAxes(config, dimensions)
  const markers = createMarkers(config, percentiles)
  const {legend, showLegend} = createLegend(config, percentiles)
  const tooltip = createTooltip(config, formatBarLabel)

  return {
    type: 'bar',
    id: config.id,
    variantId: config?.variant_id,
    title,
    subtitle,
    dimension: config.chart.dimension,
    dimensions,
    groupMode: config.chart.group_mode,
    hoverAsStack: config.chart.hover_as_stack,
    keyAccessor,
    keys,
    xAccessor,
    yAccessor,
    min,
    max: percentiles.max ? percentiles.max : max,
    formatBarLabel,
    formatStackedBarLabel,
    stackedBarLabelAccessor,
    showBarLabel,
    showStackedBarLabel,
    leftAxis,
    bottomAxis,
    legend,
    showLegend,
    tooltip,
    data: !config?.data.length ? [] : config.data,
    selectable: [config.id].includes(48) && !config.hideCaseRemovalButtons,
    styles: createStyles(config),
    markers,
  }
}

export default createBarChart

import isArray from 'lodash/isArray'
import isEmpty from 'lodash/isEmpty'
import {orderList} from '../../../utils'

export const hasPhysicians = data =>
  isArray(data) && !isEmpty(data) && (data[0].surgeon_name || data[0].name)

export const createPhysicians = (data, type) => {
  if (hasPhysicians(data) && ['physicians', 'cases'].includes(type)) {
    let list = []
    if (data[0].surgeon_name) {
      list = [...new Set(data.map(d => d.surgeon_name))]
    } else {
      list = [...new Set(data.map(d => d.name))]
    }
    return orderList(
      list.map(d => ({
        id: d,
        name: d,
        selected: true,
      })),
      'name',
    )
  }
  return []
}

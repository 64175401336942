import React from 'react'
import PropTypes from 'prop-types'
import {AgTabs} from '@aghealth/ag-components'

const defaultStyles = {
  Root: {
    px: '16px',
  },
  Tab: {
    cursor: (_, props) => (props.selected ? 'pointer' : props.loading ? 'wait' : 'pointer'),
    color: (_, props) => (props.selected ? 'black' : props.loading ? 'input-border-gray' : 'black'),
    fontWeight: 'normal',
  },
}

const Tabs = ({styles, ...props}) => (
  <AgTabs
    {...props}
    styles={styles}
  />
)

Tabs.propTypes = {
  styles: PropTypes.object,
}

Tabs.defaultProps = {
  styles: defaultStyles,
}

export default Tabs

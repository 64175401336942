import React from 'react'
import {createPortal} from 'react-dom'
import PropTypes from 'prop-types'
import {
  AgTable,
  AgFlex,
  AgText,
  AgColumn,
  usePortal,
} from '@aghealth/ag-components'
import isEmpty from 'lodash/isEmpty'
import DoubleScrollbar from './DoubleScrollbar'
import CostBySupplyTooltip from './CostBySupplyTooltip'

const BaseStyles = {
  Root: {
    variant: 'tables.round',
    'data-testid': 'explore-data-table',
  },
  Td: {
    py: '22px',
  },
  TdRow: {
    py: '22px',
    bg: (_, props) => (props.selected ? 'hover-green' : 'white'),
    'data-testid': 'explore-data-td-row',
  },
}

const ScrollStyles = {
  Root: {
    variant: 'tables.scroll',
  },
  TdRow: {
    bg: (_, props) => (props.selected ? 'hover-green' : 'white'),
  },
}

const CostByCaseStyles = {
  Root: {
    variant: 'tables.cost-by-case',
  },
  Th: {
    'data-testid': 'cost-by-case-th',
  },
  TdRow: {
    bg: (_, props) => (props.selected ? 'hover-green' : 'white'),
  },
}

const CostBySupplyStyles = {
  Root: {
    variant: 'tables.cost-by-supply',
  },
  TdRow: {
    bg: (_, props) => (props.selected ? 'hover-green' : 'white'),
  },
}

const variants = {
  base: BaseStyles,
  scroll: ScrollStyles,
  'cost-by-case': CostByCaseStyles,
  'cost-by-supply': CostBySupplyStyles,
}

const Table = ({
  selected,
  onClick,
  data,
  title,
  columns,
  rowKeyAccessor,
  isAscSort,
  sortColumn,
  variant,
  id,
}) => {
  const target = usePortal('cost-by-supply-portal', document)
  const [tooltip, setTooltip] = React.useState(false)
  const position = React.useMemo(() => {
    if (['scroll', 'cost-by-case'].includes(variant)) return 'relative'
    return 'static'
  }, [variant])
  const handleOnMouseOutBadge = React.useCallback(() => {
    setTooltip(false)
  }, [])
  const handleOnMouseOverBadge = React.useCallback(
    (_, ref) => {
      if (id === 49) {
        const {left, top} = ref.getBoundingClientRect()
        setTooltip({
          top: window.scrollY + top,
          left,
        })
      }
    },
    [id],
  )
  return (
    <AgColumn
      width="100%"
      mt="16px"
      data-html2canvas-ignore
      position={position}
    >
      {!isEmpty(data) && title && (
        <AgFlex
          borderLeft="base"
          borderRight="base"
          borderTop="base"
          bg="white"
          borderTopLeftRadius="base"
          borderTopRightRadius="base"
          borderColor="box-border-gray"
          padding="20px 20px 20px 15px"
          width="100%"
        >
          <AgText fontSize="18px" data-testid="chart-title">
            {title}
          </AgText>
        </AgFlex>
      )}
      <DoubleScrollbar
        columnsCount={columns.length}
        showTopScrollBar={id === 48}
      >
        <AgTable
          data={data}
          columns={columns}
          rowKeyAccessor={rowKeyAccessor}
          styles={variants[variant]}
          isAscSort={isAscSort}
          sortColumn={sortColumn}
          selected={selected}
          onSelect={onClick}
          showSets={id === 49}
          isMultiSort={id === 49}
          icon="triangle"
          onMouseOverBadge={handleOnMouseOverBadge}
          onMouseOutBadge={handleOnMouseOutBadge}
        />
      </DoubleScrollbar>
      {tooltip &&
        createPortal(
          React.cloneElement(<CostBySupplyTooltip />, {
            top: tooltip.top,
            left: tooltip.left,
          }),
          target,
        )}
    </AgColumn>
  )
}

Table.propTypes = {
  selected: PropTypes.string,
  onClick: PropTypes.func,
  data: PropTypes.array,
  columns: PropTypes.array,
  rowKeyAccessor: PropTypes.func,
  isAscSort: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
  sortColumn: PropTypes.oneOfType([PropTypes.array, PropTypes.number]),
  title: PropTypes.string,
  variant: PropTypes.string,
  id: PropTypes.number,
}

Table.defaultProps = {
  data: [],
}

export default Table

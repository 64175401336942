import isString from 'lodash/isString'
import {subDays, startOfYear} from 'date-fns'
import {selectOnlyOneByIndex} from '.'

export const toDatePickerFormat = date => {
  const pattern = /\d{4}-\d{2}-\d{2}/g
  const match = isString(date) ? date.match(pattern) : null
  const raw = match?.length > 0 ? match[0] : null
  const formatted = isString(raw) ? raw.split('-').join('/') : null
  return formatted
}

export const toApiFormat = date => {
  const pattern = /\d{4}-\d{2}-\d{2}/g
  const [formatted] = isString(date) ? date.match(pattern) : [null]
  return formatted
}

export const computeTimePeriods = data => {
  const min = new Date(data.min)
  const max = new Date(data.max)
  const selectedStart = new Date(data.selected_start)
  const selectedEnd = new Date(data.selected_end)

  return {
    ...data,
    selected_start:
      selectedStart >= min && selectedStart <= max
        ? data.selected_start
        : data.min,
    selected_end:
      selectedEnd >= min && selectedEnd <= max ? data.selected_end : data.max,
  }
}

export const resetTimePeriods = (data, defaultIndex = 0) => ({
  ...data,
  selected_start: '',
  selected_end: '',
  values: data.values.map(selectOnlyOneByIndex(defaultIndex)),
})

const options = {
  year: 'numeric',
  month: 'short',
  day: '2-digit',
  timeZone: 'UTC',
}

export const toDate = string => new Date(string)
export const toSubDaysLabel = (timePeriodMax, range) =>
  `${subDays(toDate(timePeriodMax), range - 1).toLocaleDateString(
    'en-US',
    options,
  )} - ${toDate(timePeriodMax).toLocaleDateString('en-US', options)}`
export const toRangelabel = (timePeriodStart, timePeriodEnd) => ` ${toDate(
  timePeriodStart,
).toLocaleDateString('en-US', options)} - 
    ${toDate(timePeriodEnd).toLocaleDateString('en-US', options)}`
export const toStartOfYearLabel = max =>
  `${startOfYear(new Date(max)).toLocaleDateString(
    'en-US',
    options,
  )} - ${toDate(max).toLocaleDateString('en-US', options)}`

export const toTimePeriodRange = (
  timePeriodMax,
  timePeriodId,
  timePeriodStart,
  timePeriodEnd,
) => {
  if (timePeriodId === 1) return toSubDaysLabel(timePeriodMax, 30)
  if (timePeriodId === 2) return toSubDaysLabel(timePeriodMax, 90)
  if (timePeriodId === 3) return toSubDaysLabel(timePeriodMax, 365)
  if (timePeriodId === 4) return toStartOfYearLabel(timePeriodMax)
  if (timePeriodId === 5) return toRangelabel(timePeriodStart, timePeriodEnd)
  return toSubDaysLabel(timePeriodMax, 30)
}

export const toTimePeriodName = (timePeriodId, ending = 'Total') => {
  if (timePeriodId === 1) return `30-Day ${ending}`
  if (timePeriodId === 2) return `90-Day ${ending}`
  if (timePeriodId === 3) return `365-Day ${ending}`
  if (timePeriodId === 4) return `Year-to-Date ${ending}`
  if (timePeriodId === 5) return `Selected Dates ${ending}`
  return '30-Day Total'
}

export const formatDateToMMDDYY = dateString => {
  if (!dateString) return ''

  const inputDate = new Date(dateString)
  const formattedDate = new Intl.DateTimeFormat('en-US', {
    timeZone: 'UTC',
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
  }).format(inputDate)
  return formattedDate
}

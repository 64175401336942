import React, {useCallback} from 'react'
import PropTypes from 'prop-types'
import {FiAlertTriangle} from 'react-icons/fi'
import {MdClose} from 'react-icons/md'

import {
  AgAbsolute,
  AgBox,
  AgButton,
  AgFixed,
  AgFlex,
  AgText,
} from '@aghealth/ag-components'

import themeColors from '../theme-2/colors'

const baseButtonStyles = {
  width: '195px',
  border: 'base',
  borderRadius: '8px',
  fontWeight: 'semibold',
  fontSize: 'md',
  padding: '16px',
  opacity: (_, props) => (props.disabled ? 0.6 : 1),
}

const styles = {
  Backdrop: {
    width: '100vw',
    height: '100vh',
    bg: 'rgba(0, 0, 0, .7)',
    top: 0,
    left: 0,
    zIndex: 'xlg',
  },
  Root: {
    top: 'calc(50% - 138px)',
    left: 'calc(50% - 200px) ',
    bg: 'white',
    borderRadius: 'base',
    padding: '16px 32px',
    width: '400px',
    height: '274px',
  },
  AlertIconContainer: {
    bg: 'rose.100',
    borderRadius: '100%',
    marginBottom: '20px',
    padding: '8px',
    border: 'base',
    borderWidth: '8px',
    borderColor: 'rose.50',
  },
  CloseIconContainer: {
    marginTop: '8px',
    color: 'new-gray.500',
    cursor: (_, props) => (props.disabled ? 'not-allowed' : 'pointer'),
  },
  Title: {
    fontSize: 'lg',
    marginBottom: '16px',
    fontWeight: 'semibold',
  },
  Subtitle: {
    fontSize: 'sm',
    marginBottom: '32px',
    color: 'new-gray.600',
  },
  SubmitButton: {
    ...baseButtonStyles,
    bg: 'rose.600',
    borderColor: 'rose.600',
    color: 'white',
    marginRight: '16px',
    cursor: (_, props) => (props.disabled ? 'wait' : 'pointer'),
  },
  CancelButton: {
    ...baseButtonStyles,
    bg: 'base.white',
    borderColor: 'new-gray.300',
    color: 'new-gray.700',
    cursor: (_, props) => (props.disabled ? 'not-allowed' : 'pointer'),
  },
  SubmitButtonDisabled: {
    opacity: 0.7,
    cursor: 'wait',
  },
}

const WarningPopup = ({
  popupRef,
  title,
  subtitle,
  buttonText,
  onSubmit,
  onCancel,
  disableButtons,
}) => {
  const handleOnSubmit = useCallback(() => {
    if (disableButtons) {
      return
    }

    onSubmit()
  }, [disableButtons, onSubmit])

  const handleOnCancel = useCallback(() => {
    if (disableButtons) {
      return
    }

    onCancel()
  }, [disableButtons, onCancel])

  return (
    <AgFixed sx={styles.Backdrop}>
      <AgAbsolute sx={styles.Root} ref={popupRef}>
        <AgFlex justifyContent="space-between" alignItems="flex-start">
          <AgBox sx={styles.AlertIconContainer}>
            <FiAlertTriangle
              size={24}
              style={{
                color: themeColors['rose'][600],
              }}
              cursor="pointer"
            />
          </AgBox>
          <AgBox
            disabled={disableButtons}
            sx={styles.CloseIconContainer}
            onClick={handleOnCancel}
          >
            <MdClose size={24} />
          </AgBox>
        </AgFlex>

        <AgText sx={styles.Title}>{title}</AgText>
        <AgText sx={styles.Subtitle}>{subtitle}</AgText>
        <AgFlex>
          <AgButton
            disabled={disableButtons}
            onClick={handleOnSubmit}
            sx={styles.SubmitButton}
          >
            {buttonText || 'Delete'}
          </AgButton>
          <AgButton
            disabled={disableButtons}
            onClick={handleOnCancel}
            sx={styles.CancelButton}
          >
            Cancel
          </AgButton>
        </AgFlex>
      </AgAbsolute>
    </AgFixed>
  )
}

WarningPopup.propTypes = {
  popupRef: PropTypes.shape({
    current: PropTypes.any,
  }),
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  disableButtons: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  buttonText: PropTypes.string,
}

export default WarningPopup

import React from 'react'
import PropTypes from 'prop-types'
import {ScrollY, Checkbox} from '.'

const CheckboxList = ({list, onChange, styles, disabled}) => (
  <ScrollY width="100%" disabled={disabled} sx={styles.Root}>
    {list.map((c, i) => (
      <Checkbox
        key={c.id}
        id={c.id}
        name={c.name}
        selected={c.selected}
        type={c.type}
        onChange={onChange}
        styles={styles.Checkbox}
        disabled={disabled}
        metadata={c.metadata}
      />
    ))}
  </ScrollY>
)

CheckboxList.propTypes = {
  onChange: PropTypes.func,
  list: PropTypes.arrayOf(PropTypes.any),
  styles: PropTypes.shape({
    Root: PropTypes.object,
    Checkbox: PropTypes.object,
  }),
  disabled: PropTypes.bool,
}

CheckboxList.defaultProps = {
  list: [],
  onChange: () => {},
  styles: {},
}

export default CheckboxList

import {toJson, getHeaders, baseURL} from './utils'

const miscodingsEndpoint = `${baseURL}/api/miscodings`

export const fetchMiscodings = toJson(async options => {
  const {search, signal} = options
  return fetch(`${miscodingsEndpoint}`.concat(search), {
    headers: await getHeaders(),
    signal,
  })
})

export const fetchMiscodingDetailsById = toJson(async options => {
  const {search, signal, id} = options
  return fetch(`${miscodingsEndpoint}/${id}`.concat(search), {
    headers: await getHeaders(),
    signal,
  })
})

export const fetchMiscodingsExport = async options => {
  const {search} = options
  const url = `${miscodingsEndpoint}/export${search}`
  const result = await fetch(url, {
    headers: await getHeaders(),
  })
  const blob = await result.blob()
  return blob
}

export const createUpdateCase = async (id = null, options = {}) => {
  const {method, body, onSuccess = () => {}, onFail = () => {}} = options
  const url = `${miscodingsEndpoint}/${id}`
  return fetch(url, {
    ...options,
    method,
    headers: await getHeaders(),
    body: body ? JSON.stringify(body) : undefined,
  }).then(r =>
    method === 'GET'
      ? r.json()
      : /2[0-9][0-9]/g.test(r.status)
      ? onSuccess(r)
      : onFail(r),
  )
}

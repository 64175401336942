import {batch} from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import {withBailOnLoading, setQuery, withFacilityType} from './utils'
import {getDateRange} from '../utils'
import {
  SET_SYSTEM_DATA,
  SET_SYSTEM_ERROR,
  SET_SYSTEM_FILTERS,
  SET_SYSTEM_METRICS,
  SET_SYSTEM_TIME_PERIODS,
  SET_SYSTEM_DATE_SAVED,
  SET_SYSTEM_LOADING_DATA,
  SET_SYSTEM_LOADING_FILTERS,
  SET_SYSTEM_RISK_ADJUSTED,
  SET_SYSTEM_FILTER_DATA_BY,
  SET_SYSTEM_IS_ONCOLOGY,
  SET_SYSTEM_EXCLUDE_ONCOLOGY,
  SET_SYSTEM_DEPARTMENTS,
} from '../types'
import {fetchSystem} from '../../api'
import {
  fromQueryString,
  selectOnlyOneById,
  selectOnlyOneByIndex,
  unselectAll,
} from '../../utils'

import {selectDefaultDepartment} from '../../utils/selectors'

import {ALL_PREFIX} from '../../pages/dashboard-v2/constants'

const setData = payload => ({type: SET_SYSTEM_DATA, payload})

const setError = payload => ({type: SET_SYSTEM_ERROR, payload})

const setFilters = (payload, meta) => ({
  type: SET_SYSTEM_FILTERS,
  payload,
  meta,
})

const setTimePeriods = payload => ({type: SET_SYSTEM_TIME_PERIODS, payload})

const setMetrics = payload => ({type: SET_SYSTEM_METRICS, payload})

const setDateSaved = payload => ({type: SET_SYSTEM_DATE_SAVED, payload})

const setLoadingData = payload => ({type: SET_SYSTEM_LOADING_DATA, payload})

const setLoadingFilters = payload => ({
  type: SET_SYSTEM_LOADING_FILTERS,
  payload,
})

const setRiskAdjusted = payload => ({type: SET_SYSTEM_RISK_ADJUSTED, payload})

const setFilterDataBy = payload => ({type: SET_SYSTEM_FILTER_DATA_BY, payload})

const setIsOncology = payload => ({
  type: SET_SYSTEM_IS_ONCOLOGY,
  payload,
})

const setExcludeOncology = payload => ({
  type: SET_SYSTEM_EXCLUDE_ONCOLOGY,
  payload,
})

const setFetchSystem =
  ({signal, search}) =>
  (dispatch, getState) => {
    const {loading_data} = getState().system
    if (!loading_data) {
      dispatch(setLoadingData(true))
    }
    return fetchSystem({signal, search})
      .then(({data, filters}) => {
        batch(() => {
          dispatch(setFilters(withFacilityType(filters)))
          dispatch(setData(data))
          dispatch(setLoadingData(false))
          dispatch(setLoadingFilters(false))
        })
      })
      .catch(error => {
        batch(() => {
          dispatch(setError(error))
          dispatch(setLoadingData(false))
          dispatch(setLoadingFilters(false))
        })
      })
  }

const selectMetric = (payload, history) => (dispatch, getState) => {
  const {filters: current_filters, date_saved} = getState().system
  const {metrics: current_metrics, time_periods: current_time_periods} =
    current_filters
  const metrics = current_metrics.map(selectOnlyOneByIndex(payload))
  const time_periods = date_saved ? current_time_periods : {}
  batch(() => {
    dispatch(setMetrics(metrics))
    dispatch(
      setQuery({
        history,
        store: 'system',
        overrides: {time_periods},
        onLoading: setLoadingData,
      }),
    )
  })
  dispatch(setMetrics(metrics))
}

const selectTimePeriod = (payload, history) => (dispatch, getState) => {
  const {filters: current_filters} = getState().system
  const {time_periods: current_time_periods} = current_filters
  const time_periods = {
    ...current_time_periods,
    selected_start: payload.selected_start,
    selected_end: payload.selected_end,
    values: current_time_periods.values.map(selectOnlyOneById(payload.value)),
  }
  if (payload.value.id === 5 && payload.selected_start === '') {
    const {id} = current_time_periods.values.find(d => d.selected)
    const range = getDateRange(id, time_periods.max)
    dispatch(setTimePeriods({...time_periods, ...range}))
  } else {
    batch(() => {
      dispatch(setDateSaved(true))
      dispatch(setTimePeriods(time_periods))
      dispatch(
        setQuery({
          history,
          store: 'system',
          onLoading: setLoadingData,
        }),
      )
    })
  }
}

const unselectProcedureGroupV2 = (payload, history) => (dispatch, getState) => {
  const {filters: current_filters, date_saved} = getState().system
  const {departments: current_departments, time_periods: current_time_periods} =
    current_filters

  const time_periods = date_saved ? current_time_periods : {}

  let departments = []

  // Track if department_id 4 was previously selected
  const wasOncologyPreviouslySelected = current_departments.some(
    department =>
      department.id === 4 &&
      (department.selected ||
        department.sub_departments.some(subDept => subDept.selected)),
  )

  if (payload.label.includes(ALL_PREFIX)) {
    const departmentLabel = payload.label.split(ALL_PREFIX)[1]

    departments = current_departments.map(department => {
      if (department.name === departmentLabel) {
        const sub_departments = department.sub_departments.map(subDept => ({
          ...subDept,
          selected: false,
        }))

        return {
          ...department,
          sub_departments: sub_departments,
          selected: false,
        }
      }

      return department
    })
  } else {
    departments = current_departments.map(department => {
      let hasSelectedSubDept = false
      const sub_departments = department.sub_departments.map(subDept => {
        if (subDept.id === payload.id) {
          return {...subDept, selected: false}
        }

        hasSelectedSubDept = hasSelectedSubDept || subDept.selected

        return subDept
      })

      return sub_departments?.length > 0
        ? {
            ...department,
            sub_departments: sub_departments,
            selected: hasSelectedSubDept,
          }
        : {
            ...department,
          }
    })
  }

  // Check if any department with id 4 is still selected after the update
  const isOncologyCurrentlySelected = departments.some(
    department =>
      department.id === 4 &&
      (department.selected ||
        department.sub_departments.some(subDept => subDept.selected)),
  )

  // Set is_oncology to false only if it was previously selected and is now unselected
  const isOncology =
    wasOncologyPreviouslySelected && !isOncologyCurrentlySelected
      ? false
      : current_filters.is_oncology

  batch(() => {
    dispatch(setIsOncology(isOncology))
    dispatch(setDepartments(departments))
    dispatch(
      setQuery({
        history,
        store: 'system',
        onLoading: setLoadingData,
        overrides: {time_periods},
      }),
    )
  })
}

const toggleRiskAdjusted = (payload, history) => (dispatch, getState) => {
  const {filters: current_filters} = getState().system
  const {risk_adjusted: current_risk_adjusted} = current_filters
  if (current_risk_adjusted !== payload) {
    const risk_adjusted = payload
    batch(() => {
      dispatch(setRiskAdjusted(risk_adjusted))
      dispatch(
        setQuery({
          history,
          store: 'system',
          onLoading: setLoadingData,
        }),
      )
    })
  }
}

const handleSetIsOncology = (payload, history) => (dispatch, getState) => {
  const {filters: current_filters} = getState().system
  const {is_oncology: current_is_oncology} = current_filters
  if (current_is_oncology !== payload) {
    batch(() => {
      dispatch(setIsOncology(payload))
      dispatch(setExcludeOncology(false))
      dispatch(
        setQuery({
          history,
          store: 'system',
          onLoading: setLoadingData,
        }),
      )
    })
  }
}

const handleSetAllCases = history => dispatch => {
  batch(() => {
    dispatch(setIsOncology(false))
    dispatch(setExcludeOncology(false))
    dispatch(
      setQuery({
        history,
        store: 'system',
        onLoading: setLoadingData,
      }),
    )
  })
}

const handleSetExcludeOncology = (payload, history) => (dispatch, getState) => {
  const {filters: current_filters} = getState().system
  const {exclude_oncology: current_exclude_oncology} = current_filters
  if (current_exclude_oncology !== payload) {
    batch(() => {
      dispatch(setExcludeOncology(payload))
      dispatch(setIsOncology(false))
      dispatch(
        setQuery({
          history,
          store: 'system',
          onLoading: setLoadingData,
        }),
      )
    })
  }
}

const fetch =
  ({signal, search, bail}) =>
  (dispatch, getState) => {
    const {
      date_saved = false,
      risk_adjusted = false,
      department_ids,
    } = fromQueryString(search, [
      'date_saved',
      'risk_adjusted',
      'department_ids',
    ])

    const refresh_date = getState()
      .config.user.organizations.filter(o => o.selected)
      .map(o => o.refresh_date)
      .join()
    const default_department = selectDefaultDepartment(getState())?.id
    let query = isEmpty(search)
      ? `?refresh_date=${refresh_date}`
      : `?refresh_date=${refresh_date}&${search.slice(1)}`

    const isOncologyDepartment =
      department_ids === 4 ||
      (Array.isArray(department_ids) && department_ids.includes(4))

    if (isOncologyDepartment) {
      query = `${query}&is_oncology=true`
    }
    query = new URLSearchParams(query)

    if (!department_ids && default_department) {
      query.append('department_ids', default_department)
    }

    const {date_saved: current_date_saved} = getState().system

    batch(() => {
      dispatch(setError(null))
      dispatch(setRiskAdjusted(risk_adjusted))
      if (date_saved !== current_date_saved) {
        dispatch(setDateSaved(date_saved))
      }
    })
    if (!bail) {
      dispatch(setFetchSystem({signal, search: `?${query.toString()}`}))
    }
  }

const unselectTimePeriod = history => (dispatch, getState) => {
  const {filters: current_filters} = getState().system
  const {time_periods: current_time_periods} = current_filters
  const time_periods = {
    ...current_time_periods,
    selected_start: '',
    selected_end: '',
    values: current_time_periods.values.map(unselectAll),
  }
  dispatch(
    setQuery({
      history,
      store: 'system',
      overrides: {time_periods},
    }),
  )
}

const toggleDateSaved = history => (dispatch, getState) => {
  const {date_saved: current_date_saved} = getState().system
  const date_saved = !current_date_saved
  batch(() => {
    dispatch(setDateSaved(date_saved))
    dispatch(
      setQuery({
        history,
        store: 'system',
        onLoading: setLoadingData,
        fetch: false,
      }),
    )
  })
}

const resetFilters = history => (dispatch, getState) => {
  const {
    time_periods: current_time_periods,
    procedure_groups: current_procedure_groups,
  } = getState().system.filters
  const time_periods = {
    ...current_time_periods,
    selected_start: '',
    selected_end: '',
    values: current_time_periods.values.map(unselectAll),
  }
  const procedure_groups = current_procedure_groups.map(selectOnlyOneByIndex(0))
  dispatch(
    setQuery({
      history,
      store: 'system',
      overrides: {
        time_periods,
        procedure_groups,
        is_oncology: null,
        exclude_oncology: null,
        risk_adjusted: null,
        date_saved: false,
        departments: [],
      },
      onLoading: setLoadingData,
    }),
  )
}

const selectFilterDataBy = payload => (dispatch, getState) => {
  const {filter_by} = getState().system
  if (filter_by !== payload) {
    dispatch(setFilterDataBy(payload))
  }
}

const setDepartments = payload => ({
  type: SET_SYSTEM_DEPARTMENTS,
  payload,
})

const handleDepartmentsSelection =
  (history, payload) => (dispatch, getState) => {
    const {filters: current_filters} = getState().system
    const {departments: current_departments} = current_filters
    // Track if department_id 4 was previously selected
    const wasOncologyPreviouslySelected = current_departments.some(
      department =>
        department.id === 4 &&
        (department.selected ||
          department.sub_departments.some(subDept => subDept.selected)),
    )
    // Check if any department with id 4 is still selected after the update
    const isOncologyCurrentlySelected = payload?.some(
      department =>
        department.id === 4 &&
        (department.selected ||
          department.sub_departments.some(subDept => subDept.selected)),
    )
    const isOncology =
      wasOncologyPreviouslySelected && !isOncologyCurrentlySelected
        ? false
        : current_filters.is_oncology
    batch(() => {
      dispatch(setIsOncology(isOncology))
      dispatch(setDepartments(payload))
      dispatch(setQuery({history, store: 'system'}))
    })
  }

const actions = {
  fetch,

  unselectProcedureGroupV2: withBailOnLoading(
    unselectProcedureGroupV2,
    'system',
  ),
  selectMetric: withBailOnLoading(selectMetric, 'system'),
  selectTimePeriod: withBailOnLoading(selectTimePeriod, 'system'),
  unselectTimePeriod: withBailOnLoading(unselectTimePeriod, 'system'),
  toggleRiskAdjusted: withBailOnLoading(toggleRiskAdjusted, 'system'),
  toggleDateSaved: withBailOnLoading(toggleDateSaved, 'system'),
  resetFilters: withBailOnLoading(resetFilters, 'system'),
  selectFilterDataBy,
  setIsOncology: withBailOnLoading(handleSetIsOncology, 'system'),
  setExcludeOncology: withBailOnLoading(handleSetExcludeOncology, 'system'),
  setAllCases: withBailOnLoading(handleSetAllCases, 'system'),
  setDepartments: withBailOnLoading(handleDepartmentsSelection, 'system'),
}

export default actions

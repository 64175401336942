import aws from './aws'
import sentry from './sentry'
import chatbot from './chatbot'

const configs = {
  aws,
  sentry,
  chatbot,
}

export default configs

import isArray from 'lodash/isArray'
import isObject from 'lodash/isPlainObject'
import isBoolean from 'lodash/isBoolean'
import isString from 'lodash/isString'
import isFunction from 'lodash/isFunction'
import omit from 'lodash/omit'
import get from 'lodash/get'
import {
  toQueryString,
  flattenArray,
  recursiveMap,
  getSelectedValues,
} from '../../utils'

export const isSupplyType = config => [48, 49, 96, 97].includes(config?.id)

export const getSupplyCategories = config => {
  if (isSupplyType(config))
    return config?.options?.values?.every(v => v.selected)
      ? undefined
      : config?.options?.values
  return undefined
}

export const getSurgeons = config =>
  config?.physicians?.every(p => p.selected) ? undefined : config?.physicians

export const toChartsParams = ({
  exclude_ip = null,
  exclude_op = null,
  icds = [],
  cpts = [],
  drgs = [],
  procedures = [],
  categories = [],
  time_periods = {},
  insurance_types = [],
  instrument_types = [],
  hospital_case_numbers = [],
  supply_manufacturer_numbers = [],
  supply_products = [],
  supply_categories = [],
  cancer_sites = [],
  comorbidities = [],
  histologies = [],
  procedure_details = [],
  stages = [],
  diagnosis_codes = [],
  risk_adjusted = null,
  has_quality_metric = null,
  all_inpatient = null,
  all_outpatient = null,
  date_saved = null,
  group_by_surgeon = false,
  group_by_organization = false,
  is_system = false,
  exclude_diagnosis_codes = false,
  is_oncology = null,
  exclude_oncology = false,
  is_robotics = null,
  exclude_robotics = null,
  surgeons = [],
  departments = [],
  supply_generic_names = [],
  raw_cpt = [],
  comparison_types = [],
}) => {
  const selected_start = isString(time_periods.selected_start)
    ? time_periods.selected_start
    : ''
  const selected_end = isString(time_periods.selected_end)
    ? time_periods.selected_end
    : ''
  const values = isArray(time_periods.values) ? time_periods.values : []
  const _exclude_ip = exclude_ip && exclude_op ? false : exclude_ip
  const _exclude_op = exclude_ip && exclude_op ? false : exclude_op

  const subDepartments = departments
    .map(department => department.sub_departments || [])
    .flat()
    .filter(subDepartment => subDepartment.selected)
    .map(subDepartment => subDepartment.id)
    .join()

  const procedureGroups = {procedure_group_ids: subDepartments}

  return {
    exclude_ip: _exclude_ip,
    exclude_op: _exclude_op,
    icd_ids: icds
      .filter(i => i.selected)
      .map(i => i.id)
      .join(),
    cpt_ids: cpts
      .filter(i => i.selected)
      .map(i => i.id)
      .join(),
    drg_ids: drgs
      .filter(i => i.selected)
      .map(i => i.id)
      .join(),
    procedure_ids: procedures
      .filter(i => i.selected)
      .map(i => i.id)
      .join(),
    chart_id: getSelectedValues(categories)
      ?.filter(c => c.selected)
      ?.map(c => c.id)
      .join(),
    time_period_id: values
      .filter(p => p.selected)
      .map(p => p.id)
      .join(),
    date_of_surgery_start: selected_start,
    date_of_surgery_end: selected_end,
    insurance_type_ids: insurance_types
      .filter(i => i.selected)
      .map(i => i.id)
      .join(),
    instrument_type_id: instrument_types
      .filter(i => i.selected)
      .map(i => i.id)
      .join(),
    cancer_sites: cancer_sites
      .filter(i => i.selected)
      .map(i => i.id)
      .join(),
    comorbidities: comorbidities
      .filter(i => i.selected)
      .map(i => i.id)
      .join(),
    histologies: histologies
      .filter(i => i.selected)
      .map(i => i.id)
      .join(),
    procedure_details: procedure_details
      .filter(i => i.selected)
      .map(i => i.id)
      .join(),
    stages: stages
      .filter(i => i.selected)
      .map(i => i.id)
      .join(),
    diagnosis_codes: flattenArray(diagnosis_codes)
      .filter(code => code.selected)
      .map(p => p.id)
      .join(),
    hospital_case_numbers: hospital_case_numbers.join(),
    supply_manufacturer_numbers: supply_manufacturer_numbers.join(),
    supply_product_names: supply_products
      .map(p => p.name?.replaceAll(',', '|'))
      .join(),
    supply_category_names: supply_categories
      .filter(c => c.selected)
      .map(c => c.name)
      .join(),
    supply_generic_names: supply_generic_names
      .filter(c => c.selected)
      .map(c => c.name)
      .join(),
    raw_cpt: raw_cpt.map(c => c?.replaceAll(',', '|')).join(),
    surgeon_ids: surgeons
      ?.filter(s => s.selected)
      ?.map(s => s.id)
      ?.join(),
    risk_adjusted,
    has_quality_metric,
    all_inpatient,
    all_outpatient,
    date_saved,
    group_by_surgeon: is_system ? group_by_surgeon : null,
    group_by_organization: is_system ? group_by_organization : null,
    exclude_diagnosis_codes,
    is_oncology,
    exclude_oncology,
    is_robotics,
    exclude_robotics,
    comparison_type_id: comparison_types
      .filter(c => c.selected)
      .map(c => c.id)
      .join(),
    department_ids: departments
      .filter(department => department.selected)
      .map(department => department.id)
      .join(),
    ...procedureGroups,
  }
}

export const toDashboardParams = ({
  departments = [],
  metrics = [],
  time_periods = {},
  risk_adjusted = null,
  date_saved = null,
  surgeons = [],
  is_department_trend = null,
  is_oncology = null,
  exclude_oncology = false,
  is_robotics = null,
  exclude_robotics = false,
  comparison_types = [],
  isNavV2 = false,
}) => {
  const selected_start = isString(time_periods.selected_start)
    ? time_periods.selected_start
    : ''
  const selected_end = isString(time_periods.selected_end)
    ? time_periods.selected_end
    : ''
  const values = isArray(time_periods.values) ? time_periods.values : []

  const subDepartments = departments
    ?.flatMap(item =>
      item?.sub_departments
        ?.filter(subDept => subDept.selected)
        .map(({id, name, selected}) => ({
          id,
          name,
          selected,
          type: 'procedure_groups',
        })),
    )
    .map(c => c?.id)
    .join()

  const procedureGroups = {procedure_group_ids: subDepartments}

  return {
    department_ids: departments
      .filter(c => c?.selected)
      .map(c => c?.id)
      .join(),
    ...procedureGroups,
    surgeon_id: surgeons
      .filter(s => s.selected && !is_department_trend)
      .map(s => s.id)
      .join(),
    surgeon_npi: surgeons
      .filter(s => s.selected && !is_department_trend)
      .map(s => s.npi)
      .join(),
    metric_id: metrics
      .filter(i => i.selected)
      .map(i => i.id)
      .join(),
    time_period_id: values
      .filter(p => p.selected)
      .map(p => p.id)
      .join(),
    date_of_surgery_start: selected_start,
    date_of_surgery_end: selected_end,
    is_department_trend,
    risk_adjusted,
    date_saved,
    is_oncology,
    exclude_oncology,
    is_robotics,
    exclude_robotics,
    comparison_type_id: comparison_types
      .filter(c => c.selected)
      .map(c => c.id)
      .join(),
  }
}

export const toSystemParams = ({
  metrics = [],
  time_periods = {},
  risk_adjusted = null,
  date_saved = null,
  is_oncology = null,
  exclude_oncology = false,
  departments = [],
}) => {
  const selected_start = isString(time_periods.selected_start)
    ? time_periods.selected_start
    : ''
  const selected_end = isString(time_periods.selected_end)
    ? time_periods.selected_end
    : ''
  const values = isArray(time_periods.values) ? time_periods.values : []

  const procedureGroups = {
    procedure_group_ids: departments
      .map(department => department.sub_departments || [])
      .flat()
      .filter(subDepartment => subDepartment.selected)
      .map(subDepartment => subDepartment.id)
      .join(),
  }

  return {
    metric_id: metrics
      .filter(i => i.selected)
      .map(i => i.id)
      .join(),
    time_period_id: values
      .filter(p => p.selected)
      .map(p => p.id)
      .join(),
    date_of_surgery_start: selected_start,
    date_of_surgery_end: selected_end,
    risk_adjusted,
    date_saved,
    is_oncology,
    exclude_oncology,
    department_ids: departments
      .filter(department => department.selected)
      .map(department => department.id)
      .join(),
    ...procedureGroups,
  }
}

export const toReportParams = ({
  time_periods = {},
  drgs = [],
  exclude_ip = null,
  exclude_op = null,
  icds = [],
  cpts = [],
  exclusions = [],
  outpatient_procedures = [],
  all_inpatient = null,
  all_outpatient = null,
  all_exclusions = null,
  date_saved = null,
}) => {
  const selected_start = isString(time_periods.selected_start)
    ? time_periods.selected_start
    : ''
  const selected_end = isString(time_periods.selected_end)
    ? time_periods.selected_end
    : ''
  const values = isArray(time_periods.values) ? time_periods.values : []
  return {
    drg_ids: drgs
      .filter(i => i.selected)
      .map(i => i.id)
      .join(),
    procedure_ids: [...drgs, ...outpatient_procedures, ...cpts, ...icds]
      .filter(i => i.selected && i.procedure_id != null)
      .map(i => i.procedure_id)
      .join(),
    outpatient_procedure_ids: outpatient_procedures
      .filter(i => i.selected)
      .map(i => i.id)
      .join(),
    exclude_ip,
    exclude_op,
    icd_ids: icds
      .filter(i => i.selected)
      .map(i => i.id)
      .join(),
    cpt_ids: cpts
      .filter(i => i.selected)
      .map(i => i.id)
      .join(),
    exclusion_ids: exclusions
      .filter(i => i.selected)
      .map(i => i.id)
      .join(),
    time_period_id: values
      .filter(p => p.selected)
      .map(p => p.id)
      .join(),
    date_of_surgery_start: selected_start,
    date_of_surgery_end: selected_end,
    all_inpatient,
    all_outpatient,
    all_exclusions,
    date_saved,
  }
}

export const toMiscodingParams = ({
  time_periods = {},
  drgs = [],
  exclude_ip = null,
  exclude_op = null,
  icds = [],
  cpts = [],
  miscodings = [],
  procedures = [],
  all_inpatient = null,
  all_outpatient = null,
  date_saved = null,
}) => {
  const selected_start = isString(time_periods.selected_start)
    ? time_periods.selected_start
    : ''
  const selected_end = isString(time_periods.selected_end)
    ? time_periods.selected_end
    : ''
  const values = isArray(time_periods.values) ? time_periods.values : []
  return {
    drg_ids: drgs
      .filter(i => i.selected)
      .map(i => i.id)
      .join(),
    procedure_ids: procedures
      .filter(i => i.selected)
      .map(i => i.id)
      .join(),
    exclude_ip,
    exclude_op,
    icd_ids: icds
      .filter(i => i.selected)
      .map(i => i.id)
      .join(),
    cpt_ids: cpts
      .filter(i => i.selected)
      .map(i => i.id)
      .join(),
    miscoding_ids: miscodings
      .filter(m => m.selected)
      .map(m => m.id)
      .join(),
    time_period_id: values
      .filter(p => p.selected)
      .map(p => p.id)
      .join(),
    date_of_surgery_start: selected_start,
    date_of_surgery_end: selected_end,
    all_inpatient,
    all_outpatient,
    date_saved,
  }
}

export const toInsightsParams = ({
  surgeons = [],
  opportunity_types = [],
  is_actionable = null,
  actionable_opportunity_types = [],
  departments = [],
}) => {
  const opportunity_type_id = Number(
    opportunity_types
      .filter(ot => ot.selected)
      .map(ot => ot.id)
      .join(),
  )
  let surgeon_ids
  if (opportunity_type_id === 1) {
    surgeon_ids = []
  } else if (opportunity_type_id === 2) {
    surgeon_ids = surgeons.map(surgeon => surgeon.id).join()
  } else {
    surgeon_ids = surgeons
      .filter(surgeon => surgeon.selected)
      .map(surgeon => surgeon.id)
      .join()
  }
  const departmentFilters = {
    department_ids: departments
      .filter(department => department.selected)
      .map(department => department.id)
      .join(),
    procedure_group_ids: departments
      .flatMap(department => department.sub_departments || [])
      .filter(subDepartment => subDepartment.selected)
      .map(subDepartment => subDepartment.id)
      .join(),
  }

  return {
    ...departmentFilters,
    surgeon_ids,
    is_actionable,
    actionable_opportunities: actionable_opportunity_types
      ?.filter(ot => ot.selected)
      ?.map(ot => ot?.name),
  }
}

export const toPatientVolumeParams = ({
  time_periods = {},
  views,
  arrives_ip_floor,
  hide_weekends,
  show_predicted,
}) => ({
  time_period_type: time_periods.time_period_type,
  time_period_value: time_periods.time_period_value,
  detail: !(views?.filter(v => v.selected)[0]?.id === 1),
  arrives_ip_floor,
  hide_weekends,
  show_predicted,
})

export const toDashboardV2Params = ({is_actionable}) => {
  return {
    is_actionable,
  }
}

export const toGoalsParams = ({departments}) => {
  return {
    department_ids: departments
      .filter(d => d.selected)
      .map(d => d.id)
      .join(),
  }
}

const params = {
  charts: toChartsParams,
  dashboard: toDashboardParams,
  system: toSystemParams,
  report: toReportParams,
  insightsV2: toInsightsParams,
  miscodings: toMiscodingParams,
  pacu: toPatientVolumeParams,
  goals: toGoalsParams,
  'dashboardV2.topOpportunities': toDashboardV2Params,
}

export const withBailOnLoading =
  (action, store = 'charts') =>
  (...args) =>
  (dispatch, getState) => {
    const {loading_data, loading, loading_filters, loading_metrics} =
      getState()[store]
    if (loading_data || loading || loading_filters || loading_metrics)
      return undefined
    return action(...args)(dispatch, getState)
  }

export const setQuery =
  (options = {}) =>
  (dispatch, getState) => {
    const store = isString(options.store) ? options.store : 'charts'
    const history = isObject(options.history)
      ? options.history
      : {push: () => {}}
    const exclude = isArray(options.omit) ? options.omit : []
    const overrides = isObject(options.overrides) ? options.overrides : {}
    const excludeAll = isBoolean(options.omit) ? options.omit : false
    const fetch = isBoolean(options.fetch) ? options.fetch : true
    const toParams = params[store]
    const is_system = getState().config.user.organizations.filter(
      o => o.selected && o.system,
    ).length
    const {
      filters,
      date_saved,
      all_inpatient,
      all_outpatient,
      all_exclusions,
      is_department_trend,
      supply_generic_names,
      group_by_surgeon,
      group_by_organization,
      opportunity_types,
      actionable_opportunity_types,
      views,
      arrives_ip_floor,
      hide_weekends,
      show_predicted,
      is_actionable,
      comparison_types,
      config,
    } = get(getState(), store)
    const supply_categories = getSupplyCategories(config)
    const surgeons = getSurgeons(config)
    const payload = excludeAll
      ? overrides
      : {
          surgeons,
          ...omit(filters, exclude),
          date_saved,
          all_inpatient,
          all_outpatient,
          all_exclusions,
          is_department_trend,
          group_by_surgeon,
          group_by_organization,
          opportunity_types,
          actionable_opportunity_types,
          views,
          arrives_ip_floor,
          hide_weekends,
          show_predicted,
          is_actionable,
          supply_categories,
          supply_generic_names,
          comparison_types,
          ...overrides,
        }

    const search = toQueryString(toParams({...payload, is_system}))

    const isPrev = search === history.location.search

    if (!isPrev) {
      if (fetch && isFunction(options.onLoading)) {
        dispatch(options.onLoading(true))
      }
      const url = `${history.location.pathname}${search}`
      history.push(url, {bail: !fetch})
    }
  }

export const withFacilityType = (filters, key = 'metrics') => ({
  ...filters,
  [key]: recursiveMap(filters[key], metric => {
    if (metric.name.includes('Hospital')) {
      return {...metric, name: metric.name.replace('Hospital', 'Facility')}
    }
    return metric
  }),
})

export const createComparisonTypes = (comparison_type_id = 1) => {
  const comparisonTypes = [
    {
      id: 1,
      name: 'All Organizations',
      selected: comparison_type_id === 1,
    },
    {
      id: 2,
      name: 'Similar Organizations',
      selected: comparison_type_id === 2,
    },
  ]
  return comparisonTypes
}

import groupBy from 'lodash/groupBy'
import {
  filterBySupplyCategory,
  groupByManufacturer,
  getRow,
  withRemoveOrganizationNames,
  isGroupBySurgeon,
} from './utils'
import {toCache} from '../../../../utils'

const withFacilityRemoved = create => config => {
  if (isGroupBySurgeon(config)) {
    return config?.table?.group_key
      ? create(config)
      : create(withRemoveOrganizationNames(config))
  }
  return create(config)
}

const createGroup = (data, {by, key, value}) => {
  const groups = groupBy(data, d => d[by])
  const grouped = Object.entries(groups).reduce((acc, [group, data]) => {
    const datum = {[by]: group}
    data.forEach(d => {
      datum[d[key]] = d[value]
    })
    return [...acc, datum]
  }, [])

  return grouped
}

const createGroupKeyData = config => {
  const row = getRow(config)
  const result = createGroup(config.data, {
    by: row.key,
    key: config.table.group_key,
    value: config.table.data_key,
  })
  return result
}

const createData = config => {
  if (config.id === 49) {
    const grouped = config.groupByManufacturer
      ? groupByManufacturer(config.data)
      : config.data
    const data = grouped.filter(
      filterBySupplyCategory(
        toCache(
          config?.options?.values?.filter(v => v.selected),
          'id',
        ),
      ),
    )
    return data
  }
  if (config.table?.group_key) {
    return createGroupKeyData(config)
  }
  return config.data
}

export default withFacilityRemoved(createData)

import React, {useRef, forwardRef, useImperativeHandle} from 'react'
import PropTypes from 'prop-types'
import {AgBox} from '@aghealth/ag-components'
import {Status} from './Status'
import {SizeEnum, StatusEnum} from './enums'

function AvatarInner(
  {name, src, size, status, className, active, children, ...rest},
  ref,
) {
  const avatarRef = useRef()

  useImperativeHandle(ref, () => ({
    focus: () => avatarRef.current.focus(),
  }))

  return (
    <AgBox ref={avatarRef} {...rest}>
      {children ? (
        children
      ) : (
        <>
          <img src={src} alt={name} width="100%" height="100%" />
          {typeof status === 'string' && <Status status={status} size={size} />}
        </>
      )}
    </AgBox>
  )
}

const Avatar = forwardRef(AvatarInner)
Avatar.displayName = 'Avatar'

Avatar.propTypes = {
  /** Primary content */
  children: PropTypes.node,

  /**
   * User name/nickname/full name for displaying initials and image alt description
   */
  name: PropTypes.string,

  /** Avatar image source */
  src: PropTypes.string,

  /** Size */
  size: PropTypes.oneOf(SizeEnum),

  /** Status. */
  status: PropTypes.oneOf(StatusEnum),

  /** Active */
  active: PropTypes.bool,

  /** Additional classes. */
  className: PropTypes.string,
}

AvatarInner.propTypes = Avatar.propTypes

AvatarInner.defaultProps = {
  name: '',
  src: '',
  size: 'md',
  active: false,
}

Avatar.defaultProps = AvatarInner.defaultProps

export {Avatar}
export default Avatar

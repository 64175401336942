import {batch} from 'react-redux'
import isArray from 'lodash/isArray'
import {getDateRange} from '../utils'
import {withBailOnLoading, setQuery} from './utils'
import {
  selectOnlyOneById,
  selectOnlyOneByIndex,
  selectOneById,
  everySelected,
  selectManyByIds,
  unselectManyByIds,
  unselectAll,
  selectAll,
  unselectOneById,
  fromQueryString,
  everyUnselected,
  getLeafNodes,
} from '../../utils'
import {
  SET_MISCODINGS_DATA,
  SET_MISCODINGS_LOADING,
  SET_MISCODINGS_NOT_LOADING,
  SET_MISCODINGS_LOADING_DATA,
  SET_MISCODINGS_NOT_LOADING_DATA,
  SET_MISCODINGS_ERROR,
  SET_MISCODINGS_FILTERS,
  SET_MISCODINGS_TIME_PERIODS,
  SET_MISCODINGS_SEARCH,
  SET_MISCODINGS_ALL_INPATIENT,
  SET_MISCODINGS_ALL_OUTPATIENT,
  SET_MISCODINGS_DRGS,
  SET_MISCODINGS_PROCEDURES,
  SET_MISCODINGS_DATE_SAVED,
  SET_MISCODINGS_ICDS,
  SET_MISCODINGS_CPTS,
  SET_MISCODINGS_EXCLUDE_IP,
  SET_MISCODINGS_EXCLUDE_OP,
  SET_MISCODINGS_MISCODINGS,
} from '../types'

import {fetchMiscodings} from '../../api'

const setFetchMiscodings =
  ({signal, initial, search}) =>
  dispatch => {
    if (initial) {
      dispatch(setLoading())
    } else {
      dispatch(setLoadingData())
    }
    return fetchMiscodings({signal, search})
      .then(({data, filters}) => {
        dispatch(setData(data))
        dispatch(setFilters(filters))
        if (initial) {
          dispatch(setNotLoading())
          dispatch(setDataNotLoading())
        } else {
          dispatch(setDataNotLoading())
        }
      })
      .catch(error => {
        dispatch(setError(error))
        if (initial) {
          dispatch(setNotLoading())
        } else {
          dispatch(setDataNotLoading())
        }
      })
  }

const setFilters = (payload, meta) => ({
  type: SET_MISCODINGS_FILTERS,
  payload,
  meta,
})

const setData = payload => ({type: SET_MISCODINGS_DATA, payload})

const setError = payload => ({type: SET_MISCODINGS_ERROR, payload})

const setLoading = () => ({type: SET_MISCODINGS_LOADING})

const setNotLoading = () => ({type: SET_MISCODINGS_NOT_LOADING})

const setLoadingData = () => ({type: SET_MISCODINGS_LOADING_DATA})

const setDataNotLoading = () => ({type: SET_MISCODINGS_NOT_LOADING_DATA})

const setTimePeriods = payload => ({type: SET_MISCODINGS_TIME_PERIODS, payload})

const setSearch = payload => ({type: SET_MISCODINGS_SEARCH, payload})

const setAllInpatient = payload => ({
  type: SET_MISCODINGS_ALL_INPATIENT,
  payload,
})

const setAllOutpatient = payload => ({
  type: SET_MISCODINGS_ALL_OUTPATIENT,
  payload,
})

const setDrgs = payload => ({type: SET_MISCODINGS_DRGS, payload})

const setProcedures = payload => ({type: SET_MISCODINGS_PROCEDURES, payload})

const setMiscodings = payload => ({type: SET_MISCODINGS_MISCODINGS, payload})

const setDateSaved = payload => ({type: SET_MISCODINGS_DATE_SAVED, payload})

const setIcds = payload => ({type: SET_MISCODINGS_ICDS, payload})

const setCpts = payload => ({type: SET_MISCODINGS_CPTS, payload})

const setExcludeIp = payload => ({type: SET_MISCODINGS_EXCLUDE_IP, payload})

const setExcludeOp = payload => ({type: SET_MISCODINGS_EXCLUDE_OP, payload})

const fetch =
  ({signal, search, initial, bail = false}) =>
  (dispatch, getState) => {
    const {
      date_saved: current_date_saved,
      all_inpatient: current_all_inpatient,
      all_outpatient: current_all_outpatient,
    } = getState().miscodings

    const {
      date_saved = false,
      all_inpatient = false,
      all_outpatient = false,
    } = fromQueryString(search, [
      'date_saved',
      'all_inpatient',
      'all_outpatient',
    ])
    batch(() => {
      if (date_saved !== current_date_saved) {
        dispatch(setDateSaved(date_saved))
      }
      if (all_inpatient !== current_all_inpatient) {
        dispatch(setAllInpatient(all_inpatient))
      }
      if (all_outpatient !== current_all_outpatient) {
        dispatch(setAllOutpatient(all_outpatient))
      }
    })
    if (!bail) {
      dispatch(setFetchMiscodings({signal, search, initial}))
    }
  }

const selectTimePeriod = (payload, history) => (dispatch, getState) => {
  const {time_periods: current_time_periods} = getState().miscodings.filters
  const time_periods = {
    ...current_time_periods,
    selected_start: payload.selected_start,
    selected_end: payload.selected_end,
    values: current_time_periods.values.map(selectOnlyOneById(payload.value)),
  }
  if (payload.value.id === 5 && payload.selected_start === '') {
    const {id} = current_time_periods.values.find(d => d.selected)
    const range = getDateRange(id, time_periods.max)
    dispatch(setTimePeriods({...time_periods, ...range}))
  } else {
    batch(() => {
      dispatch(setDateSaved(true))
      dispatch(setTimePeriods(time_periods))
      dispatch(
        setQuery({
          history,
          store: 'miscodings',
          onLoading: setLoadingData,
          overrides: {
            time_periods,
          },
        }),
      )
    })
  }
}

const toggleDrg = (payload, history) => (dispatch, getState) => {
  const {filters: current_filters, all_outpatient: current_all_outpatient} =
    getState().miscodings
  const {drgs: current_drgs, icds, cpts, procedures} = current_filters
  let drgs
  if (isArray(payload?.items)) {
    const nodes = getLeafNodes(payload)
    const payload_ids = nodes.map(p => p.id)
    drgs = current_drgs.map(
      nodes.every(p => p.selected)
        ? unselectManyByIds(payload_ids)
        : selectManyByIds(payload_ids),
    )
  } else {
    drgs = current_drgs.map(
      payload.selected ? unselectOneById(payload) : selectOneById(payload),
    )
  }
  const everyIpUnselected = everyUnselected(icds, drgs)
  const everyOpUnselected =
    everyUnselected(cpts, procedures) && !current_all_outpatient
  batch(() => {
    dispatch(setDrgs(drgs))
    if (everyIpUnselected && everyOpUnselected) {
      dispatch(setExcludeIp(false))
      dispatch(setExcludeOp(false))
    } else if (everyIpUnselected && !everyOpUnselected) {
      dispatch(setExcludeIp(true))
      dispatch(setExcludeOp(false))
    } else {
      dispatch(setExcludeIp(false))
      dispatch(setExcludeOp(everyOpUnselected))
    }
    dispatch(
      setQuery({
        history,
        store: 'miscodings',
        onLoading: setLoadingData,
      }),
    )
  })
}

const toggleProcedure = (payload, history) => (dispatch, getState) => {
  const {filters: current_filters, all_inpatient: current_all_inpatient} =
    getState().miscodings
  const {procedures: current_procedures, cpts, icds, drgs} = current_filters
  let procedures
  if (isArray(payload?.items)) {
    const nodes = getLeafNodes(payload)
    const payload_ids = nodes.map(p => p.id)
    procedures = current_procedures.map(
      nodes.every(p => p.selected)
        ? unselectManyByIds(payload_ids)
        : selectManyByIds(payload_ids),
    )
  } else {
    procedures = current_procedures.map(
      payload.selected ? unselectOneById(payload) : selectOneById(payload),
    )
  }
  const everyIpUnselected = everyUnselected(icds, drgs)
  const everyOpUnselected =
    everyUnselected(cpts, procedures) && !current_all_inpatient
  batch(() => {
    dispatch(setProcedures(procedures))
    if (everyIpUnselected && everyOpUnselected) {
      dispatch(setExcludeIp(false))
      dispatch(setExcludeOp(false))
    } else if (everyIpUnselected && !everyOpUnselected) {
      dispatch(setExcludeIp(true))
      dispatch(setExcludeOp(false))
    } else {
      dispatch(setExcludeIp(false))
      dispatch(setExcludeOp(everyOpUnselected))
    }
    dispatch(
      setQuery({
        history,
        store: 'miscodings',
        onLoading: setLoadingData,
      }),
    )
  })
}

const toggleMiscodings = (payload, history) => (dispatch, getState) => {
  const {miscodings: current_miscodings} = getState().miscodings.filters
  const miscodings = current_miscodings.map(
    payload.selected ? unselectOneById(payload) : selectOneById(payload),
  )
  batch(() => {
    dispatch(setMiscodings(miscodings))
    dispatch(
      setQuery({
        history,
        store: 'miscodings',
        onLoading: setLoadingData,
      }),
    )
  })
}

const toggleAllMiscodings = history => (dispatch, getState) => {
  const {miscodings: current_miscodings} = getState().miscodings.filters
  const everyMiscodingSelected = everySelected(current_miscodings)
  const miscodings = current_miscodings.map(
    everyMiscodingSelected ? unselectAll : selectAll,
  )
  batch(() => {
    dispatch(setMiscodings(miscodings))
    dispatch(
      setQuery({
        history,
        store: 'miscodings',
        onLoading: setLoadingData,
      }),
    )
  })
}

const toggleDateSaved = history => (dispatch, getState) => {
  const {date_saved: current_date_saved} = getState().miscodings
  const date_saved = !current_date_saved
  batch(() => {
    dispatch(setDateSaved(date_saved))
    dispatch(
      setQuery({
        history,
        store: 'miscodings',
        fetch: false,
        onLoading: setLoadingData,
      }),
    )
  })
}

const applyProcedures = (payload, history) => dispatch => {
  const everyIpUnselected =
    !payload.all_inpatient && everyUnselected(payload.icds, payload.drgs)
  const everyOpUnselected =
    !payload.all_outpatient && everyUnselected(payload.cpts, payload.procedures)
  batch(() => {
    dispatch(setDrgs(payload.drgs))
    dispatch(setCpts(payload.cpts))
    dispatch(setProcedures(payload.procedures))
    dispatch(setIcds(payload.icds))
    if (everyIpUnselected && everyOpUnselected) {
      dispatch(setExcludeIp(false))
      dispatch(setExcludeOp(false))
    } else if (everyOpUnselected && !everyIpUnselected) {
      dispatch(setExcludeOp(true))
      dispatch(setExcludeIp(false))
    } else if (everyIpUnselected && !everyOpUnselected) {
      dispatch(setExcludeIp(true))
      dispatch(setExcludeOp(false))
    } else {
      dispatch(setExcludeOp(everyOpUnselected))
      dispatch(setExcludeIp(everyIpUnselected))
    }
    dispatch(setAllOutpatient(payload.all_outpatient))
    dispatch(setAllInpatient(payload.all_inpatient))
    dispatch(
      setQuery({
        history,
        onLoading: setLoadingData,
        store: 'miscodings',
      }),
    )
  })
}
const applyMiscodings = (payload, history) => dispatch => {
  const miscodings = payload?._miscodings[0]?.items
  batch(() => {
    dispatch(setMiscodings(miscodings))
    dispatch(
      setQuery({
        history,
        store: 'miscodings',
        onLoading: setLoadingData,
      }),
    )
  })
}
const resetFilters = history => (dispatch, getState) => {
  const {
    icds: current_icds,
    drgs: current_drgs,
    cpts: current_cpts,
    miscodings: current_miscodings,
    procedures: current_procedures,
    time_periods: current_time_periods,
  } = getState().miscodings.filters
  const icds = current_icds.map(unselectAll)
  const drgs = current_drgs.map(unselectAll)
  const cpts = current_cpts.map(unselectAll)
  const miscodings = current_miscodings.map(unselectAll)
  const procedures = current_procedures.map(unselectAll)
  const time_periods = {
    ...current_time_periods,
    selected_start: '',
    selected_end: '',
    values: current_time_periods.values.map(selectOnlyOneByIndex(0)),
  }
  batch(() => {
    dispatch(setDateSaved(false))
    dispatch(setAllInpatient(false))
    dispatch(setAllOutpatient(false))
    dispatch(setTimePeriods(time_periods))
    dispatch(setIcds(icds))
    dispatch(setDrgs(drgs))
    dispatch(setCpts(cpts))
    dispatch(setMiscodings(miscodings))
    dispatch(setProcedures(procedures))
    dispatch(setDateSaved(false))
    dispatch(
      setQuery({
        history,
        store: 'miscodings',
        overrides: {
          cpts,
          icds,
          drgs,
          miscodings,
          procedures,
          all_inpatient: false,
          all_outpatient: false,
          date_saved: false,
        },
        onLoading: setLoadingData,
      }),
    )
  })
}

const toggleAllIpProcedures = history => (dispatch, getState) => {
  const {
    all_inpatient: current_all_inpatient,
    all_outpatient: current_all_outpatient,
    filters: current_filters,
  } = getState().miscodings
  const {cpts, procedures} = current_filters
  const all_inpatient = !current_all_inpatient
  const everyIpUnselected = !all_inpatient
  const everyOpUnselected =
    everyUnselected(cpts, procedures) && !current_all_outpatient
  batch(() => {
    if (everyIpUnselected && everyOpUnselected) {
      dispatch(setExcludeIp(false))
      dispatch(setExcludeOp(false))
    } else if (everyIpUnselected && !everyOpUnselected) {
      dispatch(setExcludeIp(true))
      dispatch(setExcludeOp(false))
    } else {
      dispatch(setExcludeIp(false))
      dispatch(setExcludeOp(everyOpUnselected))
    }
    dispatch(setAllInpatient(all_inpatient))
    dispatch(
      setQuery({
        history,
        store: 'miscodings',
        omit: ['icds', 'drgs'],
        onLoading: setLoadingData,
      }),
    )
  })
}

const toggleAllOpProcedures = history => (dispatch, getState) => {
  const {
    all_outpatient: current_all_outpatient,
    all_inpatient: current_all_inpatient,
    filters: current_filters,
  } = getState().miscodings
  const {icds, drgs} = current_filters
  const all_outpatient = !current_all_outpatient
  const everyOpUnselected = !all_outpatient
  const everyIpUnselected =
    everyUnselected(icds, drgs) && !current_all_inpatient
  batch(() => {
    if (everyIpUnselected && everyOpUnselected) {
      dispatch(setExcludeIp(false))
      dispatch(setExcludeOp(false))
    } else if (everyOpUnselected && !everyIpUnselected) {
      dispatch(setExcludeOp(true))
      dispatch(setExcludeIp(false))
    } else {
      dispatch(setExcludeOp(false))
      dispatch(setExcludeIp(everyIpUnselected))
    }
    dispatch(setAllOutpatient(all_outpatient))
    dispatch(
      setQuery({
        history,
        store: 'miscodings',
        omit: ['cpts', 'procedures'],
        onLoading: setLoadingData,
      }),
    )
  })
}

const toggleIcd = (payload, history) => (dispatch, getState) => {
  const {filters: current_filters, all_outpatient: current_all_outpatient} =
    getState().miscodings
  const {icds: current_icds, drgs, cpts, procedures} = current_filters
  let icds
  if (isArray(payload?.items)) {
    const nodes = getLeafNodes(payload)
    const payload_ids = nodes.map(p => p.id)
    icds = current_icds.map(
      nodes.every(p => p.selected)
        ? unselectManyByIds(payload_ids)
        : selectManyByIds(payload_ids),
    )
  } else {
    icds = current_icds.map(
      payload.selected ? unselectOneById(payload) : selectOneById(payload),
    )
  }
  const everyIpUnselected = everyUnselected(icds, drgs)
  const everyOpUnselected =
    everyUnselected(cpts, procedures) && !current_all_outpatient
  batch(() => {
    dispatch(setIcds(icds))
    if (everyIpUnselected && everyOpUnselected) {
      dispatch(setExcludeIp(false))
      dispatch(setExcludeOp(false))
    } else if (everyIpUnselected && !everyOpUnselected) {
      dispatch(setExcludeIp(true))
      dispatch(setExcludeOp(false))
    } else {
      dispatch(setExcludeIp(false))
      dispatch(setExcludeOp(everyOpUnselected))
    }
    dispatch(
      setQuery({
        history,
        store: 'miscodings',
        onLoading: setLoadingData,
      }),
    )
  })
}

const toggleCpt = (payload, history) => (dispatch, getState) => {
  const {filters: current_filters, all_inpatient: current_all_inpatient} =
    getState().miscodings
  const {cpts: current_cpts, procedures, icds, drgs} = current_filters
  let cpts
  if (isArray(payload?.items)) {
    const nodes = getLeafNodes(payload)
    const payload_ids = nodes.map(p => p.id)
    cpts = current_cpts.map(
      nodes.every(p => p.selected)
        ? unselectManyByIds(payload_ids)
        : selectManyByIds(payload_ids),
    )
  } else {
    cpts = current_cpts.map(
      payload.selected ? unselectOneById(payload) : selectOneById(payload),
    )
  }
  const everyIpUnselected =
    everyUnselected(drgs, icds) && !current_all_inpatient
  const everyOpUnselected = everyUnselected(cpts, procedures)
  batch(() => {
    dispatch(setCpts(cpts))
    if (everyOpUnselected && everyIpUnselected) {
      dispatch(setExcludeIp(false))
      dispatch(setExcludeOp(false))
    } else if (everyOpUnselected && !everyIpUnselected) {
      dispatch(setExcludeOp(true))
      dispatch(setExcludeIp(false))
    } else {
      dispatch(setExcludeOp(false))
      dispatch(setExcludeIp(everyIpUnselected))
    }
    dispatch(
      setQuery({
        history,
        store: 'miscodings',
        onLoading: setLoadingData,
      }),
    )
  })
}

const updateData = payload => (dispatch, getState) => {
  const {data: current_data} = getState().miscodings

  const data = current_data.map(d => {
    if (
      String(d.hospital_case_number) === String(payload.hospital_case_number)
    ) {
      return {
        ...d,
        reviewed: 'Reviewed',
        billing_resolution: payload.billing_resolution,
      }
    }
    return d
  })

  dispatch(setData(data))
}

const actions = {
  fetch,
  setSearch,
  toggleDateSaved: withBailOnLoading(toggleDateSaved, 'miscodings'),
  toggleIcd: withBailOnLoading(toggleIcd, 'miscodings'),
  toggleCpt: withBailOnLoading(toggleCpt, 'miscodings'),
  toggleAllIpProcedures: withBailOnLoading(toggleAllIpProcedures, 'miscodings'),
  toggleAllOpProcedures: withBailOnLoading(toggleAllOpProcedures, 'miscodings'),
  resetFilters: withBailOnLoading(resetFilters, 'miscodings'),
  selectTimePeriod: withBailOnLoading(selectTimePeriod, 'miscodings'),
  toggleDrg: withBailOnLoading(toggleDrg, 'miscodings'),
  toggleProcedure: withBailOnLoading(toggleProcedure, 'miscodings'),
  toggleMiscodings: withBailOnLoading(toggleMiscodings, 'miscodings'),
  toggleAllMiscodings: withBailOnLoading(toggleAllMiscodings, 'miscodings'),
  updateData,
  applyProcedures: withBailOnLoading(applyProcedures, 'miscodings'),
  applyMiscodings: withBailOnLoading(applyMiscodings, 'miscodings'),
}

export default actions

import groupBy from 'lodash/groupBy'
import {
  getCostId,
  isCostKey,
  isPercentKey,
  getPercentId,
  hasSupplyNames,
  hasSupplyCategories,
  hasManufacturerNames,
} from './utils'
import {orderList} from '../../../../utils'
import {format} from '../format'

const createSupplyNames = config =>
  config.supply_names.reduce((acc, s) => {
    acc[s.id] = format(s.name, 'supply_category')
    return acc
  }, {})

const createSupplyNameOptions = config => {
  const names = createSupplyNames(config)
  const mapKeyToSupplyName = key => names[getCostId(key)]
  const set = new Set()
  const keysSet = config.data?.reduce((acc, d) => {
    const keys = Object.keys(d)
    keys
      .filter(key => isCostKey(key) && d[key] !== null)
      .forEach(k => {
        acc.add(k)
      })
    return set
  }, set)
  const supplyKeys = [...keysSet]
  const values = supplyKeys.map((k, i) => ({
    key: k,
    title: mapKeyToSupplyName(k),
    format: '$',
    selected: true,
    id: k,
    name: mapKeyToSupplyName(k),
  }))
  const options = {
    ...config.options,
    values: orderList(values, 'name', config),
  }
  return options
}

const createSupplyCategoryOptions = config => {
  const categories = Object.values(
    groupBy(config.data, d => d.category_id),
  ).reduce((acc, value) => acc.concat(value[0]), [])
  const values = orderList(
    categories.map(c => ({
      id: c.category_id,
      title: format(c.category_name, 'supply_category'),
      name: format(c.category_name, 'supply_category'),
      format: '$',
      key: c.category_id,
      selected: true,
    })),
    'name',
    config,
  )

  return {title: 'Supply Categories', values}
}

export const createManufacturerNameOptions = (config, type = 'percent') => {
  const getId = type === 'percent' ? getPercentId : getCostId
  const isKey = type === 'percent' ? isPercentKey : isCostKey
  const names = config.manufacturer_names.reduce((acc, s) => {
    acc[s.id] = s.name
    return acc
  }, {})

  const mapKeyToName = key => names[getId(key)]
  const set = new Set()
  const keysSet = config.data?.reduce((acc, d) => {
    const keys = Object.keys(d)
    keys
      .filter(key => isKey(key) && d[key] !== null)
      .forEach(k => {
        acc.add(k)
      })
    return set
  }, set)
  const keys = [...keysSet]
  const values = keys.map((k, i) => ({
    key: k,
    title: mapKeyToName(k),
    format: type === 'percent' ? '%' : '$',
    selected: true,
    id: k,
    name: mapKeyToName(k),
  }))
  const options = {
    ...config.options,
    values: orderList(values, 'name', config),
  }
  return options
}

const createDefaultOptions = config => {
  const options = {
    ...config.options,
    values: orderList(
      config.options.values.map((v, i) => ({
        ...v,
        id: v.key,
        name: v.title,
      })),
      'name',
      config,
    ),
  }
  return options
}

const createOptions = config => {
  if (hasSupplyCategories(config)) return createSupplyCategoryOptions(config)
  if (hasSupplyNames(config)) return createSupplyNameOptions(config)
  if (hasManufacturerNames(config)) return createManufacturerNameOptions(config)
  return config.options ? createDefaultOptions(config) : undefined
}

export default createOptions

import isArray from 'lodash/isArray'
import isEmpty from 'lodash/isEmpty'
import isNumber from 'lodash/isNumber'

export const hasPhysicians = data =>
  isArray(data) && !isEmpty(data) && data[0].surgeon_name

export const hasGroupKey = config => config.chart?.y_scale?.group_key

export const hasOptions = config => !!config.options

export const hasSupplyNames = config => !!config.supply_names

export const hasManufacturerNames = config => !!config.manufacturer_names

export const hasSupplyCategories = config => config.data?.[0]?.category_id

export const toNumber = value => (isNumber(value) ? value : 0)

export const isCostKey = key => key.split('_')[0].includes('cost')

export const getCostId = key => key.split('cost_')[1]

export const isPercentKey = key => key.split('_')[0].includes('percent')

export const getPercentId = key => key.split('percent_')[1]

export const isGroupByOrganization = config =>
  config.isSystemView &&
  config.groupByOrganization &&
  config.data.some(d => d.organization_name)

export const isGroupBySurgeon = config =>
  config.isSystemView &&
  config.groupBySurgeon &&
  config.data.some(d => d.organization_name)

export const hasGroupBySurgeonName = d =>
  d.surgeon_name &&
  d.organization_short_name &&
  !(d.organization_short_name === d.surgeon_name)

const createGroupBySurgeonName = (d, key) =>
  hasGroupBySurgeonName(d) ? `${d[key]} (${d.organization_short_name})` : d[key]

const createGroupByOrganizationName = d => d.organization_name

export const withGroupByOrganizationNames = data =>
  data.map(d => ({
    ...d,
    id: d?.organization_id,
    name: createGroupByOrganizationName(d),
    organization_full_name: createGroupByOrganizationName(d),
    organization_short_name: createGroupByOrganizationName(d),
  }))

export const withGroupBySurgeonNames = data =>
  data.map(d => ({
    ...d,

    show_name: createGroupBySurgeonName(d, 'show_name'),
    hide_name: createGroupBySurgeonName(d, 'hide_name'),
    show_name_asterisk: createGroupBySurgeonName(d, 'show_name_asterisk'),
    hide_name_asterisk: createGroupBySurgeonName(d, 'hide_name_asterisk'),
  }))

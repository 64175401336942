import groupBy from 'lodash/groupBy'
import {toNumber} from './utils'
import {orderList} from '../../../../utils'

const createOrganizationsList = list =>
  Object.values(groupBy(list, o => o.organization_id)).reduce(
    (acc, value) => [
      ...acc,
      value.reduce(
        (acc, o) => ({
          ...o,
          case_count: acc.case_count + toNumber(o.case_count),
          selected: true,
          name: o?.organization_name,
          id: o?.organization_id,
        }),
        {case_count: 0},
      ),
    ],
    [],
  )

const createOrganizations = organizations => {
  const list = createOrganizationsList(organizations)
  return orderList(list, 'name')
}

export default createOrganizations

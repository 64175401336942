import {createLegendItems, withLegendPercentileLabels} from '../utils'

const SupplyStyles = {
  Root: {ml: '10px'},
  Label: {
    Root: {
      'data-testid': 'legend-label',
    },
    Text: {
      'data-testid': 'legend-label-text',
    },
  },
}

const createLegend = withLegendPercentileLabels((config, percentiles) => {
  const items = createLegendItems(config)
  const title = config.chart.legend_title
  const showLegend = !!items
  const legend = {
    items,
    title,
    isInteractive: [48, 96, 110].includes(config.id),
    styles: [48].includes(config.id) ? SupplyStyles : percentiles.styles,
    append: percentiles.append,
  }
  return {legend, showLegend}
})

export default createLegend

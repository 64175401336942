import React from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import {AgBox} from '@aghealth/ag-components'
import RadioButton from './RadioButton'

const checkIfSelected = item => {
  if (!item.values) return item.selected
  return item.values.some(checkIfSelected)
}

const RadioButtonGroup = ({onClick, items, styles}) => {
  if (isEmpty(items)) return null
  return (
    <AgBox sx={styles?.Root}>
      {items.map((item, i) => (
        <RadioButton
          key={item.id}
          id={item.id}
          onClick={onClick}
          selected={checkIfSelected(item)}
          name={item.name}
          type={item.type}
          values={item.values}
          styles={styles?.RadioButton}
        />
      ))}
    </AgBox>
  )
}

RadioButtonGroup.propTypes = {
  onClick: PropTypes.func,
  items: PropTypes.arrayOf(PropTypes.any),
  styles: PropTypes.object,
}

RadioButtonGroup.defaultProps = {
  onClick: () => {},
  items: [],
  styles: {},
}

export default RadioButtonGroup

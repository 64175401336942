import {computeDisplayKeys} from '../chart/utils'
import {isGroupBySurgeon, isGroupByOrganization} from './utils'
import {orderList} from '../../../../utils'

const withFacilityColumn = create => config => {
  const facility =
    isGroupBySurgeon(config) || isGroupByOrganization(config)
      ? {title: 'Facility', key: 'organization_full_name'}
      : undefined
  const columns = create(config)
  if (facility) {
    const [first, ...rest] = columns
    return [first, facility, ...rest]
  }
  return columns
}

const createSupplyCategoryColumns = config => {
  const columns = [...config.table.columns]
  if (config.groupBySupplyCategory) {
    columns[0] = {
      ...columns[0],
      key: columns[1].key,
      title: columns[1].title,
    }
    columns[1] = {
      ...columns[1],
      key: config.table.columns[0].key,
      title: config.table.columns[0].title,
    }
  }
  if (config.groupByManufacturer) {
    columns[0] = {
      ...columns[0],
      key: columns[3].key,
      title: columns[3].title,
    }
    columns.splice(3, 1)
  }
  return columns
}

const createGroupKeyColumns = config => {
  const keys = computeDisplayKeys(config, config.data)
  const start = config.table.columns
  const end = keys.map(key => ({
    title: key,
    key,
    format: config.table.data_format,
  }))
  const columns = [...start, ...end]
  return columns
}

const createSelectedColumns = config =>
  config.options && config.id !== 49
    ? orderList(
        config.options.values.filter(v => v.selected),
        'name',
        config,
      )
    : []

const createColumns = withFacilityColumn(config => {
  if (config.table?.group_key) {
    const columns = createGroupKeyColumns(config)

    return columns
  }
  if (config.id === 49) {
    const columns = createSupplyCategoryColumns(config)
    return columns
  }
  const selected = createSelectedColumns(config)
  const start = config.table.columns
  const end = config.table.end_columns ? config.table.end_columns : []
  const columns = [...start, ...selected, ...end]
  return columns
})

const createFormattedColumns = (config, format) => {
  const columns = createColumns(config)
  const formatted = format(columns)

  return isGroupByOrganization(config)
    ? formatted?.filter((_, i) => i !== 0)
    : formatted
}

export default createFormattedColumns

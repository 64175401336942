export const fontSizes = {
  xxxs: '8px',
  xxs: '10px',
  xs: '12px',
  sm: '14px',
  md: '16px',
  lg: '18px',
  xl: '20px',
  xxl: '24px',
  xxxl: '30px',
  xxxxl: '36px',
  xxxxxl: '48px',
  xxxxxxl: '60px',
  xxxxxxxl: '72px',
}

export const lineHeights = {
  xxxs: '14px',
  xxs: '16px',
  xs: '18px',
  sm: '20px',
  md: '24px',
  lg: '28px',
  xl: '30px',
  xxl: '32px',
  xxxl: '38px',
  xxxxl: '44px',
  xxxxxl: '60px',
  xxxxxxl: '72px',
  xxxxxxxl: '90px',
}

export const fonts = {
  primary: "'Roboto', sans-serif",
}

export const fontWeights = {
  light: 300,
  regular: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
}

import mean from 'lodash/mean'

const metrics = {
  mean,
}

const computeMarkerValue = (type, values) => {
  const measure = metrics[type]
  return measure ? measure(values) : values
}

const createMarkers = config => {
  const yAccessor = d => d[config.chart.y_accessor]
  const xAccessor = d => d[config.chart.x_accessor]
  const markers = config.chart.markers.map(m => ({
    axis: m.axis,
    label: m.label,
    labelDimension: 'x',
    value: computeMarkerValue(
      m.value,
      [4, 5].includes(config.id)
        ? config.unfiltered_data.map(
            'y'.includes(m.axis) ? yAccessor : xAccessor,
          )
        : config.data.map('y'.includes(m.axis) ? yAccessor : xAccessor),
    ),
    labelPosition: m.axis === 'y' ? 'top-right' : 'bottom',
  }))
  return markers
}
export default createMarkers

import React from 'react'
import PropTypes from 'prop-types'
import {AgFlex, AgText} from '@aghealth/ag-components'

const MarkerTooltip = ({label, value}) => (
  <AgFlex
    bg="#F8F8F8"
    border="md"
    borderColor="gray"
    borderRadius="base"
    width={250}
    flexDirection="row"
    boxShadow="tooltip"
    p={3}
  >
    <AgText>{label}</AgText>
    <AgText
      pl="10px"
    >
      {value}

    </AgText>
  </AgFlex>
)

MarkerTooltip.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
}

export default MarkerTooltip

import React from 'react'
import PropTypes from 'prop-types'
import {
  AgText,
  AgFlex,
  AgDatePicker,
  AgColumn,
  AgBox,
} from '@aghealth/ag-components'
import {toDatePickerFormat} from '../utils'
import {RadioButtonGroup, ToggleSwitch, InfoTooltip} from '.'

const styles = {
  RadioButton: {
    Input: {
      'data-testid': 'time-period',
    },
  },
}

const DatePicker = ({start, end, min, max, onApply}) => (
  <AgBox width="400px" bg="white" mt="10px">
    <AgDatePicker
      start={toDatePickerFormat(start)}
      end={toDatePickerFormat(end)}
      min={toDatePickerFormat(min)}
      max={toDatePickerFormat(max)}
      onApply={onApply}
      theme={{
        calendar: {
          extend: {
            width: '372px',
          },
          small: {
            daySize: '53.5px',
          },
        },
      }}
    />
  </AgBox>
)

DatePicker.propTypes = {
  start: PropTypes.string,
  end: PropTypes.string,
  min: PropTypes.string,
  max: PropTypes.string,
  onApply: PropTypes.func,
}

const TimePeriod = ({
  timePeriods,
  onClick,
  onToggleDateSaved,
  onApply,
  dateSaved,
  isCustomRange,
  disabled,
}) => {
  const handleOnApply = React.useCallback(
    ([start, end]) => {
      onApply({
        value: {id: 5},
        selected_start: start,
        selected_end: end,
      })
    },
    [onApply],
  )
  const handleOnClick = React.useCallback(
    selection => {
      onClick({
        value: selection,
        selected_start: '',
        selected_end: '',
      })
    },
    [onClick],
  )
  return (
    <AgColumn>
      <RadioButtonGroup
        items={timePeriods.values}
        onClick={handleOnClick}
        styles={styles}
      />
      {isCustomRange && (
        <DatePicker
          onApply={handleOnApply}
          start={timePeriods.selected_start}
          end={timePeriods.selected_end}
          min={timePeriods.min}
          max={timePeriods.max}
        />
      )}
      <AgFlex
        marginTop={isCustomRange ? '20px' : '10px'}
        marginRight="50px"
        marginBottom="10px"
        alignContent="center"
        width="100%"
        alignItems="center"
      >
        <AgText fontSize="14px" textAlign="center" mr="20px">
          Save Date Range
          <InfoTooltip text="Turn this on to keep a consistent date range when switching between charts, metrics, and sub-clinical areas." />
        </AgText>
        <ToggleSwitch
          onClick={onToggleDateSaved}
          on={dateSaved}
          styles={styles}
          disabled={disabled}
        />
      </AgFlex>
    </AgColumn>
  )
}

TimePeriod.propTypes = {
  timePeriods: PropTypes.shape({
    selected_start: PropTypes.string,
    selected_end: PropTypes.string,
    min: PropTypes.string,
    max: PropTypes.string,
    values: PropTypes.arrayOf(PropTypes.any),
  }),
  onClick: PropTypes.func,
  onApply: PropTypes.func,
  onToggleDateSaved: PropTypes.func,
  dateSaved: PropTypes.bool,
  isCustomRange: PropTypes.bool,
  disabled: PropTypes.bool,
}

export default TimePeriod

import React from 'react'
import PropTypes from 'prop-types'
import {AgText, AgRow, AgColumn} from '@aghealth/ag-components'
import Breadcrumbs from './Breadcrumbs'

const styles = {
  Root: {
    width: '100%',
    alignItems: 'center',
  },
  InnerBox: {
    width: '90%',
    minHeight: 125,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    bg: 'blue',
  },
}

const Page = ({title, crumbs, subtitle, children, onClickCrumb}) => (
  <AgColumn data-testid="page" sx={styles.Root}>
    <AgColumn sx={styles.InnerBox}>
      <Breadcrumbs
        crumbs={crumbs}
        onClick={onClickCrumb}
        ml={[5, 4]}
        mt="20px"
      />
      <AgText
        ml={[5, 4]}
        mt="15px"
        fontSize="28px"
        fontWeight="bold"
        lineHeight="33px"
        color="white"
        data-testid="page-title"
      >
        {title}
      </AgText>
      <AgText
        fontSize="14px"
        lineHeight="16px"
        color="#F8F8F8"
        ml={[5, 4]}
        mt="4px"
        marginBottom="15px"
      >
        {subtitle}
      </AgText>
    </AgColumn>
    <AgRow
      width="90%"
      height="100%"
      variant="boxes.scroll"
      bg="box-bg-blue"
      borderRadius="base"
    >
      {children}
    </AgRow>
  </AgColumn>
)

Page.propTypes = {
  children: PropTypes.node,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  crumbs: PropTypes.array,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onClickCrumb: PropTypes.func,
}

export default Page

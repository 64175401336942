import {getHeaders, baseURL} from './utils'

const profileEndpoint = `${baseURL}/api/profile`

export const fetchProfile = async options => {
  const {
    signal,
    method,
    body,
    onSuccess = () => {},
    onFail = () => {},
  } = options
  return fetch(`${profileEndpoint}`, {
    headers: await getHeaders(),
    signal,
    method,
    body,
  })
    .then(r => (method === 'GET' ? r.json() : Promise.resolve(r)))
    .then(async r => {
      if (method === 'PUT') {
        if (/2[0-9][0-9]/g.test(r.status)) {
          const json = await r.json()
          onSuccess(json)
        } else {
          onFail(r)
        }
      }
      return Promise.resolve(r)
    })
}

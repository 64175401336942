import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {FaPaperPlane} from 'react-icons/fa'
import {prefix} from './settings'
import Button from './Button'

export const SendButton = ({className, children, showIcon, ...rest}) => {
  const cName = `${prefix}-button--send`

  return (
    <Button
      {...rest}
      className={classNames(cName, className)}
      icon={showIcon ? <FaPaperPlane /> : null}
    >
      {children}
    </Button>
  )
}

SendButton.propTypes = {
  /** Primary content. */
  children: PropTypes.node,

  /** Additional classes. */
  className: PropTypes.string,
  showIcon: PropTypes.bool,
}

SendButton.defaultProps = {
  className: '',
}

export default SendButton

import React from 'react'
import PropTypes from 'prop-types'
import merge from 'lodash/merge'
import uniqueId from 'lodash/uniqueId'
import {AgRadioButton} from '@aghealth/ag-components'

const defaultStyles = {
  Root: {
    py: '8px',
  },
  Input: {
    'data-testid': 'radio-button',
    fill: '#129459',
    border: '2px solid',
    borderColor: (_, props) =>
      props.checked ? '#129459' : 'rgba(0, 0, 0, 0.54)',
  },
  Label: {
    ml: '11px',
  },
}

const RadioButton = React.memo(
  ({id, name, selected, type, onClick, values, styles: passedStyles}) => {
    const handleOnClick = React.useCallback(() => {
      onClick({
        id,
        name,
        selected,
        type,
        values,
      })
    }, [id, name, selected, type, onClick, values])
    const styles = React.useMemo(() => merge({}, defaultStyles, passedStyles), [
      passedStyles,
    ])
    const _id = React.useMemo(() => uniqueId(id), [id])
    return (
      <AgRadioButton
        id={_id}
        label={name}
        checked={selected}
        onClick={handleOnClick}
        styles={styles}
      />
    )
  },
)

RadioButton.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.node,
  selected: PropTypes.bool,
  type: PropTypes.string,
  onClick: PropTypes.func,
  values: PropTypes.array,
  styles: PropTypes.object,
}

RadioButton.defaultProps = {
  onClick: () => {},
  styles: {},
}

export default RadioButton

import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {AgButton} from '@aghealth/ag-components'
import {prefix} from './settings'

export const Button = ({
  children,
  className,
  icon,
  border,
  labelPosition,
  ...rest
}) => {
  const cName = `${prefix}-button`

  const lPos = typeof labelPosition !== 'undefined' ? labelPosition : 'right'
  const labelPositionClassName =
    React.Children.count(children) > 0 ? `${cName}--${lPos}` : ''
  const borderClassName = border === true ? `${cName}--border` : ''
  return (
    <AgButton
      bg="transparent"
      {...rest}
      className={classNames(
        cName,
        labelPositionClassName,
        borderClassName,
        className,
      )}
    >
      {lPos === 'left' && children}
      {icon}
      {lPos === 'right' && children}
    </AgButton>
  )
}

Button.propTypes = {
  /** Primary content */
  children: PropTypes.node,
  /** Additional classes. */
  className: PropTypes.string,
  icon: PropTypes.node,
  labelPosition: PropTypes.oneOf(['left', 'right']),
  border: PropTypes.bool,
}

Button.defaultProps = {
  children: undefined,
  className: '',
  icon: undefined,
  labelPosition: undefined,
  border: false,
}

export default Button

import * as domtoimage from 'dom-to-image'

export const measureText = (text, font = '14px Roboto') => {
  const context = document.createElement('canvas').getContext('2d')
  if (!context) return {width: 0, height: 0}
  context.font = font
  return context.measureText(text)
}

export const domToPng = (domNode, filename, options = {}) => {
  return domtoimage
    .toPng(domNode, options)
    .then(dataUrl => {
      const link = document.createElement('a')
      link.setAttribute('download', filename)
      link.setAttribute(
        'href',
        dataUrl.replace('image/png', 'image/octet-stream'),
      )
      link.click()
    })
    .catch(error => {
      console.error('Error capturing DOM to PNG:', error)
    })
}

import React from 'react'

export const noop = () => {}

export const isChildrenNil = children =>
  children === null ||
  children === undefined ||
  (Array.isArray(children) && children.length === 0)

export const getChildren = (children, types) => {
  const ret = []
  const strTypes = types.map(t => t.displayName || t.name)

  React.Children.toArray(children).forEach(item => {
    const idx = types.indexOf(item.type)
    if (idx !== -1) {
      ret[idx] = item
    } else {
      const is = item?.props?.as ?? item?.props?.is
      const typeofIs = typeof is
      if (typeofIs === 'function') {
        // Type
        const fIdx = types.indexOf(is)
        if (fIdx !== -1) {
          ret[fIdx] = React.cloneElement(item, {...item.props, as: null}) // Cloning to remove "as" attribute, which is not desirable
        }
      } else if (typeofIs === 'object') {
        // forward ref

        const typeName = is.name || is.displayName
        const tIdx = strTypes.indexOf(typeName)
        if (tIdx !== -1) {
          ret[tIdx] = React.cloneElement(item, {...item.props, as: null}) // Cloning to remove "as" attribute, which is not desirable
        }
      } else if (typeofIs === 'string') {
        const sIdx = strTypes.indexOf(is)
        if (sIdx !== -1) {
          ret[sIdx] = item
        }
      }
    }
  })

  return ret
}

export const getComponentName = component => {
  if (typeof component === 'string') {
    return component
  }

  if ('type' in component) {
    const componentType = typeof component.type

    if (componentType === 'function' || componentType === 'object') {
      if ('displayName' in component.type) {
        return component.type.displayName
      }

      if ('name' in component.type) {
        return component.type.name
      }
    } else if (componentType === 'string') {
      return component.type
    }

    return 'undefined'
  }

  return 'undefined'
}

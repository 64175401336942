import {toJson, getHeaders, baseURL} from './utils'

const endpoint = `${baseURL}/api/pacu_volumes`

export const fetchPatientVolumes = toJson(async options => {
  const {search, signal} = options
  return fetch(`${endpoint}`.concat(search), {
    headers: await getHeaders(),
    signal,
  })
})

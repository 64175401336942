import React from 'react'
import PropTypes from 'prop-types'
import merge from 'lodash/merge'
import {AgRelative, AgAbsolute} from '@aghealth/ag-components'

export const colors = {
  on: '#A0D4BC',
  off: '#666666',
}

const defaultStyles = {
  Root: {
    height: '20px',
    borderRadius: '10px',
    width: '50px',
    opacity: (_, props) => (props.disabled ? 0.4 : 1),
    bg: (_, props) => (props.isOn ? colors.on : colors.off),
  },
  Button: {
    cursor: (_, props) => (props.disabled ? 'default' : 'pointer'),
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    bg: (_, props) => (props.isOn ? '#129459' : '#F0F0F0'),
    right: (_, props) => (!props.isOn ? undefined : '-2.5px'),
    left: (_, props) => (!props.isOn ? '0px' : undefined),
    top: '-5px',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.5)',
  },
}

const ToggleSwitch = ({onClick, on, disabled, styles: _styles}) => {
  const styles = React.useMemo(
    () => merge({}, defaultStyles, _styles),
    [_styles],
  )
  return (
    <AgRelative
      isOn={on}
      disabled={disabled}
      onClick={() => (disabled ? () => {} : onClick(!on))}
      sx={styles.Root}
      data-testid="toggle-switch"
      data-toggled-on={on}
    >
      <AgAbsolute disabled={disabled} isOn={on} sx={styles.Button} />
    </AgRelative>
  )
}

ToggleSwitch.propTypes = {
  onClick: PropTypes.func,
  on: PropTypes.bool,
  disabled: PropTypes.bool,
  styles: PropTypes.object,
}

ToggleSwitch.defaultProps = {
  disabled: false,
  styles: {},
}

export default ToggleSwitch

import DOMPurify from 'dompurify'

function sanitizeUrl(r) {
  if (!r) return 'about:blank'
  var t = r
    // eslint-disable-next-line no-control-regex
    .replace(/[\u0000-\u001F\u007F-\u009F\u2000-\u200D\uFEFF]/gim, '')
    .trim()
  if (['.', '/'].indexOf(t[0]) > -1) return t
  var a = t.match(/^([^:]+):/gm)
  if (!a) return t
  var u = a[0]
  return /^([^\w]*)(javascript|data|vbscript)/im.test(u) ? 'about:blank' : t
}

if (window.trustedTypes && window.trustedTypes.createPolicy) {
  // Feature testing
  window.trustedTypes.createPolicy('default', {
    createHTML: string => {
      if (string.includes('https://player.vimeo.com/')) {
        return string
      }
      return DOMPurify.sanitize(string, {RETURN_TRUSTED_TYPE: true})
    },
    createScriptURL: string => sanitizeUrl(string),
    createScript: string => string,
  })
}

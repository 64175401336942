export const createOptionsOptions = config => {
  const series = config?.series?.map(s => ({
    format: '%',
    key: s?.id,
    title: s?.name,
    selected: true,
  }))
  const result = {
    title: config?.name?.split(' ')?.slice(0, 2)?.join(' '),
    values: series,
  }
  return result
}

import {createFormatTick, createAxisStyles} from '../utils'

const createAxes = config => {
  const bottomAxis = {
    tickFormat: config.chart.bottom_axis.tick_format,
    tickCount: config.chart.bottom_axis.tick_count,
    styles: createAxisStyles(config),
  }
  const leftAxis = {
    tickCount: 5,
    showGridLines: true,
    formatTick: createFormatTick(config, 'left_axis'),
    styles: createAxisStyles(config),
  }
  return {leftAxis, bottomAxis}
}

export default createAxes

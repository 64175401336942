import React from 'react'
import PropTypes from 'prop-types'
import {useCSVDownloader} from 'react-papaparse'
import {
  AgKebab,
  AgButton,
  AgFlex,
  AgImage,
  AgText,
} from '@aghealth/ag-components'
import download from '../../../assets/download.png'

const KebabStyles = {
  Menu: {
    mt: 1,
    border: 'base',
    borderRadius: 'sm',
    borderColor: 'btn-border-green',
    bg: '#FFFFFF',
    top: 'calc(0% + 40px)',
  },
  MenuItem: {
    fontSize: '14px',
    textAlign: 'left',
    px: 2,
    py: 2,
    whiteSpace: 'nowrap',
    bg: '#FFFFFF',
    color: 'btn-border-green',
    transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    '&:hover': {
      bg: 'btn-bg-green',
      color: '#FFFFFF',
    },
  },
}

const Trigger = () => (
  <AgButton
    fontSize="14px"
    marginLeft={20}
    height="44px"
    borderRadius="4px"
    border="base"
    bg="#FFFFFF"
    px="16px"
    borderColor="btn-border-green"
  >
    <AgFlex alignItems="center">
      <AgImage
        src={download}
        sx={{
          size: 18,
          filter:
            'brightness(0) saturate(100%) invert(36%) sepia(28%) saturate(6186%) hue-rotate(137deg) brightness(91%) contrast(86%)',
        }}
      />
      <AgText pl="5px" fontSize="14px" fontWeight="md" color="btn-bg-green">
        Download
      </AgText>
    </AgFlex>
  </AgButton>
)

const DownloadMenu = ({
  table,
  onDownloadChartImage,
  onDownloadCaseLevelData,
  download: isDownload,
  export: isExport,
}) => {
  const {CSVDownloader, Type} = useCSVDownloader()
  const kebabItems = React.useMemo(() => {
    const headers = table?.columns?.reduce((acc, c) => {
      if (table?.id === 48 && c?._key === 'hospital_case_number') {
        return [
          ...acc,
          {label: 'Hospital Case Number', key: c._key},
          {label: 'Date of Surgery', key: 'date_of_surgery'},
          {label: 'Surgeon Name', key: 'surgeon_name'},
        ]
      }
      return [...acc, {label: c.heading, key: c._key}]
    }, [])

    const kebabItems = []

    const chartData = {
      content: 'Chart',
      onClick: onDownloadChartImage,
    }
    // variable to not alter the original data source(deep copy)
    const data = JSON.parse(JSON.stringify(table.data))

    const csvData = data.reduce((acc, row) => {
      const newRow = {}
      headers.forEach(header => {
        newRow[header.label] = row[header.key]
      })
      return [...acc, newRow]
    }, [])

    const tableData = {
      content: (
        <CSVDownloader
          type={Type.Link}
          data={csvData}
          filename="table"
          style={{
            textDecoration: 'none',
            color: 'inherit',
            width: '100%',
            margin: '-8px',
            padding: '8px',
          }}
        >
          Table
        </CSVDownloader>
      ),
    }

    const casesData = {
      content: 'Case Level Data',
      onClick: onDownloadCaseLevelData,
    }
    if (table.id !== 49) {
      kebabItems.push(chartData)
    }

    if (isDownload) {
      kebabItems.push(tableData)
    }
    if (isExport) {
      kebabItems.push(casesData)
    }
    return kebabItems
  }, [
    table.columns,
    table.data,
    table.id,
    onDownloadChartImage,
    Type.Link,
    onDownloadCaseLevelData,
    isDownload,
    isExport,
  ])
  return (
    <AgKebab
      items={kebabItems}
      trigger={<Trigger />}
      styles={KebabStyles}
      context={document}
    />
  )
}

DownloadMenu.propTypes = {
  table: PropTypes.object,
  onDownloadChartImage: PropTypes.func,
  onDownloadCaseLevelData: PropTypes.func,
  export: PropTypes.bool,
  download: PropTypes.bool,
}

export default DownloadMenu

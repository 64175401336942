export const createBooleanBarChart = config => {
  const result = {
    bottom_axis: {
      tick_sublabel_accessor: 'case_count',
    },
    dimension: 'y',
    group_mode: 'stacked',
    key_accessor: 'surgeon_name',
    keys: config?.series?.map(s => s.id),
    left_axis: {
      tick_format: '%',
    },
    legend: config?.series?.map(s => s.name),
    max_value_accessor: 'total_spend',
    title: config?.name,
    type: 1,
    x_accessor: 'surgeon_name',
  }
  return result
}

export const createBooleanLineChart = config => {
  const result = {
    bottom_axis: {
      tick_count: 'month',
      tick_format: '%-b %-y',
    },
    left_axis: {
      tick_format: '%',
    },
    legend: config?.series?.slice(0, 1)?.map(s => s.name),
    title: config?.name,
    type: 2,
    x_scale: {
      format: '%-b %-y',
      key: 'date_of_surgery',
      parse: '%Y-%m',
      type: 'time',
    },
    y_scale: {
      keys: config?.series?.slice(0, 1)?.map(s => s.id),
      type: 'linear',
    },
  }
  return result
}

export const createBooleanMultiLineChart = config => {
  const result = {
    bottom_axis: {
      tick_count: 'month',
      tick_format: '%-b %-y',
    },
    left_axis: {
      tick_format: '%',
    },
    title: config?.name,
    type: 2,
    x_scale: {
      format: '%-b %-y',
      key: 'date_of_surgery',
      parse: '%Y-%m',
      type: 'time',
    },
    y_scale: {
      group_key: 'surgeon_name',
      keys: config?.series?.slice(0, 1)?.map(s => s.id),
      type: 'linear',
    },
  }
  return result
}

export const createNumericBarChart = config => {
  const result = {
    bottom_axis: {tick_sublabel_accessor: 'case_count'},
    dimension: 'y',
    key_accessor: 'surgeon_name',
    left_axis: {
      tick_format: config?.series
        ?.slice(0, 1)
        ?.map(s => s.format)
        .join(),
    },
    max_value_accessor: config?.series
      ?.slice(0, 1)
      ?.map(s => s.id)
      .join(),
    title: config?.name,
    type: 1,
    x_accessor: 'surgeon_name',
    y_accessor: config?.series
      ?.slice(0, 1)
      ?.map(s => s.id)
      .join(),
  }
  return result
}

export const createNumericScrollingBarChart = config => {
  const result = {
    dimension: 'x',
    key_accessor: 'hospital_case_number',
    left_axis: {
      tick_label_accessor: 'surgeon_name',
      tick_sublabel_accessor: 'hospital_case_number',
    },
    bottom_axis: {
      tick_format: config?.series
        ?.slice(0, 1)
        ?.map(s => s.format)
        .join(),
    },
    title: config?.name,
    type: 1,
    x_accessor: config?.series
      ?.slice(0, 1)
      ?.map(s => s.id)
      .join(),
    y_accessor: 'hospital_case_number',
    max_value_accessor: config?.series
      ?.slice(0, 1)
      ?.map(s => s.id)
      .join(),
  }
  return result
}

export const createNumericLineChart = config => {
  const result = {
    bottom_axis: {
      tick_count: 'month',
      tick_format: '%-b %-y',
    },
    left_axis: {
      tick_format: config?.series
        ?.slice(0, 1)
        ?.map(s => s.format)
        ?.join(),
    },
    title: config?.name,
    type: 2,
    x_scale: {
      format: '%-b %-y',
      key: 'date_of_surgery',
      parse: '%Y-%m',
      type: 'time',
    },
    y_scale: {
      keys: config?.series?.slice(0, 1)?.map(s => s.id),
      type: 'linear',
    },
  }
  return result
}

export const createNumericMultiLineChart = config => {
  const result = {
    bottom_axis: {
      tick_count: 'month',
      tick_format: '%-b %-y',
    },
    title: config?.name,
    left_axis: {
      tick_format: config?.series
        ?.slice(0, 1)
        ?.map(s => s.format)
        ?.join(),
    },
    type: 2,
    x_scale: {
      format: '%-b %-y',
      key: 'date_of_surgery',
      parse: '%Y-%m',
      type: 'time',
    },
    y_scale: {
      group_key: 'surgeon_name',
      keys: [
        config?.series
          ?.slice(0, 1)
          ?.map(s => s.id)
          .join(),
      ],
      type: 'linear',
    },
  }
  return result
}

export const createOptionsBarChart = config => {
  const result = {
    bottom_axis: {
      tick_sublabel_accessor: 'case_count',
    },
    dimension: 'y',
    group_mode: 'stacked',
    key_accessor: 'surgeon_name',
    max_value_accessor: config?.series
      ?.slice(0, 1)
      ?.map(s => s.id)
      .join(),
    left_axis: {
      tick_format: '%',
    },
    title: config?.name,
    type: 1,
    x_accessor: 'surgeon_name',
    x_axis_label: 'surgeon_name',
  }
  return result
}

export const createOptionsLineChart = config => {
  const result = {
    bottom_axis: {
      tick_count: 'month',
      tick_format: '%-b %-y',
    },
    left_axis: {
      tick_format: '%',
    },
    title: config?.name,
    type: 2,
    x_scale: {
      format: '%-b %-y',
      key: 'date_of_surgery',
      parse: '%Y-%m',
      type: 'time',
    },
    y_scale: {
      type: 'linear',
    },
  }
  return result
}

export const createOptionsMultiLineChart = config => {
  const result = {
    bottom_axis: {
      tick_count: 'month',
      tick_format: '%-b %-y',
    },
    left_axis: {
      tick_format: '%',
    },
    title: config?.name,
    type: 2,
    x_scale: {
      format: '%-b %-y',
      key: 'date_of_surgery',
      parse: '%Y-%m',
      type: 'time',
    },
    y_scale: {
      group_key: 'surgeon_name',
      keys: config?.series?.slice(0, 1)?.map(s => s.id),
      type: 'linear',
    },
  }
  return result
}

import React from 'react'
import {createPortal} from 'react-dom'
import PropTypes from 'prop-types'
import {
  AgButton,
  AgRow,
  AgText,
  ThumbsDown,
  ThumbsUp,
  usePortal,
} from '@aghealth/ag-components'
import merge from 'lodash/merge'
import isNumber from 'lodash/isNumber'
import FeedbackForm from './FeedbackForm'
import Modal from '../Modal'

const noop = () => {}

const FeedbackStepOne = ({onSend, onTransition, isPositive}) => {
  const transitionToStep2 = React.useCallback(() => {
    onTransition('step-2')
  }, [onTransition])
  const handleThumbsUp = React.useCallback(() => {
    transitionToStep2()
    onSend({step: 1, feedback_details: {is_positive: 1}})
  }, [transitionToStep2, onSend])
  const handleThumbsDown = React.useCallback(() => {
    transitionToStep2()
    onSend({step: 1, feedback_details: {is_positive: 0}})
  }, [onSend, transitionToStep2])

  return (
    <AgRow sx={{justifyContent: 'flex-end', height: '36px', mt: '6px'}}>
      <AgText
        sx={{variant: 'texts.sm-regular', color: 'new-gray.600', mr: '8px'}}
      >
        How is my answer?
      </AgText>
      <AgButton
        onClick={isNumber(isPositive) ? noop : handleThumbsUp}
        sx={{
          bg: isPositive === 1 ? 'green.100' : 'transparent',
          border: 'base',
          borderColor: isPositive === 1 ? 'success.500' : 'new-gray.300',
          size: '36px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '8px',
          boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
          cursor: isNumber(isPositive) ? 'default' : 'pointer',
          mr: '8px',
        }}
      >
        <ThumbsUp
          sx={{
            size: '20px',
            color: isPositive === 1 ? 'success.500' : '#595C5F',
          }}
        />
      </AgButton>
      <AgButton
        onClick={isNumber(isPositive) ? noop : handleThumbsDown}
        sx={{
          bg: isPositive === 0 ? 'error.100' : 'transparent',
          border: 'base',
          borderColor: isPositive === 0 ? 'error.500' : 'new-gray.300',
          size: '36px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '8px',
          boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
          cursor: isNumber(isPositive) ? 'default' : 'pointer',
        }}
      >
        <ThumbsDown
          sx={{
            size: '20px',
            color: isPositive === 0 ? 'error.500' : '#595C5F',
          }}
        />
      </AgButton>
    </AgRow>
  )
}

FeedbackStepOne.propTypes = {
  onSend: PropTypes.func,
  onTransition: PropTypes.func,
  isPositive: PropTypes.number,
}

const FeedbackStepTwo = ({onSend, onTransition}) => {
  const target = usePortal('chatbot-feedback-modal', document)
  const [open, setOpen] = React.useState(false)

  const handleOpen = React.useCallback(() => {
    setOpen(true)
  }, [])

  const handleClose = React.useCallback(() => {
    setOpen(false)
  }, [])

  const transitionToStep1 = React.useCallback(() => {
    onTransition('step-1')
  }, [onTransition])

  const handleOnSend = React.useCallback(
    options => {
      transitionToStep1()
      const feedback_details = merge({}, options)
      onSend({step: 2, feedback_details})
    },
    [onSend, transitionToStep1],
  )

  return (
    <AgRow
      sx={{
        justifyContent: 'flex-end',
        height: '36px',
        mt: '6px',
      }}
    >
      <AgText
        sx={{variant: 'texts.sm-regular', color: 'new-gray.600', mr: '8px'}}
      >
        Add a few details about your feedback?
      </AgText>
      <AgButton
        onClick={handleOpen}
        sx={{
          bg: 'transparent',
          mr: '8px',
          variant: 'texts.sm-bold',
          color: 'blue-dark.500',
          padding: 0,
        }}
      >
        Add details
      </AgButton>
      <AgButton
        onClick={transitionToStep1}
        sx={{
          bg: 'transparent',
          variant: 'texts.sm-bold',
          color: 'blue-dark.500',
          padding: 0,
        }}
      >
        No thanks
      </AgButton>
      {open &&
        createPortal(
          React.cloneElement(
            <Modal visible={open} onClose={handleClose} justifyContent="center">
              <FeedbackForm
                onClose={handleClose}
                onSendFeedback={handleOnSend}
              />
            </Modal>,
          ),
          target,
        )}
    </AgRow>
  )
}

FeedbackStepTwo.propTypes = {
  onSend: PropTypes.func,
  onTransition: PropTypes.func,
}

const steps = {
  'step-1': FeedbackStepOne,
  'step-2': FeedbackStepTwo,
}

const Feedback = ({onSend, messageId, feedbackId, isPositive}) => {
  const [step, setStep] = React.useState('step-1')

  const Step = React.useMemo(() => steps[step], [step])
  const handleOnTransition = React.useCallback(s => {
    setStep(s)
  }, [])
  const handleOnSend = React.useCallback(
    options => {
      const message_id = Number(messageId)
      const feedback_details = feedbackId
        ? merge({}, options?.feedback_details, {
            feedback_id: Number(feedbackId),
          })
        : options?.feedback_details
      const result = {
        step: options?.step,
        message_id,
        feedback_details,
      }
      onSend(result)
    },
    [onSend, messageId, feedbackId],
  )

  return (
    <Step
      onTransition={handleOnTransition}
      onSend={handleOnSend}
      isPositive={isPositive}
    />
  )
}

Feedback.propTypes = {
  onSend: PropTypes.func,
  messageId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  feedbackId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isPositive: PropTypes.number,
}

export default Feedback

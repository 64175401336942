import React from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import groupBy from 'lodash/groupBy'
import orderBy from 'lodash/orderBy'
import partition from 'lodash/partition'
import {AgColumn, AgSearchInput} from '@aghealth/ag-components'
import {useSearch} from '../hooks'
import {flattenArray} from '../utils'
import {CollapsibleRadioButtonGroup, ScrollY} from '.'

const defaultStyles = {
  SearchInput: {
    Root: {
      borderRadius: '4px',
    },
    Input: {
      py: '7.5px',
    },
    Icon: {
      size: '17.49px',
    },
  },
}

const buildList = searched => {
  const groups = groupBy(searched, s => JSON.stringify(s.parent))
  const parents = Object.entries(groups).reduce((acc, [p, values]) => {
    const parent = JSON.parse(p)
    return [...acc, {...parent, values}]
  }, [])
  const [roots, nonRoots] = partition(parents, p => !p.parent)
  let remaining = []
  if (nonRoots?.length > 0) {
    remaining = buildList(nonRoots)
  }
  return orderBy([...roots, ...remaining], b => b.id)
}

const SearchableCollapsibleRadioButtonGroup = ({
  list,
  onChange,
  styles,
  search,
}) => {
  const flattened = React.useMemo(
    () => flattenArray(list).filter(l => l.searchable),
    [list],
  )
  const [{searched, searchTerm}, {setSearchTerm, setSearched}] =
    useSearch(flattened)

  const handleOnSearch = React.useCallback(
    term => {
      setSearchTerm(term)
    },
    [setSearchTerm],
  )

  const handleOnChange = React.useCallback(
    item => {
      onChange(item)
    },
    [onChange],
  )

  const handleSetSearch = React.useCallback(
    item => {
      const current = searched.map(s => {
        if (s.id === item.id) {
          return {
            ...s,
            selected: true,
            values: s?.values?.map((v, i) => ({...v, selected: i === 0})),
          }
        }
        return {
          ...s,
          selected: false,
          values: s?.values?.map((v, i) => ({...v, selected: false})),
        }
      })
      setSearched(current)
      handleOnChange(item)
    },
    [handleOnChange, searched, setSearched],
  )
  const filtered = React.useMemo(() => buildList(searched), [searched])
  return (
    <AgColumn>
      {search && (
        <AgSearchInput
          data-testid="search-input"
          value={searchTerm}
          onChange={handleOnSearch}
          styles={defaultStyles.SearchInput}
          context={document}
        />
      )}

      {isEmpty(searchTerm) ? (
        <ScrollY maxHeight={300} mt="4px">
          {list.map(c => (
            <CollapsibleRadioButtonGroup
              key={c.id}
              label={c.name}
              items={c.values}
              onClick={handleOnChange}
              visible={c.values?.some(v => v.selected)}
              styles={styles}
            />
          ))}
        </ScrollY>
      ) : (
        <ScrollY maxHeight={300} mt="4px">
          {filtered.map(c => (
            <CollapsibleRadioButtonGroup
              key={c.id}
              label={c.name}
              items={c.values}
              onClick={handleSetSearch}
              visible
              styles={styles}
            />
          ))}
        </ScrollY>
      )}
    </AgColumn>
  )
}
SearchableCollapsibleRadioButtonGroup.propTypes = {
  onChange: PropTypes.func,
  list: PropTypes.arrayOf(PropTypes.any),
  styles: PropTypes.object,
  search: PropTypes.bool,
}

SearchableCollapsibleRadioButtonGroup.defaultProps = {
  search: true,
}

export default SearchableCollapsibleRadioButtonGroup

import React from 'react'
import PropTypes from 'prop-types'
import {AgFlex, AgText} from '@aghealth/ag-components'
import {measureText} from '../../../utils'
import SparkBar from './SparkBar'

const SparkBarColumn = ({max, value, formatter}) => {
  const {width} = measureText(formatter ? formatter(max) : max)
  return (
    <AgFlex height="100%" width="100%" alignItems="end">
      <AgFlex
        height="100%"
        width={width}
        justifyContent="flex-end"
        alignItems="center"
      >
        <AgText fontSize="16px" lineHeight="20px" marginRight={2}>
          {value === undefined || value === null
            ? formatter
              ? formatter(0)
              : 0
            : formatter
            ? formatter(value)
            : value}
        </AgText>
      </AgFlex>
      <SparkBar color="spark-blue" height={50} max={max} value={value} />
    </AgFlex>
  )
}

SparkBarColumn.propTypes = {
  max: PropTypes.number,
  value: PropTypes.number,
  formatter: PropTypes.func,
}

export default SparkBarColumn

import React from 'react'
import PropTypes from 'prop-types'
import merge from 'lodash/merge'
import {AgFlex} from '@aghealth/ag-components'
import WindowRedirect from './WindowRedirect'

const styles = {
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: 100,
}

const ErrorMessage = ({status, ...rest}) => {
  if (status === 401) {
    return <WindowRedirect />
  }
  if (status === 403) {
    return (
      <AgFlex {...merge(styles, rest)}>
        You do not have access to this product. Please contact
        support@avantgardehealth.com for more information.
      </AgFlex>
    )
  }
  if (status === 404) {
    return (
      <AgFlex {...merge(styles, rest)}>
        Page not found. Please contact support@avantgardehealth.com for more
        information.
      </AgFlex>
    )
  }
  if (status === 'NO ORGANIZATIONS') {
    return (
      <AgFlex {...merge(styles, rest)}>
        We are unable to determine which hospital to direct you to. Please
        contact support@avantgardehealth.com to clear your access.
      </AgFlex>
    )
  }
  return (
    <AgFlex {...merge(styles, rest)} data-testid="error-message">
      An error has occurred. Please try again later or contact
      support@avantgardehealth.com.
    </AgFlex>
  )
}

ErrorMessage.propTypes = {
  status: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default ErrorMessage

const colors = [
  '#53ADA2',
  '#F0F4CD',
  '#378DCE',
  '#ead6f1',
  '#ed796e',
  '#B7DD85',
  '#FB8B24',
  '#A89ED1',
  '#EBE684',
  '#FF7C70',
  '#5A4A9C',
  '#C2C695',
  '#DD1155',
  '#388DCE',
  '#E7BB4B',
  '#63A375',
  '#EAD6F1',
  '#719AC2',
  '#A8F9FF',
  '#EF9C6B',
  '#035763',
  '#32ADA2',
  '#FBA2C9',
  '#C9D84C',
  '#06BDD5',
  '#820263',
  '#DAFBE0',
  '#395C6B',
  '#A2B7C8',
  '#0D3B66',
  '#8D8E6E',
  '#555E68',
  '#C79A63',
  '#68A357',
  '#D64933',
  '#8896AB',
]

export default colors

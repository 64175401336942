import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {AgBox} from '@aghealth/ag-components'
import {StatusEnum, SizeEnum} from './enums'
import {prefix} from './settings'

export const Status = ({
  status,
  size,
  className,
  name,
  selected,
  children,
  ...rest
}) => {
  const cName = `${prefix}-status`
  const bullet = <AgBox className={`${cName}__bullet`}></AgBox>
  const named = name || children

  return (
    <AgBox
      {...rest}
      aria-selected={selected === true ? '' : null}
      className={classNames(
        cName,
        `${cName}--${size}`,
        `${cName}--${status}`,
        {[`${cName}--selected`]: selected},
        {[`${cName}--named`]: named},
        className,
      )}
    >
      {bullet}
      {named && (
        <AgBox className={`${cName}__name`}>{name ? name : children}</AgBox>
      )}
    </AgBox>
  )
}

Status.propTypes = {
  /** Primary content */
  children: PropTypes.node,

  /** Status. */
  status: PropTypes.oneOf(StatusEnum).isRequired,

  /** Size. */
  size: PropTypes.oneOf(SizeEnum),

  /** Name */
  name: PropTypes.node,

  /** Selected */
  selected: PropTypes.bool,

  /** Additional classes. */
  className: PropTypes.string,
}

Status.defaultProps = {
  size: 'md',
}

export default Status

import React from 'react'
import PropTypes from 'prop-types'
import {AgAlert} from '@aghealth/ag-components'
import {animated, useTransition} from 'react-spring'

const AnimatedToast = ({message, onRemove, duration}) => {
  const [show, setShow] = React.useState(true)

  const transitions = useTransition(show, {
    from: {opacity: 0, transform: 'translateY(100%)'},
    enter: {opacity: 1, transform: 'translateY(0)'},
    leave: {opacity: 0, transform: 'translateY(100%)'},
    onRest: () => !show && onRemove(), // Call onRemove when hide animation is done
  })

  // Trigger the leave animation before the component is unmounted
  React.useEffect(() => {
    // Assuming you might have some condition to trigger hiding,
    // otherwise you can directly set a timeout for demonstration
    const timeoutId = setTimeout(() => setShow(false), duration) // Adjust time as needed
    return () => clearTimeout(timeoutId)
  }, [duration])

  const handleOnClose = React.useCallback(() => {
    setShow(false)
    onRemove()
  }, [onRemove])

  return transitions(
    (styles, item) =>
      item && (
        <animated.div style={styles}>
          <AgAlert message={message} onClose={handleOnClose} />
        </animated.div>
      ),
  )
}

AnimatedToast.propTypes = {
  message: PropTypes.string,
  onRemove: PropTypes.func,
  duration: PropTypes.number,
}

export default AnimatedToast

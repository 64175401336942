export const StatusEnum = [
  'available',
  'unavailable',
  'away',
  'dnd',
  'invisible',
  'eager',
]

export const SizeEnum = ['xs', 'sm', 'md', 'lg', 'fluid']

export const MessageTypeEnum = ['html', 'text', 'image', 'custom']

const enums = {
  SizeEnum,
  StatusEnum,
  MessageTypeEnum,
}

export default enums

import orderBy from 'lodash/orderBy'
import {
  toJson,
  withMassageFilters,
  massageMetrics,
  baseURL,
  getHeaders,
} from './utils'

const endpoint = `${baseURL}/api/dashboard`

const massageDashboardMetrics = ({data}) =>
  Promise.resolve({
    data: data.map(metric => {
      const values = massageMetrics(metric.values)
      return {...metric, values}
    }),
  })

export const fetchDashboardMetrics = async options => {
  const {
    method = 'GET',
    onSuccess = () => {},
    onFail = () => {},
    body = {},
  } = options
  options = {...options, headers: await getHeaders()}

  const url = `${endpoint}/metrics`
  return method === 'GET'
    ? fetch(url, options)
        .then(r => r.json())
        .then(massageDashboardMetrics)
    : fetch(url, {
        ...options,
        body: JSON.stringify(body),
      }).then(r => {
        if (/2[0-9][0-9]/g.test(r.status)) {
          onSuccess(r)
        } else {
          onFail(r)
        }
        return Promise.resolve(r)
      })
}

export const fetchDashboardDetails = toJson(async options => {
  const {search, signal} = options
  return fetch(`${endpoint}/details`.concat(search), {
    signal,
    headers: await getHeaders(),
  })
})

export const fetchDashboardSurgeons = toJson(async options => {
  const {search, signal} = options
  return fetch(`${endpoint}/surgeons`.concat(search), {
    signal,
    headers: await getHeaders(),
  })
})

const withMassageDashboard = fetchCall => options =>
  fetchCall(options).then(response => {
    const result = {
      ...response,
      filters: {
        ...response.filters,
        surgeons: orderBy(response.filters.surgeons, d => d.name),
        metrics: massageMetrics(response.filters.metrics),
      },
    }
    return Promise.resolve(result)
  })

const _fetchDashboard = toJson(async options => {
  const {search, signal} = options
  return fetch(endpoint.concat(search), {headers: await getHeaders(), signal})
})

export const fetchDashboardProceduresSurgeons = async (options = {}) => {
  const {body} = options

  return fetch(`${endpoint}/procedures-surgeons`, {
    headers: await getHeaders(),
    method: 'POST',
    body: JSON.stringify(body),
  })
}

export const fetchDashboardRenderJob = async (options = {}) => {
  const {body} = options

  return fetch(`${endpoint}/render-job`, {
    headers: await getHeaders(),
    method: 'POST',
    body: JSON.stringify(body),
  })
}

export const fetchDashboardScorecards = toJson(async (options = {}) => {
  const {search} = options
  return fetch(`${endpoint}/scorecards`.concat(search), {
    headers: await getHeaders(),
  })
})

export const fetchDashboard = withMassageFilters(
  withMassageDashboard(_fetchDashboard),
)

const colors = {
  gray: '#F4F4F4',
  black: '#000000',
  white: '#FFFFFF',
  blue: '#1B263F',
  pink: '#F7065B',
  'link-blue': '#4A90E2',
  'spark-blue': '#B8E5F5',
}
colors['hover-green'] = '#E4FCEB'
colors['font-primary'] = '#041424'
colors['font-secondary'] = '#333333'
colors['bg-dark-gray'] = '#D9DBDE'
colors['border-dark-gray'] = '#A5AAB0'
colors['bg-gray'] = '#F4F4F4'
colors['border-gray'] = '#979797'
colors['btn-border-green'] = '#129459'
colors['btn-bg-green'] = '#129459'
colors['input-bg-gray'] = colors.gray
colors['input-border-gray'] = '#979797'
colors['input-border-green'] = '#42BD4B'
colors['input-bg-green'] = '#E2F5E3'
colors['box-border-gray'] = '#CCCCCC'
colors['box-bg-blue'] = '#ECEFF7'
colors['insights-box-bg-blue'] = '#DCE9F7'
colors['insights-box-bg-red'] = '#FBE1DA'
colors['insights-box-bg-green'] = '#E2EDE2'
colors['insights-table-border-gray'] = '#E7EDF0'
export default colors

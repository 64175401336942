import produce from 'immer'
import configs from '../../configs'

import {
  SET_CHATBOT_LOADING,
  SET_CHATBOT_MESSAGES,
  SET_CHATBOT_SHOW,
  SET_CHATBOT_ID,
  SET_CHATBOT_STEP_ID,
} from '../types'

const initialState = {
  loading: false,
  id: null,
  stepId: 0,
  messages: [],
  show: configs.chatbot.showChatbot,
}

const reducer = (state = initialState, action) => {
  if (action.type === SET_CHATBOT_LOADING) {
    return produce(state, draftState => {
      draftState.loading = action.payload
    })
  }
  if (action.type === SET_CHATBOT_MESSAGES) {
    return produce(state, draftState => {
      draftState.messages = action.payload
    })
  }
  if (action.type === SET_CHATBOT_SHOW) {
    return produce(state, draftState => {
      draftState.show = action.payload
    })
  }
  if (action.type === SET_CHATBOT_STEP_ID) {
    return produce(state, draftState => {
      draftState.stepId = action.payload
    })
  }
  if (action.type === SET_CHATBOT_ID) {
    return produce(state, draftState => {
      draftState.id = action.payload
    })
  }
  return state
}

export default reducer

import React from 'react'
import maximum from 'lodash/max'
import {MarkerTooltip} from '../../components'
import {hasPercentiles, createFormatter} from './utils'

const createPercentiles = config => {
  const markers = []
  const append = []
  let styles = {}
  let max
  const percentilesExist = hasPercentiles(config)
  if (percentilesExist) {
    config.percentiles.forEach(p => {
      const format = config.chart.left_axis
        ? createFormatter(config.chart.left_axis.tick_format)
        : null
      const tooltipValue = format
        ? format(p[Object.keys(p)[0]])
        : p[Object.keys(p)[0]]
        ? p[Object.keys(p)[0]].toFixed(2)
        : null
      if (p[Object.keys(p)[0]]) {
        let label = `${Object.keys(p)[0]}th National Percentile:`
        label =
          config?.comparisonType?.id === 2
            ? config?.organization_type_id === 1
              ? label?.replace('National', 'ASC')
              : label?.replace('National', 'Hospital')
            : label
        markers.push({
          tooltip: {
            context: {document, window},
            content: <MarkerTooltip label={label} value={tooltipValue} />,
            styles: {zIndex: 100},
            anchor: 'top',
          },
          value: p[Object.keys(p)[0]],
          axis: 'y',
          stroke: Object.keys(p)[0] === '50' ? '#F7065B' : '#B14AED',
        })
        let legendLabel = `${Object.keys(p)[0]}th National Percentile`
        legendLabel =
          config?.comparisonType?.id === 2
            ? config?.organization_type_id === 1
              ? legendLabel?.replace('National', 'ASC')
              : legendLabel?.replace('National', 'Hospital')
            : legendLabel
        append.push({
          id: `percentiles_${Object.keys(p)[0]}`,
          label: legendLabel,
          color: Object.keys(p)[0] === '50' ? '#F7065B' : '#B14AED',
          shape: 'line',
          disableSelected: true,
        })
      }
    })
    if (markers.length) {
      if (
        config.chart.left_axis &&
        config.chart.left_axis.tick_format === '%'
      ) {
        max = 100
      } else {
        const values = markers.map(m => m.value)
        values.push(
          maximum(config.data.map(d => d[config.chart.max_value_accessor])),
        )
        max = maximum(values)
      }
      styles = {
        Label: {
          Line: {strokeDasharray: 7},
          Text: {'data-testid': 'chart-legend-label'},
        },
      }
    }
  }
  const result = {
    markers,
    append,
    styles,
    max,
    exists: percentilesExist,
  }
  return result
}

export default createPercentiles

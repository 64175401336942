/* eslint-disable max-len */
import React from 'react'
import PropTypes from 'prop-types'
import {AgText} from '@aghealth/ag-components'
import styled from 'styled-components'
import {theme} from '../../../theme/Theme'

const Box = styled.div`
  background-color: ${theme.colors['bg-gray']};
  position: absolute;
  width: ${props => `${props.width}px`};
  top: ${props => `${props.top}px` || 0};
  left: ${props => `${props.left}px` || 0};
  z-index: 100000;
  border: 1px solid ${theme.colors['box-border-gray']};
  border-radius: 4px;
  box-shadow: ${theme.shadows.tooltip};
  padding-left: 8px;
  padding-right: 8px;
  &:after {
    content: '';
    display: block;
    position: absolute;
    top: calc(100% - 15px);
    left: calc(50% - 15px);
    width: 30px;
    height: 30px;
    background-color: ${theme.colors['bg-gray']};
    transform: rotate(45deg);
    border-bottom: 1px solid ${theme.colors['box-border-gray']};
    border-right: 1px solid ${theme.colors['box-border-gray']};
    box-shadow: 0 2 4px rgba(0, 0, 0, 0.3);
    z-index: -1;
  }
`

const CostBySupplyTooltip = ({left, top}) => {
  const ref = React.useRef()
  const [dimensions, setDimensions] = React.useState()
  React.useLayoutEffect(() => {
    if (ref && ref.current) {
      const {height, width} = ref.current.getBoundingClientRect()
      setDimensions({
        top: top - 15 - height,
        left: left - width / 2 + 11.5,
      })
    }
  }, [top, left])
  return (
    <Box ref={ref} width={300} {...dimensions}>
      <AgText
        color="font-primary"
        fontSize="14px"
        textAlign="left"
        lineHeight="16px"
        fontWeight="bold"
        py={2}
      >
        Multiple Column Sorting
      </AgText>
      <AgText
        color="font-primary"
        fontSize="base"
        textAlign="left"
        lineHeight="14px"
        py={2}
      >
        Sort multiple columns by clicking on the column you want to sort by
        first, then click on the next column to sort by that second column.
      </AgText>
      <AgText
        color="font-primary"
        fontSize="base"
        textAlign="left"
        lineHeight="14px"
        py={2}
      >
        A second click on a sorted column will change the ordering and a third
        click will remove the sorting from that column.
      </AgText>
    </Box>
  )
}

CostBySupplyTooltip.propTypes = {
  left: PropTypes.number,
  top: PropTypes.number,
}

export default CostBySupplyTooltip

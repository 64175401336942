export const SET_PACU_DATA = 'SET_PACU_DATA'
export const SET_PACU_LOADING = 'SET_PACU_LOADING'
export const SET_PACU_NOT_LOADING = 'SET_PACU_NOT_LOADING'
export const SET_PACU_LOADING_DATA = 'SET_PACU_LOADING_DATA'
export const SET_PACU_NOT_LOADING_DATA = 'SET_PACU_NOT_LOADING_DATA'
export const SET_PACU_ERROR = 'SET_PACU_ERROR'
export const SET_PACU_FILTERS = 'SET_PACU_FILTERS'
export const SET_SCHEDULED_CASE_VOLUME = 'SET_SCHEDULED_CASE_VOLUME'
export const SET_PACU_TIME_PERIODS = 'SET_PACU_TIME_PERIODS'
export const SET_PACU_PATIENT_TYPES = 'SET_PACU_PATIENT_TYPES'
export const SET_PACU_VIEWS = ' SET_PACU_VIEWS'
export const SET_PACU_SHOW_PREDICTED = 'SET_PACU_SHOW_PREDICTED'
export const SET_PACU_ARRIVES_IP_FLOOR = 'SET_PACU_ARRIVES_IP_FLOOR'
export const SET_PACU_HIDE_WEEKENDS = 'SET_PACU_HIDE_WEEKENDS'

import {createSelector} from 'reselect'
import {getCohortTypeFromOrganization} from './format'

export const selectIsSystemView = createSelector(
  state => state.config?.user?.organizations,
  organizations =>
    organizations?.filter(o => o.selected && o.system)?.length > 0,
)

export const selectIsDemoView = createSelector(
  state => state.config?.user?.organizations,
  organizations => organizations?.filter(o => o.selected && o.demo)?.length > 0,
)

export const selectDefaultDepartment = createSelector(
  state => state.config?.departments,
  departments =>
    departments?.find(department => department.default) || {id: undefined},
)

// https://amplitude.com/docs/sdks/analytics/browser/browser-sdk-2#initialize-the-sdk
export const selectAmplitudeUserId = createSelector(
  state => state.config.user,
  user => {
    return user?.id !== undefined ? `${user.id}`.padStart(5, '0') : undefined
  },
)

export const selectIsPAC403 = createSelector(
  state => state.pac,
  pac => pac && pac.error && pac.error.status === 403,
)

export const selectIsInFacilityType = createSelector(
  selectIsSystemView,
  state => state.config.user.organization_type_id,
  (isSystem, organization_type_id) => isSystem || organization_type_id === 1,
)

export const selectOrganization = createSelector(
  state => state.config.user.organizations,
  organizations => organizations.filter(o => o.selected)[0],
)

export const selectNoDepartments = createSelector(
  state => state.config.departments,
  departments => !departments?.length > 0,
)

export const selectChatbotUserName = createSelector(
  state => state.config.user,
  user => (user?.first_name ? `${user.first_name} ${user.last_name}` : 'You'),
)

export const selectHasChatbot = createSelector(
  state => state.config.feature_flags,
  feature_flags => feature_flags?.includes(11),
)

export const selectHasDashboardV2 = createSelector(
  state => state.config.feature_flags,
  feature_flags => feature_flags?.includes(13),
)

export const selectHasGoals = createSelector(
  state => state.config.feature_flags,
  feature_flags => feature_flags?.includes(14),
)

export const selectHasNavigationV2 = createSelector(
  state => state.config.feature_flags,
  feature_flags => feature_flags?.includes(15),
)

export const selectRefreshDate = createSelector(
  state => state.config.user.organizations,
  organizations =>
    organizations
      .filter(organization => organization.selected)
      .map(organization => organization.refresh_date)
      .join(),
)

export const selectIsStaff = createSelector(
  state => state?.config?.user,
  user => user?.is_staff,
)

export const selectDepartmentSubrouteRegex = createSelector(
  state => state.config.departments.map(department => department.param),
  departmentParams => departmentParams.join('|'),
)

export const selectHasProjectGuidanceTool = createSelector(
  state => state.config.feature_flags,
  feature_flags => feature_flags?.includes(16),
)

export const selectHasBilling = createSelector(
  state => state.config.feature_flags,
  feature_flags => feature_flags?.includes(5),
)

export const selectCohortType = createSelector(
  state => state?.config?.user?.organization_type_id,
  organization_type_id => getCohortTypeFromOrganization(organization_type_id),
)

export const selectOrganizationTypeId = createSelector(
  state => state.config.user.organization_type_id,
  organization_type_id => organization_type_id,
)

import React from 'react'
import PropTypes from 'prop-types'
import {AgFlex, AgBox} from '@aghealth/ag-components'

const DoubleScrollbar = ({children, columnsCount, showTopScrollBar}) => {
  const topScrollBar = React.useRef(null)
  const bottomScrollBar = React.useRef(null)
  const [width, setWidth] = React.useState('auto')
  const [count, setCount] = React.useState(0)

  React.useLayoutEffect(() => {
    if (
      showTopScrollBar &&
      count > 9 &&
      bottomScrollBar.current &&
      topScrollBar.current
    ) {
      topScrollBar.current.onscroll = () => {
        bottomScrollBar.current.scrollLeft = topScrollBar.current.scrollLeft
      }
      bottomScrollBar.current.onscroll = () => {
        topScrollBar.current.scrollLeft = bottomScrollBar.current.scrollLeft
      }
      setWidth(`${bottomScrollBar.current.scrollWidth}px`)
    } else {
      setWidth('auto')
    }
  }, [bottomScrollBar, topScrollBar, showTopScrollBar, count])

  React.useLayoutEffect(() => {
    setCount(columnsCount)
  }, [columnsCount])

  return (
    <>
      {showTopScrollBar && count > 9 && (
        <AgBox
          data-testid="top-scrollbar"
          ref={topScrollBar}
          width="100%"
          overflowX="scroll"
        >
          <AgBox height="0.5px" width={width} />
        </AgBox>
      )}
      <AgFlex
        data-testid="bottom-scrollbar"
        overflowX={columnsCount > 8 ? 'scroll' : 'visible'}
        width="100%"
        ref={bottomScrollBar}
      >
        {React.cloneElement(children)}
      </AgFlex>
    </>
  )
}

export default DoubleScrollbar

DoubleScrollbar.propTypes = {
  children: PropTypes.node,
  columnsCount: PropTypes.number,
  showTopScrollBar: PropTypes.bool,
}

import isNumber from 'lodash/isNumber'

const validate = formatFn => (value, digits) =>
  isNumber(value) ? formatFn(value, digits) : (value, digits)

export const toDollarString = validate((num, digits = 0) => {
  const isNegative = num < 0
  const prefix = isNegative ? '-' : ''
  const dollars = `$${Math.abs(num).toLocaleString(undefined, {
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  })}`
  return prefix.concat(dollars)
})
export const toPercentString = validate(
  (num, digits = 0) =>
    `${num?.toLocaleString(undefined, {
      minimumFractionDigits: digits,
      maximumFractionDigits: digits,
    })}%`,
)

export const toNumberString = validate((num, digits = 0) => {
  const isNegative = num < 0
  const prefix = isNegative ? '-' : ''
  const number = `${Math.abs(num).toLocaleString(undefined, {
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  })}`
  return prefix.concat(number)
})

export const toDecimalString = validate((num, digits = 2) => {
  const isNegative = num < 0
  const prefix = isNegative ? '-' : ''
  const roundedNum = Math.round(Math.abs(num) * 100) / 100 // round to two decimal places
  const number = `${roundedNum.toLocaleString(undefined, {
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  })}`
  return prefix.concat(number)
})

export const formatDashboardDetailsValues = (id, value) => {
  const format = {
    '-1': `${value} cases`,
    1: toDollarString(value),
    10: toDollarString(value),
    9: toDollarString(value),
    12: toPercentString(value),
    13: toPercentString(value),
    14: value,
    8: `${value} minutes`,
    15: toPercentString(value),
    7: `${value?.toLocaleString(undefined, {
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    })} days`,
    16: toPercentString(value),
    17: toPercentString(value),
    19: toPercentString(value),
    20: toPercentString(value),
    21: toPercentString(value),
  }
  return format[id]
}

export const formatNumberWithDecimalPlaces = (number, decimalPlaces) => {
  return parseFloat(number.toFixed(decimalPlaces))
}

export const getCohortTypeFromOrganization = organization_type_id => {
  if (organization_type_id === 1) return 2 // ASC
  if (organization_type_id === 2) return 3 // Hospital
  return 1 // Full Organization
}

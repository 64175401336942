import {toJson} from './utils'

export const fetchVideos = toJson(options => {
  const {signal} = options
  return fetch('https://api.vimeo.com/users/56770243/folders/3103617/videos', {
    signal,
    headers: {
      Authorization: `Bearer ${process.env.REACT_APP_VIMEO_TOKEN}`,
    },
  })
})

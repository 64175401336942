import isBoolean from 'lodash/isBoolean'
import {createFormatter, getChartFormat} from '../utils'

const computeShowStackedBarLabel = config => {
  if ([48, 96, 110, 113].includes(config.id)) return config.showNumbers
  const values = config?.options?.values
  const selected = values?.filter(v => v.selected)
  if (config?.chart?.left_axis?.tick_format === '%') {
    return config?.chart?.max_value_accessor
      ? true
      : ![values?.length, 1].includes(selected?.length)
  }
  return config.showNumbers && selected?.length > 1
}

const createLabels = config => {
  const chartFormat = getChartFormat(config)
  const formatBarLabel = createFormatter(chartFormat)
  const showStackedBarLabel = computeShowStackedBarLabel(config)
  const showBarLabel = isBoolean(config.chart.show_bar_label)
    ? config.showNumbers && config.chart.show_bar_label
    : config.showNumbers
  const title = config.chart.title ? config.chart.title : null
  const stackedBarLabelAccessor = d => {
    if (config.options && config.id === 48) {
      const selected_keys = config.options.values
        .filter(o => o.selected)
        .map(v => v.key)
      const result = selected_keys.reduce(
        (acc, key) => (d[key] ? acc + d[key] : acc),
        0,
      )
      return result
    }
    if (config.options && !config.options.values.every(o => o.selected)) {
      const selected_keys = config.options.values
        .filter(o => o.selected)
        .map(v => v.key)
      const result = selected_keys.reduce(
        (acc, key) => (d[key] ? acc + d[key] : acc),
        0,
      )
      return result
    }
    if (chartFormat === '%') return 100
    if (config.chart.max_value_accessor)
      return d[config.chart.max_value_accessor]

    return null
  }
  return {
    title,
    subtitle: config.id === 48 ? `${config.data.length} cases total` : '',
    formatBarLabel,
    formatStackedBarLabel: (value, d) =>
      config?.chart?.max_value_accessor && chartFormat === '%'
        ? createFormatter([110, 113].includes(config.id) ? '$' : null)(
            d?.[config.chart.max_value_accessor],
          )
        : formatBarLabel(value),
    showBarLabel,
    showStackedBarLabel,
    stackedBarLabelAccessor,
  }
}

export default createLabels
